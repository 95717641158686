import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  textField: {
    margin: '6px 0',
    padding: 0,
  },
  inputField: {
    padding: '12px 14px',
    fontSize: 14,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingRight: '8px',
    background: 'rgb(235, 237, 247)',
  },
  toolbarTitle: {
    flex: '1 0 auto',
    color: '#5c6bc0',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.75',
    letterSpacing: '0.00938em'
  }
});

class FlexItemList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [{
        id: 0,
        label: 'custom content 1',
        text: '',
        selected: false,
        error: false,
        errorLabel: ''
      }],
      lastId: 1,
      numSelected: 0
    }
  }

  onClickAddMoreButton(event) {
    const offset = this.state.lastId + 1
    const usedText = 'custom content ' + offset
    let newFields = this.state.fields.slice();
    newFields.push({
      id: offset,
      multiline: false,
      label: usedText,
      text: '',
      selected: false,
      error: false,
      errorLabel: ''
    })
    this.setState({fields: newFields, lastId: offset})
  }

  onClickCheckbox(event, fieldId) {
    if(this.isSelected(fieldId)) {
      this.unselectField(fieldId);
    } else {
      this.selectField(fieldId);
    }
  }

  onClickDeleteButton(event) {
    const newFields = this.state.fields.filter(x => x.selected === false)
    this.setState({fields: newFields, numSelected: newFields.filter(x => x.selected === true).length})
  }

  onClickSaveButton(event) {
    const fieldsToSave = this.state.fields.filter(x => x.selected === true)
    this.props.onSave(fieldsToSave);
  }

  onChangeText(event, fieldId) {
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    const newText = event.target.value;
    let newFields = [...this.state.fields];

    if(newText != '') {
      newFields[index].selected = true;
    } else {
      newFields[index].selected = false;
    }

    if(newText != '' && (this.props.data.includes(newText) || this.state.fields.filter(x => x.text == newText).length > 0)) {
      newFields[index].error = true
      newFields[index].errorLabel = 'already exists'
    } else {
      newFields[index].error = false
      newFields[index].errorLabel = ''
    }

    newFields[index].text = newText;

    this.setState({fields: newFields, numSelected:newFields.filter(x => x.selected === true).length});
  }

  isSelected(fieldId) {
    return this.state.fields.find(x => x.id == fieldId).selected
  }

  selectField(fieldId) {
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    let newFields = [...this.state.fields];
    newFields[index].selected = true;
    this.setState({fields: newFields, numSelected: this.state.fields.filter(x => x.selected === true).length});
  }

  unselectField(fieldId) {
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    let newFields = [...this.state.fields];
    newFields[index].selected = false;
    this.setState({fields: newFields, numSelected: this.state.fields.filter(x => x.selected === true).length});
  }

  hasSelected() {
    return this.state.numSelected > 0;
  }

  allowDelete() {
    return this.state.fields.length > this.state.numSelected;
  }

  allowSave() {
    const invalidFields = this.state.fields.filter(x => x.selected === true).filter(x => x.text === '' || x.error === true)

    return invalidFields.length === 0
  }

  render() {
    const { t, classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.toolbarTitle}>
            {t('customContents.addNewCustomContent')}
          </Typography>
          <Tooltip title="Add more" arrow>
            <div>
              <IconButton aria-label="Add more" onClick={event => {this.onClickAddMoreButton(event)}}>
                <PlaylistAddIcon/>
              </IconButton>              
            </div>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <div>
              <IconButton aria-label="Delete" disabled={this.hasSelected() && this.allowDelete() ? false : true} onClick={event => {this.onClickDeleteButton()}}>
                <DeleteIcon/>
              </IconButton>              
            </div>
          </Tooltip>
          <Tooltip title="Save" arrow>
            <div>
              <IconButton aria-label="Save" disabled={this.hasSelected() && this.allowSave() ? false : true} onClick={event => {this.onClickSaveButton()}}>
                <SaveIcon/>
              </IconButton>              
            </div>
          </Tooltip>
        </Toolbar>

        <Divider />
        <div>
          <List style={{padding: 0}}>
          {this.state.fields.map((field, index) => {
            return (
            <div key={`flexItem-${index}`}>
              <ListItem disabled={this.props.isPending}>
                <Checkbox checked={this.isSelected(field.id)} style={{padding: '8px 0'}} onChange={event => this.onClickCheckbox(event, field.id)}/>
                <div style={{ 'paddingLeft': '9px', 'paddingRight': '9px', 'width': '100%' }}>

                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  minRows="5"
                  maxRows="10"
                  fullWidth
                  autoFocus
                  placeholder={field.label}
                  value={field.text}
                  helperText={field.errorLabel}
                  error={field.error}
                  margin="none"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      input: classNames(classes.targetField, classes.inputField),
                    }
                  }}
                  className={classes.textField}
                  onChange={event => {this.onChangeText(event, field.id)}}
                />

                </div>
              </ListItem>
              <Divider />
            </div>
            )
          })}
          </List>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(withTranslation()(FlexItemList));
