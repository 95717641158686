import API from '../api/api'
import {_exportDownloadLinkDecorator, _getAttributeProgress} from '../helper'
import cloneDeep from 'lodash/cloneDeep';

const LOAD_METAOBJECT_DEFINITIONS_RESET_QUERY = 'LOAD_METAOBJECT_DEFINITIONS_RESET_QUERY'
const LOAD_METAOBJECT_DEFINITIONS_PENDING = 'LOAD_METAOBJECT_DEFINITIONS_PENDING'
const LOAD_METAOBJECT_DEFINITIONS_RESPONSE = 'LOAD_METAOBJECT_DEFINITIONS_RESPONSE'

const LOAD_METAOBJECTS_RESET_QUERY = 'LOAD_METAOBJECTS_RESET_QUERY'
const LOAD_METAOBJECTS_PENDING = 'LOAD_METAOBJECTS_PENDING'
const LOAD_METAOBJECTS_RESPONSE = 'LOAD_METAOBJECTS_RESPONSE'

const LOAD_METAOBJECT_PENDING = 'LOAD_METAOBJECT_PENDING'
const LOAD_METAOBJECT_RESPONSE = 'LOAD_METAOBJECT_RESPONSE'

const SAVE_METAOBJECTS_PENDING = 'SAVE_METAOBJECTS_PENDING'
const SAVE_METAOBJECTS_PROGRESS = 'SAVE_METAOBJECTS_PROGRESS'
const SAVE_METAOBJECTS_RESPONSE = 'SAVE_METAOBJECTS_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  load_metaobject_definitions_pending: false,
  load_metaobjects_pending: false,
  load_metaobject_pending: false,
  save_metaobjects_pending: false,
  metaobject_definitions: [],
  metaobjects: [],
  progress: 0,
  page_info: null,
  cursor: null,
  definitions_page_info: null,
  definitions_cursor: null,
  error: false,
  saveErrors: [],
  permissionUpdateNeeded: false,
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {

    case LOAD_METAOBJECT_DEFINITIONS_RESET_QUERY: {
      return {
        ...state,
        metaobject_definitions: [],
        definitions_page_info: null,
      }
    }
    case LOAD_METAOBJECT_DEFINITIONS_PENDING: {
      return {
        ...state,
        load_metaobject_definitions_pending: true,
      }
    }
    case LOAD_METAOBJECT_DEFINITIONS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          load_metaobject_definitions_pending: false,
          metaobject_definitions: [].concat(state.metaobject_definitions, action.response.metaobject_definitions), 
          definitions_page_info: action.response.page_info,
          definitions_cursor: action.response.page_info.has_next_page ? action.response.page_info.end_cursor : null
        }
      } else {
        return {
          ...state,
          load_metaobject_definitions_pending: false,
          definitions_page_info: null,
          definitions_cursor:  null,
          permissionUpdateNeeded: (action.response.error_tag && action.response.error_tag === 'ACCESS_DENIED') ? true : false,
          //error: {
          //  message: action.response,
          //  action: action.action,
          //  level: 1,
          //},
        }
      }
    }


    case LOAD_METAOBJECTS_RESET_QUERY: {
      return {
        ...state,
        metaobjects: [],
        page_info: null,
        errors: null,
        saveErrors: null,
      }
    }
    case LOAD_METAOBJECTS_PENDING: {
      return {
        ...state,
        load_metaobjects_pending: true
      }
    }
    case LOAD_METAOBJECTS_RESPONSE: {
      if(action.response.success === true) {
        const newMetaObjects = cloneDeep(state.metaobjects);
        action.response.metaobjects.forEach((metaObject) => {
          if(!newMetaObjects.find(x => x.shopify_id === metaObject.shopify_id)) {
            newMetaObjects.push(metaObject);
          }
        });
        return {
          ...state,
          load_metaobjects_pending: false,
          metaobjects: newMetaObjects,
          page_info: action.response.page_info,
          cursor: action.response.page_info.has_next_page ? action.response.page_info.end_cursor : null
        }
      } else {
        return {
          ...state,
          load_metaobjects_pending: false,
          //page_info: null,
          //cursor:  null,
          //error: {
          //  message: action.response,
          //  action: action.action,
          //  level: 1,
          //},
        }
      }
    }

    case LOAD_METAOBJECT_PENDING: {
      return {
        ...state,
        load_metaobject_pending: true
      }
    }
    case LOAD_METAOBJECT_RESPONSE: {
      //debugger;
      if(action.response.success === true) {
        var hadNoObjectToUpdate = false;
        var newMetaObjects = cloneDeep(state.metaobjects);
        var metaObjectToUpdate = newMetaObjects.find(x => x.shopify_id.indexOf(action.metaObjectId) >= 0);

        // 
        if(!metaObjectToUpdate) {
          hadNoObjectToUpdate = true;
          metaObjectToUpdate = {};
          metaObjectToUpdate.shopify_id = 'gid://shopify/Metaobject/' + action.metaObjectId;
          metaObjectToUpdate.id = action.metaObjectId;
          metaObjectToUpdate.display_name = action.response.metaobject.display_name;
        }

        metaObjectToUpdate.translations = cloneDeep(action.response.translations);
        metaObjectToUpdate.synchronized = true;
        metaObjectToUpdate.progress = _getAttributeProgress(action.response.translations, 'target');

        if(hadNoObjectToUpdate === true) {
          newMetaObjects.push(metaObjectToUpdate);
        }
        
        return {
          ...state,
          load_metaobject_pending: false,
          metaobjects: newMetaObjects
        }
      } else {
        return {
          ...state,
          load_metaobject_pending: false,
          //error: {
          //  message: action.response,
          //  action: action.action,
          //  level: 1,
          //},
        }
      }
    }


    case SAVE_METAOBJECTS_PENDING: {
      var newMetaObjects = cloneDeep(state.metaobjects);

      // Reset all errors
      let itemsWithErrors = newMetaObjects.filter(x => x.hasError === true);
      itemsWithErrors.forEach(item => {
        item.hasError = false;
        item.translations.forEach(translation => {
          translation.error = false;
        })
      })

      return {
        ...state,
        metaobjects: newMetaObjects,
        save_metaobjects_pending: true,
        saveErrors: null,
      }
    }
    case SAVE_METAOBJECTS_PROGRESS: {
      if(!action.item) {
        return {
          ...state,
          progress: action.progress
        }
      }
      return {
        ...state,
        //metaobjects: newMetaObjects
      }
    }
    case SAVE_METAOBJECTS_RESPONSE: {
      //debugger;
      var newMetaObjects = cloneDeep(state.metaobjects);
      var error = false;
      var newSaveErrors_ = [];
      var responses = action.response;

      //console.log(action.response, action.response.success === true, Array.isArray(action.response), Array.isArray(action.response) && action.response.every(x => x.success === true))
      if(!Array.isArray(responses)) {
        responses = [action.response]
      }
      
      for(var i=0; i<responses.length; i++) {
        let res = cloneDeep(responses[i]);
        if(res.requestBody) {      

          let metaObjectToUpdate = newMetaObjects.find(x => x.shopify_id == res.requestBody.resource_id);

          // Added translations
          if(res.added_translations) {
            // User errors
            if(res.added_translations.errors && res.added_translations.errors.errors) {
              if(res.added_translations.errors.errors.length > 0) {
                newSaveErrors_.push(cloneDeep(res.added_translations.errors))
                // Fields-Level
                metaObjectToUpdate.translations.forEach((translation) => {
                  translation.error = false;
                  // When a error occured for this field, set the last user input for 'target' and store the value in the 'restore' property
                  if(res.added_translations.errors.errors.find(x => x.object_key === translation.key)) {
                    translation.error = true;
                    const restoreValue = translation.target;
                    const requestBody = res.requestBody.translations.find(x => x.key === translation.key).value;
                    translation.target = requestBody ? requestBody : '';
                    translation.restore = restoreValue;
                    metaObjectToUpdate.hasError = true;
                  }
                });
              }
              // No Errors
              if(res.added_translations.errors.errors.length === 0) {                  
                metaObjectToUpdate.hasError = false;
                metaObjectToUpdate.translations.forEach((translation, index) => {
                  translation.error = false;
                });
              }
            }
            
            // Added translations
            if(res.added_translations.data.translationsRegister.translations.length > 0) {
              metaObjectToUpdate.translations.forEach(translation => {
                if(res.added_translations.data.translationsRegister.translations.find(x => x.key == translation.key)) {
                  translation.target = res.added_translations.data.translationsRegister.translations.find(x => x.key == translation.key).value;
                }
              });
            }
          }

          // Removed translations
          if(res.removed_translations) {
            if(res.removed_translations.data.translationsRemove.translations && res.removed_translations.data.translationsRemove.translations.length > 0) {
              metaObjectToUpdate.translations.forEach(translation => {
                if(res.removed_translations.data.translationsRemove.translations.find(x => x.key == translation.key)) {
                  translation.target = '';
                }
              });
            }
          }        
          
          metaObjectToUpdate.progress = _getAttributeProgress(metaObjectToUpdate.translations, 'target');
        }
      }

      return {
        ...state,
        metaobjects: newMetaObjects,
        save_metaobjects_pending: false,
        error: error,
        saveErrors: newSaveErrors_,
      }
    }

    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
        saveErrors: [],
      }
    }
    default:
      return state
  }
}




// ACTIONS CREATORS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const loadMetaObjectDefinitions = (filterObject, resetQuery = false, callback) => {  
  return dispatch => {
    if(resetQuery === true) {
      dispatch({
        type: LOAD_METAOBJECT_DEFINITIONS_RESET_QUERY
      })
    }

    dispatch({
      type: LOAD_METAOBJECT_DEFINITIONS_PENDING
    })

    API.metaObjectsDefinitions(
      filterObject,
      response => {
        dispatch({
          type: LOAD_METAOBJECT_DEFINITIONS_RESPONSE,
          response: response,
          action: () => loadMetaObjectDefinitions(filterObject, resetQuery, callback),
        });
        if(callback) {
          callback(response);
        }
      }
    )
  }
}

export const loadMetaObjects = (filterObject, resetQuery = false, callback) => {  
  return dispatch => {
    if(resetQuery === true) {
      dispatch({
        type: LOAD_METAOBJECTS_RESET_QUERY
      })
    }

    dispatch({
      type: LOAD_METAOBJECTS_PENDING
    })

    API.metaObjectsSearch(
      filterObject,
      response => {
        dispatch({
          type: LOAD_METAOBJECTS_RESPONSE,
          response: response,
          action: () => loadMetaObjects(filterObject, resetQuery),
        });
        if(callback) {
          callback(response);
        }
      }
    )
  }
}

export const loadMetaObject = (metaObjectId, languageId, callback) => {  
  return dispatch => {
    dispatch({
      type: LOAD_METAOBJECT_PENDING
    });

    API.metaObjectSync(
      metaObjectId,
      languageId,
      response => {
        dispatch({
          type: LOAD_METAOBJECT_RESPONSE,
          response: response,
          metaObjectId: metaObjectId,
          action: () => loadMetaObject(metaObjectId, languageId, callback),
        });
        if(callback) {
          callback(response);
        }
      }
    )
  }
}

export const saveMetaObjects = (languageId, items, callback) => {
  return dispatch => {
    dispatch({
      type: SAVE_METAOBJECTS_PENDING
    });

    API.metaObjectSave(
      languageId,
      items,
      (progress, item) => {
        dispatch({
          type: SAVE_METAOBJECTS_PROGRESS,
          progress: progress,
          item: item
        })
      },
      response => {
        dispatch({
          type: SAVE_METAOBJECTS_RESPONSE,
          response: response,
          items: items,
          action: () => saveMetaObjects(languageId, items, callback),
        });
        if(callback) {
          callback(response);
        }
      }
    )
  }
}
