import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Documentation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: '',
      papers: [
        {
          title: 'FAQ',
          subtext: 'Get answers on frequently asked questions',
          panels: [
            {
              title: 'Does langify work with my theme?',
              content: 'Absolutely!<br>langify works with every theme. If you need assistance with setting up langify with your theme simply reach out to us and we\'ll be more than happy to assist'
            },
            {
              title: 'I\'m not satisfied with the default language switcher! Do you provide language switcher customizations?',
              content: 'In almost all cases we can assist with this. Please get in touch with our support via support@langify-app.com'
            },
            {
              title: 'Does langify translate the products across the checkout and notifications?',
              content: 'Yes, as long as you have translated the product in langify the corresponding translation will show up across the checkout and the notifications. Make sure that you have translated your notifications in langify, too!'
            },
            {
              title: 'Can I translate my notifications?',
              content: 'Yes, you can translate all notifications in langify. Just note that notifications will only show up in langify if they\'ve been saved in your shopify backend.'
            },
            {
              title: 'Can I translate my shop policy and payment gateways?',
              content: 'Definitely! You can translate those in the "Shop -> Payment Gateways and Shop -> Policies" sections in langify. Just note that those will only show up in langify if they\'ve been saved in your shopify backend.'
            },
            {
              title: 'I\'ve installed some third party apps. Can I translate those apps in langify?',
              content: 'This depends on the app. Some apps come with a langify integration and can be translated out of the box. Other apps use so called metafields to store their content. Apps using metafields can be translated in langify. If all of that fails, we allow you to translate any kind of content via our custom content feature. That said almost all third part apps can be translated in langify in one way or another :)'
            },
          ]
        },
      ]
    };
  }

  setExpanded = function(panel) {
    this.setState({expanded: panel})
  }

  onChange = function(panel) {
    this.setState({expanded: panel})
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Documentation
        </Typography>
        <Divider className="divider-white" />
        
        {
          this.state.papers.map(paper => {
            return (
              <Paper className={classes.root} elevation="1">
                <Typography variant="h5" component="h3">
                  {paper.title}
                </Typography>
                <Typography className={classes.subtext}>
                  {paper.subtext}
                </Typography>

                {
                  paper.panels.map(panel => {
                    return (

                      <ExpansionPanel expanded={this.state.expanded === paper.title + panel.title} onChange={event => this.onChange(paper.title + panel.title)}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>{panel.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography className={classes.content} dangerouslySetInnerHTML={{__html:panel.content}}/>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>

                    )
                  })
                }
              </Paper>
            ) 
          })
        }

      </React.Fragment> 
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
    padding: 24,
  },
  subtext: {
    padding: 2,
    marginBottom: 10,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  content: {
    color: '#999'
  }
})

export default withStyles(styles)(Documentation)