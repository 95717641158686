import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: window.localStorage.getItem('ly-ui-language') ? window.localStorage.getItem('ly-ui-language') : 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    de: {
      translations: require('./locales/de/translations.json')
    },
    it: {
      translations: require('./locales/it/translations.json')
    },
    fr: {
      translations: require('./locales/fr/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'de', 'it', 'fr', 'es'];

export default i18n;