import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';



export const LocalHistory = {
  addHistoryEntry: function(id, language, value, oldValue) {
    // ly_/assets/ly-rtl.css.liquid_pt-PT_1519129853500
    const entryId = `ly_${id}_${language}_${Date.now()}`;

    if(oldValue) {
      if(this.getHistoryById(id, language).length === 0) {
        const originalEntryId = `ly_${id}_${language}_${Date.now()-3600}`;
        window.localStorage.setItem(originalEntryId, oldValue);
      }
    }
    if(!window.localStorage.getItem(entryId)) {
      window.localStorage.setItem(entryId, value);
    }

    const customEvent = new CustomEvent('langify:storage:added', { 
      detail: { 
        id: id, 
        language: language,
        value: value,
        oldValue: oldValue,
      } 
    });
    document.dispatchEvent(customEvent);
  },
  getHistoryById: function(id, language) {
    const localStorage = window.localStorage;
    const items = {...localStorage};
    let history = [];

    for(var item in items) {
      const entryId = `ly_${id}_${language}_`;
      if(item.indexOf(entryId) === 0) {
        const value = window.localStorage.getItem(item);
        history.push({
          id: item,
          timestamp: item.replace(entryId, ''),
          value: value
        });
      }
    }
    return history;
  },
  _relativeDays: function(timestamp) {
    const rtf = new Intl.RelativeTimeFormat('en', {
      dateStyle: 'full',
      timeStyle: 'long',
      numeric: 'auto',
      //unit: 'minute',
    });
    const oneDayInMs = 1000 * 60 * 60 * 24;
    const daysDifference = Math.round(
      (timestamp - new Date().getTime()) / oneDayInMs,
    );
  
    return rtf.format(daysDifference, 'day');
  }
}


const useStyles = makeStyles({
  root: {
    
  },
  formControl: {

  },
  selectRoot: {
    padding: '6px 32px 6px 9px',
  }
});


export default function LocaleHistorySelect(props, state) {
  const [history, setHistory] = useState(LocalHistory.getHistoryById(props.id, null));
  const [selectedVersion, setSelectedVersion] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const classes = useStyles();


	// onComponentDidMount
  useEffect(() => {
    //currentValue = props.currentValue;
    //console.log(currentValue);
    document.addEventListener('langify:storage:added', (event) => {
      setHistory(LocalHistory.getHistoryById(event.detail.id, event.detail.language));
    });
  }, [history, currentValue]);

	// onComponentDidUpdate
  useEffect(() => {
    if(currentValue === '' && props.currentValue !== '') {
      setCurrentValue(props.currentValue);
    }
  }, [props.currentValue]);

  const handleChange = (e) => {
    setSelectedVersion(e.target.value);

    if(props.onSelect && e.target.value !== '') {
      if(e.target.value === 'current') {
        props.onSelect({
          id: `ly_${props.id}_${props.language}_current`,
          timestamp: null,
          value: currentValue
        });
      } else {
        props.onSelect(history.find(x => x.timestamp === e.target.value));
      }
    }
  }


  return <span>
    {(history && history.length > 0) ? <FormControl variant="outlined" size="small" className={classes.formControl}>
      <Select
        id="local-history-select-outlined"
        value={selectedVersion}
        onChange={handleChange}
        displayEmpty
        classes={{
          root: classes.selectRoot
        }}
      >
        <MenuItem value="" disabled>
          <em>Load from locale history</em>
        </MenuItem>
        {history.sort((x, y) => y.timestamp - x.timestamp).map((entry, index) => {
          const timestamp = entry.id.split('_').pop();
          
          return <MenuItem value={timestamp}>
            {(index === 0) ?
              'Current'
            :
              <React.Fragment>
                {LocalHistory._relativeDays(timestamp)}
                <small> {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(timestamp)}</small>
              </React.Fragment>
            }
          </MenuItem>
        })}
      </Select>
    </FormControl>
  :
    null
  }
  </span>
}