import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import TranslationFields from './TranslationFields';

class VariantsFields extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      scroll: 'paper',
    };
  }
  

  handleClickOpen = scroll => (event) => {
    event.stopPropagation();
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
  };



  handleChange = (event, data) => {
    this.props.onChange(null, {
      ...data,
      parentField: 'variants'
    });
  }

  render() {
    const {
      classes,
      fields
    } = this.props;

    return (
      <React.Fragment>
        <Button 
          onClick={this.handleClickOpen('paper')} 
          size="medium"
          color="default"
          variant="outlined"
          className={classes.editBtn}
        >
          Edit variant translations
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll} 
          aria-labelledby="scroll-dialog-title"
          //maxWidth="md"
          fullWidth
        >
          <DialogTitle id="scroll-dialog-title">Edit Product Variants</DialogTitle>
          <DialogContent>
            <TranslationFields 
              data={fields} 
              nested
              isPending={this.props.isPending} 
              onChange={this.handleChange} 
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} size="small" variant="outlined" color="default">
              Cancel
            </Button>
            <Button onClick={this.handleClose} size="small" variant="outlined" color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  editBtn: {
    margin: '12px 0',
    /*
    marginTop: -20,
    position: 'absolute',
    padding: '0px 12px',
    minWidth: 0,
    */
  }
});

export default withStyles(styles)(VariantsFields);