import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { doSearch, cancelSearch, searchMoreProducts, searchMoreCollections } from '../modules/search'
import { syncProductByShopifyId, syncCollectionByShopifyId } from '../modules/sync_by_shopify_id'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import CollectionsIcon from '@material-ui/icons/CollectionsOutlined';
import LoadingIndicator from '../components/LoadingIndicator';
import Divider from '@material-ui/core/Divider';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({
  inputRoot: {
    color: 'inherit',
    width: '90%',
  },
  inputInput: {
    opacity: .5,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    opacity: .5,
  },
  closeIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    opacity: .5,
  },
  progressIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
  },
  progressIconCircle: {
    width: '20px !important',
    height: '20px !important',
    color: '#000 !important',
    opacity: .5,
  },
  searchResults: {
    overflowY: 'auto',
    position: 'fixed',
    top: 64,
    left: 240,
    width: 300,
    height: 'calc(100vh - 64px)',
    backgroundColor: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    //boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  black: {
    color: '#000'
  },
  loadMore: {
    '&:hover': {
    backgroundColor: "#5c6bc0",
    },
    '&:focus': {
    backgroundColor: "#5c6bc0",
    },
    textAlign: "center",
    backgroundColor: "#5c6bc0",
  },
  loadMoreText: {
    color: "#fff",
    fontSize: "0.875rem",
    fontWeight: "500"
  },
  subheader: {
    margin: "-10px"
  },
  noResults: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.875rem",
    fontWeight: "500",
    textAlign: "center",
    paddingTop: "5px",
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  listSubheader: {
    lineHeight: '38px',
  },
  listItemText: {
    whiteSpace: 'normal',
  }
});

class Search extends React.Component {

  constructor(props) {
    super(props);

    this.timer = null

    this.state = {
      query: '',
      opened: false,
      moreProductsAvailable: false,
      productsCursor: null,
      moreCollectionsAvailable: false,
      collectionsCursor: null,
      products: [],
      collections: [],
      pages: [],
      theme: [],
      customContents: [],
      links: [],
      tags: [],
      types: [],
      vendors: []
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentWillReceiveProps = (newProps) => {
    if(this.props.search.pending === true && newProps.search.pending === false) {
        const newProducts = [];
        if(newProps.search.results.products) {
          newProps.search.results.products.results.forEach(result => {
            newProducts.push({
              id: result.id,
              title: result.title
            })
          })
        }
      
        const newCollections = [];
        if(newProps.search.results.collections) {
          newProps.search.results.collections.results.forEach(result => {
            newCollections.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newPages = [];
        if(newProps.search.results.pages) {
          newProps.search.results.pages.results.forEach(result => {
            newPages.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newTheme = [];
        if(newProps.search.results.theme) {
          newProps.search.results.theme.results.forEach(result => {
            newTheme.push({
              id: result.id,
              path: result.path,
              title: result.title
            })
          })
        }

        const newCustomContents = [];
        if(newProps.search.results.custom_contents) {
          newProps.search.results.custom_contents.results.forEach(result => {
            newCustomContents.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newLinks = [];
        if(newProps.search.results.links) {
          newProps.search.results.links.results.forEach(result => {
            newLinks.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newTags = [];
        if(newProps.search.results.tags) {
          newProps.search.results.tags.results.forEach(result => {
            newTags.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newTypes = [];
        if(newProps.search.results.types) {
          newProps.search.results.types.results.forEach(result => {
            newTypes.push({
              id: result.id,
              title: result.title
            })
          })
        }

        const newVendors = [];
        if(newProps.search.results.vendors) {
          newProps.search.results.vendors.results.forEach(result => {
            newVendors.push({
              id: result.id,
              title: result.title
            })
          })
        }

        this.setState({
          moreProductsAvailable: newProps.search.results.products ? newProps.search.results.products.hasNextPage : false,
          productsCursor: newProps.search.results.products ? newProps.search.results.products.cursor : null,
          products: newProducts,
          pages: newPages,
          moreCollectionsAvailable: newProps.search.results.collections ? newProps.search.results.collections.hasNextPage : false,
          collectionsCursor: newProps.search.results.collections ? newProps.search.results.collections.cursor : null,
          collections: newCollections,
          theme: newTheme,
          customContents: newCustomContents,
          links: newLinks,
          tags: newTags,
          types: newTypes,
          vendors: newVendors
        })
    }

    if(this.props.syncByShopifyId.pending === true && newProps.syncByShopifyId.pending === false) {
      this.setState({
        opened: false, 
        results: []})
      if(newProps.syncByShopifyId.result.type === 'product') {
        this.props.history.push('/products/' + newProps.syncByShopifyId.result.id)
      } else if(newProps.syncByShopifyId.result.type === 'collection') {
        this.props.history.push('/collections/' + newProps.syncByShopifyId.result.id)
      }
    }
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        opened: false
      });
    }
  }

  cancelSearch = () => {
      this.props.cancelSearch()
      this.setState({
        query: '',
        opened: false,
        moreProductsAvailable: false,
        productsCursor: null,
        moreCollectionsAvailable: false,
        collectionsCursor: null,
        products: [],
        collections: [],
      })
  }

  openMobileSearch = () => {
    this.setState({
      opened: true
    });
  }

  onChange = (event) => {
    if(this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(() => {
      this.props.doSearch(this.props.theme, this.state.query)
    }, 500);
    //this.setState({})

    const query = event.target.value
    if(query.trim() === '') {
      this.cancelSearch()
    } else {
      this.setState({opened: true, query:query})
    }
  }

  onClose = () => {
    this.cancelSearch()
  }

  onFocus = () => {
    this.setState({
      opened: true,
    });
  }

  loadMoreProducts = () => {
    if(this.state.moreProductsAvailable && this.state.productsCursor) {
      this.props.searchMoreProducts(this.state.query, this.state.productsCursor)
    }
  }

  loadMoreCollections = () => {
    if(this.state.moreCollectionsAvailable && this.state.collectionsCursor) {
      this.props.searchMoreCollections(this.state.query, this.state.collectionsCursor)
    }
  }

  onClickProduct = (item) => {
    this.props.syncProductByShopifyId(item.id)
  }

  onClickCollection = (item) => {
    this.props.syncCollectionByShopifyId(item.id)
  }

  onClickPage = (item) => {
    this.setState({
      opened: false, 
      results: []
    })

    this.props.history.push('/pages/' + item.id)
  }

  onClickTheme = (item) => {
    this.setState({
      opened: false, 
      results: []
    })

    this.props.history.push('/theme/' + this.props.theme + '/' + item.id)
  }

  onClickCustomContent = (item) => {
    this.setState({
      opened: false, 
      results: []
    })

    this.props.history.push('/custom/' + item.id)
  }

  onClickLink = (item) => {
    this.setState({
      opened:false,
      resutls: []
    })

    this.props.history.push('/navigation/' + item.id)
  }

  onClickTag = (item) => {
    this.setState({
      opened:false,
      resutls: []
    })

    this.props.history.push('/products/tags')
  }

  onClickType = (item) => {
    this.setState({
      opened:false,
      resutls: []
    })

    this.props.history.push('/products/types')
  }

  onClickVendor = (item) => {
    this.setState({
      opened:false,
      resutls: []
    })

    this.props.history.push('/products/vendors')
  }

  render() {
    const { t, classes } = this.props;

    return (
      <div>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <div className="mobile-trigger" onClick={this.openMobileSearch}>

        </div>
        <div className="form-wrap">
          {(this.state.opened && !this.props.search.pending) ? (
            <div className={classNames(classes.closeIcon, 'close-btn')}>
              <CloseIcon onClick={this.onClose}/>
            </div>
          ) : null}
          {(this.state.opened && this.props.search.pending) ? (
            <div className={classes.progressIcon}>
              <CircularProgress className={classes.progressIconCircle} />
            </div>
          ) : null}

          <InputBase
            placeholder={t('search.placeholder')}
            value={this.state.query}
            onChange={this.onChange}
            onFocus={this.onFocus}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        {this.state.opened &&
          <React.Fragment>


            <List
              subheader={<React.Fragment>
                <ListSubheader />
                <Divider />                    
              </React.Fragment>}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.searchResults}
            >

            <div className="form-wrap-mobile">
              {(this.state.opened && !this.props.search.pending) ? (
                <div className={classNames(classes.closeIcon, 'close-btn')}>
                  <CloseIcon onClick={this.onClose}/>
                </div>
              ) : null}
              {(this.state.opened && this.props.search.pending) ? (
                <div className={classes.progressIcon}>
                  <CircularProgress className={classes.progressIconCircle} />
                </div>
              ) : null}

              <InputBase
                placeholder="Search…"
                value={this.state.query}
                onChange={this.onChange}
                onFocus={this.onFocus}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            { this.state.products.length <= 0 && this.state.collections.length <= 0 && this.state.theme.length <= 0 && this.state.customContents.length <= 0 ? <div className={classes.noResults}>{t('search.noResultsMsg')}</div> : null}
            
            { this.state.products.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.products')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.products.map((item, n) => {
                return (
                  <React.Fragment key={`search-product-${n}`}>
                    <ListItem button onClick={() => this.onClickProduct(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }
            { this.state.moreProductsAvailable ? <ListItem button className={classes.loadMore} onClick={this.loadMoreProducts}><ListItemText classes={{primary:classes.loadMoreText}} primary={t('search.loadMoreProducts')} /></ListItem> : null }
            
            { this.state.collections.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.collections')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.collections.map((item, n) => {
                return (
                  <React.Fragment key={`search-collection-${n}`}>
                    <ListItem button onClick={() => this.onClickCollection(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }
            { this.state.moreCollectionsAvailable ? <ListItem button className={classes.loadMore} onClick={this.loadMoreCollections}><ListItemText classes={{primary:classes.loadMoreText}} primary={t('search.loadMoreCollections')}/></ListItem> : null }

            { this.state.pages.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.pages')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.pages.map((item, n) => {
                return (
                  <React.Fragment key={`search-page-${n}`}>
                    <ListItem button onClick={() => this.onClickPage(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.theme.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.theme')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.theme.map((item, n) => {
                return (
                  <React.Fragment key={`search-product-${n}`}>
                    <ListItem button onClick={() => this.onClickTheme(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.customContents.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.customContent')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.customContents.map((item, n) => {
                return (
                  <React.Fragment key={`search-collection-${n}`}>
                    <ListItem button onClick={() => this.onClickCustomContent(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.links.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.navigation')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.links.map((item, n) => {
                return (
                  <React.Fragment key={`search-link-${n}`}>
                    <ListItem button onClick={() => this.onClickLink(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.tags.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.tags')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.tags.map((item, n) => {
                return (
                  <React.Fragment key={`search-tag-${n}`}>
                    <ListItem button onClick={() => this.onClickTag(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.types.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.types')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.types.map((item, n) => {
                return (
                  <React.Fragment key={`search-type-${n}`}>
                    <ListItem button onClick={() => this.onClickType(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            { this.state.vendors.length > 0 ? <React.Fragment><ListSubheader classes={{root: classes.listSubheader}}>{t('search.vendors')}</ListSubheader><Divider /></React.Fragment> : null }
            { this.state.vendors.map((item, n) => {
                return (
                  <React.Fragment key={`search-vendor-${n}`}>
                    <ListItem button onClick={() => this.onClickVendor(item)}>
                      <ListItemText primary={item.title} classes={{root: classes.listItemText}} />
                    </ListItem>
                    <Divider />                    
                  </React.Fragment>
                )
              })
            }

            </List>
          </React.Fragment>
        }
        <LoadingIndicator
          pending={this.props.syncByShopifyId.pending}
          withLogo
        />
      </div>
    )
  }
}

const mapStateToProps = ({ search, syncByShopifyId }) => ({
  search: search,
  syncByShopifyId: syncByShopifyId
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSearch,
      cancelSearch,
      searchMoreProducts,
      searchMoreCollections,
      syncProductByShopifyId,
      syncCollectionByShopifyId
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Search))))
