import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import _ from 'lodash';



const useStyles = makeStyles({
  root: {
    
  },
  externalLink: {
    color: '#1a237e',
    textDecoration: 'none',
    "&:visited": {
      color: '#1a237e',
      textDecoration: 'none'
    },
    "&:hover": {
      color: '#1a237e',
      textDecoration: 'underline'
    },
    "&:active": {
      color: '#1a237e',
      textDecoration: 'none'
    },
  },
  externalLinkDisabled: {
    color: '#666',
  },
  noDomainWarning: {
    background: "#cc0000",
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
    color: "#fff"
  }
});



const LanguagePreviewLink = (props) => {
  const [hreflangList, setHeflangList] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();



  const handlePreviewLinks = () => {
    // Fetch store page and extract hreflang tags
    fetch('https://langify-ux-3-2.myshopify.com/index.json').then(response => {
      //return response.json();
      return response.text();
    })
    .then(html => {
      // Convert the HTML string into a document object
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, 'text/html');

      //setHeflangList(getHreflangList(doc));


      console.log(doc);
    })
    .catch(err => {
      // There was an error
      console.warn('Could not load store-page', err);
    });
  }


  const getHreflangList = (doc) => {
    var hreflangTags = doc.querySelectorAll('link[hreflang]');
    var hreflangList = [];
    var countryCode = false;
    /*
    // Cross Domain Links option
    if(LyHelper.isDomainFeatureEnabled()) {
      for(var i = 0; i < langify.locale.languages.length; i++) {
        hreflangList.push({
          href: 'https://' + langify.locale.languages[i].domain + window.location.pathname + window.location.search + window.location.hash,
          lang: langify.locale.languages[i].iso_code,
        });
      }
    }
    */
    for(var i = 0; i < hreflangTags.length; i++) {
      var hreflang = hreflangTags[i].getAttribute('hreflang').toLowerCase();
      hreflang = hreflang.replace('zh-hans', 'zh-cn').replace('zh-hant', 'zh-tw'); // Chinese special cases
      hreflangList.push({
        href: hreflangTags[i].getAttribute('href'),
        lang: hreflang
      });
    }
    return hreflangList;
  }


  let usedPreviewLink = <Tooltip title="Cannot be viewed before assigned to at least one domain or market" arrow="true">
    <span className={classes.externalLinkDisabled} onClick={handlePreviewLinks}>{t('languagesSection.view')} <OpenInNewIcon style={{fontSize:'18px',color:'#666'}}/></span>
  </Tooltip>;
  
  
  /*
  if(row.published == false || (row.assigned_to_domains && row.assigned_to_domains.length > 0)) {
    let usedDomain = (row.assigned_to_domains && row.assigned_to_domains.length > 0) ? row.assigned_to_domains[0] : {'host':this.props.shop.shop.url, 'is_default': row.base};
    let validLink = 'https://' + usedDomain.host;
    if(usedDomain.is_default == false) {
      validLink += '/' + row.code;
    }

    usedPreviewLink = <Tooltip title="You must be logged in shopify to preview un-published languages" arrow={true}><a className={classes.externalLink} href={validLink} target="_blank">{t('languagesSection.view')} <OpenInNewIcon style={{fontSize:'18px',color:'#1a237e'}}/></a></Tooltip>
  }
  */


  return usedPreviewLink;
}

export default LanguagePreviewLink;