import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from '../api/api';
import { DropzoneArea, DropzoneAreaBase, DropzoneDialogBase } from 'material-ui-dropzone';
import { CircularProgress } from "@material-ui/core";


const useStyles = makeStyles({
  dropzone: {
      fontSize: 16,
      margin: 0,
  },
  dropzoneText: {
      fontSize: 16,
      margin: 24,
  },
  uploadImageBtn: {
    //position: 'absolute',
    padding: '6px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  },
  dropzoneText: {
    fontSize: 16,
    margin: 24,
  },
  altTextField: {
    marginTop: 16
  }
});

const FileUploadDialog = ({iconUrl, itemKey,  onUploaded, onRemoved}) => {
  const textFieldRef = useRef(null);

  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [altText, setAltText] = useState(null);
  const [pending, setPending] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  
  useEffect(() => {
    //setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  }
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClearImage = () => {
    setImageUrl('');
  };

  const handleFileChange = files => { 
    setSelectedFile(files[0]);
    console.log('File:', files[0]);
    if(files[0] && files[0].type === 'image/svg+xml') {
      //handleSvg(files);
    }
  }; 
  
  const handleSvg = useCallback((files) => {
    // Handle dropped files
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const svgData = event.target.result;
        console.log('SVG Data:', svgData);

        // Encode SVG content to base64
        const base64Encoded = btoa(svgData);

        // Create data URL
        const dataURL = 'data:image/svg+xml;base64,' + base64Encoded;
        // Now you can use svgData as needed, for example, display it in the FileViewer
        // or send it to a server for further processing.
    
        setSelectedFile(dataURL);
      };

      reader.readAsText(file); // Read the file as text (SVG is an XML-based format)
    });
  }, []);
  
  useEffect(() => {
    if(iconUrl) {
      setImageUrl(iconUrl);

      if(iconUrl.indexOf(';') > -1) {
        let extractedAltText = iconUrl.substring(iconUrl.indexOf(';') + 1).trim();
        setAltText(extractedAltText);
      }
    }
  }, [selectedFile, iconUrl])

  const handleFileUpload = () => {
    const file = selectedFile;
    const formData = new FormData();
    formData.append('image', file);

    setPending(true);
    API.uploadMedia(formData, response => {
      if(response.success == true) {
        setImageUrl(response.url);
        setPending(false);
        onUploaded(response.url, itemKey, textFieldRef.current.value);
        handleClose();
      } else {
        setImageUrl(response.url);
        setPending(false);
        //this.setState({target:this.props.source, isPending:false, showEditDialog:false})
      }
    });
  };

  const handleFileError = (message, variant) => {
    console.log(message, variant);
  };

  const handleFileRejected = (rejectedFiles) => {
    console.log(rejectedFiles);
  };

  const handleDelete = () => {
    onRemoved(itemKey);
    handleClose();
  }


  const dialogTitle = () => (
    <>
      <span>Upload file</span>
      <IconButton
        style={{right: -16, top: -8, position: 'absolute'}}
        onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </>
  );

  //return null;
  return <>
    <IconButton onClick={handleOpen} className={classes.uploadImageBtn} color={iconUrl ? 'secondary' : 'default'}>
      <PhotoCameraIcon />
    </IconButton>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {dialogTitle()}
      </DialogTitle>
      <DialogContent dividers>
        <DropzoneArea
          fullWidth={true}
          maxWidth={maxWidth}
          dropzoneClass={classes.dropzone}
          dropzoneParagraphClass={classes.dropzoneText}
          dropzoneText={'Drop files here or click to upload'}
          showPreviewsInDropzone={true}
          showAlerts={false}
          filesLimit={1}
          maxFileSize={20000000}
          onChange={handleFileChange} 
          onDropRejected={handleFileRejected}
          onAlert={handleFileError}
          onDelete={handleDelete}
          acceptedFiles={['image/*', 'application/svg+xml']}
          initialFiles={iconUrl ? [iconUrl] : []}
          previewGridClasses={{
            container: 'custom-icon-upload-container',
            item: 'custom-icon-upload-item',
          }}
          previewGridProps={{
            container: {
              columnSpacing: 0,
              columns: 1,
              sx: 1},
            item: {sx: 12}
          }}
        />
        <TextField 
          inputRef={textFieldRef}
          variant="outlined"
          defaultValue={altText}
          placeholder="Alternative text"
          size="small"
          fullWidth
          className={classes.altTextField}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button variant={'contained'} disabled={(selectedFile && !pending) ? false : true} onClick={handleFileUpload} color="primary">
          {!pending ?
            <>Upload <PublishIcon /></>
          : 
            <>Uploading <CircularProgress size={24} /></>
          }
        </Button>
      </DialogActions>
    </Dialog>
  </>
  }

  export default FileUploadDialog