import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

class Privacy extends React.Component {

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.privacy.title')}
        </Typography>
        <Divider className="divider-white" />
        
        <Paper className={classes.root} elevation="1">
          <p>
            <Trans i18nKey={'pages.privacy.content'}>
              <h3></h3>
            </Trans>
          </p>
        </Paper>
      </React.Fragment> 
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
    padding: 24,
  },
  contentContainer: {
    marginBottom: theme.spacing(4),
  },
})

export default withStyles(styles)(withTranslation()(Privacy))