import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Rating from '@material-ui/lab/Rating';
import CloseIcon from '@material-ui/icons/Close';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    position: 'fixed',
    bottom: 65,
    right: 24
  },
  ratingContainer: {
    textAlign: 'center',
    margin: '.5rem 0',
    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
      margin: 4,
    }
  },
  closeBtn: {
    position: 'absolute', 
    top: 4, 
    right: 4, 
    cursor: 'pointer'
  },
  cardActions: {
    justifyContent: 'end',
  }
});

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function SatisfactionQuote() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [rate, setRate] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRatingChange = (event) => {
    setRate(event.value);
  }; 

  return <Slide direction="up" in={open} mountOnEnter unmountOnExit>
    <Card className={classes.root} elevation={10}>
      <CardActionArea>
        <CardContent>
          <IconButton variant="outlined" onClick={handleClose} color="default" size="medium" className={classes.closeBtn}>
            <CloseIcon />
          </IconButton>
          <Typography gutterBottom variant="h5" component="h2">
            How do you like langify?
          </Typography>

          <div className={classes.ratingContainer}>
            <Rating
              onChange={handleRatingChange}
              name="highlight-selected-only"
              //IconContainerComponent={IconContainer}
              highlightSelectedOnly
              max={5}
              size="large"
            />
          </div>

          <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions classes={{root: classes.cardActions}}>
        <Button size="small" color="primary" onClose={handleClose}>
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  </Slide>;
}
