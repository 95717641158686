import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, Button, Divider, Link, Dialog, DialogContent } from '@material-ui/core';
import classNames from 'classnames';
//import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importiere useTranslation-Hook
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import GTranslateOutlinedIcon from '@material-ui/icons/GTranslateOutlined';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import TabUnselectedOutlinedIcon from '@material-ui/icons/TabUnselectedOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import RechargePlans from '../../components/RechargePlans';
import API from '../../api/api';

const useStyles = makeStyles(theme => ({
  card: {
    margin: 'auto',
    backgroundColor: '#1a237e',
    margin: '24px 0 36px 0',
    '& *': {
      color: 'white'
    }
  },
  cardContent: {
    color: 'white'
  },
  title: {
    '& svg': {
      fontSize: '3.75rem'
    }
  },
  gridContainer: {
    padding: '0 10%',
    [theme.breakpoints.up('lg')]: {
      padding: '0 15%',
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px 0',
    '& svg': {
      fontSize: '3.75rem'
    }
  },
  footer: {
    marginTop: 24,
  },
  btnIcon: {
    marginRight: 8,
  },
  gotItBtn: { 
    marginLeft: 40, 
    color: '#1a237e',
    '& *': {
      color: '#1a237e',
    } 
  },


  videoBtn: {
    border: '2px solid rgba(255,255,255, 0.5)'
  },
  dialogContent: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio (adjust as needed)
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '75%', // Adjust the aspect ratio for mobile devices
    },
  },
  iframe: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  aspectRatio16x9: {
    width: '100%',
    height: 0,
    paddingBottom: '56.25%'
  }
}));


const VideoDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(); // Initialisiere den useTranslation-Hook

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography align="center" gutterBottom>
        <Button onClick={handleClickOpen} className={classes.videoBtn} variant="outlined" size="large" color="default">
          <SlowMotionVideoIcon style={{marginRight: 12}} /> {t('onboarding.welcomeTile.watchVideo')}
        </Button>
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '100%',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogContent className={classNames(classes.dialogContent, classes.aspectRatio16x9)}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/cRdPYj1-zks"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className={classes.iframe}
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};



const WelcomeTile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(); // Initialisiere den useTranslation-Hook
  const [showRechargePlans, setShowRechargePlans] = useState(false);

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
  }

  const openRechargePlans = () => {
    setShowRechargePlans(true);
  }
  
  const onCloseRechargePlans = () => {
    setShowRechargePlans(false)
  }
  
  const onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        //console.log(res)
      }
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h3" align="center" gutterBottom className={classes.title}>
          {t('onboarding.welcomeTile.title')}
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          {t('onboarding.welcomeTile.subtitle')}
        </Typography>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={6} md={3} className={classes.gridItem}>
            <PublicOutlinedIcon />
            <Typography variant="h6" align="center">
              {t('onboarding.welcomeTile.step1')}
            </Typography>
            <Typography variant="body2" align="center">
              <Link href="https://support.langify-app.com/support/solutions/articles/11000081195-manage-languages" underline={'always'} target={'_blank'}>{t('onboarding.welcomeTile.link')}</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} className={classes.gridItem}>
            <TranslateOutlinedIcon />
            <Typography variant="h6" align="center">
              {t('onboarding.welcomeTile.step2')}
            </Typography>
            <Typography variant="body2" align="center">
              <Link href="https://support.langify-app.com/support/solutions/folders/11000014738" underline={'always'} target={'_blank'}>{t('onboarding.welcomeTile.link')}</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} className={classes.gridItem}>
            <TabUnselectedOutlinedIcon />
            <Typography variant="h6" align="center">
              {t('onboarding.welcomeTile.step3')}
            </Typography>
            <Typography variant="body2" align="center">
              <Link href="https://support.langify-app.com/support/solutions/articles/11000084362-switcher-configurator" underline={'always'} target={'_blank'}>{t('onboarding.welcomeTile.link')}</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} className={classes.gridItem}>
            <LanguageOutlinedIcon />
            <Typography variant="h6" align="center">
              {t('onboarding.welcomeTile.step4')}
            </Typography>
            <Typography variant="body2" align="center">
              <Link href="https://support.langify-app.com/support/solutions/articles/11000082042-publishing-in-app" underline={'always'} target={'_blank'}>{t('onboarding.welcomeTile.link')}</Link>
            </Typography>
          </Grid>
        </Grid>

        <VideoDialog />

        <Typography align='center' className={classes.footer}>
          {t('onboarding.welcomeTile.footerText')} <Link onClick={openRechargePlans} underline={'always'}>{t('onboarding.welcomeTile.pricingLink')}</Link>
          <Button
            align="right"
            variant="contained"
            color="default"
            size="small"
            className={classes.gotItBtn}
            onClick={close}
          >
            <DoneOutlinedIcon className={classes.btnIcon} /> {t('onboarding.welcomeTile.okay')}
          </Button>
        
        </Typography>



        <RechargePlans
          visible={showRechargePlans}
          onClose={onCloseRechargePlans}
          onBuy={onBuyWords}
        />
      </CardContent>
    </Card>
  );
};

export default WelcomeTile;
