
import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next'; // Importiere useTranslation-Hook
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Button, Divider, Tooltip, List, ListItem, ListItemText, Link, Card, CardActions, Typography, IconButton, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { withRouter, useHistory } from "react-router-dom";
import { SnackbarContent, useSnackbar } from 'notistack';
import { parseItemId } from '../helper';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline'
  },
  actionRoot: {
    paddingLeft: 16,
  },
  typography: {
    flex: 1,
    color: 'white',
  },
  btn: {
    marginLeft: 8,
  },
  list: {
    marginLeft: '0 !important',
  }
}));

export const SaveInfosList = React.forwardRef(({id, ...props}, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(true);
  const [copied, setCopied] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);


  const handleClick = (itemId, fieldId, subfieldsId) => {
    props.history.push(`/${props.scope}/${itemId}#${subfieldsId ? subfieldsId + ':' + fieldId : fieldId}`);
  };

  const handleOpenTemplate = (url) => {
    window.open(url, '_blank');
  }

  const handleCopyToClipboard = (text) => {
    var textToCopy = text;
    var textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }
  
  return <SnackbarContent className={classNames(classes.root, 'error-list-snackbar')}>
    <Card className={classes.card} style={{ backgroundColor: "red" }}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <Typography variant="body2" className={classes.typography}>
          <ErrorOutlineOutlinedIcon style={{marginRight: 12}} />
          <Trans i18nKey={'saveInfos.itemCount'} count={props.infos.length}></Trans>
        </Typography>
        <div className={classes.icons}>
          <IconButton
            aria-label="Show more"
            size="small"
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton
            size="small"
            className={classes.expand}
            onClick={handleDismiss}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.paper}>
          <List dense>
            {props.infos.map((item) => (
              <ListItem key={`info-list-${item.object}`}>
                <ListItemText
                className={classes.list}
                  disableTypography
                  primary={<span>
                      {props.rawData.find(x => x.id === parseItemId(item.object)) && props.rawData.find(x => x.id === parseItemId(item.object)).title}
                      <Tooltip title={'Copy to Clipboard'} arrow>
                        <Button onClick={() => {handleCopyToClipboard(item.info.asset_value_published)}} size="small" variant="outlined" className={classes.btn}>
                          <FileCopyOutlinedIcon style={{marginRight: 8, fontSize: 16}} />{copied ? 'Copied!' : 'Copy'}
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Open Template in Shopify'} arrow>
                        <Button onClick={() => {handleOpenTemplate(item.info.full_asset_url)}} size="small" variant="outlined" className={classes.btn}>
                          <OpenInNewOutlinedIcon style={{marginRight: 8, fontSize: 16}} />Open
                        </Button>
                      </Tooltip>
                    </span>
                  }
                  //secondary={}
                />
              </ListItem>
            )
          )}
          </List>
        </Paper>
      </Collapse>
    </Card>
  </SnackbarContent>
})

export const SaveErrorsList = React.forwardRef(({id, ...props}, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);


  useEffect(() => {
    //console.log(props.errors)
  }, [props.errors])

  const handleClick = (itemId, fieldId, subfieldsId) => {
    props.history.push(`/${props.scope}/${itemId}#${subfieldsId ? subfieldsId + ':' + fieldId : fieldId}`);
  };
  
  return <SnackbarContent className={classNames(classes.root, 'error-list-snackbar')}>
    <Card className={classes.card} style={{ backgroundColor: "red" }}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <Typography variant="body2" className={classes.typography}>
          <ErrorOutlineOutlinedIcon style={{marginRight: 12}} />
          <Trans i18nKey={'saveErrors.errorCount'} count={props.errors.reduce((sum, row) => sum + row.errors.length, 0)}></Trans>
          <Trans i18nKey={'saveErrors.itemCount'} count={props.errors.length}></Trans>
        </Typography>
        <div className={classes.icons}>
          <IconButton
            aria-label="Show more"
            size="small"
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton
            size="small"
            className={classes.expand}
            onClick={handleDismiss}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.paper}>
          <List dense>
            {props.errors.map((item) => (
              <ListItem key={`error-list-${item.object}`} 
                //component={Link} 
                //to={'/metaobjects/1844412506'}
                //href={`/${props.scope}/${item.itemId}`}
                //onClick={() => { handleClick(item.itemId)}}
              >

                  <ListItemText
                    disableTypography
                    primary={<span onClick={() => { handleClick(parseItemId(item.object), '')}}>
                        {props.rawData.find(x => x.id === parseItemId(item.object)) && props.rawData.find(x => x.id === parseItemId(item.object)).title}{' > '}
                      </span>
                    }
                    secondary={<ul>
                      {item.errors && Array.isArray(item.errors) && item.errors.map((error, i) => 
                        <li key={`snackbar-error-list-item-${i}`} onClick={() => { handleClick(parseItemId(item.object), error.object_key, error.object_subfieldsId)}} style={{cursor: 'pointer'}}>
                          {error.object_subfieldsId && `${error.object_subfieldsId} > `}
                          {`${error.object_key} > `}
                          {t(`saveErrors.errorTypes.${Object.entries(error.validation_errors)[0][0]}`,{ type: error.object_type })}
                          <Tooltip title={Object.entries(error.validation_errors)[0][1]}>
                            <InfoOutlinedIcon style={{ fontSize: 18, marginLeft: 4 }} />
                          </Tooltip>
                        </li>
                      )}
                    </ul>}
                  />

              </ListItem>
            )
          )}
          </List>
        </Paper>
      </Collapse>
    </Card>
  </SnackbarContent>
})