import React, { useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import HorizontalSplitOutlinedIcon from '@material-ui/icons/HorizontalSplitOutlined';
import { Trans, withTranslation, useTranslation } from 'react-i18next';


function fileSize(size) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root': {
      padding: '4px 12px !important'
    },
    '&>.MuiDialogContentText-root': {
      marginBottom: '0px !important'
    }
  },
  dialog: {
    minWidth: '450px !important'
  },
  checkbox: {
    padding: '4px 12px !important'
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  loadingSpinnerContainer: {
    margin: 'auto', 
    top: 1,
    position: 'absolute',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1
  },
  selectFileBtn: {
    width: 'fit-content'
  }
}));

function ExportImportDialogs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showImportCompleteDialog, setShowImportCompleteDialog] = useState(false);
  const [showImportSettingsDialog, setShowImportSettingsDialog] = useState(false);
  const [file, setFile] = useState(null);
  
  const [exportOptions, setExportOptions] = useState({
    product_tags: true,
    product_types: true,
    product_vendors: true,
    custom_contents: true,
    export_filter: 'untranslated,translated',
  });
  
  const [importOptions, setImportOptions] = useState({
    product_tags: false,
    product_types: false,
    product_vendors: false,
    custom_contents: props.scope === 'products' ? false : true, // TODO: Change back to true as soon CC is live
  });

  let inputRef = null;


  useEffect(() => {
    if(props.forceOpenExportDialog === true) {
      onShowDownloadDialog();
    }
    if(props.forceOpenImportDialog === true) {
      setShowImportSettingsDialog(true);
    }
    if(props.showImportCompleteDialog === true) {
      onShowImportCompleteDialog();
    }
  }, [
    exportOptions, 
    file,
    props.showDownloadDialog, 
    props.forceOpenExportDialog,
    props.forceOpenImportDialog,
    props.showImportCompleteDialog, 
    props.export_advanced_csv_download_url, 
    props.export_csv_download_url, 
    props.export_po_download_url, 
    props.imported_entries,
    props.pending
  ]); 

  const onCancel = () => {
    if(props.clearFunc) {
      setTimeout(props.clearFunc, 1000);
    }
    setShowDownloadDialog(false);
  }
  const onDownload = () => {
    setShowDownloadDialog(false);
    if(props.clearFunc) {
      setTimeout(props.clearFunc, 1000);
    }
  }
  
  const onShowDownloadDialog = () => {
    setShowDownloadDialog(true);
  }
  const onCloseDownloadDialog = () => {
    setShowDownloadDialog(false);
  }

  const onShowImportCompleteDialog = () => {
    onCloseDownloadDialog();
    setShowImportCompleteDialog(true);
  }
  const onCloseImportCompleteDialog = () => {
    setShowImportSettingsDialog(false);
    setShowImportCompleteDialog(false);

    //window.location.reload();
  }

  const onChangeExportOption = (evt) => {
    let newExportOptions = {...exportOptions};
    if(evt.target.name === 'export_filter') {
      newExportOptions[evt.target.name] = evt.target.value;
    } else {
      newExportOptions[evt.target.name] = !newExportOptions[evt.target.name];
    }
    setExportOptions(newExportOptions);
  }
  const onGenerateDownload = () => {
    props.exportFunc(_cleanOptionsObjectForUrl(exportOptions));
  }

  const onChangeImportOption = (evt) => {
    let newImportOptions = {...importOptions};
    newImportOptions[evt.target.name] = !newImportOptions[evt.target.name];
    setImportOptions(newImportOptions);
  }
  const onSelectImportFile = (e) => {
    inputRef.click();
  }
  const onImportFile = (e) => {
    setShowImportSettingsDialog(false);
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
    if(fileType != 'mo' && fileType != 'csv') {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    props.importFunc(formData, fileType, _cleanOptionsObjectForUrl(importOptions));
  }


  const _cleanOptionsObjectForUrl = (options) => {
    const newOptions = {};
    Object.entries(options).map(opt => {
      if(props.additionalFields.indexOf(opt[0]) > -1) {
        if(opt[1] === true) {
          newOptions[opt[0]] = true;
        }
      }
      if(opt[0] === 'export_filter') {
        newOptions['export_filter'] = options.export_filter;
      }
    });
    return newOptions;
  }

  const handleClose = () => {
    setShowDownloadDialog(false);
    setShowImportSettingsDialog(false);
    setShowImportCompleteDialog(false);
  }

  return <div className={classes.root}>
    <Dialog
      open={showDownloadDialog}
      onClose={onCloseDownloadDialog}
      aria-labelledby="export-dialog-title"
      aria-describedby="export-dialog-description"
      classes={{paper: classes.dialog}}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      {(props.export_po_download_url || props.export_csv_download_url) ? 
        <React.Fragment>
          <DialogTitle id="export-dialog-title">{t('exportFeature.step2.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="export-dialog-description">
              <Trans i18nKey={'exportFeature.step2.info1'}></Trans>
              <br />
              <br />
              <Trans i18nKey={'exportFeature.step2.info2'}>
                <Link href="https://poedit.net/" target="_blank" color="primary" rel="noreferrer"></Link>
              </Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              {t('system.cancel')}
            </Button>
            {props.export_advanced_csv_download_url &&
              <Tooltip title={t('exportFeature.downloadAdvancedCSVTooltip')} placement="top" arrow>
                <Button href={props.export_advanced_csv_download_url} onClick={onDownload} download variant="outlined" color="secondary">{t('exportFeature.downloadAdvancedCSV')}</Button>
              </Tooltip>
            }
            <Button href={props.export_csv_download_url} onClick={onDownload} download variant="outlined" color="secondary">{t('exportFeature.downloadCSV')}</Button>
            <Button href={props.export_po_download_url} onClick={onDownload} download variant="outlined" color="primary">{t('exportFeature.downloadPO')}</Button>
          </DialogActions>          
        </React.Fragment>
      : 
        <React.Fragment>
          <DialogTitle id="export-dialog-title">{t('exportFeature.step1.title')}</DialogTitle>
          <DialogContent id="export-dialog-description">
            <FormControl component="fieldset" fullWidth>
              <FormGroup>
                {props.additionalFields.length > 1 && // TODO: Change back to 0 as soon CC is live
                  <React.Fragment>
                    <DialogContentText gutterBottom>{t('exportFeature.step1.info2')}</DialogContentText>
                    <FormControlLabel
                      control={<Checkbox checked={true} disabled className={classes.checkbox} />}
                      label={t(`navigation.primary.${props.scope}`)}
                    />
                    {props.additionalFields.indexOf('product_tags') > -1 && 
                      <FormControlLabel
                        control={<Checkbox checked={exportOptions.product_tags} onChange={onChangeExportOption} name="product_tags" className={classes.checkbox} />}
                        label={t('exportFeature.step1.tagsLabel')}
                      />
                    }
                    {props.additionalFields.indexOf('product_types') > -1 && 
                      <FormControlLabel
                        control={<Checkbox checked={exportOptions.product_types} onChange={onChangeExportOption} name="product_types" className={classes.checkbox} />}
                        label={t('exportFeature.step1.typesLabel')}
                      />
                    }
                    {props.additionalFields.indexOf('product_vendors') > -1 && 
                      <FormControlLabel
                        control={<Checkbox checked={exportOptions.product_vendors} onChange={onChangeExportOption} name="product_vendors" className={classes.checkbox} />}
                        label={t('exportFeature.step1.vendorsLabel')}
                      />
                    }
                    {props.additionalFields.indexOf('custom_contents') > -1 && 
                      <FormControlLabel
                        control={<Checkbox checked={exportOptions.custom_contents} onChange={onChangeExportOption} name="custom_contents" className={classes.checkbox} />}
                        label={t('exportFeature.step1.customContentLabel')}
                      />
                    }
                    <Divider style={{ margin: '1rem 0'}}/> 
                  </React.Fragment>
                }
                <DialogContentText gutterBottom>{t('exportFeature.step1.info1')}</DialogContentText>
                <FormControl>
                  {/*<FormLabel id="exportfilter-radio-buttons-group-label">{t('exportFeature.step1.exportFilterLabel')}</FormLabel>*/}
                  <RadioGroup
                    aria-labelledby="exportfilter-radio-buttons-group-label"
                    name="export_filter"
                    value={exportOptions['export_filter']}
                    onChange={onChangeExportOption}
                  >
                    <FormControlLabel value="untranslated,translated" control={<Radio className={classes.checkbox}  />} label={t('exportFeature.step1.exportFilterOption1')} />
                    <FormControlLabel value="untranslated" control={<Radio className={classes.checkbox} />} label={t('exportFeature.step1.exportFilterOption2')} />
                    <FormControlLabel value="translated" control={<Radio className={classes.checkbox} />} label={t('exportFeature.step1.exportFilterOption3')} />
                  </RadioGroup>
                </FormControl>
                {/** 
                <FormControlLabel
                  control={<Checkbox checked={exportOptions.export_filter === 'translated' ? true : false} onChange={onChangeExportOption} name="export_filter" className={classes.checkbox} />}
                  label={t('exportFeature.step1.exportFilterLabel')}
                />
                <FormHelperText>{t('exportFeature.step1.exportFilterSublabel')}</FormHelperText>
                */}
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">{t('system.cancel')}</Button>
            <Button onClick={onGenerateDownload} disabled={props.pending} variant="contained" color="primary">
              {t('exportFeature.step1.generateExport')}<SaveAltIcon style={{marginLeft: 8}} />
              <span className={classes.loadingSpinnerContainer}>
                {props.pending &&
                  <CircularProgress className={classes.loadingSign} size={25} />
                }
              </span>
            </Button>
          </DialogActions>          
        </React.Fragment>
      }
    </Dialog>


    <Dialog
      open={showImportSettingsDialog}
      onClose={onCloseImportCompleteDialog}
      aria-labelledby="import-dialog-title"
      aria-describedby="import-dialog-description"
      classes={{paper: classes.dialog}}
    >
      <DialogTitle id="import-dialog-title">{t('importFeature.settings.title')}</DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent id="import-dialog-description">
        <FormControl component="fieldset">
          <FormGroup>
            <DialogContentText>{t('importFeature.settings.selectFileInfo')}</DialogContentText>
            <input style={{display: 'none'}} 
              accept={'.mo,.csv'} 
              ref={(ref) => inputRef = ref} 
              type="file" 
              onChange={(e) => { setFile(e.target.files[0]); }}
            />
            <Button className={classes.selectFileBtn} variant="contained" color="secondary" size="large" onClick={onSelectImportFile}>{t('importFeature.settings.selectFileBtn')}</Button>
            {file &&
              <Typography variant="caption">
                Name: {file.name}<br />
                Filesize: {fileSize(file.size)}<br />
                Type: {file.type}
              </Typography>
            }
            <FormHelperText>{t('importFeature.settings.selectFileSublabel')}</FormHelperText>
            {props.additionalFields.length > 1 && // TODO: Change back to 0 as soon CC is live
              <React.Fragment>
                <Divider style={{ margin: '1rem 0'}}/> 
                <DialogContentText>{t('importFeature.settings.optionsInfo')}</DialogContentText>
                <FormControlLabel
                  control={<Checkbox checked={true} disabled className={classes.checkbox} />}
                  label={t(`navigation.primary.${props.scope}`)}
                />
                {props.additionalFields.indexOf('product_tags') > -1 && 
                  <FormControlLabel
                    control={<Checkbox checked={importOptions.product_tags} onChange={onChangeImportOption} name="product_tags" className={classes.checkbox} />}
                    label={t('exportFeature.step1.tagsLabel')}
                  />
                }
                {props.additionalFields.indexOf('product_types') > -1 && 
                  <FormControlLabel
                    control={<Checkbox checked={importOptions.product_types} onChange={onChangeImportOption} name="product_types" className={classes.checkbox} />}
                    label={t('exportFeature.step1.typesLabel')}
                  />
                }
                {props.additionalFields.indexOf('product_vendors') > -1 && 
                  <FormControlLabel
                    control={<Checkbox checked={importOptions.product_vendors} onChange={onChangeImportOption} name="product_vendors" className={classes.checkbox} />}
                    label={t('exportFeature.step1.vendorsLabel')}
                  />
                }
                {props.additionalFields.indexOf('custom_contents') > -1 && 
                  <FormControlLabel
                    control={<Checkbox checked={importOptions.custom_contents} onChange={onChangeImportOption} name="custom_contents" className={classes.checkbox} />}
                    label={t('exportFeature.step1.customContentLabel')}
                  />
                }                
              </React.Fragment>
            }
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">{t('system.cancel')}</Button>
        <Button onClick={onImportFile} color="secondary" variant="contained" disabled={!file}>
          {t('importFeature.settings.importBtn')}
        </Button>
      </DialogActions>
    </Dialog>


    <Dialog
      open={showImportCompleteDialog}
      onClose={onCloseImportCompleteDialog}
      aria-labelledby="import-complete-dialog-title"
      aria-describedby="import-complete-dialog-description"
      classes={{paper: classes.dialog}}
    >
      <DialogTitle id="import-complete-dialog-title">{t('importFeature.complete.title')}</DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="import-complete-dialog-description">
          {props.imported_entries === 0 ?
            t('importFeature.complete.description', { imported_entries: props.imported_entries })
          :
            t('importFeature.complete.descriptionSuccess', { imported_entries: props.imported_entries })
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.imported_entries > 0 ?
          <React.Fragment>
            {importOptions.custom_contents === true || props.scope === 'custom' || !props.syncFunc ?
              <Button onClick={() => {window.location.reload()}} color="secondary" variant="contained">
                Resync
              </Button>
            :
              <Button onClick={() => {props.syncFunc(); onCloseImportCompleteDialog();}} color="secondary" variant="contained">
                Resync
              </Button>
            }
          </React.Fragment>
        :
          <Button onClick={onCloseImportCompleteDialog} color="secondary" variant="contained">
            Ok
          </Button>
        }
      </DialogActions>
    </Dialog>
  </div>
}
ExportImportDialogs.defaultProps = {
  additionalFields: [],
  label: "Button Text"
};
export default ExportImportDialogs;