import API from '../api/api'

const GET_RESULTS_PENDING = 'GET_RESULTS_PENDING'
const GET_RESULTS_RESPONSE = 'GET_RESULTS_RESPONSE'
const GET_MORE_PRODUCTS_RESPONSE = 'GET_MORE_PRODUCTS_RESPONSE'
const GET_MORE_COLLECTIONS_RESPONSE = 'GET_MORE_COLLECTIONS_RESPONSE'

const initialState = {
  results: [],
  pending: false
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case GET_RESULTS_PENDING: {
      return {
        ...state,
        pending: true
      }
    }
    case GET_RESULTS_RESPONSE: {
      if(action.response.success === true) {
        return  {
          ...state,
          results: action.response.results,
          pending: false
        }
      } else {
        return  {
          ...state,
          pending: false
        }
      }
    }
    case GET_MORE_PRODUCTS_RESPONSE: {
      if(action.response.success === true) {
        const newProducts = state.results.products.results.slice();
        action.response.results.products.results.forEach(result => {
          newProducts.push({
            id: result.id,
            title: result.title,
          })
        })
        return  {
          ...state,
          results: {
            ...state.results,
            products: {
              hasNextPage: action.response.results.products.hasNextPage,
              cursor: action.response.results.products.cursor,
              results: newProducts
            }
          },
          pending: false
        }
      } else {
        return  {
          ...state,
          pending: false
        }
      }
    }
    case GET_MORE_COLLECTIONS_RESPONSE: {
      if(action.response.success === true) {
        const newCollections = state.results.collections.results.slice();
        action.response.results.collections.results.forEach(result => {
          newCollections.push({
            id: result.id,
            title: result.title,
          })
        })
        return  {
          ...state,
          results: {
            ...state.results,
            collections: {
              hasNextPage: action.response.results.collections.hasNextPage,
              cursor: action.response.results.collections.cursor,
              results: newCollections
            }
          },
          pending: false
        }
      } else {
        return  {
          ...state,
          pending: false
        }
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const doSearch = (themeId, query) => { 
  return dispatch => {
    dispatch({
      type: GET_RESULTS_PENDING
    })

    API.search(
      themeId,
      query,
      response => {
        dispatch({
          type: GET_RESULTS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const searchMoreProducts = (query, cursor) => { 
  return dispatch => {
    dispatch({
      type: GET_RESULTS_PENDING
    })

    API.searchMoreProducts(
      query,
      cursor,
      response => {
        dispatch({
          type: GET_MORE_PRODUCTS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const searchMoreCollections = (query, cursor) => { 
  return dispatch => {
    dispatch({
      type: GET_RESULTS_PENDING
    })

    API.searchMoreCollections(
      query,
      cursor,
      response => {
        dispatch({
          type: GET_MORE_COLLECTIONS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const cancelSearch = (query) => { 
  return dispatch => {
    API.cancelSearch()
  }
}