import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  progress: {
    width: '100%',
  }
});

class ProgressToolbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { classes } = this.props;

    return (
      <Toolbar>
        <LinearProgress color="secondary" className={classes.progress}/>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(ProgressToolbar);
