import React, { useState, useEffect, useCallback } from 'react';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '@material-ui/core/Collapse';
import { Card, CardActions, IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { SnackbarContent, useSnackbar } from 'notistack';
import { _clearUnchangedFields, _getValidationPropsFromValidations } from '../helper';
import API from '../api/api';


const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline'
  },
  actionRoot: {
    paddingLeft: 16,
  },
  typography: {
    flex: 1,
    color: 'white',
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btn: {
    color: 'white'
  }
}));

export const UpdateAppPermissionsSnackbar = React.forwardRef(({id, ...props}, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onUpdate = () => {
    setLoading(true);
    API.accessUpdate((res) => {
      if(res.confirm_URL) {
        window.location.href = res.confirm_URL;
      }
      setLoading(false);
    })
  }

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);


  return <SnackbarContent className={classes.root} ref={ref} class="error-list-snackbar">
    <Card className={classes.card} style={{ backgroundColor: "red" }}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <Typography variant="body2" className={classes.typography}>
          <ErrorOutlineOutlinedIcon style={{marginRight: 12}} />
          {props.message}
        </Typography>
        <div className={classes.icons}>
          <span className={classes.wrapper}>
            <Button
              className={classes.btn}
              aria-label="Update"
              size="small" 
              onClick={onUpdate}>
              Update
            </Button>
            {loading && 
              <CircularProgress size={24} className={classes.buttonProgress} />
            }
          </span>
          <IconButton
            size="small"
            className={classes.expand}
            onClick={handleDismiss}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </CardActions>
    </Card>
  </SnackbarContent>
})



const UpdateAppPermissionsDialog = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const access_rights = useSelector((state) => state.shop.shop.access_rights);

  useEffect(() => {
    if(access_rights && access_rights.access_lack && access_rights.access_lack.length > 0) {
      setOpen(true);
    }
  }, [access_rights])

  const onUpdate = () => {
    setLoading(true);
    API.accessUpdate((res) => {
      if(res.confirm_URL) {
        window.location.href = res.confirm_URL;
      }
      setLoading(false);
    })
  }

  const toggleDetails = () => {
    setOpenDetails(!openDetails);
  }
  
  return open ? <Alert severity="warning" 
      onClose={(e) => {setOpen(false)}} 
      style={{
        marginTop: props.marginTop ? 20 : 0,
        marginBottom: props.marginBottom ? 20 : 0,
      }}>
    <AlertTitle>{t('updateAppPermissionsDialog.title')}</AlertTitle>
    <Typography>{t('updateAppPermissionsDialog.description')}</Typography>
    {access_rights.access_lack && <div>
        <Collapse in={openDetails}>
          <Typography>{t('updateAppPermissionsDialog.detail')}</Typography>
          {access_rights.access_lack.map((accessType, i) => <Typography key={`access-type-${i}`}> - {accessType}</Typography>)}
        </Collapse>
        <Typography onClick={toggleDetails} variant={'caption'} style={{cursor: 'pointer', textDecoration: 'underline'}}>
          {!openDetails ? t('updateAppPermissionsDialog.detailShowBtn') : t('updateAppPermissionsDialog.detailHideBtn')}
        </Typography>        
      </div>
    }
    <Typography>
      <Button disabled={loading} variant="outlined" size="small" onClick={onUpdate} style={{marginTop: 8}}>
        {t('updateAppPermissionsDialog.updateBtn')}
        {loading && 
          <CircularProgress size={24} style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,}} />
        }
      </Button>    
    </Typography>
  </Alert> : null 
}
export default UpdateAppPermissionsDialog;