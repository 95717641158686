import API from '../api/api'
import { deepMerge } from '../helper'

const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
const GET_PLANS_RESPONSE = 'GET_PLANS_RESPONSE';
const CHANGE_PLAN_PENDING = 'CHANGE_PLAN_PENDING';
const CHANGE_PLAN_RESPONSE = 'CHANGE_PLAN_RESPONSE';

const initialState = {
	get_plans_pending: false,
  change_plan_pending: false,
	availablePlans: null,
	plans: [],
  featureList: []
}


const updateFeaturesWithPlans = (features, plans) => {
  // Clone the features object to avoid mutating the original
  let updatedFeatures = JSON.parse(JSON.stringify(features));

  // Iterate over each plan
  plans.forEach(plan => {
    // Find the feature list for the current plan
    let featureList = updatedFeatures[plan.key];

    // Update the feature list with the plan properties
    featureList.forEach(feature => {
      if (plan.hasOwnProperty(feature.key)) {
        feature.value = plan[feature.key];
      }
    });
  });
  return updatedFeatures;
}
let list = [
  { "key": "languages", "name": "Languages", "value": 5, "tease": true },
  { "key": "support_24_7", "name": "24/7 Support", "value": true, "tease": true },
  { "key": "seo", "name": "SEO", "value": true, "tease": true },
  { "key": "analytics_dashboard", "name": "Analytics", "value": true, "tease": false },
  { "key": "manual_translations", "name": "Manual Translation", "value": true, "tease": false },
  { "key": "checkout_translation", "name": "Checkout Translation", "value": true, "tease": false },
  { "key": "auto_translations", "name": "Auto Translation", "value": false, "tease": true },
  { "key": "onetime_words", "name": "Free Auto-translation words", "value": 10000, "tease": true },
  { "key": "import_export", "name": "Import & Export", "value": false, "tease": true },
  { "key": "custom_content", "name": "Custom Content Feature", "value": false, "tease": true },
  { "key": "crossdomain_switcher", "name": "Switcher Configurator", "value": false, "tease": true },
  { "key": "country_currency", "name": "Country & Currency Switcher", "value": false, "tease": false },
  { "key": "language_detection", "name": "Language Detection", "value": false, "tease": false },
  { "key": "image_translation", "name": "Image Translations", "value": false, "tease": false },
  { "key": "bulk_translations", "name": "Bulk Translations", "value": false, "tease": true },
  { "key": "priority_support", "name": "Priority Support", "value": false, "tease": true },
  { "key": "max_word_discount", "name": "Maximum word discount", "value": 0, "tease": false },
  { "key": "available_words_log", "name": "Wordslog timespan", "value": 0, "tease": false },
]
let features = {
  "Free": list.slice(),
  "Basic": list.slice(),
  "Growth": list.slice(),
  "Premium": list.slice(),
};



// REDUCER
export default (state = initialState, action) => {
	switch(action.type) {

		case GET_PLANS_PENDING: {
			return {
				...state,
				get_plans_pending: true,
			}
		}
		case GET_PLANS_RESPONSE: {
			if(action.response.success === true) {
				const newPlans = action.response.plans.slice();
        const newFeaturesList = updateFeaturesWithPlans(features, newPlans);
				return {
					...state,
          get_plans_pending: false,
					plans: newPlans,
          featureList: newFeaturesList,
				}
			} else {
        return {
          ...state,
          get_plans_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
			}
		}


		case CHANGE_PLAN_PENDING: {
			return {
				...state,
        change_plan_pending: true,
			}
		}
		case CHANGE_PLAN_RESPONSE: {
			return {
				...state,
        change_plan_pending: false,
			}
		}


		default:
			return state
	}
}



export const getPlans = (callback) => { 
	return dispatch => {
		dispatch({
			type: GET_PLANS_PENDING,
		})

		API.getPlans(
			response => {
				dispatch({
					type: GET_PLANS_RESPONSE,
					response: response,
					action: () => getPlans(callback)
				});
				if(callback) {
					callback(response);
				}
		})
	}
}

export const changePlan = (newPlan) => {  
	return dispatch => {
		dispatch({
			type: CHANGE_PLAN_PENDING,
		});

    console.log(newPlan);
		API.changePlan(
			newPlan,
			response => {
				dispatch({
					type: CHANGE_PLAN_RESPONSE,
					response: response,
					action: () => changePlan(newPlan)
				}
			)
		})
	}
}
