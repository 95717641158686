import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DoneOutlined from "@material-ui/icons/DoneOutlined";
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import FileUploadDialog from './FileUploadDialog';
import _ from 'lodash';


const styles = theme => ({
  formControl: {
    display: 'block'
  },
  switchContainer: {
    top: 'unset',
    right: '8px',
    height: '16px',
    position: 'absolute'
  },
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
  },
  infoText: {
    color: '#5c6bc0',
  },
  inputBase: {
    padding: 0,
    width: '100%'
  },
  textField: {
    margin: '8px 0',
    fontSize: '0.85rem'
  },
  input: {
    padding: '6px 0',
    fontSize: '0.85rem'
  },
  toggleEditModeBtn: {
    position: 'absolute',
    right: -12,
    top: -12,
  },
  actionBar: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'fixed',
    bottom: 24,
    left: 265,
    right: 24,
    padding: 8,
    borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    borderRadius: '0 0 6px 6px',
    '&>.item': {
      flexGrow: 1,
      height: 36,
      margin: 8,
      maxWidth: 250
    },
    '&>.item2': {
      //width: 80,
      height: 36,
      margin: 8
    },
    '&> .MuiFormControl-root .MuiInputBase-root input, .MuiOutlinedInput-root.MuiInputBase-fullWidth input': {
      padding: '8px 16px !important',
    },
    '&> .MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '8px 32px 8px 8px !important',
    }
  }
});

const DragHandle = SortableHandle(() => (
  <ListItemIcon className={'toggleEditModeBtn'} style={{marginRight: 0, minWidth: 'auto', cursor: 'ns-resize', alignItems: 'center', justifyContent: 'right'}}>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement((props) => {
  const { classes, customIcons, language, onChangeDomain, onRemoveRow, index, listIndex, onEditModeChange, onUploaded, onRemoved, itemKey, icon_url } = props;
  const rawLanguage = props.rawLanguages.find(item => item.code === language.iso_code);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({});

  const onChangeCustomName = (e, field) => {
    let datas = {...data};
    datas[field] = { 
      field: field,
      value: e.target.value, 
      index: listIndex,
      language
    }
    setData(datas);
  }
  const switchEditingMode = () => {
    //onEditModeChange(!isEditing, data);
    setIsEditing(!isEditing);
  }

  const getAssignedDomain = () => {
    if(rawLanguage && typeof rawLanguage.assigned_to_domains.find(item => item.is_default === true) !== 'undefined')
      return rawLanguage.assigned_to_domains.find(item => item.is_default === true).host;
    else
      return '';
  }

  const getCustomIconIfExisting = (key) => {
    if(customIcons[key]) {
      return customIcons[key];
    }
    return null;
  }

  return (
    <TableRow key={`${language.iso_code}-${language.country_code}-${language.currency_code}`}>
      <TableCell component="th" scope="row"><DragHandle /></TableCell>
      <TableCell component="th" scope="row">
        <span style={{position: 'relative'}}>
          {getCustomIconIfExisting(`${language.country_code ? language.country_code+'-' : ''}${language.iso_code}`) ?
            <img className={'ly-flag-icon'} src={getCustomIconIfExisting(`${language.country_code ? language.country_code+'-' : ''}${language.iso_code}`)} />
          :
            <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${language.iso_code}.svg')`}} />
          } 
          <FileUploadDialog 
            onUploaded={onUploaded}
            onRemoved={onRemoved}
            itemKey={itemKey}
            iconUrl={icon_url}
          />
        </span>
        {/*<i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${language.country_code ? language.country_code : language.iso_code}.svg')`}} />*/}
        {language.iso_code ? language.iso_code : '' }
        {language.country_code ? '-'+language.country_code.toUpperCase() : '' }
        {language.currency_code ? ' / '+language.currency_code : '' }
      </TableCell>
      <TableCell component="th" scope="row">
        <div style={{position: 'relative', height: 24}}>
          <Typography variant="body2" className={classes.input}>
            {(language.custom_name && language.custom_name !== '') ? language.custom_name : language.name}
          </Typography> 
        </div>
      </TableCell>
      <TableCell>
        <div style={{position: 'relative', height: 24}}>
          <Typography variant="body2" className={classes.input}>
            {language.domain ? language.domain : getAssignedDomain()}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="right">
        <span style={{position: 'relative', height: 24, marginRight: 16}}>
          <IconButton onClick={() => props.onShowEditDialog(language, props.listIndex )} style={styles.toggleEditModeBtn} className={classes.toggleEditModeBtn}>
            <EditOutlined />
          </IconButton>
        </span>
        <Button disabled={!props.crossDomainLinksEnabled} size="small" onClick={() => onRemoveRow(language)} variant="outlined" color="secondary">Remove</Button> 
      </TableCell>
    </TableRow>
  );
});

const SortableList = SortableContainer((props) => {
  const { classes, customIcons, selectedLanguageTemplate, rawLanguages, onChangeDomain, onChangeNewField, onSelectLanguage, onAddRow, onRemoveRow, onEditModeChange, onShowEditDialog, onUploaded, onRemoved } = props;
  
  const getCustomIconIfExisting = (key) => {
    if(customIcons[key]) {
      return customIcons[key];
    }
    return null;
  }

  return (<Table className={props.classes.table} size="small" aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Order</TableCell>
        <TableCell align='left'>Code</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Domain</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.items && props.items.map((item, index) => (
        
        <SortableItem key={`sortable-item-${item.iso_code}-${item.country_code}-${props.scope}`} 
          index={index} 
          listIndex={index} 
          language={item} 
          //onEditModeChange={onEditModeChange}
          icon_url={getCustomIconIfExisting(`${item.country_code ? item.country_code + '-' : ''}${item.iso_code}`)}
          itemKey={`${item.country_code ? item.country_code + '-' : ''}${item.iso_code}`}
          onUploaded={onUploaded}
          onRemoved={onRemoved}
          customIcons={customIcons}
          onShowEditDialog={onShowEditDialog}
          {...props}
        />
      ))}
    </TableBody>
  </Table>
  
  );
});


class DomainLocationMapper extends React.Component {
  _selectedLanguageObject = null;

  constructor(props) {
    super(props);

    this.state = {
      crossDomainLinksEnabled: props.crossDomainLinksEnabled,
      languages: props.languages,
      selectedLanguageTemplate: '',
      newName: '',
      newCustomName: '',
      newDomain: '',
      newIsoCode: '',
      newCountryCode: '',
      newCurrencyCode: '',
      dialogOpen: false,
      editingIndex: false,
    }
  }

  componentDidMount() {
    var newLanguages = [];
    this.props.rawLanguages.map((language, index) => {
      if(language.published) {
        let _domain = language.domain ? language.domain : false;
        let _root_url = language.base ? '/' : '/' + language.code;
        if(typeof language.assigned_to_domains.find(item => item.is_default === true) !== 'undefined') {
          _domain = language.assigned_to_domains.find(item => item.is_default === true).host;
          _root_url = '/';
        }  
        newLanguages.push({
          iso_code: language.code,
          root_url: _root_url,
          name: language.name,
          primary: language.base,
          domain: _domain,
          published: language.published,
          custom_name: language.custom_name ? language.custom_name : false
        });      
      }
    });
    this.setState({languages: newLanguages});
  }

  
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.crossDomainLinksEnabled !== prevState.crossDomainLinksEnabled) {
      return {crossDomainLinksEnabled: nextProps.crossDomainLinksEnabled};
    }
    if(!_.isEqual(nextProps.languages, prevState.languages)){
      return {languages : nextProps.languages};
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  onToggleCrossDomainLinks = () => {
    if(this.state.crossDomainLinksEnabled == true) {
      this.props.onDisableCrossDomainLinks();
    } else {
      this.props.onEnableCrossDomainLinks();
    }
  }

  onChangeDomain = (language, e) => {
    let newLanguages = this.state.languages.slice();
    newLanguages.find((x) => x.iso_code === language.iso_code).domain = e.target.value;

    this.setState({
      languages: newLanguages
    });
    this.props.onChange(newLanguages);
  }

  onChangeNewField = (field, e) => {
    //var field = field.replace('custom_', '');
    var newValue = e.target.value;
    console.log({field,newValue}, _.camelCase('new ' + field.replace('_', ' ')))
    if(field === 'domain') {
      if(newValue.indexOf('https://') === 0 || newValue.indexOf('http://') === 0 || newValue.indexOf('//') === 0) {
        newValue = newValue.replace('https://', '').replace('http://', '').replace('//', '');
      }
    }
    this.setState({
      [_.camelCase('new ' + field.replace('_', ' '))]: newValue
    });
  }

  onAddRow = (row) => {
    this._selectedLanguageObject = {
      iso_code: this.state.newIsoCode ? this.state.newIsoCode : '',
      country_code: this.state.newCountryCode ? this.state.newCountryCode : '',
      currency_code: this.state.newCurrencyCode ? this.state.newCurrencyCode : '',
      root_url: '/',
      name: this.state.newName,
      custom_name: this.state.newCustomName,
      primary: false,
      domain: this.state.newDomain,
      published: true,
    }

    let newLanguages = [...this.state.languages, this._selectedLanguageObject];

    console.log(this._selectedLanguageObject)

    this.setState({
      ...this._selectedLanguageObject,
      languages: newLanguages,
      selectedLanguageTemplate: '',
      newName: '',
      newCustomName: '',
      newDomain: '',
      newIsoCode: '',
      newCountryCode: '',
      newCurrencyCode: '',
      dialogOpen: false
    });

    this._selectedLanguageObject = null;
    this.props.onChange(newLanguages);
  }

  onEditRow = () => {
    if(this.state.editingIndex === false) return false;

    let newLanguages = this.state.languages.slice();
    this._selectedLanguageObject = newLanguages[this.state.editingIndex];
    this._selectedLanguageObject.iso_code = (this.state.newIsoCode !== false) ? this.state.newIsoCode : this._selectedLanguageObject.iso_code;
    this._selectedLanguageObject.country_code = (this.state.newCountryCode !== false) ? this.state.newCountryCode : this._selectedLanguageObject.country_code;
    this._selectedLanguageObject.currency_code = (this.state.newCurrencyCode !== false) ? this.state.newCurrencyCode : this._selectedLanguageObject.currency_code;
    this._selectedLanguageObject.custom_name = (this.state.newCustomName !== false) ? this.state.newCustomName : this._selectedLanguageObject.custom_name;
    this._selectedLanguageObject.domain = (this.state.newDomain !== false) ? this.state.newDomain : this._selectedLanguageObject.domain;

    console.log(this._selectedLanguageObject, this.state.newDomain)
    newLanguages[this.state.editingIndex] = {...newLanguages[this.state.editingIndex], ...this._selectedLanguageObject};

    this.setState({
      ...this._selectedLanguageObject,
      languages: newLanguages,
      selectedLanguageTemplate: '',
      newName: '',
      newCustomName: '',
      newDomain: '',
      newIsoCode: '',
      newCountryCode: '',
      newCurrencyCode: '',
      dialogOpen: false
    });

    this._selectedLanguageObject = null;
    this.props.onChange(newLanguages);
  }

  onRemoveRow = (row) => {
    let removeIndex = this.state.languages.findIndex(item =>  (item.iso_code === row.iso_code) && (item.country_code === row.country_code) && (item.currency_code === row.currency_code));
    let newLanguages = this.state.languages.slice();
    newLanguages.splice(removeIndex, 1);
    
    this.setState({
      languages: newLanguages
    });
    this.props.onChange(newLanguages);
  }

  onSelectLanguage = (e) => {
    if(e.currentTarget.getAttribute('template')) {

      this.setState({
        selectedLanguageTemplate: e.currentTarget.getAttribute('template'),
        dialogOpen: true
      });
    } else {
      const language = this.props.rawLanguages.find(item => item.code === e.target.value);
      if(language) {
        this._selectedLanguageObject = {
          iso_code: language.code,
          country_code: language.country_code,
          root_url: language.base ? '/' : '/' + language.code,
          name: language.name,
          custom_name: language.custom_name ? language.custom_name : null,
          primary: language.base,
          domain: language.domain ? language.domain : false,
          published: language.published,
        }
        this.setState({
          newName: this._selectedLanguageObject.name,
          newCustomName: this._selectedLanguageObject.custom_name,
          newDomain: this._selectedLanguageObject.domain,
          newIsoCode: this._selectedLanguageObject.iso_code,
          newCountryCode: this._selectedLanguageObject.country_code,
          newCurrencyCode: this._selectedLanguageObject.currency_code,
          selectedLanguageTemplate: e.target.value,
          dialogOpen: true,
          isEditing: false,
          editingIndex: false,
        });
      }
    }
  }

  onSortStart = ({node, index, collection, isKeySorting}, event)  => {
    const elem = document.querySelector('#sortableListWrapper2 table');
    const width = elem.offsetWidth;
    document.querySelector('.sortableHelper2').style.width = width+'px !important';
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const newLanguages = arrayMove(this.state.languages, oldIndex, newIndex);
    this.setState({
      languages: newLanguages,
    });
    this.props.onChange(newLanguages);
  };

  /*
  onEditModeChange = (isEditing, data) => {
    let newLanguages = this.state.languages.slice();
    if(!isEditing) {
      Object.entries(data).forEach(([key, value]) => {
        let newValue = data[key].value;
        
        if(key === 'domain') {
          if(data[key].value.indexOf('https://') === 0 || data[key].value.indexOf('http://') === 0 || data[key].value.indexOf('//') === 0) {
            newValue = newValue.replace('https://', '').replace('http://', '').replace('//', '');
          }
          if(data[key].value === '') {
            newValue = false;
          }
        }
        else if(key === 'custom_name' || key === 'iso_code' || key === 'country_code' || key === 'currency_code') {
          if(data[key].value === '') {
            newValue = newLanguages[data[key].index][data[key].field];
          }
        }

        if(key === 'iso_code' && data[key].value.indexOf('-') > -1) {
          
        }

        newLanguages[data[key].index][data[key].field] = newValue;
      })
      this.props.onChange(newLanguages);
    }
    this.setState({ 
      isEditing: isEditing,
      languages: newLanguages
    });
  }
  */

  handleShowEditDialog = (item, editingIndex) => {
    console.log(editingIndex)
    this.setState({
      newName: item.name,
      newCustomName: item.custom_name || item.name,
      newDomain: item.domain,
      newIsoCode: item.iso_code,
      newCountryCode: item.country_code,
      newCurrencyCode: item.currency_code,
      dialogOpen: true,
      isEditing: true,
      editingIndex: editingIndex,
    });
  }

  handleCloseDialog = (e) => {
    this.setState({
      selectedLanguageTemplate: '',
      newName: '',
      newCustomName: '',
      newDomain: '',
      newIsoCode: '',
      newCountryCode: '',
      newCurrencyCode: '',
      dialogOpen: false,
      isEditing: false,
      editingIndex: false,
    })
  }

  isKeyExisting = () => {
    if(!this.state.languages) return false;
    let foundKey = this.state.languages.find(language => {
      if (`${language.iso_code}-${language.country_code ? language.country_code : ''}-${language.currency_code ? language.currency_code : ''}`.toLowerCase() === `${this.state.newIsoCode}-${this.state.newCountryCode ? this.state.newCountryCode : ''}-${this.state.newCurrencyCode ? this.state.newCurrencyCode : ''}`.toLowerCase()){ 
        return true; 
      }
    });
    return (typeof foundKey !== 'undefined') ? true : false;
  }

  render() {
    const { t, customIcons, classes, title, description, bottom, rawLanguages } = this.props;

    let newRawLangs = [];
    rawLanguages.forEach((language, index) => {
      //if(!this.state.languages.find(x => x.iso_code === language.code)) 
        newRawLangs.push(<MenuItem key={`lang-${language.code}`} value={language.code}>
          <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${language.code}.svg')`}} /> {language.name}
        </MenuItem>);
    });

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">{t('switcherConfigurator.domainTitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t('switcherConfigurator.domainEnable')}
              <FormControlLabel
                className={classes.switchContainer}
                control={
                  <Switch onChange={this.onToggleCrossDomainLinks} checked={this.state.crossDomainLinksEnabled} defaultValue={this.state.crossDomainLinksEnabled} />
                }
                label={<Typography variant="caption">{this.state.crossDomainLinksEnabled ? t('system.enabled') : t('system.disabled')}</Typography>}
                labelPlacement="start"
              />
            </FormLabel>
            <FormGroup>
              <Divider style={{marginTop: 16, marginBottom: 0}} />
              <Typography variant="body2" style={{marginTop: 16, marginBottom: 8}}>
                {t('switcherConfigurator.domainInfo1')}
              </Typography>
              <ul style={{padding: '0 16px', margin: '12px 0 0 0'}}>
                <li>{t('switcherConfigurator.domainInfo2')}</li>
                <li>{t('switcherConfigurator.domainInfo3')}</li>
              </ul>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid id="sortableListWrapper2" item xs={12} style={{position: 'relative', marginBottom: 50}}>
          <SortableList 
            scope="cross-domain-links"
            items={this.state.languages} 
            customIcons={customIcons}
            onSortStart={this.onSortStart}
            onSortEnd={this.onSortEnd} 
            distance={10}
            helperClass={'sortableHelper2'}
            lockAxis="y"
            lockToContainerEdges={true}
            helperContainer={document.querySelector('#sortableListWrapper2 table')}
            useDragHandle

            onAddRow={this.onAddRow}
            onRemoveRow={this.onRemoveRow}
            onChangeDomain={this.onChangeDomain}
            onChangeNewField={this.onChangeNewField}
            onSelectLanguage={this.onSelectLanguage}
            //onEditModeChange={this.onEditModeChange}
            classes={classes}
            newName={this.state.newName}
            newDomain={this.state.newDomain}
            selectedLanguageTemplate={this.state.selectedLanguageTemplate}
            rawLanguages={this.props.rawLanguages} 
            crossDomainLinksEnabled={this.state.crossDomainLinksEnabled}
            onUploaded={this.props.onUploaded}
            onRemoved={this.props.onRemoved}
            onShowEditDialog={this.handleShowEditDialog}
          />
          <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, backgroundColor: 'rgba(255,255,255,.5)', display: !this.state.crossDomainLinksEnabled ? 'block' : 'none'}}></div>
        </Grid>



        <div id="addCrossDomainLinksBar" 
        className={classes.actionBar}
        >
          <FormControl className={'item'} variant="outlined" style={{width: 225, flexGrow: 'unset'}}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedLanguageTemplate}
              onChange={(e) => this.onSelectLanguage(e)} 
              disabled={!this.state.crossDomainLinksEnabled}
              size="medium"
              displayEmpty
              classes={{root: classes.input}}
              inputprops={{
                classes: {input: classes.input}
              }}
            >
              <MenuItem value="">
                <em>{t('switcherConfigurator.selectSwitcherItem')}</em>
              </MenuItem>
              <ListSubheader>{t('switcherConfigurator.customLinks')}</ListSubheader>
              <Tooltip 
                disableFocusListener={this.state.selectedLanguageTemplate === "__custom__"} 
                disableHoverListener={this.state.selectedLanguageTemplate === "__custom__"} 
                disableTouchListener={this.state.selectedLanguageTemplate === "__custom__"} 
                title="Extern links redirect to another extern domain and not to the actual own store." style={{ zIndex: 100 }} placement="right" arrow>
                <MenuItem value="__custom__" template="__custom__">
                  <div><InsertLinkIcon fontSize="small" />&nbsp;{t('switcherConfigurator.externLink')}</div>
                </MenuItem>
              </Tooltip>
              <Tooltip 
                disableFocusListener={this.state.selectedLanguageTemplate === "__intern__"} 
                disableHoverListener={this.state.selectedLanguageTemplate === "__intern__"} 
                disableTouchListener={this.state.selectedLanguageTemplate === "__intern__"} 
                title="Intern links redirect to a language specific route or another domain that belongs to the own store." style={{ zIndex: 100 }} placement="right" arrow>
                <MenuItem value="__intern__" template="__intern__">
                  <div><InsertLinkIcon fontSize="small" />&nbsp;{t('switcherConfigurator.internLink')}</div>
                </MenuItem>
              </Tooltip>
              <ListSubheader>{t('switcherConfigurator.languages')}</ListSubheader>
              {newRawLangs.length > 0 ?
                (newRawLangs)
              :
                <MenuItem disabled>
                  <small>{t('switcherConfigurator.allInUseInfo')}</small>
                </MenuItem>
              }
            </Select>
          </FormControl>

            {/*this.state.selectedLanguageTemplate &&
                <Button 
                  className={'item2'} 
                  disabled={this.state.selectedLanguageTemplate === "__custom__" && (this.state.newName === '' || this.state.newDomain === '') || 
                            this.state.selectedLanguageTemplate === "__intern__" && (this.state.newName === '' || this.state.newDomain === '' || this.state.newIsoCode === '')} 
                  variant="contained" 
                  color="secondary" 
                  size="medium"
                  onClick={this.onAddRow}
                >
                  Add link
                </Button>
            */}


          <Dialog aria-labelledby="simple-dialog-title" open={this.state.dialogOpen} onClose={this.handleCloseDialog}>
            <DialogTitle id="simple-dialog-title">
              {this.state.isEditing ?
                'Edit Switcher Link'
              :
                'Create new Switcher Link'
              }
            </DialogTitle>
            <DialogContent>
              {/*(this.state.selectedLanguageTemplate === "__custom__" || this.state.selectedLanguageTemplate === "__intern__") &&*/
                <React.Fragment>
                  <div style={{display: 'flex', justifyContent: 'space-between', gap: 12}}>
                    {/*(this.state.selectedLanguageTemplate === "__custom__" || this.state.selectedLanguageTemplate === "__intern__") &&*/
                      <TextField
                        value={this.state.newIsoCode}
                        onChange={(e) => this.onChangeNewField('iso_code', e)}
                        label={'Language code'} 
                        placeholder={'e.g. fr'} 
                        helperText={this.state.selectedLanguageTemplate !== "__intern__" && 'optional'}
                        disabled={!this.state.crossDomainLinksEnabled}
                        size="medium"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        className={classes.textField}
                        //fullWidth
                      />
                    }
                    {/*(this.state.selectedLanguageTemplate === "__intern__" || this._selectedLanguageObject) &&*/
                      <>
                        <Tooltip title="If you want to link to a language on a specific market, add the country code here." style={{ zIndex: 100 }} placement="top" arrow>
                          <TextField
                            value={this.state.newCountryCode}
                            onChange={(e) => this.onChangeNewField('country_code', e)}
                            label={'Country code'} 
                            placeholder={'e.g. CA'} 
                            helperText={'optional'}
                            disabled={!this.state.crossDomainLinksEnabled}
                            size="medium"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            //fullWidth
                          />
                        </Tooltip>
                        <Tooltip title="Extern links redirect to another extern domain and not to the actual own store." style={{ zIndex: 100 }} placement="top" arrow>
                          <TextField
                            value={this.state.newCurrencyCode}
                            onChange={(e) => this.onChangeNewField('currency_code', e)}
                            label={'Currency code'} 
                            placeholder={'e.g. €'} 
                            helperText={'optional'}
                            disabled={!this.state.crossDomainLinksEnabled}
                            size="medium"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            //fullWidth
                          />
                        </Tooltip>
                      </>
                    }
                  </div>
                  <TextField
                    value={this.state.newCustomName}
                    onChange={(e) => this.onChangeNewField('custom_name', e)}
                    label={'Name'} 
                    placeholder={'Eg. the language name'} 
                    disabled={!this.state.crossDomainLinksEnabled}
                    size="medium"
                    className={classes.textField}
                    //InputProps={{classes: {input: classes.input}}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    value={this.state.newDomain ? this.state.newDomain : ''}
                    onChange={(e) => this.onChangeNewField('domain', e)}
                    label={'Domain'} 
                    placeholder={'Enter a domain here...'} 
                    disabled={!this.state.crossDomainLinksEnabled}
                    size="medium"
                    className={classes.textField}
                    //InputProps={{classes: {input: classes.input}}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />      
                </React.Fragment>
              }
              {this.isKeyExisting() && !this.state.isEditing &&
                <Typography variant={'caption'} style={{color: 'red'}}>
                  There already is another object with the same key in the list. Please edit or change the language-, country- or currency-code.
                </Typography>
              }
            </DialogContent>
            <DialogActions>
              <Button 
                className={'item2'} 
                size="medium"
                onClick={this.handleCloseDialog}
              >
                Cancel
              </Button>
              {this.state.isEditing ?
                <Button 
                  className={'item2'} 
                  disabled={(this.state.selectedLanguageTemplate === "__custom__" && (this.state.newName === '' || this.state.newDomain === '')) || 
                            (this.state.selectedLanguageTemplate === "__intern__" && (this.state.newName === '' || this.state.newDomain === '' || this.state.newIsoCode === ''))} 
                  variant="contained" 
                  color="secondary" 
                  size="medium"
                  onClick={this.onEditRow}
                >
                  Edit link
                </Button>
              :
                <Button 
                  className={'item2'} 
                  disabled={this.isKeyExisting() === true || 
                            (this.state.selectedLanguageTemplate === "__custom__" && (this.state.newCustomName === '' || this.state.newDomain === '')) || 
                            (this.state.selectedLanguageTemplate === "__intern__" && (this.state.newCustomName === '' || this.state.newDomain === '' || this.state.newIsoCode === ''))} 
                  variant="contained" 
                  color="secondary" 
                  size="medium"
                  onClick={this.onAddRow}
                >
                  Create link
                </Button>
              }
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(DomainLocationMapper));