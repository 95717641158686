import API from '../api/api'

const GET_CUSTOM_CONTENTS_PENDING = 'GET_CUSTOM_CONTENTS_PENDING'
const GET_CUSTOM_CONTENTS_RESPONSE = 'GET_CUSTOM_CONTENTS_RESPONSE'
const SAVE_CUSTOM_CONTENTS_PENDING = 'SAVE_CUSTOM_CONTENTS_PENDING'
const SAVE_CUSTOM_CONTENTS_PROGRESS = 'SAVE_CUSTOM_CONTENTS_PROGRESS'
const SAVE_CUSTOM_CONTENTS_RESPONSE = 'SAVE_CUSTOM_CONTENTS_RESPONSE'
const CREATE_CUSTOM_CONTENTS_PENDING = 'CREATE_CUSTOM_CONTENTS_PENDING'
const CREATE_CUSTOM_CONTENTS_PROGRESS = 'CREATE_CUSTOM_CONTENTS_PROGRESS'
const CREATE_CUSTOM_CONTENTS_RESPONSE = 'CREATE_CUSTOM_CONTENTS_RESPONSE'
const DELETE_CUSTOM_CONTENTS_PENDING = 'DELETE_CUSTOM_CONTENTS_PENDING'
const DELETE_CUSTOM_CONTENTS_PROGRESS = 'DELETE_CUSTOM_CONTENTS_PROGRESS'
const DELETE_CUSTOM_CONTENTS_RESPONSE = 'DELETE_CUSTOM_CONTENTS_RESPONSE'

const EXPORT_CUSTOM_CONTENTS_PENDING = 'EXPORT_CUSTOM_CONTENTS_PENDING'
const EXPORT_CUSTOM_CONTENTS_PROGRESS = 'EXPORT_CUSTOM_CONTENTS_PROGRESS'
const EXPORT_CUSTOM_CONTENTS_RESPONSE = 'EXPORT_CUSTOM_CONTENTS_RESPONSE'

const IMPORT_CUSTOM_CONTENTS_PENDING = 'IMPORT_CUSTOM_CONTENTS_PENDING'
const IMPORT_CUSTOM_CONTENTS_PROGRESS = 'IMPORT_CUSTOM_CONTENTS_PROGRESS'
const IMPORT_CUSTOM_CONTENTS_RESPONSE = 'IMPORT_CUSTOM_CONTENTS_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  get_custom_contents_pending: true,
  save_custom_contents_pending: false,
  export_custom_contents_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_custom_contents_pending: false,
  imported_entries: 0,
  create_custom_contents_pending: false,
  delete_custom_contents_pending: false,
  progress: 0,
  error: false,
  custom_contents: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case GET_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        get_custom_contents_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case GET_CUSTOM_CONTENTS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_custom_contents_pending: false,
          custom_contents: action.response.custom_contents
        }
      } else {
        return {
          ...state,
          get_custom_contents_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }

    case SAVE_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        save_custom_contents_pending: true,
        error: false,
      }
    }
    case SAVE_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_CUSTOM_CONTENTS_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.custom_contents);
      console.log(action.response, action.items);
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          newCustomContents[action.items[i].id].target.text = action.items[i].text;
        } else {
          newCustomContents[response.custom_content.id] = {
            progress: response.custom_content.progress,
            language_id: response.custom_content.language_id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          }
        }
      })
      return {
        ...state,
        custom_contents: newCustomContents,
        save_custom_contents_pending: false,
        error: error
      }
    }

    case CREATE_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_CUSTOM_CONTENTS_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.custom_contents);
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          newCustomContents[response.custom_content.id] = {
            progress: response.custom_content.progress,
            language_id: response.custom_content.language_id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          }
        }
      })
      return {
        ...state,
        custom_contents: newCustomContents,
        create_custom_contents_pending: false,
        error: error
      }
    }

    case DELETE_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        delete_custom_contents_pending: true,
        error: false,
      }
    }
    case DELETE_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_CUSTOM_CONTENTS_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.custom_contents);
      action.response.forEach(response => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          delete newCustomContents[response.id];
        }
      })
      return {
        ...state,
        custom_contents: newCustomContents,
        delete_custom_contents_pending: false,
        error: error
      }
    }

    case EXPORT_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        progress: 0,
        export_custom_contents_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_CUSTOM_CONTENTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_CUSTOM_CONTENTS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_custom_contents_pending: false,
          export_po_download_url: action.response.po_download_url,
          export_csv_download_url: action.response.csv_download_url

        }
      } else {
        return {
          ...state,
          progress: 100,
          export_custom_contents_pending: false
        }
      }
    }
    case IMPORT_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        progress: 0,
        import_custom_contents_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_CUSTOM_CONTENTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_CUSTOM_CONTENTS_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_custom_contents_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_custom_contents_pending: false,
          imported_entries: importedEntries
        }
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const getCustomContents = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_CUSTOM_CONTENTS_PENDING
    })

    API.getCustomContents(language, response => {
      dispatch({
        type: GET_CUSTOM_CONTENTS_RESPONSE,
        response: response,
        action: () => getCustomContents(language)
      })
    })
  }
}

export const saveCustomContents = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_CUSTOM_CONTENTS_PENDING
    })

    API.saveCustomContents(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => saveCustomContents(language, items)
        })
      })
  }
}

export const createCustomContents = (language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_CUSTOM_CONTENTS_PENDING
    })

    API.createCustomContents(
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createCustomContents(items)
        })
      })
  }
}

export const deleteCustomContents = (ids) => {  
  return dispatch => {
    dispatch({
      type: DELETE_CUSTOM_CONTENTS_PENDING
    })

    API.deleteCustomContents(
      ids,
      progress => {
        dispatch({
          type: DELETE_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          action: () => deleteCustomContents(ids)
        })
      })
  }
}

export const exportCustomContents = (language) => {
  return dispatch => {
    dispatch({
      type: EXPORT_CUSTOM_CONTENTS_PENDING
    })

    API.exportCustomContents(
      language,
      progress => {
        dispatch({
          type: EXPORT_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_CUSTOM_CONTENTS_RESPONSE,
          response: response
        }
      )
    })
  }
}

export const importCustomContents = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_CUSTOM_CONTENTS_PENDING
    })

    API.importCustomContents(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_CUSTOM_CONTENTS_RESPONSE,
          response: response
        }
      )
    })
  }
}