import API from '../api/api'

const SYNC_IMAGES_PENDING = 'SYNC_IMAGES_PENDING'
const SYNC_IMAGES_PROGRESS = 'SYNC_IMAGES_PROGRESS'
const SYNC_IMAGES_RESPONSE = 'SYNC_IMAGES_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  sync_images_pending: true,
  progress: 0,
  error: false,
  images: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case SYNC_IMAGES_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_images_pending: true,
        error: false,
      }
    }
    case SYNC_IMAGES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_IMAGES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_images_pending: false,
          error: false,
          images: action.response.images
        }
      } else {
        return {
          ...state,
          sync_images_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
      return {
        ...state,
        sync_images_pending: false
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncImages = (themeId, languageId) => {  
  return dispatch => {
    dispatch({
      type: SYNC_IMAGES_PENDING
    })

    API.syncImages(
      themeId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_IMAGES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_IMAGES_RESPONSE,
          response: response
        }
      )
    })
  }
}