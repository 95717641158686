import API from '../api/api'
import Cookies from 'universal-cookie';

const SET_RECENTLY_CHANGED_CONTENT_FILTER = 'SET_RECENTLY_CHANGED_CONTENT_FILTER'
const RECENTLY_CHANGED_CONTENT_FILTER_CHANGED = 'RECENTLY_CHANGED_CONTENT_FILTER_CHANGED'

const GET_TRANSLATION_PROGRESSES_PENDING = 'GET_TRANSLATION_PROGRESSES_PENDING'
const GET_TRANSLATION_PROGRESSES_PROGRESS = 'GET_TRANSLATION_PROGRESSES_PROGRESS'
const GET_TRANSLATION_PROGRESSES_RESPONSE = 'GET_TRANSLATION_PROGRESSES_RESPONSE'

const GET_RECENTLY_CHANGED_CONTENT_PENDING = 'GET_RECENTLY_CHANGED_CONTENT_PENDING'
const GET_RECENTLY_CHANGED_CONTENT_PROGRESS = 'GET_RECENTLY_CHANGED_CONTENT_PROGRESS'
const GET_RECENTLY_CHANGED_CONTENT_RESPONSE = 'GET_RECENTLY_CHANGED_CONTENT_RESPONSE'

const GET_MORE_RECENTLY_CHANGED_CONTENT_PENDING = 'GET_MORE_RECENTLY_CHANGED_CONTENT_PENDING'
const GET_MORE_RECENTLY_CHANGED_CONTENT_PROGRESS = 'GET_MORE_RECENTLY_CHANGED_CONTENT_PROGRESS'
const GET_MORE_RECENTLY_CHANGED_CONTENT_RESPONSE = 'GET_MORE_RECENTLY_CHANGED_CONTENT_RESPONSE'

const cookies = new Cookies();

const initialState = {
  recently_changed_content_filter: cookies.get('recently_changed_content_filter') ? cookies.get('recently_changed_content_filter') : 'products',
  get_translation_progresses_pending: true,
  get_recently_changed_content_pending: false,
  get_more_recently_changed_content_pending: false,
  translation_progresses: [],
  recently_changed_content: [],
  recently_changed_content_next_page_links: {},
  error: false,
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case GET_TRANSLATION_PROGRESSES_PENDING: {
      return {
        ...state,
        progress: 0,
        get_translation_progresses_pending: true
      }
    }
    case GET_TRANSLATION_PROGRESSES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_TRANSLATION_PROGRESSES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_translation_progresses_pending: false,
          error: false,
          translation_progresses: action.response.translation_progresses.slice(),
        }
      } else {
        return {
          ...state,
          get_translation_progresses_pending: false,
          error: true
        }
      }
      return {
        ...state,
        get_translation_progresses_pending: false
      }
    }
    case GET_RECENTLY_CHANGED_CONTENT_PENDING: {
      return {
        ...state,
        progress: 0,
        get_recently_changed_content_pending: true
      }
    }
    case GET_RECENTLY_CHANGED_CONTENT_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_RECENTLY_CHANGED_CONTENT_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_recently_changed_content_pending: false,
          error: false,
          recently_changed_content: action.response.recently_changed_content['content'].slice(),
          recently_changed_content_next_page_links: {...action.response.recently_changed_content['next_page_links']}
        }
      } else {
        return {
          ...state,
          get_recently_changed_content_pending: false,
          error: true
        }
      }
    }
    case GET_MORE_RECENTLY_CHANGED_CONTENT_PENDING: {
      return {
        ...state,
        progress: 0,
        get_more_recently_changed_content_pending: true
      }
    }
    case GET_MORE_RECENTLY_CHANGED_CONTENT_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_MORE_RECENTLY_CHANGED_CONTENT_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_more_recently_changed_content_pending: false,
          error: false,
          recently_changed_content: state.recently_changed_content.concat(action.response.recently_changed_content['content'].slice()),
          recently_changed_content_next_page_links: {...action.response.recently_changed_content['next_page_links']}
        }
      } else {
        return {
          ...state,
          get_more_recently_changed_content_pending: false,
          error: true
        }
      }
    }
    case RECENTLY_CHANGED_CONTENT_FILTER_CHANGED: {
      return {
        ...state,
        recently_changed_content_filter: action.filter
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const getTranslationProgress = (languageId, themeId) => { 
  return dispatch => {
    dispatch({
      type: GET_TRANSLATION_PROGRESSES_PENDING
    })

    API.getTranslationProgress(
      languageId,
      themeId,
      progress => {
        dispatch({
          type: GET_TRANSLATION_PROGRESSES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_TRANSLATION_PROGRESSES_RESPONSE,
          response: response,
          languageId: languageId
        })
      }
    )
  }
}

export const getRecentlyChangedContent = (filter, languageId) => { 
  return dispatch => {
    dispatch({
      type: GET_RECENTLY_CHANGED_CONTENT_PENDING
    })

    API.getRecentlyChangedContent(
      filter,
      languageId,
      progress => {
        dispatch({
          type: GET_RECENTLY_CHANGED_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_RECENTLY_CHANGED_CONTENT_RESPONSE,
          response: response,
          languageId: languageId
        })
      }
    )
  }
}

export const getMoreRecentlyChangedContent = (pageLinks, languageId) => { 
  return dispatch => {
    dispatch({
      type: GET_MORE_RECENTLY_CHANGED_CONTENT_PENDING
    })

    API.getMoreRecentlyChangedContent(
      pageLinks,
      languageId,
      progress => {
        dispatch({
          type: GET_MORE_RECENTLY_CHANGED_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_MORE_RECENTLY_CHANGED_CONTENT_RESPONSE,
          response: response,
          languageId: languageId
        })
      }
    )
  }
}

export const setRecentlyChangedContentFilter = (filter) => { 
  return dispatch => {
    cookies.set('recently_changed_content_filter', filter, {path: '/'})

    dispatch({
      type: RECENTLY_CHANGED_CONTENT_FILTER_CHANGED,
      filter: filter
    })
  }
}