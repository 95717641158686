import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  contained: {
    marginTop: '0 !important',
    borderRadius: 0,
  },
  progressContainer: {
    padding: '11px 16px 11px 16px'
  },
  progress: {
    background: '#eee',
    padding: '10px',
    background: '#eee',
    margin: '10px'
  },
});

class ItemsListSkeleton extends React.Component {
  render() {
    const { classes, rows, contained } = this.props;

    let listItems = [];
    for(let i = 0; i < rows; i++) {
      listItems.push(
        <div key={`placeholder-${i}-${rows}`}>
          <div className={classes.progressContainer}>
            <div className={classes.progress}>
              
            </div>
          </div>   
          <Divider />       
        </div>
      )
    }

    return (
      <Paper className={classNames(classes.root, contained ? classes.contained : null)} elevation={contained ? 0 : 1}>
        {!this.props.hideTopToolbar &&
          <Toolbar>
            <div className={classes.progressContainer} style={{padding: 0, width: '10%'}}>
              <div className={classes.progress}>
                
              </div>
            </div>
          </Toolbar>
        }
        <Divider />
        <div>
          <List style={{padding: 0}}>
          {listItems}
          </List>
        </div>

        {!this.props.hideBottomToolbar && <Toolbar />}
      </Paper>
    );
  }
}

export default withStyles(styles)(ItemsListSkeleton);
