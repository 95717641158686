import API from '../api/api'
import cloneDeep from 'lodash/cloneDeep';

const SYNC_MARKETS_PENDING = 'SYNC_MARKETS_PENDING'
const SYNC_MARKETS_PROGRESS = 'SYNC_MARKETS_PROGRESS'
const SYNC_MARKETS_RESPONSE = 'SYNC_MARKETS_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  sync_markets_pending: true,
  progress: 0,
  error: false,
  markets: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case SYNC_MARKETS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_markets_pending: true,
        error: false,
      }
    }
    case SYNC_MARKETS_RESPONSE: {
      if(action.response.data) {

        let newMarkets = [];

        action.response.data.markets.nodes.forEach(market => {

          /*
{
    "name": "United States",
    "id": "gid://shopify/Market/263716954",
    "webPresence": {
        "market": {
            "regions": {
                "pageInfo": {
                    "hasNextPage": false
                },
                "nodes": [
                    {
                        "name": "United States"
                    }
                ]
            },
            "enabled": true,
            "name": "United States",
            "currencySettings": {
                "baseCurrency": {
                    "currencyCode": "EUR",
                    "currencyName": "Euro"
                },
                "localCurrencies": false
            }
        },
        "domain": null,
        "defaultLocale": "en",
        "alternateLocales": [],
        "rootUrls": [
            {
                "url": "https://langify-ux-3-2.myshopify.com/en-tw",
                "locale": "en"
            }
        ],
        "subfolderSuffix": "tw"
    }
}
           */
          let newMarket = {
            name: market.name,
            id: market.id,
            regions: market.webPresence.market.regions,
            currencySettings: market.webPresence.market.currencySettings,
            domain: market.webPresence.domain,
            defaultLocale: market.webPresence.defaultLocale,
            alternateLocales: market.webPresence.alternateLocales,
            rootUrls: market.webPresence.rootUrls,
            subfolderSuffix: market.webPresence.subfolderSuffix,
          }
          newMarkets.push(newMarket)
        });

        return {
          ...state,
          sync_markets_pending: false,
          error: false,
          markets: newMarkets
        }
      } else {
        return {
          ...state,
          sync_markets_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
      return {
        ...state,
        sync_markets_pending: false
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncMarkets = () => {  
  return dispatch => {
    dispatch({
      type: SYNC_MARKETS_PENDING
    })
    API.getMarkets(
      response => {
        dispatch({
          type: SYNC_MARKETS_RESPONSE,
          response: response
        }
      )
    })
  }
}