import React, { Component, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  icon: {
    marginRight: 5, 
    marginTop: -5, 
    '& svg': {
      marginTop:-5
    }
  }
});

const DontAskAgainDialog = (props) => {

  const storageKey = `ly_not_ask_again_${props.context}`;

  const [isOpen, setIsOpen] = useState(false);
  const [notAskAgain, setAskAgain] = useState(localStorage.getItem(storageKey));
  const classes = useStyles(props);

  const t = props.t;

  useEffect(() => {
    if(props.open === true) {
      if(localStorage.getItem(storageKey)) {
        if(props.onSubmit) {
          handleSubmit();
        }
      } else {
        setIsOpen(true);
      }
      if(props.initialChecked) {
        handleAskAgain({target: {checked: true}});
      }
    }
  }, [props.open]);

  const closeDialog = (e) => {
    setAskAgain(false);
    setIsOpen(false);
    setAskAgain(false);
    if(props.onClose) {
      props.onClose();
    }
  }

  const handleAskAgain = event => {
    if(!event.target.checked) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, event.target.checked);
    }
    setAskAgain(event.target.checked);
  };

  const handleSubmit = (e) => {
    if(props.onSubmit) {
      props.onSubmit();
    }
    closeDialog();
  }

  const handleCancel = () => {
    localStorage.removeItem(storageKey);
    if(props.onCancel) {
      props.onCancel();
    }
    closeDialog();
  }

  return <Dialog
    onClose={!props.suppressBackdropClick ? closeDialog : null}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    BackdropProps={{
      style: {
        backgroundColor: 'rgba(255, 255, 255, .5)'
      }
    }}
  >
    <DialogTitle id="customized-dialog-title" onClose={() => setIsOpen(false)}>
      <Typography variant="h5" component="span">
        {props.icon && <span className={classes.icon}>{props.icon}</span>}
        {props.title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      {props.content}
    </DialogContent>
    <DialogActions>
      <FormControlLabel
        control={
          <Checkbox
            style={{paddingRight: 6}}
            checked={notAskAgain || false}
            onChange={handleAskAgain}
            value="notAskAgain"
          />
        }
        style={{
          marginRight: 16, 
          opacity: props.visuallyHideCheckbox ? 0 : 1,
          cursor: props.visuallyHideCheckbox ? 'default' : 'pointer',
        }}
        label={t('system.notAskAgain')}
      />
      <Button variant="outlined" onClick={handleCancel} color="default" size="medium">
        {t('system.cancel')}
      </Button>
      <Button variant="contained" onClick={handleSubmit} color="secondary" size="medium">
        {t('system.continue')}
      </Button>
    </DialogActions>
  </Dialog>;
} 

export default withTranslation()(DontAskAgainDialog);