import API from '../api/api';

const GET_SWITCHER_CONFIG_PENDING = 'GET_SWITCHER_CONFIG_PENDING';
const GET_SWITCHER_CONFIG_RESPONSE = 'GET_SWITCHER_CONFIG_RESPONSE';
const GET_CUSTOM_ICONS_PENDING = 'GET_CUSTOM_ICONS_PENDING';
const GET_CUSTOM_ICONS_RESPONSE = 'GET_CUSTOM_ICONS_RESPONSE';
const UPDATE_CUSTOM_ICONS_METAFIELD_PENDING = 'UPDATE_CUSTOM_ICONS_METAFIELD_PENDING';
const UPDATE_CUSTOM_ICONS_METAFIELD_RESPONSE = 'UPDATE_CUSTOM_ICONS_METAFIELD_RESPONSE';
const UPDATE_SWITCHER_LANGUAGES_PENDING = 'UPDATE_SWITCHER_LANGUAGES_PENDING';
const UPDATE_SWITCHER_LANGUAGES_PROGRESS = 'UPDATE_SWITCHER_LANGUAGES_PROGRESS';
const UPDATE_SWITCHER_LANGUAGES_RESPONSE = 'UPDATE_SWITCHER_LANGUAGES_RESPONSE';
const UPDATE_SWITCHER_CONFIG = 'UPDATE_SWITCHER_CONFIG';


const initialState = {
  error: false,
  switcherConfig: {},
  getSwitcherConfigPending: false,
  updateSwitcherConfig: false,
  customIcons: {}
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case GET_SWITCHER_CONFIG_PENDING: {
      return {
        ...state,
        updateSwitcherConfig: true,
      }
    }
    case GET_SWITCHER_CONFIG_RESPONSE: {
      let switcherConfig = {};
      if(action.response.success && action.response.result && action.response.result.value) {
        switcherConfig = JSON.parse(action.response.result.value);
      }
      return {
        ...state,
        updateSwitcherConfig: false,
        switcherConfig: switcherConfig,
      }
    }

    case GET_CUSTOM_ICONS_PENDING: {
      return {
        ...state,
      }
    }
    case GET_CUSTOM_ICONS_RESPONSE: {
      let customIcons = {};
      if(action.response.success && action.response.result && action.response.result.value) {
        customIcons = JSON.parse(action.response.result.value);
      }
      return {
        ...state,
        customIcons: customIcons,
      }
    }

    case UPDATE_CUSTOM_ICONS_METAFIELD_PENDING: {
      return {
        ...state,
      }
    }
    case UPDATE_CUSTOM_ICONS_METAFIELD_RESPONSE: {
      let customIcons = {};
      if(action.response.success && action.response.result && action.response.result.value) {
        customIcons = JSON.parse(action.response.result.value);
      }
      return {
        ...state,
        customIcons: customIcons,
      }
    }

    case UPDATE_SWITCHER_LANGUAGES_PENDING: {
      return {
        ...state,
        updateSwitcherConfig: false,
      }
    }
    case UPDATE_SWITCHER_LANGUAGES_PROGRESS: {
      return  {
        ...state,
      }
    }
    case UPDATE_SWITCHER_LANGUAGES_RESPONSE: {
      return {
        ...state,
      }
    }
    case UPDATE_SWITCHER_CONFIG: {
      return {
        ...state,
        updateSwitcherConfig: action.updateSwitcherConfig,
      }
    }
    default:
      return state
  }
}

// ACTIONS    
export const getSwitcherConfig = (themeId, callback) => { 
  return dispatch => {
    dispatch({
      type: GET_SWITCHER_CONFIG_PENDING
    })
    API.getMetafield('global', `ly_switcher_data_${themeId}`, (response) => {
        dispatch({
          type: GET_SWITCHER_CONFIG_RESPONSE,
          response: response
        })
        callback(response);
    });
  }
}

export const getCustomIcons = () => { 
  return dispatch => {
    dispatch({
      type: GET_CUSTOM_ICONS_PENDING
    })
    API.getMetafield('global', 'ly_switcher_data_custom_icons', (response) => {
        dispatch({
          type: GET_CUSTOM_ICONS_RESPONSE,
          response: response
        })
    });
  }
}

export const updateCustomIconsMetafield = (action, imageKey, url, alt) => {
  //console.log(action, imageKey, url)
  return dispatch => {
    dispatch({
      type: UPDATE_CUSTOM_ICONS_METAFIELD_PENDING
    })
    API.getMetafield('global', 'ly_switcher_data_custom_icons', (data) => {
      if(data.success && data.result) { // && data.result.value) {
        let customImages = data.result.value ? JSON.parse(data.result.value) : {};
        if(action === 'add' && url !== '') {
          customImages[imageKey] = url + ';' + alt;
        }
        if(action === 'del' && customImages[imageKey]) {
          delete customImages[imageKey];
        }
        API.saveCustomMetafield('global', 'ly_switcher_data_custom_icons', JSON.stringify(customImages), 'json_string', (res) => {
          if(res.success) { 

          }

          dispatch({
            type: UPDATE_CUSTOM_ICONS_METAFIELD_RESPONSE,
            response: res,
          })
        })
      }
    });
  }
}

//
// TODO: countryCode param muss noch in languages object eingebunden werden.
// TODO: saveIconAsset nicht ausführen, wenn App Extensions enabled ist. 
export const uploadFlagIcons = (languages, themeId) => {
  const  saveIconAsset = (code, iconData) => {
    const data = {
      key: `assets/ly-icon-${code}.svg`,
      value: iconData,
    }
    API.getCustomAsset(themeId, data, (res) => {
      if(res.success === false || res.value === null || !res.value || res.value.value !== iconData) {
        API.saveCustomAsset(themeId, data, (res) => {
          //console.log('Icon uploaded', res);
        });        
      }
    });
  }
  languages.map((language) => {

    let flagIconsFolderName = 'flag-icons';
    if(language.countryCode) {
      flagIconsFolderName += '-country';
    }

    API.saveCustomMetafield('v2_lang_name_map', `ly-${language.countryCode ? language.countryCode : language.code}`, language.name, 'string', (res) => {
      //console.log(res);
    });

    fetch(`/${flagIconsFolderName}/${language.code}.svg`)
      .then(response => response.text())
      .then(text => saveIconAsset(language.code, text));

    fetch(`/${flagIconsFolderName}/${language.code}_1x1.svg`)
      .then(response => response.text())
      .then(text => saveIconAsset(`${language.code}_1x1`, text));
  });
}




const updateSwitcherConfig = (currentLanguages, themeId, themes, callback) => {
  const currentTheme = themes.find(x => x.id == themeId);
  API.getMetafield('global', `ly_switcher_data_${currentTheme.shopify_id}`, (data) => {
    if(data.success && data.result && data.result.value) {
      let options = JSON.parse(data.result.value);

      if(!options.languages) return false;

      // Update languages array
      let configLanguages = [...options.languages];
      let deletedLanguages = [];

      // languages aus switcher config iterieren
      // checken ob language im languages Objekt vorhanden ist und wenn nicht lang aus configLanguages entfernen
      configLanguages.map((language, index) => {
        if(currentLanguages.findIndex(x => x.code === language.iso_code) === -1) {
          if(language.iso_code.indexOf('__custom__') === -1) {
            deletedLanguages.push(index);
          }
        } else {
          language.published = currentLanguages.find(x => x.code === language.iso_code).published;
        }
      });
      deletedLanguages.forEach(item => {
        configLanguages.splice(item, 1);

      });

      // Prüfen, ob in languages ein item enthalten ist, welches es noch nicht in configLanguages gibt und ggf. hinzufügen
      if(configLanguages.length < currentLanguages.length) {
        currentLanguages.map(lang => {
          if(configLanguages.findIndex(x => x.iso_code === lang.code) === -1) {
            if(lang.published) {
              configLanguages.push({
                iso_code: lang.code,
                root_url: lang.base ? '/' : '/' + lang.code,
                name: lang.name,
                primary: false,
                domain: lang.domain ? lang.domain : false,
                published: lang.published,
                custom_name: lang.custom_name ? lang.custom_name : false,
                endonym_name: lang.endonym_name ? lang.endonym_name : lang.name, // TODO: Pass through the endonym_name from backend
              });
            }
          }
        });
      }

      // Save updated config and update metafield
      options.languages = configLanguages;

      callback(options);

      API.saveCustomMetafield('global', `ly_switcher_data_${currentTheme.shopify_id}`, JSON.stringify(options), 'json_string', (res) => {});
    }
  });
}

const lyDelimiter = '{% capture ly_liquid_content %}';
export const updateSwitcherLanguages = (languages, themeId, themes) => { 
  return dispatch => {
    dispatch({
      type: UPDATE_SWITCHER_LANGUAGES_PENDING
    })



    // Check if langify is published on the theme
    const data = {
      key: 'layout/theme.liquid',
    };
    API.getCustomAsset(themeId, data, (res) => {
      if(res.success && res.value) {
        if(res.value.value.indexOf(lyDelimiter) !== -1) {
          //uploadFlagIcons(languages, themeId);
          updateSwitcherConfig(languages, themeId, themes, (config) => {
            dispatch({
              type: UPDATE_SWITCHER_CONFIG,
              updateSwitcherConfig: config,
            });
          });

          dispatch({
            type: UPDATE_SWITCHER_LANGUAGES_RESPONSE
          })
        }
      }
    });
  }
}