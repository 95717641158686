import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '20px',
    padding: '24px 16px'
  },
  title: {
    color: '#000 !important',
    textAlign: 'center',
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    marginTop: '10px',
    marginBottom: '10px'
  },
  description: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.5em',
    textAlign: 'center'
  },
  bottom: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.5em',
    textAlign: 'center'
  },
  rootDense: {
    background: 'transparent',
    padding: 12,
    margin: 0,
    boxShadow: 'none',
  },
  titleDense: {
    color: '#000 !important',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
    marginTop: '10px',
    marginBottom: '10px'
  },
  descriptionDense: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.5em',
    textAlign: 'center',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  bottomDense: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.5em',
    textAlign: 'center'
  },
});

class EmptyView extends React.Component {

  render() {
    const { classes, title, description, bottom, dense } = this.props;

    return (
      <Paper className={dense ? classes.rootDense : classes.root}>
          <h6 className={dense ? classes.titleDense : classes.title}>{title}</h6>
          {description && <p className={dense ? classes.descriptionDense : classes.description}>{description}</p>}
          {bottom && <p className={dense ? classes.bottomDense : classes.bottom}>{bottom}</p>}
      </Paper>
    );
  }
}

export default withStyles(styles)(EmptyView);
