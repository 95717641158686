import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  textField: {
    margin: 0,
    padding: 0,
  },
  inputField: {
    padding: '12px 14px',
    fontSize: 14,
  },
  toolbar: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingRight: '8px',
    background: 'rgb(235, 237, 247)',
  },
  toolbarTitle: {
    flex: '1 0 auto',
    color: '#5c6bc0',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.75',
    letterSpacing: '0.00938em'
  },
  createDialog: {
    width: '600px'
  }
});

class NewFlexItemList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: [{
        id: 0,
        label: 'custom content 1',
        text: '',
        error: false,
        errorLabel: '',
      }],
      lastId: 1,
      reservedKeyWords: ['all']
    }
  }

  onClickAddMoreButton(event) {
    const offset = this.state.lastId + 1
    const usedText = 'custom content ' + offset
    let newFields = this.state.fields.slice();
    newFields.push({
      id: offset,
      multiline: false,
      label: usedText,
      text: '',
      selected: false,
      error: false,
      errorLabel: ''
    })
    this.setState({fields: newFields, lastId: offset})
  }

  onClickCheckbox(event, fieldId) {
    if(this.isSelected(fieldId)) {
      this.unselectField(fieldId);
    } else {
      this.selectField(fieldId);
    }
  }

  onClickDeleteButton(fieldId) {
    const newFields = this.state.fields.filter(x => x.id !== fieldId)
    this.setState({fields: newFields})
  }

  onChangeText(event, fieldId) {
    const { t } = this.props;
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    const newText = event.target.value.replace(/(\r\n|\n|\r)/gim," ");
    let newFields = [...this.state.fields];

    if(newText != '') {
      newFields[index].selected = true;
    } else {
      newFields[index].selected = false;
    }
    if(this.state.reservedKeyWords.includes(newText)) {
        newFields[index].error = true
        newFields[index].errorLabel = 'reserved keyword'
    } else {
      if(newText != '' && (this.props.data.includes(newText) || this.state.fields.filter(x => x.text == newText).length > 0)) {
        newFields[index].error = true
        newFields[index].errorLabel = t('customContents.errorAlreadyExists')
      } 
      else if (newText != '' && /<\/?[a-z][\s\S]*>/i.test(newText)) {
        newFields[index].error = true
        newFields[index].errorLabel = t('customContents.errorNoHtml')
      }
      else {
        newFields[index].error = false
        newFields[index].errorLabel = ''
      }
    }

    newFields[index].text = newText;

    this.setState({fields: newFields, numSelected:newFields.filter(x => x.selected === true).length});
  }

  isSelected(fieldId) {
    return this.state.fields.find(x => x.id == fieldId).selected
  }

  selectField(fieldId) {
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    let newFields = [...this.state.fields];
    newFields[index].selected = true;
    this.setState({fields: newFields, numSelected: this.state.fields.filter(x => x.selected === true).length});
  }

  unselectField(fieldId) {
    const index = this.state.fields.findIndex(x => x.id === fieldId);
    let newFields = [...this.state.fields];
    newFields[index].selected = false;
    this.setState({fields: newFields, numSelected: this.state.fields.filter(x => x.selected === true).length});
  }

  hasSelected() {
    return this.state.numSelected > 0;
  }

  allowDelete() {
    return this.state.fields.length > this.state.numSelected;
  }

  allowSave() {
    const invalidFields = this.state.fields.filter(x => x.selected === true).filter(x => x.text === '' || x.error === true)

    return invalidFields.length === 0
  }

  render() {
    const { t, classes } = this.props;

    return (
      <Dialog 
        fullWidth={true} 
        maxWidth={'md'} 
        open={true} 
        onClose={this.onCloseCreateDialog} 
        aria-labelledby="form-dialog-title"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, .5)'
          }
         }
        }
      >
      <DialogTitle style={{paddingBottom: "0"}} id="form-dialog-title">
        <div style={{display:"inline", float:"left"}}>{t('customContents.addCustomContent')}</div>
        <div style={{display:"inline", float:"right", position:"relative", bottom:"8px"}}>
          <Tooltip title="Add more" style={{display:"inline"}} arrow>
            <div>
              <IconButton aria-label="Add more" onClick={event => {this.onClickAddMoreButton(event)}}>
                <PlaylistAddIcon/>
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </DialogTitle>

      <DialogContent>

        <div>
          <List style={{padding: 0}}>
          {this.state.fields.map((field, index) => {
            return (
            <div key={`flexItem-${index}`}>
              <ListItem disableGutters disabled={this.props.isPending} style={{padding: 0}}>

                <div style={{ 'paddingRight': '40px', 'width': '100%' }}>

                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  minRows="5"
                  maxRows="10"
                  fullWidth
                  autoFocus
                  placeholder={field.label}
                  value={field.text}
                  helperText={field.errorLabel}
                  error={field.error}
                  margin="none"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      root: classes.textField,
                      input: classNames(classes.targetField, classes.inputField),
                    }
                  }}
                  className={classes.textField}
                  onChange={event => {this.onChangeText(event, field.id)}}
                />

                <Tooltip title="Delete" style={{display: "inline", position:"absolute", right: "0"}} arrow>
                  <div>
                    <IconButton aria-label="Delete" disabled={this.state.fields.length <= 1} onClick={event => {this.onClickDeleteButton(field.id)}}>
                      <DeleteIcon/>
                    </IconButton>              
                  </div>
                </Tooltip>

                </div>
              </ListItem>
              {this.state.fields.length > 1 && index < this.state.fields.length - 1 ? (<Divider />) : null}
            </div>
            )
          })}
          </List>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={this.props.onCancel} variant="outlined" color="default" size="medium">
          {t('system.cancel')}
        </Button>
        <Button disabled={this.hasSelected() && this.allowSave() ? false : true} onClick={e => {this.props.onSave(this.props.scope, this.state.fields)}} variant="contained" type="submit" color="secondary" size="medium">
          {t('system.save')}
        </Button>
      </DialogActions>

      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation()(NewFlexItemList));
