import React from 'react';
import withRouter from 'with-router'
import { getRecentlyChangedContent, getMoreRecentlyChangedContent, setRecentlyChangedContentFilter } from '../../modules/stats'
import { syncProductByShopifyId, syncCollectionByShopifyId, syncPageByShopifyId, syncArticleByShopifyId } from '../../modules/sync_by_shopify_id'
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import SmallProgressIndicator from '../../components/SmallProgressIndicator.js'
import MTooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const data02 = [
  {
    "name": "Translated Fields",
    "value": 21400
  },
  {
    //"name": "Untranslated Fields",
    //"value": 4567
  },
];

class RecentlyUpdatedContentSection extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      untranslatedItems: [],
      untranslatedItemsMenuAnchor: null,
      showUntranslatedItemsMenu: false,
    };
  }

  componentDidMount() {
    this.props.getRecentlyChangedContent(this.props.stats.recently_changed_content_filter, this.props.editLanguage)
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.getRecentlyChangedContent(this.props.stats.recently_changed_content_filter, newLanguage)
      return
    }

    const newUntranslatedItems = [];
    for(let i = 0; i < newProps.stats.recently_changed_content.length; i++) {
      const item = newProps.stats.recently_changed_content[i];
      newUntranslatedItems.push({
        type: item.type,
        id: item.id,
        shopify_id: item.shopify_id,
        title: item.title,
        progress: item.progress ? item.progress : 0,
        path: item.path
      })
    }

    this.setState({
      untranslatedItems: newUntranslatedItems
    });

    if(this.props.syncByShopifyId.pending === true && newProps.syncByShopifyId.pending === false) {
      switch(newProps.syncByShopifyId.result.type) {
        case 'product':
          this.props.history.push('/products/' + newProps.syncByShopifyId.result.id)
          break;
        case 'collection':
          this.props.history.push('/collections/' + newProps.syncByShopifyId.result.id)
          break;
        case 'page':
          this.props.history.push('/pages/' + newProps.syncByShopifyId.result.id)
          break;
        case 'article':
          this.props.history.push('/articles/' + newProps.syncByShopifyId.result.id)
          break;
      }
    }
  }

  onScrollRecentlyChangedItems = (event) => {
    const element = event.target;
    if(!this.props.stats.get_recently_changed_content_pending && !this.props.stats.get_more_recently_changed_content_pending && element.scrollHeight - element.scrollTop == element.clientHeight) {
      if(this.props.stats.recently_changed_content_next_page_links) {
        let nextPageLinksAvailable = false;
        for(let nextPageLink in this.props.stats.recently_changed_content_next_page_links) {
          if(this.props.stats.recently_changed_content_next_page_links[nextPageLink] !== null) {
            nextPageLinksAvailable = true;
          }
        }

        if(nextPageLinksAvailable) {
          this.props.getMoreRecentlyChangedContent(this.props.stats.recently_changed_content_next_page_links, this.props.editLanguage)
        }
      }
    }
  }

  onClickRecentlyChangedItem = (item) => {
    if(item.path) {
        this.props.history.push(item.path)
    } else {
      switch(item.type) {
        case 'product':
          this.props.syncProductByShopifyId(item.shopify_id);
          break;
        case 'collection': 
          this.props.syncCollectionByShopifyId(item.shopify_id);
          break;
        case 'page':
          this.props.syncPageByShopifyId(item.shopify_id);
          break;
        case 'article':
          this.props.syncArticleByShopifyId(item.shopify_id);
          break;
      }
    }
  }

  setUntranslatedItemsFilter = (filter) => {
    this.props.setRecentlyChangedContentFilter(filter);
    this.setState({showUntranslatedItemsMenu: false});

    this.props.getRecentlyChangedContent(filter, this.props.editLanguage)
  }

  openUntranslatedItemsMenu = (e) => {
    this.setState({
      untranslatedItemsMenuAnchor:e.target,
      showUntranslatedItemsMenu: true
    });
  }

  closeUntranslatedItemsMenu = () => {
    this.setState({showUntranslatedItemsMenu: false});
  }

  renderSelect = () => {
    const { t, classes, stats, availableAutoTranslationWords } = this.props;
    const usedUpdatedLabel = stats.recently_changed_content_filter.charAt(0).toUpperCase() + stats.recently_changed_content_filter.slice(1);
    return <React.Fragment>
      <span onClick={(e) => {this.openUntranslatedItemsMenu(e)}} style={{cursor:'pointer',float:'right',fontSize: 16}}><span style={{position:'relative',left:'4px'}}>{t(`recentlyUpdatedTile.${usedUpdatedLabel.toLowerCase()}`)}</span> <ExpandMoreIcon/></span>
      <Menu
        dense="true"
        onClose={this.closeUntranslatedItemsMenu}
        anchorEl={this.state.untranslatedItemsMenuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.showUntranslatedItemsMenu}
      >
        <MenuItem selected={stats.recently_changed_content_filter == 'products'} className={classes.newFieldsMenuItem} onClick={() => {this.setUntranslatedItemsFilter('products')}}>{t('recentlyUpdatedTile.products')}</MenuItem>
        <MenuItem selected={stats.recently_changed_content_filter == 'collections'} className={classes.newFieldsMenuItem} onClick={() => {this.setUntranslatedItemsFilter('collections')}}>{t('recentlyUpdatedTile.collections')}</MenuItem>
        <MenuItem selected={stats.recently_changed_content_filter == 'pages'} className={classes.newFieldsMenuItem} onClick={() => {this.setUntranslatedItemsFilter('pages')}}>{t('recentlyUpdatedTile.pages')}</MenuItem>
        <MenuItem selected={stats.recently_changed_content_filter == 'articles'} className={classes.newFieldsMenuItem} onClick={() => {this.setUntranslatedItemsFilter('articles')}}>{t('recentlyUpdatedTile.articles')}</MenuItem>
      </Menu>
    </React.Fragment>
  }

  render() {
    const { t, classes, stats } = this.props;
    if(stats.get_recently_changed_content_pending) {
      return (
        <div variant="body1" className={classNames(classes.contentContainer)}>
            <Card className={classes.card}>
              <CardContent className={classNames(classes.cardContent, classes.fluidContent)} style={{height: '313px'}}>
                <Typography variant="subtitle1" style={{margin: '0 16px 0 24px', position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
                  {t('recentlyUpdatedTile.title')}
                  { this.renderSelect() }
                </Typography>
                <Divider style={{marginTop: 16, marginBottom: 0}} />
                <CircularProgress color="primary" size={60} style={{margin: 'auto'}} thickness={2}/>
              </CardContent>
            </Card>
        </div>
      );
    } else {
      const usedUpdatedLabel = stats.recently_changed_content_filter.charAt(0).toUpperCase() + stats.recently_changed_content_filter.slice(1);
      return (
        <div variant="body1" className={classNames(classes.contentContainer)}>
            <Card className={classes.card}>
              <CardContent className={classNames(classes.cardContent, classes.fluidContent)} style={{height: '313px'}}>
                <Typography variant="subtitle1" style={{margin: '0 16px 0 24px', position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
                {t('recentlyUpdatedTile.title')}
                  { this.renderSelect() }
                </Typography>

                <Divider style={{marginTop: 16, marginBottom: 0}} />

              <List className={classes.list} classes={{root: classes.list}} dense={true} onScroll={this.onScrollRecentlyChangedItems}>
                {this.state.untranslatedItems.map((item, n) => (
                  <div key={`untranslated-item-${n}`}>
                    <ListItem className={classes.recentlyChangedItem} style={{margin: 0, backgroundColor: n % 2 == 0 ? '#f9f9f9' : '#fff'}}>
                      <span style={{marginRight:'24px', position: 'relative', left: '-16px', top: '-17px'}}><SmallProgressIndicator progress={item.progress}/></span>
                      <ListItemText primaryTypographyProps={{noWrap: true}} primary={item.title} onClick={() => this.onClickRecentlyChangedItem(item)}/>
                    </ListItem>
                  </div>
                ))}
                {stats.get_more_recently_changed_content_pending &&
                  <div style={{padding:'8px 16px 8px 16px', textAlign: 'center'}}>
                    <CircularProgress style={{color:'#666'}} size={24} />
                  </div>
                }
              </List>

              </CardContent>
            </Card>
        </div>
      )
    }
  }
}

const styles = (theme, state) => ({
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fff',
    background: 'linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%)',
    position: 'relative',
    padding: '16px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    }
  },
  fluidContent: {
    padding: '0 !important',
    justifyContent: 'flex-start',
  },
  radarChart: {
    padding: '10px',
    '& text': {
      fontSize: '.8rem',
    },
    '& .recharts-surface': {
      height: '230px !important',
      overflow: 'initial'
    }
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
    padding: 0,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: '#fcfcfc',
    lineHeight: 'initial',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  recentlyChangedItem: {
    paddingRight: '150px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e9e9e9 !important'
    }
  },
});


const mapStateToProps = ({shop, stats, syncByShopifyId}) => ({
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  syncByShopifyId: syncByShopifyId
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRecentlyChangedContent,
      getMoreRecentlyChangedContent,
      syncProductByShopifyId,
      syncCollectionByShopifyId,
      syncPageByShopifyId,
      syncArticleByShopifyId,
      setRecentlyChangedContentFilter
    },
    dispatch
  )
  
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(RecentlyUpdatedContentSection))))
