import API from '../api/api'
import DOMPurify from 'dompurify';
import tickets from '../containers/profile/tickets';
import { deepMerge } from '../helper'

const GET_CONTACT_PENDING = 'GET_CONTACT_PENDING';
const GET_CONTACT_RESPONSE = 'GET_CONTACT_RESPONSE';
const CREATE_CONTACT_PENDING = 'CREATE_CONTACT_PENDING';
const CREATE_CONTACT_RESPONSE = 'CREATE_CONTACT_RESPONSE';
const GET_TICKET_PENDING = 'GET_TICKET_PENDING';
const GET_TICKET_RESPONSE = 'GET_TICKET_RESPONSE';
const CREATE_TICKET_PENDING = 'CREATE_TICKET_PENDING';
const CREATE_TICKET_RESPONSE = 'CREATE_TICKET_RESPONSE';
const REPLY_TICKET_PENDING = 'REPLY_TICKET_PENDING';
const REPLY_TICKET_RESPONSE = 'REPLY_TICKET_RESPONSE';

const initialState = {
	contact: null,
  tickets: [],
  get_contact_pending: false,
  create_contact_pending: false,
  create_ticket_pending: false,
  get_ticket_pending: false,
  reply_ticket_pending: false,
}


// REDUCER
export default (state = initialState, action) => {
	switch(action.type) {

		case GET_CONTACT_PENDING: {
			return {
				...state,
        get_contact_pending: true,
			}
		}
		case GET_CONTACT_RESPONSE: {
			if(action.response.success === true) {
				return {
					...state,
          contact: {...action.response.contact},
          tickets: [...action.response.contact.tickets],
          get_contact_pending: false,
				}
			} else {
        return {
          ...state,
          contact: null,
          tickets: [],
          get_contact_pending: false,
          error: {
            message: action.response.message,
            action: action.action,
            level: 0,
          },
        }
			}
		}


		case CREATE_CONTACT_PENDING: {
			return {
				...state,
        create_contact_pending: true,
			}
		}
		case CREATE_CONTACT_RESPONSE: {
			return {
				...state,
        create_contact_pending: false,
			}
		}


		case GET_TICKET_PENDING: {
			return {
				...state,
        get_ticket_pending: true,
			}
		}
		case GET_TICKET_RESPONSE: {
			if(action.response.success === true) {
        let newTickets = [...state.tickets];
        let currentTicket = newTickets.find(x => x.id == action.response.data.ticket.id);
        currentTicket.conversations = [...action.response.data.conversations];
        currentTicket.ticket = {...action.response.data.ticket};
        return {
          ...state,
          tickets: newTickets,
          get_ticket_pending: false,
        } 
      } else {
          return {
          ...state,
          get_ticket_pending: false,
        }
			}
		}


		case CREATE_TICKET_PENDING: {
			return {
				...state,
        create_ticket_pending: true,
			}
		}
		case CREATE_TICKET_RESPONSE: {
      if(action.response.success === true) {
        let newTickets = [action.response.ticket.ticket, ...state.tickets];
        return {
          ...state,
          create_ticket_pending: false,
          tickets: newTickets,
        }
      } else {
        return {
          ...state,
          create_ticket_pending: false,
        }
      }
		}


		case REPLY_TICKET_PENDING: {
			return {
				...state,
        reply_ticket_pending: true,
			}
		}
		case REPLY_TICKET_RESPONSE: {
      let newTickets = [...state.tickets];
      let ticket = newTickets.find(x => x.id === action.ticketId)
      ticket.conversations = [...action.response.data.ticket.conversations];
			if(action.response.success === true) {
        return {
          ...state,
          reply_ticket_pending: false,
          tickets: newTickets,
        }
      } else {
        return {
          ...state,
          reply_ticket_pending: false,
        }
      }
		}


		default:
			return state
	}
}



export const getContact = (callback) => { 
	return dispatch => {
		dispatch({
			type: GET_CONTACT_PENDING,
		});

		API.freshdeskGetContact(
			response => {
				dispatch({
					type: GET_CONTACT_RESPONSE,
					response: response,
					action: () => getContact(callback)
				});
				if(callback) {
					callback(response);
				}
		})
	}
}

export const saveContact = (firstname, lastname, email, callback) => {  
	return dispatch => {
		dispatch({
			type: CREATE_CONTACT_PENDING,
		});

		API.freshdeskCreateContact(
			firstname,
      lastname,
      email,
			response => {
				dispatch({
					type: CREATE_CONTACT_RESPONSE,
					response: response,
					action: () => saveContact(firstname, lastname, email, callback)
				});
        if(callback) {
          callback(response);
        }
      }
    )
	}
}

export const getTicket = (ticketId, callback) => {  
	return dispatch => {
		dispatch({
			type: GET_TICKET_PENDING,
		});

		API.freshdeskGetTicket(
			ticketId,
			response => {
				dispatch({
					type: GET_TICKET_RESPONSE,
					response: response,
          ticketId: ticketId,
					action: () => getTicket(ticketId, callback)
				});
        if(callback) {
          callback(response);
        }
      }
    )
	}
}

export const createTicket = (formData, callback) => {  
	return dispatch => {
		dispatch({
			type: CREATE_TICKET_PENDING,
		});

		API.freshdeskCreateTicket(
			formData, 
			response => {
				dispatch({
					type: CREATE_TICKET_RESPONSE,
					response: response,
					action: () => createTicket(formData, callback)
				});
        if(callback) {
          callback(response);
        }
      }
    )
	}
}

export const replyTicket = (ticketId, formData, callback) => {  
	return dispatch => {
		dispatch({
			type: REPLY_TICKET_PENDING,
		});

		API.freshdeskReplyTicket(
			ticketId, 
      formData, 
			response => {
				dispatch({
					type: REPLY_TICKET_RESPONSE,
					response: response,
          ticketId: ticketId,
					action: () => replyTicket(ticketId, formData, callback)
				});
        if(callback) {
          callback(response);
        }
      }
    )
	}
}