import React from 'react';
import { syncSMSNotifications, saveSMSNotifications, exportSMSNotifications, importSMSNotifications, resetErrors } from '../../modules/SMSNotifications'
import withRouter from 'with-router'
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import OptionsMenu from '../../components/OptionsMenu';
import LinkElement from '@material-ui/core/Link';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: '#ccc',
    top: '4px',
    '&:hover': {
      backgroundColor: 'rgba(26, 35, 126, 0.5)',
      borderColor: "#ccc",
    }
  }
});

class SMSNotifications extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sms_notifications: [],
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      errorLockCollapse: false
    }
  }

  componentDidMount() {
    this.props.syncSMSNotifications(this.props.editLanguage)

    if(this.props.SMSNotifications.export_po_download_url || this.props.SMSNotifications.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncSMSNotifications(newLanguage)
      return
    }

    if(this.props.SMSNotifications.import_sms_notifications_pending === true && newProps.SMSNotifications.import_sms_notifications_pending === false) {
      this.setState({showImportCompleteDialog: true})
    }

    if((this.props.SMSNotifications.sync_sms_notifications_pending === true && newProps.SMSNotifications.sync_sms_notifications_pending === false) || (this.props.SMSNotifications.save_sms_notifications_pending === true && newProps.SMSNotifications.save_sms_notifications_pending === false)) {
      const newNotifications = [];
      
      newProps.SMSNotifications.sms_notifications.forEach(item => {
        
        newNotifications.push({
          type: 'sms_notification',
          id: item.id,
          progress: item.progress,
          title: item.source.substring(0, 70) + '...',
          info: '',
          fields: [{
            multiline: true,
            rows: 10,
            id: 'body',
            type: 'text',
            label: 'Description',
            source: item.source || '',
            target: item.target || '',
          }]
        })
      })

      this.setState({sms_notifications:newNotifications});
    }
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        body: item.fields.find(x => x.id === 'body').target,
      });
    })

    this.props.saveSMSNotifications(this.props.editLanguage, usedItems)
  }

  onResaveAll = () => {
    const items = this.state.sms_notifications;
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        body: item.fields.find(x => x.id === 'body').target,
      });
    })

    this.props.saveSMSNotifications(this.props.editLanguage, usedItems)
  }

  onImport = (e) => {
    this.setState({ optionsMenuAnchorEl: null });

    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    if(fileType != 'mo' && fileType != 'csv') {
      return;
    }
    
    const formData = new FormData();
    formData.append('file', file);

    this.props.importSMSNotifications(formData, fileType, this.props.editLanguage)
  }

  onExport = (e) => {
    if(!this.props.SMSNotifications.export_po_download_url && !this.props.SMSNotifications.export_csv_download_url) {
      this.props.exportSMSNotifications(this.props.editLanguage);
    } else {
      this.setState({showDownloadDialog: false}, () => {
        this.setState({showDownloadDialog: true});
      });
    }
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  render() {
    const { t, classes } = this.props

    if(this.props.SMSNotifications.sync_sms_notifications_pending === true || (this.props.SMSNotifications.error && this.props.SMSNotifications.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.smsNotifications.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={this.props.SMSNotifications.sync_sms_notifications_pending || this.props.SMSNotifications.save_sms_notifications_pending}
          progress={this.props.SMSNotifications.progress}
          error={this.props.SMSNotifications.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Alert severity="error" style={{marginBottom: 16}}>
          {t('pages.smsNotifications.stoppedShopifySupport')}
      </Alert>
      <div style={{position: 'absolute', zIndex: 100, width: '100%', height: '100%', left: 0}}></div>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.smsNotifications.title')}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          onResaveAll={this.onResaveAll}
          shopPlan={this.props.shopPlan}
        />
      </Typography>
      <Divider className="divider-white" />
      <ItemsList 
        disabled
        type="page"
        pagination={true}
        isPending={
          this.props.SMSNotifications.sync_sms_notifications_pending ||
          this.props.SMSNotifications.save_sms_notifications_pending ||
          this.props.SMSNotifications.export_sms_notifications_pending ||
          this.props.SMSNotifications.import_sms_notifications_pending
        }
        isSaving={
          this.props.SMSNotifications.save_sms_notifications_pending
        }
        onSave={this.onSave}
        data={this.state.sms_notifications}
        allowAutoTranslation={true}
        error={this.props.SMSNotifications.error}
        errorLockCollapse={this.state.errorLockCollapse}
        emptyMessage={
          <EmptyView 
            title={t('pages.smsNotifications.noContent')}
            bottom={<Button href={`https://${this.props.shopUrl}/admin/settings/notifications`} target="_blank" variant="contained" color="secondary">{t('system.createOneHere')}</Button>}
          />
        }
      />
      <LoadingIndicator
        pending={this.props.SMSNotifications.sync_sms_notifications_pending || this.props.SMSNotifications.save_sms_notifications_pending || this.props.SMSNotifications.export_sms_notifications_pending || this.props.SMSNotifications.import_sms_notifications_pending}
        loadingType={this.props.SMSNotifications.save_sms_notifications_pending ? 'saving' : 'loading'}
        progress={this.props.SMSNotifications.progress}
        error={this.props.SMSNotifications.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.SMSNotifications.imported_entries}
        export_po_download_url={this.props.SMSNotifications.export_po_download_url}
        export_csv_download_url={this.props.SMSNotifications.export_csv_download_url}
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ shop, SMSNotifications }) => ({
  SMSNotifications: SMSNotifications,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  shopPlan: shop.shop.charge.name,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncSMSNotifications,
      saveSMSNotifications,
      exportSMSNotifications,
      importSMSNotifications,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(SMSNotifications))))