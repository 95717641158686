import React from 'react';
import { setLangifyNotificationAsUnviewed, setLangifyNotificationAsViewed, getLangifyNotifications, resetErrors } from '../../modules/langifyNotifications'
import { getShopSubscription } from '../../modules/shop'
import withRouter from 'with-router'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
//import Link from '@material-ui/core/Link';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import ProfileMenu from './ProfileMenu.js';
import { ChargeTimeline } from './charges/index.js'
import { formatDate } from '../../helper.js';

const styles = theme => ({
  contentColumn: {
    margin: 0,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12
  },
  divider: {
    margin: '1rem 0 1rem 0'
  },
  avatarIcon: {
    width: '5rem',
    height: '5rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginBottom: '-1.5rem'
  }
});

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      avatarData: null
    }
  }

  componentDidMount() {
    this.setState({ 
      avatarData: this.generateAvatarData(this.props.shopUrl)
    });


    this.props.getShopSubscription();
  }

  generateAvatarData(shopifyUrl) {
    // Extract the subdomain from the URL
    const url = new URL('https://'+shopifyUrl);
    const subdomain = url.hostname.split('.')[0];
  
    // Generate the abbreviation from the subdomain
    const parts = subdomain.split('-');
    let abbreviation;
    if (parts.length > 1) {
      abbreviation = parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
    } else {
      abbreviation = subdomain.slice(0, 2).toUpperCase();
    }
  
    // Generate a fixed hexadecimal color for the abbreviation
    const hash = abbreviation.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const color = `#${((hash & 0xFFFFFF).toString(16).padStart(6, '0')).toUpperCase()}`;
  
    // Return the desired object
    return {
      shopifyUrl: shopifyUrl,
      abbreviation: abbreviation,
      color: color
    };
  }

  render() {
    const { t, classes } = this.props;

    return <React.Fragment>
      <Grid container spacing={3} alignItems="flex-start" className="page-content">
        <Grid item xs={12}>
          <ProfileMenu />
        </Grid>
        <Grid item xs={12} className="page-content">
          <Typography variant="h4" component="h2">My Profile</Typography>
          <Divider className="divider-white" />
        </Grid>
        <Grid item xs={9} className={classes.contentColumn}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatarIcon} style={{backgroundColor: this.state.avatarData?.color || 'grey'}}>
              {this.state.avatarData?.abbreviation || null}
            </Avatar>
            <Typography variant="h4" gutterBottom align="center">
              {this.props.shopUrl.replace('.myshopify.com', '')}
            </Typography>
            <Typography variant="body2" gutterBottom align="center">
              <a href={this.props.shopUrl} target="_blank">{this.props.shopUrl}</a>
            </Typography>

            <Divider className={classes.divider} />

            <Typography variant="body2" gutterBottom>
              <strong>Current plan: </strong> {this.props.shop.charge.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Charge status: </strong> {this.props.shop.charge.status}
            </Typography>
            {this.props.subscription.length > 0 && 
              <Typography variant="body2" gutterBottom>
                <strong>Next Charge: </strong> {formatDate(this.props.subscription[0].currentPeriodEnd)}
              </Typography>
            }

            <Divider className={classes.divider} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
        </Grid>



        <Grid item xs={9}>
          <Paper className={classes.paper}>
            <ChargeTimeline 
              charge={this.props.charge}
              subscription={this.props.subscription}
              //classes={classes}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
    </React.Fragment>
  }
}
const mapStateToProps = ({ langifyNotifications, shop }) => ({
  notifications: langifyNotifications.notifications,
  editLanguage: shop.shop.edit_language,
  shop: shop.shop,
  shopUrl: shop.shop.url,
  charge: shop.shop.charge,
  subscription: shop.subscription
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLangifyNotifications,
      setLangifyNotificationAsUnviewed,
      setLangifyNotificationAsViewed,
      resetErrors,
      getShopSubscription
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Profile))))