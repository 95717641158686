import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneOutlined from '@material-ui/icons/DoneOutlined';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

class ProgressIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  componentDidMount() {
    if(this.props.progress === 100) {
      this.setState({isCompleted: true})
    } else {
      this.setState({isCompleted: false})
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.progress === 100) {
      this.setState({isCompleted: true})
    } else {
      this.setState({isCompleted: false})
    }
  }

  render() {
    const {
      classes,
      progress,
    } = this.props;
    const {
      isCompleted,
    } = this.state;

    if(progress === 'empty') {
      return (
        <span styles={classes.root}>
          <CircularProgress 
            variant="determinate" 
            value={100} 
            className={classes.progressBg}
            size={24}
          />
          <span className={classes.emptySign}>
            <DoneOutlined />
          </span>
        </span>
      )
    } else {
      return (
        <span styles={classes.root}>
          <CircularProgress 
            variant="determinate" 
            value={100} 
            className={classes.progressBg}
            size={24}
          />
          <CircularProgress 
            variant="determinate" 
            value={progress} 
            className={classes.progress}
            size={24}
            classes={{
              colorPrimary: progress > 50 ? classes.green : classes.red,
            }}
          />
          {isCompleted && !this.props.outOfSync ?
            (<span className={classes.completedSign}>
              <DoneOutlined />
            </span>) : null
          }
          {this.props.outOfSync &&
            <span className={classes.outOfSyncSign}>
              <MoreHorizOutlined />
            </span>
          }
          {progress === -1 &&
            <Tooltip title="Progress will be calculated when the item is opened." top arrow>
              <FiberManualRecordIcon style={{position: 'absolute', left: 24, top: 20, opacity: .5}} />
            </Tooltip>
          }
        </span>
      )
    }
  }
}

const styles = theme => ({
  root: {
    position: 'relative',
  },
  progressBg: {
    position: 'absolute',
    top: 20,
    left: 24,
    opacity: .25,
    color: '#000',
  },
  progress: {
    position: 'absolute',
    top: 20,
    left: 24,
    color: 'seagreen',
  },
  completedSign: {
    position: 'absolute',
    top: 21,
    left: 28,
    color: 'seagreen',
    '& svg': {
      fontSize: 16
    }
  },
  emptySign: {
    position: 'absolute',
    top: 21,
    left: 28,
    opacity: .25,
    color: '#000',
    '& svg': {
      fontSize: 16
    }
  },
  outOfSyncSign: {
    position: 'absolute',
    top: 21,
    left: 28,
    '& svg': {
      fontSize: 16
    }
  },
  red: {
    color: 'orangered !important'
  },
  green: {
    color: 'seagreen !important'
  }
});

export default withStyles(styles)(ProgressIndicator);