import React, { useState, useEffect } from 'react';
import { loadMetaObjectDefinitions, loadMetaObjects, loadMetaObject, saveMetaObjects, resetErrors } from '../../modules/metaObjects'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';
import { _clearUnchangedFields, _getValidationPropsFromValidations } from '../../helper';
import API from '../../api/api';
import { FilterOptionsSelect, FilterOptionsQuery, FilterOptionsSort } from '../../components/ItemsListFilterOptions';
import UpdateAppPermissionsDialog from '../../components/UpdateAppPermissionsDialog';


class MetaObjects extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      metaobjects: [],
      errorLockCollapse: false,
      initialItem: null
    }
  }

  componentDidMount() {
    this.props.loadMetaObjectDefinitions({}, true);

    // Deeplinking
    let uriParts = this.props.location.pathname.split('/');
    let id = uriParts[3] ? uriParts[3] : uriParts[2];
    if(id) {
      this.props.loadMetaObject(id, this.props.editLanguage);
      this.setState({
        initialItem: id
      })

    } else {
      this.props.loadMetaObjects({}, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.editLanguage !== prevProps.editLanguage) {
      this.props.loadMetaObjectDefinitions({}, true);

      // Deeplinking
      let uriParts = this.props.location.pathname.split('/');
      let id = uriParts[3] ? uriParts[3] : uriParts[2];
      if(id) {
        this.props.loadMetaObjects({}, true, (res) => {
          this.props.loadMetaObject(id, this.props.editLanguage);
          this.setState({
            initialItem: id
          })
        });
      } else {
        this.props.loadMetaObjects({}, true);
      }
      return
    }
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;

    if((this.props.metaObjects.load_metaobject_pending === true && newProps.metaObjects.load_metaobject_pending === false) 
    || (this.props.metaObjects.save_metaobjects_pending === true && newProps.metaObjects.save_metaobjects_pending === false) 
    || (this.props.metaObjects.load_metaobjects_pending === true && newProps.metaObjects.load_metaobjects_pending === false)) {
      const newMetaobjects = [];
      if(newProps.metaobjects && newProps.metaobjects.length > 0) {
        newProps.metaobjects.forEach(item => {
          let newFields = [];
          if(item.translations) {
            item.translations.forEach((field) => {
              newFields.push({
                id: field.key,
                type: 'text',
                label: field.key,
                source: field.source,
                target: field.target === null ? '' : field.target,
                error: field.error, 
                restore: field.restore,
                ..._getValidationPropsFromValidations(field.validations)
              })
            })            
          }
          //console.log(newFields)
          newMetaobjects.push({
            type: 'metaobject',
            id: item.shopify_id.replace('gid://shopify/Metaobject/', ''),
            shopify_id: item.shopify_id,
            progress: !item.progress ? -1 : item.progress,
            synchronized: item.synchronized,
            title: item.display_name ? item.display_name : '',
            info: '',
            hasError: item.hasError,
            fields: newFields ? cloneDeep(newFields) : null
          })
        })       
        this.setState({metaobjects: newMetaobjects});
      }
    }
  }

  onExpand(id) {
    if(this.state.metaobjects.find(x => x.id == id).synchronized !== true) {
      this.props.loadMetaObject(id, this.props.editLanguage);
    }
  }

  onCollapsed = (id) => {
    
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      const newTranslations = [];
      item.fields.forEach((field, i) => {
        newTranslations.push({
          key: field.id,
          value: field.target || '',
        })
      })
      usedItems.push({
        resource_id: item.shopify_id,
        translations: newTranslations
      });
    })
    this.props.saveMetaObjects(this.props.editLanguage, usedItems, res => {

    })
  }

  onLoadMore = () => {
    this.props.loadMetaObjects({
      cursor: this.props.metaObjects.cursor,
      ...Object.assign({}, this.filterObject)
    })
  }

  render() {
    const { t, classes } = this.props

    const FilterQueryModule = <FilterOptionsQuery 
      initialItem={this.state.initialItem ? this.state.initialItem : null}
      onChange={(filterObject) => {
        this.filterObject = filterObject;
        this.props.loadMetaObjects(filterObject, true);
      }}
      history={this.props.history}
    />
    const FilterDefinitionsModule = <FilterOptionsSelect 
      options={this.props.metaobject_definitions}
      onChange={(filterObject) => {
        this.filterObject = filterObject;
        this.props.loadMetaObjects(filterObject, true);
      }}
      onLoadMore={this.props.metaObjects.definitions_cursor ? () => {
        this.props.loadMetaObjectDefinitions({
          cursor: this.props.metaObjects.definitions_cursor
        });
      } : null}
      history={this.props.history}
    />
    const FilterSortModule = <FilterOptionsSort 
      initialValue={'updated_at'}
      options={[
        {type: 'id', name: 'ID'},
        {type: 'type', name: 'Type'},
        {type: 'updated_at', name: 'Updated at'},
        {type: 'display_name', name: 'Display name'},
      ]}
      onChange={(filterObject) => {
        this.filterObject = filterObject;
        this.props.loadMetaObjects(filterObject, true);
      }}
      history={this.props.history}
    />

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.metaobjects.title')}
        {/*<OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          shopPlan={this.props.shopPlan}
        />*/}      
      </Typography>

      <Divider className="divider-white" />

      {this.props.metaObjects.permissionUpdateNeeded  &&
        <UpdateAppPermissionsDialog shop={this.props.shop} />
      }

      <ItemsList 
        //key={`metaobjects-itemslist-${this.props.editLanguage}`}
        type="metaobjects"
        useLazyloading
        loadMoreButton={this.props.metaObjects.cursor}
        onLoadMore={this.onLoadMore}
        filterModules={[
          FilterQueryModule,
          (this.state.metaobjects.length === 1 && window.location.pathname.split('/').length === 3) ? null : FilterDefinitionsModule,
          (this.state.metaobjects.length === 1 && window.location.pathname.split('/').length === 3) ? null : FilterSortModule
        ]}
        //allowGroupFiltering={true}

        pagination={false}
        paginate={false}
        onExpand={(id) => {this.onExpand(id)}}
        onCollapsed={this.onCollapsed}
        isPending={
          this.props.metaObjects.load_metaobjects_pending ||
          this.props.metaObjects.load_metaobject_pending ||
          this.props.metaObjects.save_metaobjects_pending
        }
        isSubfieldsPending={false}
        isSubfieldsRemoving={false}
        isSaving={
          this.props.metaObjects.save_metaobjects_pending
        }
        onSave={this.onSave}
        //onResaveAll={this.onResaveAll}
        data={this.state.metaobjects}
        onExpandCollapsableFields={this.onExpandCollapsableFields}
        allowAutoTranslation={true}
        error={this.props.metaObjects.error}
        errorLockCollapse={this.state.errorLockCollapse}
        saveErrors={this.props.metaObjects.saveErrors}
        emptyMessage={(this.props.metaObjects.load_metaobjects_pending === false && this.props.metaobjects.length === 0) ? <EmptyView 
            title={'No metaobjects available'}
            bottom={<Button href={`https://${this.props.shopUrl}/admin/content/entries`} variant="contained" color="secondary">{t('system.createOneHere')}</Button>}
          />
        :
          <ItemsListSkeleton rows={5} />
        }
      />
      <LoadingIndicator
        //transparent
        pending={
          (this.props.metaObjects.load_metaobjects_pending && this.state.metaobjects.length === 0) || 
          this.props.metaObjects.load_metaobject_pending || 
          this.props.metaObjects.save_metaobjects_pending || 
          this.props.metaObjects.load_metaobject_definitions_pending
        }
        loadingType={this.props.metaObjects.save_metaobjects_pending ? 'saving' : 'loading'}
        progress={(this.props.metaObjects.progress < 0) ? 0 : this.props.metaObjects.progress}
        error={this.props.metaObjects.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo={this.props.metaObjects.load_metaobjects_pending}
      />

    </React.Fragment> 
  }
}

const styles = theme => ({

})

const mapStateToProps = ({ metaObjects, shop }) => ({
  metaObjects: metaObjects,
  metaobjects: metaObjects.metaobjects,
  metaobject_definitions: metaObjects.metaobject_definitions,
  pageInfo: metaObjects.page_info,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  shopPlan: shop.shop.charge.name,
  shop: shop.shop
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadMetaObjectDefinitions,
      loadMetaObjects,
      loadMetaObject,
      saveMetaObjects,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(MetaObjects))))