import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';

import TranslationFields from "./TranslationFields";

const flattenObject = function(ob) {
	var toReturn = {};
	for (var i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		else if (typeof ob[i] == 'object' && typeof ob[i] != null) {
			var flatObject = flattenObject(ob[i]);
			for (var x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				toReturn[i + '.' + x] = flatObject[x];
			}
		} 
		else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};
function compareObjects(a, b) {
	let fa = flattenObject(a);
	let fb = flattenObject(b);
	if(fa.length != fb.length) return false;
	for (var i in fa) {
		let key = i;
		if(!fb.hasOwnProperty(key)) return false;
	}
  return true;
}
function parseString(str) {

	// Try to parse as boolean first
	if (typeof str === 'string') {
		str = str.trim();
		if (str.toLowerCase() === "true") return true;
		if (str.toLowerCase() === "false") return false;		
	}
	if (typeof str === 'boolean') {
		return str;
	}
	
	// Try to parse as a number
	if (!isNaN(str) && str !== "") {
    let num = Number(str);
    return num;
  }
	
	// Return the original string if it can't be parsed as boolean or number
	return str;
}
function set(obj, path, value) {
	var schema = obj;  // a moving reference to internal objects within obj
	var pList = path.split('.');
	var len = pList.length;
	for(var i = 0; i < len-1; i++) {
			var elem = pList[i];

			// Prüfen, ob das nächste Element im Pfad eine Zahl ist
			var nextElemIsArrayIndex = !isNaN(parseInt(pList[i + 1]));

			// Wenn das aktuelle Element nicht existiert, anlegen
			if (!schema[elem]) {
					// Falls das nächste Element ein Index ist, erstelle ein Array, ansonsten ein Objekt
					schema[elem] = nextElemIsArrayIndex ? [] : {};
			}

			// Falls das existierende Element ein Array ist und das nächste Element ein Objekt sein soll
			if (Array.isArray(schema[elem]) && !nextElemIsArrayIndex) {
					schema[elem] = {};
			}

			// Falls das existierende Element ein Objekt ist und das nächste Element ein Index sein soll
			if (!Array.isArray(schema[elem]) && nextElemIsArrayIndex) {
					schema[elem] = [];
			}
			schema = schema[elem];
	}
	schema[pList[len-1]] = parseString(value);
}



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const JsonEditor = (props) => {
	let sourceObject = {};
	let targetObject = {};
	let _flatSourceObject = null;
	let _flatTargetObject = null;

	const rightMargin = (props.parentField === 'customfields' || window.location.pathname.indexOf('/custom') >= 0);
  const { classes } = props;
	
  const [open, setOpen] = useState(false);
  //const [sourceObject, setSourceObject] = useState({});
  const [finalTargetObject, setFinalTargetObject] = useState({});
  const [flatSourceObject, setFlatSourceObject] = useState(null);
  const [flatTargetObject, setFlatTargetObject] = useState(null);
  const [error, setError] = useState(null);
  const [fields, setFields] = useState(null);


	// Initialisation
  useEffect(() => {
		try {
			sourceObject = JSON.parse(props.source);
			_flatSourceObject = flattenObject(sourceObject);
			try {
				targetObject = JSON.parse(props.target);
				_flatTargetObject = flattenObject(targetObject);
	
				if(!compareObjects(sourceObject, targetObject)) {
					setError('structure')
				} else {
					setError(null);
				}
			}
			catch (e) {}
		}
		catch (e) {}

		if(_flatSourceObject) {
			let newFields = [];
			Object.keys(_flatSourceObject).map((key) => {
				newFields.push({
					id: key,
					type: 'text',
					label: key,
					source: _flatSourceObject[key],
					target: (_flatTargetObject && _flatTargetObject[key]) ? _flatTargetObject[key] : ''
				});
			});		
			setFields(newFields);
			setFlatSourceObject(_flatSourceObject);
			setFlatTargetObject(_flatTargetObject);
		}		

  }, [props.source, props.target]);



  const handleOpen = () => {
		sourceObject = JSON.parse(props.source);
		_flatSourceObject = flattenObject(sourceObject);
		try {
			targetObject = JSON.parse(props.target);
			_flatTargetObject = flattenObject(targetObject);

			if(!compareObjects(sourceObject, targetObject)) {
				setError('structure');
			} else {
				setError(null);
			}
		}
		catch (e) {}

		
		let newFields = [];
		Object.keys(_flatSourceObject).map((key) => {
			newFields.push({
				id: key,
				type: 'text',
				label: key,
				source: _flatSourceObject[key],
				target: (_flatTargetObject && _flatTargetObject[key]) ? _flatTargetObject[key] : ''
			});

			if (_flatTargetObject?.[key]) {
				// Build Object structure for targetObject and leave empty targets blank
				set(targetObject, key, (!_flatTargetObject[key] || _flatTargetObject[key] === '') ? '' : _flatTargetObject[key]);
			} else {
				set(targetObject, key, _flatSourceObject[key]);
			}
		});		
		setFinalTargetObject(targetObject);
		
		setFields(newFields);
		setFlatSourceObject(_flatSourceObject);
		setFlatTargetObject(_flatTargetObject);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditingDone = () => {
		props.onChange(null, {
			id: props.id,
			changed: true,
			initial: props.target,
			target: JSON.stringify({...finalTargetObject}),
			parentField: props.parentField,
		})

    setOpen(false);
  };

	const handleChange = (e, data) => {
		let newTargetObject = {...finalTargetObject};
    set(newTargetObject, data.id, data.target);
		setFinalTargetObject(newTargetObject);

		let newFields = [...fields];
		let editField = newFields.find(x => x.id === data.id);
		editField.target = data.target;
		setFields(newFields);
	};

	const importFromOriginal = () => {
		props.onChange(null, {
			id: props.id,
			changed: true,
			initial: props.target,
			target: props.source,
			parentField: props.parentField,
		})
	};

	return (
		<div>
			{flatSourceObject &&
				<Tooltip title="Translate JSON Object" arrow>
					<IconButton color="default" component="span" size="small" variant="contained" className={classes.openBtn} style={{marginRight: rightMargin ? 40 : 0}} onClick={handleOpen}>
						<MenuOpenRoundedIcon />
					</IconButton>
				</Tooltip>
			}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
				fullWidth={true}
				maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">JSON Object Translation</DialogTitle>
				<div className={classes.msgBox}>
					{error === 'structure' ?
						<Alert severity="error">
							The object structure of your target does not match with the original structure.
						</Alert>
					:
						<Alert severity="info">Note: Do not translate fields that could be used programmatically, such as <strong>"null"</strong>, <strong>"true"</strong>, and <strong>"false"</strong>.</Alert>
					}
				</div>
        <DialogContent>
				{flatSourceObject ?
					<div>
						{(fields && error === null )?
							<TranslationFields 
								data={fields} 
								nested
								onChange={handleChange} 
								parentField="json-editor"
							/>
						:
							<p style={{textAlign: 'center', padding: 16}}>
								It is necessary that both the original and the target object have an identical structure.<br/>
								This can be fixed by importing the original content.<br/>
								<Button variant="outlined" color="secondary" onClick={importFromOriginal} style={{marginTop: 8}}>Import from original</Button>
							</p>
						}
					</div>
					:
					<p>No items found in object.</p>
				}
        </DialogContent>

				{error === null &&
					<DialogActions>
						<Button variant="outlined" onClick={handleClose} color="default">
							Cancel
						</Button>
						<Button variant="contained" onClick={handleEditingDone} color="secondary" endIcon={<CheckIcon />}>
							Editing Done
						</Button>
					</DialogActions>
				}
      </Dialog>
		</div>
	);
}


const styles = (theme) => ({
  msgBox: {
    width: '100%',
    '& > *': {
      borderRadius: 0,
    },
  },
	openBtn: {
		position: 'absolute',
		top: 16,
		right: 16,
	}
});

export default withStyles(styles)(JsonEditor);