import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '20px'
  },
  paper: {
    height: '350px',
    width: '300px',
    padding: '20px',
    textAlign: 'center'
  },
  box: {
    padding: '10px'
  },
  boxHeadline: {
    textTransform: 'capitalize',
    margin: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  price: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '2.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  words: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.55rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  confirmButton: {
    marginTop: 40,
    background: '#009900',
    color: '#ffffff',
    '&:hover': {
      background: '#009900',
      color: '#ffffff',
    }
  },
  checkIcon: {
    fontSize: '60px',
    color: '#009900'
  },
});

class RechargeConfirmation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: true
    }
  }

  close = () => {
    window.history.pushState('name', '', window.location.href.split("?")[0]);
    this.setState({visible:false})
  }

  render() {
    const { classes, rows, contained } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        onClose={this.close}
        aria-labelledby="customized-dialog-title"
        open={this.state.visible}
        maxWidth={'lg'}
        fullWidth={false}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '10px'
          }
        }}
        BackdropProps={{
          style: {
            background: 'rgba(255, 255, 255, .75)'
          }
        }}
      >
        <Grid id={'boxes'} container alignItems={'center'} justify="center" onClick={(e) => {if(e.target.id == 'boxes') {this.close()}}}>
          <Grid item className={classes.box}>
            <Paper className={classes.paper}>
              <h6 className={classes.boxHeadline}>{this.props.plan}</h6>
              <div><CheckIcon className={classes.checkIcon}/></div>
              <div className={classes.words}>{this.props.words} words</div>
              <div> have been successfully added to your autotranslations account</div>
              <Button onClick={(e) => {this.close()}} variant="contained" color="secondary" type="submit" size="medium" className={classes.confirmButton}>
                Continue
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
export default withStyles(styles)(RechargeConfirmation);
