import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next';
import { UpgradePlanHint } from '../../components/PricingPlans'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    borderRadius: 4
  },
  horizontalMenuList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  horizontalMenuItem: {
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

export default function ProfileMenu() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const currentPlan = useSelector((state) => state.shop.shop.charge.name);
  const anchorRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {

  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <MenuList className={classes.horizontalMenuList}>
          <MenuItem selected={window.location.pathname === '/profile'} className={classes.horizontalMenuItem} button component={Link} to={'/profile'}>
            {t('profile.navigation.myProfile')}
          </MenuItem>
          
          {/*<MenuItem selected={window.location.pathname === '/profile/charges'} className={classes.horizontalMenuItem} button component={Link} to={'/profile/charges'}>Charges</MenuItem>*/}
          
          <MenuItem selected={window.location.pathname === '/profile/plans'} className={classes.horizontalMenuItem} button component={Link} to={'/profile/plans'}>
            {t('profile.navigation.mySubscription')}
          </MenuItem>
          
          {currentPlan === 'free' ?
            <div style={{ position: 'relative' }}>
              <MenuItem disabled={true} className={classes.horizontalMenuItem} style={{ paddingRight: 80 }} button component={Link} to={'/profile/wordslog'}>
                {t('profile.navigation.wordsLog')}
              </MenuItem>
              <UpgradePlanHint
                title={t('plans.tooltipFeatures.tooltipTitle')}
                features={[]}
                placement={'bottom'}
                neededPlan={'basic'} 
                position="end" 
                top={6} 
                isBlock 
                isOverlay 
              />
            </div>
          :
            <MenuItem selected={window.location.pathname.indexOf('/profile/wordslog') >= 0} className={classes.horizontalMenuItem} button component={Link} to={'/profile/wordslog'}>
              {t('profile.navigation.wordsLog')}
            </MenuItem>
          }

          {currentPlan !== 'premium' ?
            <div style={{ position: 'relative' }}>
              <MenuItem disabled={true} className={classes.horizontalMenuItem} style={{ paddingRight: 80 }} button component={Link} to={'/profile/tickets'}>
                {t('profile.navigation.supportTickets')}
              </MenuItem>
              <UpgradePlanHint
                title={t('plans.tooltipFeatures.tooltipTitle')}
                features={[
                  t('plans.tooltipFeatures.jumpTheLine'),
                  t('plans.tooltipFeatures.ticketsystem')
                ]}
                placement={'bottom'}
                neededPlan={'premium'} 
                position="end" 
                top={6} 
                isBlock 
                isOverlay 
              />
            </div>
          :
            <MenuItem selected={window.location.pathname.indexOf('/profile/tickets') >= 0} className={classes.horizontalMenuItem} button component={Link} to={'/profile/tickets'}>
              {t('profile.navigation.supportTickets')}
            </MenuItem>
          }
        </MenuList>
      </Paper>
    </div>
  );
}
