import React, { useState, useEffect } from 'react';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import { _clearUnchangedFields, _getValidationPropsFromValidations } from '../helper';




const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      marginLeft: 8,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuPaper: {
      //maxHeight: 300
    },
    menuItem: {
      //height: 800
    },

    queryFilterRoot: {
      flex: '3'
    },
    closeIcon: {
      position: 'absolute',
      left: 0,
      top: 12,
      background: 'white',
      cursor: 'pointer'
    },
    text: {
      marginRight: 16
    },
    textInput: {
      
    },

    optionsFilterRoot: {
      flex: 0,
      marginRight: 8
    },

    sortFilterRoot: {
      flex: 0
    },
  })
);


let FilterOptionsObject = {
  definition_type: null,
  //sort_key: 'updated_at',
  //search: {
  //  display_name: '',
  //  updated_at: ''
  //},
};
  




/**
 * 
 * @param {*} props 
 * @returns 
 */
export const FilterOptionsQuery = (props) => {
  let inputTimeout = null;
  const classes = useStyles();
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [initialItem, setInitialItem] = React.useState('');

  // ComponentDidMount
  useEffect(() => {
    setQuery(props.initialItem);
    setInitialItem(props.initialItem);
  }, [])

  const handleChange = (event) => {
    FilterOptionsObject.search = {
      display_name: event.target.value,
      updated_at: ""
    }
    setQuery(event.target.value);
    if(inputTimeout) {
      clearTimeout(inputTimeout)
    }
    inputTimeout = setTimeout(() => {
      props.onChange(FilterOptionsObject);
      closeOpenItem();
    }, 1000);
  };

  const handleResetQuery = (event) => {
    FilterOptionsObject.search = {
      display_name: "",
      updated_at: ""
    }
    props.onChange(FilterOptionsObject);
    setQuery('');

    if(initialItem) {
      setInitialItem(null);
      closeOpenItem();
    }
  }

  const closeOpenItem = () => {
    let uriParts = window.location.pathname.split('/');
    let id = uriParts[3] ? uriParts[3] : uriParts[2];
    if(id) {
      props.history.push(`/${uriParts[1]}`);
    }
  }

  return (
    <span className={classes.queryFilterRoot}>
      <FormControl className={classes.formControl} style={{width: '100%'}}>
        {props.hideFilterbar ?
          <Typography variant="subtitle1" style={{ color: '#999' }}>
            {props.toolbarTitle ? props.toolbarTitle : 'Metadata'}
          </Typography>
        :
          <React.Fragment>
            <TextField
              style={{ margin: 0, paddingRight: 16 }}
              onChange={handleChange}
              value={query}
              placeholder={t('itemsList.filterPlaceholder')}
              fullWidth
              InputProps={{
                startAdornment: <SearchOutlinedIcon style={{opacity: .5, marginRight:10}} />,
                classes: {
                  root: classes.text,
                  input: classes.textInput
                }
              }}
            />
            {query &&
              <span className={classNames(classes.closeIcon)}>
                <CloseIcon onClick={handleResetQuery}/>
              </span>
            }
          </React.Fragment>
        }
      </FormControl>
    </span>
  );
};
  
  

  

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const FilterOptionsSelect = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [definition, setDefinition] = React.useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Funktion, um weitere Optionen nachzuladen
    const loadMoreOptions = async () => {
      // Simuliere eine API-Anfrage mit setTimeout
      setIsLoading(true);
      if(props.onLoadMore) {
        props.onLoadMore();
      }
      setIsLoading(false);
    };

    // Lade weitere Optionen, wenn die Seite geändert wird
    loadMoreOptions();
  }, [page]);

  const handleChange = (event) => {
    setDefinition(event.target.value);
    FilterOptionsObject.definition_type = event.target.value;
    props.onChange(FilterOptionsObject);
    closeOpenItem();
  };

  const handleOpen = (event) => {
    if(props.onLoadMore) {
      props.onLoadMore();
    }
  };

  const handleScroll = event => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setPage(prevPage => prevPage + 1); // Erhöhe die Seitenzahl, wenn das Ende erreicht ist
    }
  };

  const closeOpenItem = () => {
    let uriParts = window.location.pathname.split('/');
    let id = uriParts[3] ? uriParts[3] : uriParts[2];
    if(id) {
      props.history.push(`/${uriParts[1]}`);
    }
  }

  return (
    <span className={classes.optionsFilterRoot}>
      <FormControl className={classes.formControl}>
        {/*<InputLabel shrink id="definition-select-label">Definitions</InputLabel>*/}
        <Select
          labelId="definition-select-label"
          id="definition-select"
          value={definition}
          displayEmpty={true}
          onChange={handleChange}
          onScroll={handleScroll}
          onOpen={handleOpen}
          classes={{
            selectMenu: classes.menuPaper
          }}
          MenuProps={{
            classes: {
              paper: classes.menuPaper
            }
          }}
        >
          <MenuItem className={classes.menuItem} value="">
            <em>Select Definition</em>
          </MenuItem>
          {props.options && props.options.map((option) => (
            <MenuItem className={classes.menuItem} key={`${option.type}-${option.name}`} value={option.type}>
              {option.name}
            </MenuItem>
          ))}
          {isLoading && <MenuItem disabled>Loading...</MenuItem>}
        </Select>
      </FormControl>
    </span>
  );
};
  
  



/**
 * 
 * @param {*} props 
 * @returns 
 */
export const FilterOptionsSort = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sort, setSort] = React.useState(props.initialValue);

  const handleChange = (event) => {
    setSort(event.target.value);
    FilterOptionsObject.sort_key = event.target.value;
    props.onChange(FilterOptionsObject);
    closeOpenItem();
  };

  const closeOpenItem = () => {
    let uriParts = window.location.pathname.split('/');
    let id = uriParts[3] ? uriParts[3] : uriParts[2];
    if(id) {
      props.history.push(`/${uriParts[1]}`);
    }
  }

  return (
    <span className={classes.sortFilterRoot}>
      <FormControl className={classes.formControl}>
        <Select
          labelId="sort-select-label"
          id="sort-select"
          value={sort}
          displayEmpty={true}
          onChange={handleChange}
          classes={{
            selectMenu: classes.menuPaper
          }}
          MenuProps={{
            classes: {
              paper: classes.menuPaper
            }
          }}
        >
          {props.options && props.options.map((option) => (
            <MenuItem className={classes.menuItem} key={`${option.type}-${option.name}`} value={option.type}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </span>
  );
};
  