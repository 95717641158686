import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import SlideshowOutlinedIcon from '@material-ui/icons/SlideshowOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 12,
    right: 24,
  },
  wrapper: {
    position: 'relative',
  },
  container: {
    position: 'absolute',
    bottom: 60,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'right',
    }
  },
  margin: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  toggleBtn: {
    opacity: .75,
    '& svg': {
      //fontSize: '2.4rem',
    }
  },
  subBtn: {
    //float: 'right',
    margin: '8px 0',
    whiteSpace: 'nowrap',
    boxShadow: '0px 15px 25px -3px rgba(155,155,155,.75),0px 8px 100px 1px rgba(255,255,255,0.9),0px 3px 14px 2px rgba(255,255,255,0.12)',
    '&.light': {
      backgroundColor: '#ffffff',
    }
  }
});

class GetSupport extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      checked: false,
    };
  }
  
  handleContactSupport = () => {
    window.FreshWidget.show();
  };

  handleStartTour = () => {
    this.props.onStartTour();
  };

  handleOpen = () => {
    this.setState(state => ({ checked: true }));
  };
  handleClose = () => {
    this.setState(state => ({ checked: false }));
  };

  render() {
    const { classes } = this.props;
    const { checked } = this.state;

    return (
      <div className={classes.root} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            {this.props.onStartTour &&
              <Grow in={checked} unmountOnExit={true} {...(checked ? { timeout: 1500 } : {})}>
                <div>
                  <Button variant="outlined" size="medium" color="default" className={classNames(classes.subBtn, 'light')} 
                    onClick={this.handleStartTour}>
                    Starter Tour
                    <FontDownloadOutlinedIcon className={classes.rightIcon} />
                  </Button>
                </div>
              </Grow>
            }
            <Grow in={checked} unmountOnExit={true} {...(checked ? { timeout: 1000 } : {})}>
              <div>
                <Button variant="outlined" size="medium" color="default" className={classNames(classes.subBtn, 'light')} 
                  href="http://support.langify-app.com/support/solutions/11000004136" target="blank">
                  Documentation
                  <OpenInNewOutlinedIcon className={classes.rightIcon} />
                </Button>
              </div>
            </Grow>
            <Grow in={checked} unmountOnExit={true} {...(checked ? { timeout: 500 } : {})}>
              <div>
                <Button variant="contained" size="large" color="secondary" className={classNames(classes.subBtn)}
                  onClick={this.handleContactSupport}>
                  Get Support
                  <ChatBubbleOutlineOutlinedIcon className={classes.rightIcon} style={{marginRight: -5}} />
                </Button>
              </div>
            </Grow>
          </div>

          <Fab color={checked ? 'default' : 'secondary'} aria-label="small" className={classes.toggleBtn} >
            <HelpOutlineRoundedIcon fontSize="large" />
          </Fab>     
        </div>
      </div>
    )
  }
}

GetSupport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GetSupport);
