import React from 'react';
import { getShopSEO,  saveShopSEO, getShopPaymentGateways, saveShopPaymentGateways, getShopShippingMethods, saveShopShippingMethods, getShopPolicies, saveShopPolicies, syncMetafields, saveMetafields, getShopProgress, resetErrors } from '../../modules/shop'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import LoadingIndicator from '../../components/LoadingIndicator';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({

});

class Shop extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [
        { id: 'seo', progress: 0, title: 'Title and meta description', synchronized: false, fields: [] },
        { id: 'metafields', progress: 0, title: 'Metafields', synchronized: false, fields: [] }
      ],
      errorLockCollapse: false
    }

    if(this.props.shop.shop.version > 2) {
      this.state.data.push({ id: 'payment_gateways', progress: 0, title: 'Payment Gateways', synchronized: false, fields: [] })
      this.state.data.push({ id: 'shipping_methods', progress: 0, title: 'Shipping Methods', synchronized: false, fields: [] })
      this.state.data.push({ id: 'policies', progress: 0, title: 'Legals', synchronized: false, fields: [] });
    }
  }
  
  componentDidMount() {
    this.props.getShopProgress(this.props.editLanguage)
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      window.location.reload();
      return
    }

    let seoFields = this.state.data.find(x => x.id === 'seo').fields.slice();
    let seoSynchronized = this.state.data.find(x => x.id === 'seo').synchronized

    let metafieldsFields = this.state.data.find(x => x.id === 'metafields').fields.slice();
    let metafieldsSynchronized = this.state.data.find(x => x.id === 'metafields').synchronized

    if((this.props.shop.get_shop_seo_pending === true && newProps.shop.get_shop_seo_pending === false) || (this.props.shop.save_shop_seo_pending === true && newProps.shop.save_shop_seo_pending === false)) {
      seoFields = [{
        id: 'title',
        type: 'text',
        source: newProps.shop.seo.source.title || '',
        target: newProps.shop.seo.target.title || '',
        maxLength: 70,
      },{
        id: 'description',
        multiline: true,
        rows: 5,
        type: 'text',
        source: newProps.shop.seo.source.description || '',
        target: newProps.shop.seo.target.description || '',
        maxLength: 320,
      }]

      seoSynchronized = newProps.shop.get_shop_seo_synchronized
    }

    if((this.props.shop.sync_shop_metafields_pending === true && newProps.shop.sync_shop_metafields_pending === false) || (this.props.shop.save_shop_metafields_pending === true && newProps.shop.save_shop_metafields_pending === false)) {
      metafieldsFields = []

      newProps.shop.metafields.forEach(item => {
        const htmlContent = item.source.match(/^<p.*>(.*)<\/p>$/gim);
        metafieldsFields.push({
          id: item.id,
          multiline: true,
          rows: 5,
          type: htmlContent ? 'richtext' : 'text',
          source: item.source,
          target: item.target || '',
          label: item.namespace + ' -> ' + item.key
        })
      })

      metafieldsSynchronized = newProps.shop.shop_metafields_synchronized
    }

    const newData = [
      { id: 'seo', progress: newProps.shop.seo.progress, title: 'Title and meta description', synchronized: seoSynchronized, fields: seoFields },
      { id: 'metafields', progress: newProps.shop.metafields_progress, title: 'Metafields', synchronized: metafieldsSynchronized, fields: metafieldsFields }
    ]

    if(this.props.shop.shop.version > 2) {
      let paymentGatewaysFields = this.state.data.find(x => x.id === 'payment_gateways').fields.slice();
      let paymentGatewaysSynchronized = this.state.data.find(x => x.id === 'payment_gateways').synchronized

      let policyFields = this.state.data.find(x => x.id === 'policies').fields.slice();
      let policiesSynchronized = this.state.data.find(x => x.id === 'policies').synchronized
      
      if((this.props.shop.get_shop_payment_gateways_pending === true && newProps.shop.get_shop_payment_gateways_pending === false) || (this.props.shop.save_shop_payment_gateways_pending === true && newProps.shop.save_shop_payment_gateways_pending === false)) {
        paymentGatewaysFields = []

        newProps.shop.payment_gateways.forEach(item => {
          paymentGatewaysFields.push({
            id: item.id,
            multiline: true,
            rows: 5,
            type: 'text',
            source: item.source,
            target: item.target || ''
          })
        })

        paymentGatewaysSynchronized = newProps.shop.get_shop_payment_gateways_synchronized
      }

      let shippingMethodsFields = this.state.data.find(x => x.id === 'shipping_methods').fields.slice();
      let shippingMethodsSynchronized = this.state.data.find(x => x.id === 'shipping_methods').synchronized

      if((this.props.shop.get_shop_shipping_methods_pending === true && newProps.shop.get_shop_shipping_methods_pending === false) || (this.props.shop.save_shop_shipping_methods_pending === true && newProps.shop.save_shop_shipping_methods_pending === false)) {
        shippingMethodsFields = []

        newProps.shop.shipping_methods.forEach(item => {
          shippingMethodsFields.push({
            id: item.id,
            multiline: true,
            rows: 5,
            type: 'text',
            source: item.source,
            target: item.target || ''
          })
        })

        shippingMethodsSynchronized = newProps.shop.get_shop_shipping_methods_synchronized
      }

      if((this.props.shop.get_shop_policies_pending === true && newProps.shop.get_shop_policies_pending === false) || (this.props.shop.save_shop_policies_pending === true && newProps.shop.save_shop_policies_pending === false)) {
        policyFields = []

        newProps.shop.policies.forEach(item => {
          policyFields.push({
            id: item.id,
            type: 'richtext',
            source: item.source,
            target: item.target || ''
          })
        })

        policiesSynchronized = newProps.shop.get_shop_policies_synchronized
      }

      newData.push({ id: 'payment_gateways', progress: newProps.shop.payment_gateways_progress, title: 'Payment Gateways', synchronized: paymentGatewaysSynchronized, fields: paymentGatewaysFields })
      newData.push({ id: 'shipping_methods', progress: newProps.shop.shipping_methods_progress, title: 'Shipping Methods', synchronized: shippingMethodsSynchronized, fields: shippingMethodsFields })
      newData.push({ id: 'policies', progress: newProps.shop.policies_progress, title: 'Legals', synchronized: policiesSynchronized, fields: policyFields })
    }

    this.setState({data:newData});
  }

  onExpand(id) {
    if(id === 'seo' && this.state.data.find(x => x.id === 'seo').synchronized === false) {
      this.props.getShopSEO(this.props.editLanguage)
    } else if(id === 'payment_gateways' && this.state.data.find(x => x.id === 'payment_gateways').synchronized === false) {
      this.props.getShopPaymentGateways(this.props.editLanguage)
    } else if(id === 'shipping_methods' && this.state.data.find(x => x.id === 'shipping_methods').synchronized === false) {
      this.props.getShopShippingMethods(this.props.editLanguage)
    } else if(id === 'policies' && this.state.data.find(x => x.id === 'policies').synchronized === false) {
      this.props.getShopPolicies(this.props.editLanguage)
    } else if(id === 'metafields' && this.state.data.find(x => x.id === 'metafields').synchronized === false) {
      this.props.syncMetafields(this.props.editLanguage);
    }
  }

  onCollapsed(id) {
  }

  onSave = (items, fields) => {
    const seoItem = items.find(x => x.id === 'seo');
    if(seoItem) {
      this.props.saveShopSEO(
        this.props.editLanguage,
        {
          id: seoItem.id,
          title: seoItem.fields.find(x => x.id === 'title').target,
          description: seoItem.fields.find(x => x.id === 'description').target
        }
      )
    }

    const metafieldItem = items.find(x => x.id === 'metafields');
    if(metafieldItem) {
      let usedFields = [];
      metafieldItem.fields.forEach(field => {
        if(fields[`metafields-${field.id}`] || fields[`metafields-${field.id}`] === "") {
          usedFields.push({
            id: field.id,
            value: field.target
          })
        }
      })

      this.props.saveMetafields(
        this.props.editLanguage,
        usedFields
      )
    }

    const paymentGatewayItem = items.find(x => x.id === 'payment_gateways');
    if(paymentGatewayItem) {
      let usedFields = [];
      paymentGatewayItem.fields.forEach(field => {
        usedFields.push({
          id: field.id,
          name: field.target
        })
      })

      this.props.saveShopPaymentGateways(
        this.props.editLanguage,
        usedFields
      )
    }

    const shippingMethodItem = items.find(x => x.id === 'shipping_methods');
    if(shippingMethodItem) {
      let usedFields = [];
      shippingMethodItem.fields.forEach(field => {
        usedFields.push({
          id: field.id,
          name: field.target
        })
      })

      this.props.saveShopShippingMethods(
        this.props.editLanguage,
        usedFields
      )
    }

    const policyItem = items.find(x => x.id === 'policies');
    if(policyItem) {
      let usedFields = [];
      policyItem.fields.forEach(field => {
        usedFields.push({
          id: field.id,
          body: field.target
        })
      })

      this.props.saveShopPolicies(
        this.props.editLanguage,
        usedFields
      )
    }
  }

  render() {
    const { t, classes } = this.props

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.shop.title')}
      </Typography>
      <Divider className="divider-white" />
      <ItemsList 
        type="shop"
        onExpand={(id) => {this.onExpand(id)}}
        onSave={this.onSave}
        isPending={
          this.props.shop.get_shop_seo_pending ||
          this.props.shop.save_shop_seo_pending ||
          this.props.shop.get_shop_payment_gateways_pending ||
          this.props.shop.save_shop_payment_gateways_pending ||
          this.props.shop.get_shop_shipping_methods_pending ||
          this.props.shop.save_shop_shipping_methods_pending ||
          this.props.shop.get_shop_policies_pending ||
          this.props.shop.save_shop_policies_pending ||
          this.props.shop.sync_shop_metafields_pending ||
          this.props.shop.save_shop_metafields_pending ||
          this.props.shop.get_shop_progress_pending
        }
        isSaving={
          this.props.shop.save_shop_seo_pending ||
          this.props.shop.save_shop_payment_gateways_pending ||
          this.props.shop.save_shop_shipping_methods_pending ||
          this.props.shop.save_shop_policies_pending ||
          this.props.shop.save_shop_metafields_pending
        }
        data={this.state.data}
        allowAutoTranslation={true}
        allowFieldFiltering={true}
        error={this.props.shop.error}
        errorLockCollapse={this.state.errorLockCollapse}
      />
      <LoadingIndicator
        pending={this.props.shop.get_shop_seo_pending || this.props.shop.save_shop_seo_pending || this.props.shop.get_shop_payment_gateways_pending || this.props.shop.save_shop_payment_gateways_pending || this.props.shop.get_shop_shipping_methods_pending || this.props.shop.save_shop_shipping_methods_pending || this.props.shop.get_shop_policies_pending || this.props.shop.save_shop_policies_pending || this.props.shop.sync_shop_metafields_pending || this.props.shop.save_shop_metafields_pending || this.props.shop.get_shop_progress_pending}
        progress={this.props.shop.progress}
        error={this.props.shop.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />
    </React.Fragment>
  }

}

const mapStateToProps = ({ shop }) => ({
  shop: shop,
  editLanguage: shop.shop.edit_language
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getShopSEO,
      saveShopSEO,
      getShopPaymentGateways,
      saveShopPaymentGateways,
      getShopShippingMethods,
      saveShopShippingMethods,
      getShopPolicies,
      saveShopPolicies,
      syncMetafields,
      saveMetafields,
      getShopProgress,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Shop))))