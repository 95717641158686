import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const GET_NEW_CUSTOM_CONTENTS_PENDING = 'GET_NEW_CUSTOM_CONTENTS_PENDING'
const GET_NEW_CUSTOM_CONTENTS_RESPONSE = 'GET_NEW_CUSTOM_CONTENTS_RESPONSE'

const CREATE_NEW_CUSTOM_CONTENTS_PENDING = 'CREATE_NEW_CUSTOM_CONTENTS_PENDING'
const CREATE_NEW_CUSTOM_CONTENTS_PROGRESS = 'CREATE_NEW_CUSTOM_CONTENTS_PROGRESS'
const CREATE_NEW_CUSTOM_CONTENTS_RESPONSE = 'CREATE_NEW_CUSTOM_CONTENTS_RESPONSE'

const DELETE_NEW_CUSTOM_CONTENT_PENDING = 'DELETE_NEW_CUSTOM_CONTENT_PENDING'
const DELETE_NEW_CUSTOM_CONTENT_PROGRESS = 'DELETE_NEW_CUSTOM_CONTENT_PROGRESS'
const DELETE_NEW_CUSTOM_CONTENT_RESPONSE = 'DELETE_NEW_CUSTOM_CONTENT_RESPONSE'

const SAVE_NEW_CUSTOM_CONTENTS_PENDING = 'SAVE_NEW_CUSTOM_CONTENTS_PENDING'
const SAVE_NEW_CUSTOM_CONTENTS_PROGRESS = 'SAVE_NEW_CUSTOM_CONTENTS_PROGRESS'
const SAVE_NEW_CUSTOM_CONTENTS_RESPONSE = 'SAVE_NEW_CUSTOM_CONTENTS_RESPONSE'

const EXPORT_NEW_CUSTOM_CONTENTS_PENDING = 'EXPORT_NEW_CUSTOM_CONTENTS_PENDING'
const EXPORT_NEW_CUSTOM_CONTENTS_PROGRESS = 'EXPORT_NEW_CUSTOM_CONTENTS_PROGRESS'
const EXPORT_NEW_CUSTOM_CONTENTS_RESPONSE = 'EXPORT_NEW_CUSTOM_CONTENTS_RESPONSE'

const IMPORT_NEW_CUSTOM_CONTENTS_PENDING = 'IMPORT_NEW_CUSTOM_CONTENTS_PENDING'
const IMPORT_NEW_CUSTOM_CONTENTS_PROGRESS = 'IMPORT_NEW_CUSTOM_CONTENTS_PROGRESS'
const IMPORT_NEW_CUSTOM_CONTENTS_RESPONSE = 'IMPORT_NEW_CUSTOM_CONTENTS_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  get_new_custom_contents_pending: true,
  create_new_custom_contents_pending: false,
  delete_new_custom_content_pending: false,
  save_new_custom_contents_pending: false,
  export_new_custom_contents_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_new_custom_contents_pending: false,
  imported_entries: 0,
  progress: 0,
  error: false,
  new_custom_contents: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case GET_NEW_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        get_new_custom_contents_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case GET_NEW_CUSTOM_CONTENTS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_new_custom_contents_pending: false,
          new_custom_contents: action.response.new_custom_contents
        }
      } else {
        return {
          ...state,
          get_new_custom_contents_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case CREATE_NEW_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_new_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_NEW_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_NEW_CUSTOM_CONTENTS_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.new_custom_contents);
      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          newCustomContents[response.new_custom_content.scope].custom_contents.unshift({
            id: response.new_custom_content.id,
            progress: response.new_custom_content.progress,
            language_id: response.new_custom_content.language_id,
            source: {
              text: response.new_custom_content.source.text
            },
            target: {
              text: response.new_custom_content.target.text
            }
          })
        }
      }
      return {
        ...state,
        new_custom_contents: newCustomContents,
        create_new_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_NEW_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_new_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_NEW_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_NEW_CUSTOM_CONTENT_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.new_custom_contents);
      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        newCustomContents[action.response.scope].custom_contents = newCustomContents[action.response.scope].custom_contents.filter(x => x.id != action.response.id);
      }

      return {
        ...state,
        new_custom_contents: newCustomContents,
        delete_new_custom_content_pending: false,
        error: error
      }
    }
    case SAVE_NEW_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        save_new_custom_contents_pending: true,
        error: false,
      }
    }
    case SAVE_NEW_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_NEW_CUSTOM_CONTENTS_RESPONSE: {
      let error = false;
      let newCustomContents = Object.assign({}, state.new_custom_contents);
      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          response.new_custom_contents.forEach(newCustomContent => {
            const itemToUpdate = newCustomContents[newCustomContent.scope].custom_contents.find(x => x.id == newCustomContent.id)
            if(itemToUpdate) {
              itemToUpdate.progress = newCustomContent.progress;
              itemToUpdate.target.text = newCustomContent.target.text;
            }
          })
        }
      }
      return {
        ...state,
        new_custom_contents: newCustomContents,
        save_new_custom_contents_pending: false,
        error: error
      }
    }
    case EXPORT_NEW_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        progress: 0,
        export_new_custom_contents_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_NEW_CUSTOM_CONTENTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_NEW_CUSTOM_CONTENTS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_new_custom_contents_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),

        }
      } else {
        return {
          ...state,
          progress: 100,
          export_new_custom_contents_pending: false
        }
      }
    }
    case IMPORT_NEW_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        progress: 0,
        import_new_custom_contents_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_NEW_CUSTOM_CONTENTS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_NEW_CUSTOM_CONTENTS_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_new_custom_contents_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_new_custom_contents_pending: false,
          imported_entries: importedEntries
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_new_custom_contents_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const getNewCustomContents = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_NEW_CUSTOM_CONTENTS_PENDING
    })

    API.getNewCustomContents(language, response => {
      dispatch({
        type: GET_NEW_CUSTOM_CONTENTS_RESPONSE,
        response: response,
        action: () => getNewCustomContents(language)
      })
    })
  }
}

export const createNewCustomContents = (language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_NEW_CUSTOM_CONTENTS_PENDING
    })

    API.createNewCustomContents(
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_NEW_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_NEW_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createNewCustomContents(language, items)
        })
      })
  }
}

export const deleteNewCustomContent = (id, deprecated) => {
  return dispatch => {
    dispatch({
      type: DELETE_NEW_CUSTOM_CONTENT_PENDING
    })

    API.deleteNewCustomContent(
      id,
      deprecated,
      progress => {
        dispatch({
          type: DELETE_NEW_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_NEW_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deleteNewCustomContent(id, deprecated)
        })
      })
  }
}

export const saveNewCustomContents = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_NEW_CUSTOM_CONTENTS_PENDING
    })

    API.saveNewCustomContents(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_NEW_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_NEW_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => saveNewCustomContents(language, items)
        })
      })
  }
}

export const exportNewCustomContents = (language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_NEW_CUSTOM_CONTENTS_PENDING
    })

    API.exportNewCustomContents(
      language,
      progress => {
        dispatch({
          type: EXPORT_NEW_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_NEW_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          options: options,
          action: () => exportNewCustomContents(language, options)
        }
      )
    })
  }
}

export const importNewCustomContents = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_NEW_CUSTOM_CONTENTS_PENDING
    })

    API.importNewCustomContents(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_NEW_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_NEW_CUSTOM_CONTENTS_RESPONSE,
          response: response
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}