import { RootRef } from "@material-ui/core";
import metaObjects from "../modules/metaObjects";

const PROTOCOL = 'https://';
const DEFAULT_HOST = window.loadEnv("REACT_APP_LANGIFY_DEFAULT_HOST", process.env.REACT_APP_LANGIFY_DEFAULT_HOST);
const RETURN_URL = window.loadEnv("REACT_APP_LANGIFY_RETURN_URL", process.env.REACT_APP_LANGIFY_RETURN_URL);
const ENDPOINT = '/v2/api/';
const KEY = 'wvSQbHavRa';

let HOST = null;

const DELAY = 1000;
function later(time) {
  return new Promise(function(resolve,reject){
    setTimeout(function(){resolve(time);},time);
  });
};

const handleError = function (err) {
	console.warn(err);
	return new Response(JSON.stringify(err));
};

const sleeper = function (ms) {
	if(ms > 0) { 
        console.info(`A fetch() is delayed for ${ms} milliseconds to reduce load on the Shopify API.`); 
    }
    return function(x) {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
}

let apiCallLimitWaitDate = new Date();
let apiCallLimitWaitTime = 0;
const _fetch = (url, options) => {
    //console.log(`fetch("${url}")`);
    const nowDate = new Date();
    const defaultOptions = {
        cors: 'no-cors',
        headers: {
            'x-referrer': window.location.href
        }
    }
    if(nowDate.valueOf() > apiCallLimitWaitDate.valueOf()) {
        apiCallLimitWaitTime = 0;
    }
    return fetch(url, {defaultOptions, ...options})
        .then(sleeper(apiCallLimitWaitTime))
        .then((res) => {
            if(res.headers.get('x-langify-api-call-limit')) {
                const apiCallLimitData = JSON.parse(res.headers.get('x-langify-api-call-limit'));
                if(apiCallLimitData && apiCallLimitData.currentlyAvailable < 500) {
                    apiCallLimitWaitTime = ((apiCallLimitData.maximumAvailable - apiCallLimitData.currentlyAvailable) / apiCallLimitData.restoreRate) * 1000;
                    apiCallLimitWaitDate = new Date(Date.now() + apiCallLimitWaitTime)
                }
            }
            return res;
        });
}

const fetchSequential = (urls, payloads, index = 0, results = []) => {
	if (index >= urls.length) {
		//console.log('ALL DONE', results);
	  return Promise.resolve(results);
	}
	//console.log(`fetch url: ${urls[index]}`);
	return fetch(urls[index], payloads[index])
	  .then(response => {
			if (!response.ok) {
				throw new Error('Network response error');
			}
			return response.json();
		})
		.then(data => {
			//console.log(`Data from ${urls[index]}:`, data);
			results.push(data);
			return fetchSequential(urls, payloads, index + 1, results);
		});
};
function chunkArray(array, chunkSize) {
	const chunkedArray = [];
	for (let i = 0; i < array.length; i += chunkSize) {
	  chunkedArray.push(array.slice(i, i + chunkSize));
	}
	return chunkedArray;
}
function chunkObject(object, chunkSize) {
  const keys = Object.keys(object);
  const chunkedObjects = [];
  for (let i = 0; i < keys.length; i += chunkSize) {
    const chunkedObject = {};
    const currentKeys = keys.slice(i, i + chunkSize);
    currentKeys.forEach(key => {
      chunkedObject[key] = object[key];
    });
    chunkedObjects.push(chunkedObject);
  }
  return chunkedObjects;
}


const API = {
    currentBulkTimeout: null,
    isRunningBulkOperation: false,
    clearHost: () => {
        localStorage.removeItem('langify_host');

        return true;
    },
    changeHost: (host) => {
        localStorage.setItem('langify_host', host);

        return true;
    },
    startSession: (response) => {
        API.generateToken(data => {
            if(data.success === true && data.token && data.api_host) {
                let usedHost = data.api_host;

                // locally changed hosts win over the ones returned by the server
                if(localStorage.getItem('langify_host')) {
                    usedHost = localStorage.getItem('langify_host');
                }

                if(usedHost == DEFAULT_HOST) {
                    HOST = DEFAULT_HOST;
                    response({success:true});
                } else {
                    HOST = usedHost;

                    API.initiateSession(data.token, data => {
                        if(data.success === true) {
                            response(data);
                        } else {
                            API.logout(result => {
                                if(result.success === true && result.redirect_uri) {
                                    window.location.replace(result.redirect_uri)
                                }
                            });
                        }
                    });
                }
            } else {
                if(window.location.hostname === 'v2.langify-app.com') {
                    window.location.replace('https://langify-app.com')
                } else if(window.location.hostname === 'localhost' || window.location.hostname === 'v2.langify.local') {
                    window.location.replace('https://langify.local')
                } else if(window.location.hostname === 'langify.lovely-app.com') {
                    window.location.replace('https://langify.herokuapp.com')
                }
            }
        });
    },
    generateToken: (response) => {
        fetch(PROTOCOL + DEFAULT_HOST + ENDPOINT + 'token/generate?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data)
        })
        .catch(error => response(error));
    },
    initiateSession: (token, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'session/initiate?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    newLogout: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/logout?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    getShop: (response) => {
        _fetch(PROTOCOL + HOST + ENDPOINT + 'shop' + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.version < 3) {
                if(window.location.hostname === 'v2.langify-app.com') {
                    window.location.replace('https://langify-app.com')
                } else if(window.location.hostname === 'localhost' || window.location.hostname === 'v2.langify.local') {
                    window.location.replace('https://langify.local')
                } else if(window.location.hostname === 'langify.lovely-app.com') {
                    window.location.replace('https://langify.herokuapp.com')
                }
            }
            if(data.success === false && data.redirect_url) {
                window.location.replace(data.redirect_url)
            }
            response(data);
        })
        .catch(() => {
            if(window.location.hostname === 'v2.langify-app.com') {
                window.location.replace('https://langify-app.com')
            } else if(window.location.hostname === 'localhost' || window.location.hostname === 'v2.langify.local') {
                window.location.replace('https://langify.local')
            } else if(window.location.hostname === 'langify.lovely-app.com') {
                window.location.replace('https://langify.herokuapp.com')
            }
        })
    },
    setEditLanguage: (language, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/edit_language/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    oldLogout: (response) => {
        fetch(PROTOCOL + DEFAULT_HOST + ENDPOINT + 'shop/logout?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    logout: (response) => {
        if(HOST === DEFAULT_HOST) {
            API.oldLogout(result => {
                response(result);
            });
        } else {
            API.newLogout(result => {
                API.oldLogout(result => {

                    // Cookie für langify-app.com löschen
                    document.cookie = "PHPSESSID=; path=/; domain=langify-app.com; expires=Thu, 01 Jan 1970 00:00:00 UTC";

                    // Cookie für api.langify-app.com löschen
                    document.cookie = "PHPSESSID=; path=/; domain=api.langify-app.com; expires=Thu, 01 Jan 1970 00:00:00 UTC";
                   
                    response(result);
                })
            })
        }
    },
    getShopSEO: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/seo/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },  
    saveShopSEO: (language, item, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/seo/save/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(item),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    getShopPaymentGateways: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/payment_gateways/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },  
    saveShopPaymentGateways: async (language, items, progress, response) => {
        let fetches = []
        let perCall = 5;
        let calls = Math.ceil(items.length / perCall)

        for(let i = 0; i < calls; ++i) {
            let usedItems = []
            for(let j = 0; j < perCall; ++j) {
                const index = (i * perCall) + j;
                if(index < items.length) {
                    usedItems.push(items[index])
                }
            }


            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'shop/payment_gateways/save/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(usedItems),
                        method: 'POST'
                    })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error));
    },
    getShopShippingMethods: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/shipping_methods/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },  
    saveShopShippingMethods: async (language, items, progress, response) => {
        let fetches = []
        let perCall = 5;
        let calls = Math.ceil(items.length / perCall)

        for(let i = 0; i < calls; ++i) {
            let usedItems = []
            for(let j = 0; j < perCall; ++j) {
                const index = (i * perCall) + j;
                if(index < items.length) {
                    usedItems.push(items[index])
                }
            }


            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'shop/shipping_methods/save/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(usedItems),
                        method: 'POST'
                    })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error));
    },
    getShopPolicies: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/policies/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },  
    saveShopPolicies: async (language, items, progress, response) => {
        let fetches = []
        let perCall = 5;
        let calls = Math.ceil(items.length / perCall)

        for(let i = 0; i < calls; ++i) {
            let usedItems = []
            for(let j = 0; j < perCall; ++j) {
                const index = (i * perCall) + j;
                if(index < items.length) {
                    usedItems.push(items[index])
                }
            }


            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'shop/policies/save/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(usedItems),
                        method: 'POST'
                    })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error));
    },
    getShopProgress: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/progress/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch();
    },
    syncShopMetafields: (language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/metafields/count/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('shop/metafields/ids', null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false);
                }
            })
            .then(data => {
                return API.__paginateItems('shop/metafields/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'shop/metafields/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))

    },
    saveShopMetafields: async (language, items, progress, response) => {
        let fetches = []
        let perCall = 5;
        let calls = Math.ceil(items.length / perCall)

        for(let i = 0; i < calls; ++i) {
            let usedItems = []
            for(let j = 0; j < perCall; ++j) {
                const index = (i * perCall) + j;
                if(index < items.length) {
                    usedItems.push(items[index])
                }
            }


            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'shop/metafields/save/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(usedItems),
                        method: 'POST'
                    })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error));
    },
    getShopSubscription: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/subscription?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => { response(data); })
        .catch(error => response(error));
    },  
    syncProductMetafields: (product, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        _fetch(PROTOCOL + HOST + ENDPOINT + 'product/metafields/count/' + product + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('product/metafields/ids/' + product, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('product/metafields/sync/start/' + product, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return _fetch(PROTOCOL + HOST + ENDPOINT + 'product/metafields/sync/end/' + product + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    syncProductVariantMetafields: (product, language, progress, response) => {
        let metafields = [];
        let totalItems = 0;
        let processedItems = 0;
        _fetch(PROTOCOL + HOST + ENDPOINT + 'product_variants/' + product + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(async data => {
            totalItems = data.variants.length;
            for(let i = 0; i < totalItems; i++) {
                await API.__syncProductVariantMetafields(
                    data.variants[i],
                    language,
                    tmpProgress => {},
                    tmpData => {
                        processedItems++;

                        progress(Math.round(processedItems / (totalItems) * 100));

                        if(tmpData.success === true) {
                            metafields = metafields.concat(tmpData.metafields);
                        }

                        if(processedItems === totalItems) {
                            progress(100);
                            response({success:true, product_id: product, variant_metafields: metafields});
                        }
                    })
            }
        })
    },
    __syncProductVariantMetafields: (productVariant, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        _fetch(PROTOCOL + HOST + ENDPOINT + 'product_variant/metafields/count/' + productVariant + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('product_variant/metafields/ids/' + productVariant, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('product_variant/metafields/sync/start/' + productVariant, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return _fetch(PROTOCOL + HOST + ENDPOINT + 'product_variant/metafields/sync/end/' + productVariant + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    syncPageMetafields: (page, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        fetch(PROTOCOL + HOST + ENDPOINT + 'page/metafields/count/' + page + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('page/metafields/ids/' + page, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('page/metafields/sync/start/' + page, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'page/metafields/sync/end/' + page + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))

    },
    syncCollectionMetafields: (collection, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        fetch(PROTOCOL + HOST + ENDPOINT + 'collection/metafields/count/' + collection + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('collection/metafields/ids/' + collection, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('collection/metafields/sync/start/' + collection, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'collection/metafields/sync/end/' + collection + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))

    },
    syncBlogMetafields: (blog, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        fetch(PROTOCOL + HOST + ENDPOINT + 'blog/metafields/count/' + blog + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('blog/metafields/ids/' + blog, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('blog/metafields/sync/start/' + blog, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'blog/metafields/sync/end/' + blog + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))

    },
    syncArticleMetafields: (article, language, progress, response) => {
        let ids = [];
        let numPages = 0;
        let numAllPages = 0;
        const perPage = 50;
        const allPerPage = 250;

        fetch(PROTOCOL + HOST + ENDPOINT + 'article/metafields/count/' + article + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil(data.count / perPage)
                    numAllPages = Math.ceil(data.all_count / allPerPage)

                    return API.__paginateItems('article/metafields/ids/' + article, null, 1, numAllPages, allPerPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false)
                }
            })
            .then(data => {
                return API.__paginateItems('article/metafields/sync/start/' + article, language, 1, numPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'article/metafields/sync/end/' + article + '/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))

    },
    getLanguages: (response) => {
      _fetch(PROTOCOL + HOST + ENDPOINT + 'languages' + '?key=' + KEY,
      {
          credentials: 'include'
      })
      .then(data => data.json())
      .then(data => {
          response(data);
      })
      .catch();
    },
    createLanguage: (languageData, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/create?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(languageData),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()

    },
    saveLanguage: (language, languageData, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/save/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(languageData),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()
    },
    restoreLanguage: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/restore/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()
    },
    deleteLanguage: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/delete/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()
    },
    publishLanguage: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/publish/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()
    },
    unpublishLanguage: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'languages/unpublish/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch()
    },
    getThemes: (response) => {
        _fetch(PROTOCOL + HOST + ENDPOINT + 'themes' + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
      })
      .catch();
    },
    publishTranslations: (themeId, progress, response) => {
      later(DELAY * 3)
      .then(() => {
        response({success: true, themeId: themeId})
      })
    },
    unpublishTranslations: (themeId, progress, response) => {
      later(DELAY * 3)
      .then(() => {
        response({success: true, themeId: themeId})
      })
    },
    refreshTranslations: (themeId, progress, response) => {
      later(DELAY * 6)
      .then(() => {
        response({success: true, progress, themeId: themeId})
      })
    },
    getCustomContents: (language, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'custom_contents/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
      })
      .catch(error => response(error));
    },
    saveCustomContents: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'custom_contents/save/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    createCustomContents: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'custom_contents/create/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteCustomContents: async (ids, progress, response) => {
        let i = 0;
        let j = ids.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'custom_contents/delete/' + ids[i] + '?key=' + KEY,
                {
                    credentials: 'include',
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncPages: (language, progress, response, sync_reset) => {
        let ids = [];
        let datastring = null;

        fetch(PROTOCOL + HOST + ENDPOINT + 'pages/count/' + language + '?key=' + KEY,
            {
                credentials: 'include',
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    const count = data.count
                    const perPage = 50
                    const numPages = Math.ceil(count / perPage)
                    return API.__paginateItems('pages/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, sync_reset)
                }
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'pages/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error));
    },
    syncPage: (pageId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'page/sync/' + pageId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    savePages: async (language, items, force, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        let errors = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'page/save/' + language + '/' + force + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => response(data))
        .catch(error => response(error));
    },
    __paginateItems: async (apiAction, language, page, numPages, perPage, pageLink, callback, sync_reset, options={}) => { 
        //debugger;
        let bodyObj = {
            'page_link': pageLink,
        };
        bodyObj = {...bodyObj, ...options};
        if(sync_reset === true) {
            bodyObj.sync_reset = sync_reset;
        }
        if(numPages <= 0 && !sync_reset) {
            return new Promise((resolve, reject) => {resolve({})})
        }

        console.log(bodyObj)

        return await _fetch(PROTOCOL + HOST + ENDPOINT + apiAction + '/' + page + '/' + perPage + (language ? '/' + language : '') + '?key=' + KEY,
            {
                credentials: 'include',
                body: JSON.stringify(bodyObj),
                method: 'POST'
            })
        .then(data => data.json())
        .then(data => {
            callback(data, Math.round(page / (numPages) * 100))
            if(page < numPages && data.next_page_link) {
                return API.__paginateItems(apiAction, language, page + 1, numPages, perPage, data.next_page_link, callback, sync_reset, options)
            }

            return data;
        })
    },
    __paginateNavigation: async (apiAction, language, perPage, cursor, callback) => {
        return await fetch(PROTOCOL + HOST + ENDPOINT + apiAction + '/' + perPage + (language ? '/' + language : '') + '?key=' + KEY,
            {
                credentials: 'include',
                body: JSON.stringify({'cursor': cursor}),
                method: 'POST'
            })
        .then(data => data.json())
        .then(data => {
            callback(data)
            if(data.page_info.has_next_page && data.page_info.end_cursor) {
                return API.__paginateNavigation(apiAction, language, perPage, data.page_info.end_cursor, callback)
            }

            return data;
        })
    },
    __importTranslations: async (apiAction, language, url, page, numPages, perPage, translations, map, nextLine, callback, progress, payloadData={}) => {
        return await _fetch(PROTOCOL + HOST + ENDPOINT + apiAction + '/' + perPage + '/' + language + '?key=' + KEY,
            {
                credentials: 'include',
                body: JSON.stringify({
                    url: url, 
                    translations: translations, 
                    map: map, 
                    next_line: nextLine,
                    ...payloadData
                }),
                method: 'POST'
            })
        .then(data => data.json())
        .then(data => {
            callback(data)
            if(page < numPages) {
                progress(Math.round(page / numPages * 100));
                return API.__importTranslations(apiAction, language, url, page + 1, numPages, perPage, translations, data.map, data.next_line, callback, progress, payloadData)
            }

            return data;
        })
    },
    syncProducts: (language, progress, response, sync_reset) => {
        let ids = [];
        let tags = [];
        let types = [];
        let vendors = [];

        _fetch(PROTOCOL + HOST + ENDPOINT + 'products/count/' + language + '?key=' + KEY,
            {
                credentials: 'include',
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    const count = data.count
                    const perPage = 70
                    const numPages = Math.ceil(count / perPage)
                    return API.__paginateItems('products/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                        tags = tags.concat(data.tags)
                        types = types.concat(data.types)
                        vendors = vendors.concat(data.vendors)
                    }, sync_reset)
                }
            })
            .then(data => {
                let fetches = [];
                const allFilters = ['products', 'tags', 'types', 'vendors']
                tags = tags.filter((item, pos) => {return tags.indexOf(item) == pos})
                types = types.filter((item, pos) => {return types.indexOf(item) == pos})
                vendors = vendors.filter((item, pos) => {return vendors.indexOf(item) == pos})

                allFilters.map((filter) => {
                    fetches.push(
                        fetch(PROTOCOL + HOST + ENDPOINT + 'products/sync/end/' + language + '?key=' + KEY, {
                            credentials: 'include',
                            method: 'POST',
                            body: JSON.stringify({
                                ids:ids, 
                                filter: filter,
                                tags:tags, 
                                types:types, 
                                vendors:vendors
                            })
                        })
                        .then(data =>  {
                            return data.json()
                        })
                        .then(data => {
                            return {
                                key: filter,
                                value: data[filter]
                            }
                        })
                    )
                });
                return Promise.all(fetches)

                /*
                return fetch(PROTOCOL + HOST + ENDPOINT + 'products/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify({
                            ids:ids, 
                            filter: null,
                            tags:tags, 
                            types:types, 
                            vendors:vendors
                        })
                    })
                */
            })
            .then(data => {

                let newData = {};
                data.forEach(item => {
                    newData[item.key] = item.value;
                });
                newData.success = true;

                progress(100)
                response(newData)
            })
            .catch(error => response(error))

    },
    resyncProducts: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'products/reset/sync/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success == true) {
                API.syncProducts(language, progress, response)
            } else {
                response(data)
            }
        })
        .catch(error => response(error));
    },
    syncProduct: (productId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'product/sync/' + productId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveProducts: async (language, items, force, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/save/' + language  + '/' + force + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => {
                    progress(Math.round(i / j * 100))
                    return data.json()
                })
                .catch(e => e)
            )
        }

        Promise.allSettled([
            ...fetches, 
            //...[Promise.reject(new Error("an error"))]
        ])
        .then(data => {
            progress(100)
            response(data)
        })
        .catch(error => response(error))
    },
    resaveProducts: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/resave/' + items[i] + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => {
                    progress(Math.round(i / j * 100))
                    return data.json()
                })
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            progress(100)
            response(data)
        })
        .catch(error => response(error))
    },
    saveProductsMetadata: async (language, tags, types, vendors, force, progress, response) => {
        let fetches = []

        if(tags.length > 0) {
            const tagsCount = tags.length
            const tagsPerPage = 50
            const tagsNumPages = Math.ceil(tagsCount / tagsPerPage)

            for(let i = 0; i < tagsNumPages; i++) {
                let tagItems = [];

                for(let j = 0; j < tagsPerPage; j++) {
                    let index = (i * tagsPerPage) + j;
                    if(index < tags.length) {
                        tagItems.push({...tags[index], type:'tags'})
                    }
                }

                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/save_metadata/' + language + '/' + force + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(tagItems),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .catch(e => e)
                )
            }
        }

        if(types.length > 0) {
            const typesCount = types.length
            const typesPerPage = 50
            const typesNumPages = Math.ceil(typesCount / typesPerPage)

            for(let i = 0; i < typesNumPages; i++) {
                let typeItems = [];

                for(let j = 0; j < typesPerPage; j++) {
                    let index = (i * typesPerPage) + j;
                    if(index < types.length) {
                        typeItems.push({...types[index], type:'types'})
                    }
                }

                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/save_metadata/' + language + '/' + force + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(typeItems),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .catch(e => e)
                )
            }
        }

        if(vendors.length > 0) {
            const vendorsCount = vendors.length
            const vendorsPerPage = 50
            const vendorsNumPages = Math.ceil(vendorsCount / vendorsPerPage)

            for(let i = 0; i < vendorsNumPages; i++) {
                let vendorItems = [];

                for(let j = 0; j < vendorsPerPage; j++) {
                    let index = (i * vendorsPerPage) + j;
                    if(index < vendors.length) {
                        vendorItems.push({...vendors[index], type:'vendors'})
                    }
                }

                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/save_metadata/' + language + '/' + force + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(vendorItems),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .catch(e => e)
                )
            }
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncCollections: (language, progress, response, sync_reset) => {
        let ids = [];
        let perPage = 50
        let numPages = 0;

        fetch(PROTOCOL + HOST + ENDPOINT + 'collections/count/' + language + '?key=' + KEY,
            {
                credentials: 'include',
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    numPages = Math.ceil((data.custom_count + data.smart_count) / perPage)
                    return API.__paginateItems('collections/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, sync_reset)
                }
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'collections/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error));
    },
    syncCollection: (collectionId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'collection/sync/' + collectionId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveCollections: async (language, items, force, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'collection/save/' + language + '/' + force + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncBlogs: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'blogs/sync/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    syncBlog: (blogId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'blog/sync/' + blogId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveBlogs: async (language, items, force, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'blog/save/' + language  + '/' + force + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncArticles: (language, progress, response, sync_reset) => {
        let articles = [];

        fetch(PROTOCOL + HOST + ENDPOINT + 'blogs/sync/' + language + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            let fetches = [];
            
            data.blogs.forEach(item => {
                let ids = [];
                fetches.push(
                    _fetch(PROTOCOL + HOST + ENDPOINT + 'articles/' + item.id + '/count/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(async data => {
                        if(data.success === true) {
                            const count = data.count
                            const perPage = 50
                            const numPages = Math.ceil(count / perPage)


                            return API.__paginateItems('articles/' + item.id + '/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                                progress(currentProgress);
                                ids = ids.concat(data.ids)
                            }, sync_reset)
                        }
                    })
                    .then(data => {
                        return fetch(PROTOCOL + HOST + ENDPOINT + 'articles/' + item.id + '/sync/end/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(ids)
                            })
                    })
                    .then(data => data.json())
                    .then(data => {
                        articles = articles.concat(data.articles)
                    })
                )
            })

            return Promise.all(fetches)
        })
        .then(data => {
            response({
                success: true,
                articles: articles
            });
        })
        .catch(error => response(error));
    },
    syncArticle: (articleId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'articles/sync/' + articleId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveArticles: async (language, items, force, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'article/save/' + language + '/' + force + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncNavigation: (language, progress, response) => {
        progress(0)
        let ids = [];

        fetch(PROTOCOL + HOST + ENDPOINT + 'navigation/count/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    return API.__paginateNavigation('navigation/sync/start', language, 250, null, (data) => {
                        ids = ids.concat(data.ids)
                    })
                }
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'navigation/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error));
    },
    saveNavigation: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'navigation/save/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncStaticTheme: (themeId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme/' + themeId + '/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    syncStaticThemeAsset: (themeId, assetId, languageId, progress, response, themeWrite=true) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme_asset/' + themeId + '/' + assetId + '/sync/' + languageId + '?theme_write=' + themeWrite + '&key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveStaticTheme: async (themeId, language, items, progress, response, themeWrite=true) => {
        const perPage = 20
        const numPages = Math.ceil(items.length / perPage)
        let fetches = []
        const prepareAssets = []
        for(let i = 0; i < numPages; i++) {
            let usedItems = [];

            for(let j = 0; j < perPage; j++) {
                let index = (i * perPage) + j;
                if(index < items.length) {
                    if(!prepareAssets.includes(items[index].assetId)) {
                        prepareAssets.push(items[index].assetId)
                    }
                    usedItems.push({...items[index]})
                }
            }

            fetches.push(
                await fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme_asset_save/' + themeId + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(usedItems),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }
        for(let i = 0; i < prepareAssets.length; ++i) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme_asset_prepare/' + themeId + '/' + prepareAssets[i] + '/' + language + '?theme_write=' + themeWrite + '&key=' + KEY,
                {
                    credentials: 'include'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncNewTheme: (themeId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'new_theme/' + themeId + '/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveNewTheme: async (themeId, language, items, progress, response) => {
        const perPage = 15
        const numPages = Math.ceil(items.length / perPage)
        let fetches = []
        for(let i = 0; i < numPages; i++) {
            let usedItems = [];

            for(let j = 0; j < perPage; j++) {
                let index = (i * perPage) + j;
                if(index < items.length) {
                    usedItems.push({...items[index]})
                }
            }


            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'new_theme/' + themeId + '/save_all/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(usedItems),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(
            fetch(PROTOCOL + HOST + ENDPOINT + 'new_theme/' + themeId + '/update_locale_file/' + language + '?key=' + KEY,
            {
                credentials: 'include',
                method: 'POST'
            })
            .then(data => data.json())
            .then(data =>{
                response(data)
            })
            .catch(error => response(error))
        )
        .catch(error => response(error))
    },
    syncTheme: (themeId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/sync?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    syncCheckout: (themeId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/checkout/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveCheckout: (themeId, language, items, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/checkout/save/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(items),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100);
            response(data)
        })
        .catch(error => response(error))
    },
    syncSections: (themeId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/sections/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveSections: (themeId, language, items, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/sections/save/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(items),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data)
        })
        .catch(error => response(error))
    },
    syncAsset: (themeId, assetId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/asset/' + assetId + '/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveAsset: async (themeId, languageId, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'theme/' + themeId + '/asset/save/' + languageId + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncImages: (themeId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'images/' + themeId + '/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    syncNotifications: (languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'notifications/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveNotifications: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'notifications/save/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    syncSMSNotifications: (language, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'sms_notifications/sync/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    saveSMSNotifications: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'sms_notifications/save/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    autoTranslateSingleString: (from, to, string, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'autotranslations/translate_single_string?key=' + KEY,
            {
                credentials: 'include',
                body: JSON.stringify({
                    strings: [string],
                    from: from,
                    to: to,
                    force: 'deepl' 
                }),
                method: 'POST'
            })
            .then(data => data.json())
            .then(data => {
              if(data.success === true){
                response(data.Strings[0]);
              } else {
                response(data.message);
              }
            })
            .catch(error => response(error));
    },
    autoTranslateStrings: (from, to, strings, response, process) => {
			let urls = [];
			let payloads = [];
			let chunkedStrings = chunkObject(strings, 1000);
			//console.log(chunkedStrings, strings);
			chunkedStrings.forEach(stringsChunk => {
				urls.push(PROTOCOL + HOST + ENDPOINT + 'autotranslations/translate?key=' + KEY);
				payloads.push({
					credentials: 'include',
					cors: 'no-cors',
					headers: {
						'x-referrer': window.location.href
					},
					body: JSON.stringify({
						map: stringsChunk,
						from: from,
						to: to,
						disableCache: false,
					}),
					method: 'POST'
				})
			});
			fetchSequential(urls, payloads)
				.then(data => {
					if(!data.error){
						let res = data[0];
						let allTranslatedStrings = {};
						data.forEach(x => {
							allTranslatedStrings = {...allTranslatedStrings, ...x.Strings};
						});
						res.Strings = allTranslatedStrings;
						response(res);
					} else {
						response(data.error);
					}
				})
				.catch(error => response(error));
    },
    buyWords: (plan, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'autotranslations/buy_words/' + plan + '?key=' + KEY,
            {
                credentials: 'include',
                method: 'GET'
            })
            .then(data => data.json())
            .then(data => {
                if(data.success === true) {
                    window.location.replace(data.confirmation_url)
                }
            })
            .catch(error => response(error));
    },
    updateCharge: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'application_charge/update?key=' + KEY,
            {
                credentials: 'include',
                method: 'GET'
            })
            .then(data => data.json())
            .then(data => {
                if(data.success === true) {
                    if(data.confirmation_url) {
                        window.location.replace(data.confirmation_url)
                    }
                }
            })
            .catch(error => response(error));
    },
    search: (themeId, query, response) => {
        console.log(themeId)
        fetch(PROTOCOL + HOST + ENDPOINT + 'r_search/' + themeId + '/' + query + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    searchMoreProducts: (query, cursor, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'search_more_products/' + query + '/' + cursor + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    searchMoreCollections: (query, cursor, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'search_more_collections/' + query + '/' + cursor + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    syncProductFromSearch: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'search/sync_product/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    syncCollectionFromSearch: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'search/sync_collection/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    cancelSearch: () =>{
    },
    getCustomAsset: (themeId, data, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset/' + themeId + '/get?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(data),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    restoreFromLocalAsset: async (themeId, data, response) => {
        if (!Array.isArray(data)) {
            fetch(PROTOCOL + HOST + ENDPOINT + 'local_asset/restore/' + themeId + '?key=' + KEY,
            {
                credentials: 'include',
                body: JSON.stringify(data),
                method: 'POST'
            })
            .then(data => data.json())
            .then(data => {
                response(data);
            })
            .catch(error => response(error));
        } else {
            let fetches = [];
            let _data = [];
            _data = data;

            for(let i = 0; i < _data.length; i++) {
                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'local_asset/restore/' + themeId + '?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify(_data[i]),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .then(data => {
                        console.log(data)
                        return data
                    })
                );
            }
            Promise.all(fetches)
            .then(data => {
                response(data);
            })
            .catch(error => response(error));  
        }
    },
    saveCustomAsset: (themeId, data, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset/' + themeId + '/save?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify(data),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveCustomizedAssets: async (themeId, assets, response) => {
        const fetches = [];
        const j = assets.length;
        for(let i = 0; i < j; i++) {
            const asset = assets[i];

            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'asset/customized/' + themeId + '/save?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(asset),
                    method: 'POST'
                })
                .then(data => data.json())
                .then(data => {
                    return data
                })
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data);
        })
        .catch();                
    },
    removeCustomAsset: (themeId, snippet, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset/' + themeId + '/remove?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify({key:snippet}),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveCustomMetafield: (namespace, key, value, type, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset_metafield/save?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify({
                namespace: namespace,
                key: key,
                value: value,
                type: type
            }),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    addV3Scripts: (themeId, response, themeWrite=true) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'add_v3_scripts/' + themeId + '?theme_write=' + themeWrite + '&key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    removeV3Scripts: (themeId, response, themeWrite=true) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'remove_v3_scripts/' + themeId + '?theme_write=' + themeWrite + '&key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    createURL: (action) => {
        return PROTOCOL + HOST + ENDPOINT + action + '?key=' + KEY
    },
    exportProducts: (language, options, progress, response) => {

        let queryAppendix = '';
        Object.keys(options).forEach(key => {
            if(options[key] === true) {
                queryAppendix = queryAppendix + `&${key}=${options[key]}`
            }
        });

        API.isRunningBulkOperation = true;
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/current?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success === true) {
 

                if(data.operation.status == 'RUNNING') {

                    // TODO: Hier das bulk-canceling ausbauen bzw. im Frontnd muss eine Dialog angezeigt werden, über den man dann canceln kann.
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/cancel?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify({
                            id: data.operation.id
                        }),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.exportProducts(language, {}, progress, response)}, 1000)
                        }
                    })
                } else if(data.operation.status == 'CANCELING') {
                    if(API.isRunningBulkOperation) {
                        if(API.currentBulkTimeout) {
                            clearTimeout(API.currentBulkTimeout);
                        }

                        API.currentBulkTimeout = setTimeout(function() {API.exportProducts(language, {}, progress, response)}, 1000)
                    }
                } else {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/products/create/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.getCurrentBulkOperation(
                                language,
                                progress,
                                data => {
                                    if(API.isRunningBulkOperation == true) {
                                        API.isRunningBulkOperation = false;
                                        if(data.success === true) {
                                            response({
                                                success: true,
                                                po_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/products/export/po/' + language + '?key=' + KEY + queryAppendix,
                                                csv_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/products/export/csv/' + language + '?key=' + KEY + queryAppendix,
                                                advanced_csv_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/products/export/advanced_csv/' + language + '?key=' + KEY + queryAppendix
                                            })
                                        } else {
                                            response({
                                                success: false,
                                                message: 'Export failed'
                                            })
                                        }
                                    }
                                }
                            )}, 1000)
                        } else {
                            response(data)
                        }
                    })
                }
            }
        })
    },
    exportProductMetafields: (language, progress, response) => {
        API.isRunningBulkOperation = true;
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/current?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success === true) {
                if(data.operation.status == 'RUNNING') {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/cancel?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify({
                            id: data.operation.id
                        }),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.exportProductMetafields(language, progress, response)}, 1000)
                        }
                    })
                } else if(data.operation.status == 'CANCELING') {
                    if(API.isRunningBulkOperation) {
                        if(API.currentBulkTimeout) {
                            clearTimeout(API.currentBulkTimeout);
                        }

                        API.currentBulkTimeout = setTimeout(function() {API.exportProductMetafields(language, progress, response)}, 1000)
                    }
                } else {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/product_metafields/create/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.getCurrentBulkOperation(
                                language,
                                progress,
                                data => {
                                    if(API.isRunningBulkOperation == true) {
                                        API.isRunningBulkOperation = false;
                                        if(data.success === true) {
                                            response({
                                                success: true,
                                                po_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/product_metafields/export/po/' + language + '?key=' + KEY,
                                                csv_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/product_metafields/export/csv/' + language + '?key=' + KEY,
                                                advanced_csv_download_url: PROTOCOL + HOST + ENDPOINT + 'bulk_operation/product_metafields/export/advanced_csv/' + language + '?key=' + KEY
                                            })
                                        } else {
                                            response({
                                                success: false,
                                                message: 'Export failed'
                                            })
                                        }
                                    }
                                }
                            )}, 1000)
                        } else {
                            response(data)
                        }
                    })
                }
            }
        })
    },
    exportProductsForImport: (language, progress, response) => {
        API.isRunningBulkOperation = true;
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/current?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success === true) {
                if(data.operation.status == 'RUNNING') {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/cancel?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify({
                            id: data.operation.id
                        }),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.exportProductsForImport(language, progress, response)}, 1000)
                        }
                    })
                } else if(data.operation.status == 'CANCELING') {
                    if(API.isRunningBulkOperation) {
                        if(API.currentBulkTimeout) {
                            clearTimeout(API.currentBulkTimeout);
                        }

                        API.currentBulkTimeout = setTimeout(function() {API.exportProductsForImport(language, progress, response)}, 1000)
                    }
                } else {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/products_import/create/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.getCurrentBulkOperation(
                                language,
                                progress,
                                data => {
                                    if(API.isRunningBulkOperation == true) {
                                        API.isRunningBulkOperation = false;
                                        if(data.success === true) {
                                            response({
                                                success: true,
                                                jsonl_url: data.operation.url
                                            })
                                        } else {
                                            response({
                                                success: false,
                                                message: 'Export failed'
                                            })
                                        }
                                    }
                                }
                            )}, 1000)
                        } else {
                            response(data)
                        }
                    })
                }
            }
        })
    },
    exportProductMetafieldsForImport: (language, progress, response) => {
        API.isRunningBulkOperation = true;
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/current?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success === true) {
                if(data.operation.status == 'RUNNING') {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/cancel?key=' + KEY,
                    {
                        credentials: 'include',
                        body: JSON.stringify({
                            id: data.operation.id
                        }),
                        method: 'POST'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.exportProductMetafieldsForImport(language, progress, response)}, 1000)
                        }
                    })
                } else if(data.operation.status == 'CANCELING') {
                    if(API.isRunningBulkOperation) {
                        if(API.currentBulkTimeout) {
                            clearTimeout(API.currentBulkTimeout);
                        }

                        API.currentBulkTimeout = setTimeout(function() {API.exportProductMetafieldsForImport(language, progress, response)}, 1000)
                    }
                } else {
                    fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/product_metafields_import/create/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(data => {
                        if(data.success == true) {
                            if(API.currentBulkTimeout) {
                                clearTimeout(API.currentBulkTimeout);
                            }

                            API.currentBulkTimeout = setTimeout(function() {API.getCurrentBulkOperation(
                                language,
                                progress,
                                data => {
                                    if(API.isRunningBulkOperation == true) {
                                        API.isRunningBulkOperation = false;
                                        if(data.success === true) {
                                            response({
                                                success: true,
                                                jsonl_url: data.operation.url
                                            })
                                        } else {
                                            response({
                                                success: false,
                                                message: 'Export failed'
                                            })
                                        }
                                    }
                                }
                            )}, 1000)
                        } else {
                            response(data)
                        }
                    })
                }
            }
        })
    },
    getCurrentBulkOperation: (language, progress, response)  => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_operation/current?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            if(data.success == true) {
                if(data.operation.status == 'RUNNING' || data.operation.status == 'CREATED') {
                    if(API.isRunningBulkOperation) {
                        if(API.currentBulkTimeout) {
                            clearTimeout(API.currentBulkTimeout);
                        }

                        API.currentBulkTimeout = setTimeout(function() {API.getCurrentBulkOperation(language, progress, response)}, 1000);
                    }
                } else if(data.operation.status == 'COMPLETED') {
                    if(API.currentBulkTimeout) {
                        clearTimeout(API.currentBulkTimeout);
                    }
                    response(data);
                } else {
                    response({
                        success: false
                    });
                }
            }
        })
    },
    cancelCurrentBulkTimeout: () => {
        API.isRunningBulkOperation = false;
        if(API.currentBulkTimeout) {
            clearTimeout(API.currentBulkTimeout);
        }
    },
    /*
    exportProducts: (language, progress, response) => {
        let ids = [];
        let tags = [];
        let types = [];
        let vendors = [];

        fetch(PROTOCOL + HOST + ENDPOINT + 'export_products/count/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    const count = data.count
                    const perPage = 30
                    const numPages = Math.ceil(count / perPage)

                    return API.__paginateItems('export_products/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                        tags = tags.concat(data.tags)
                        types = types.concat(data.types)
                        vendors = vendors.concat(data.vendors)
                    }, false)
                }
            })
            .then(data => {
                tags = tags.filter((item, pos) => {return tags.indexOf(item) == pos})
                types = types.filter((item, pos) => {return types.indexOf(item) == pos})
                vendors = vendors.filter((item, pos) => {return vendors.indexOf(item) == pos})

                return fetch(PROTOCOL + HOST + ENDPOINT + 'export_products/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify({ids:ids, tags:tags, types:types, vendors:vendors})
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    */
    importProducts: (file, fileType, options, language, progress, callback) => {

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                let numImportedEntries = 0;
                const translations = response.translations

                API.exportProductsForImport(
                    language,
                    null,
                    data => {
                        if(data.success === true) {
                            const url = data.jsonl_url;
                            fetch(PROTOCOL + HOST + ENDPOINT + 'import_products/count/lines?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify({
                                    url: url,
                                    ...options
                                })
                            })
                            .then(data => data.json())
                            .then(async data => {
                                if(data.success === true) {
                                    const count = data.lines;
                                    if (options.product_tags !== true &&
                                        options.product_types !== true &&
                                        options.product_vendors !== true &&
                                        options.custom_contents !== true ) {
                                        var perPage = 60;
                                    } else {
                                        var perPage = 20;
                                    }
                                    const map = data.map;

                                    // Calc a new numPages value depending on the enabled importOptions and the line counts returned by the /count/lines API endpoint.
                                    //const numPages = Math.ceil(count / perPage);
                                    let lineCount = data.products_lines;
                                    if(options.product_tags === true) lineCount += data.tags_count;
                                    if(options.product_types === true) lineCount += data.types_count;
                                    if(options.product_vendors === true) lineCount += data.vendors_count;
                                    if(options.custom_contents === true) lineCount += data.custom_contents_count;
                                    const numPages = Math.ceil(lineCount / perPage);

                                    return  API.__importTranslations(
                                        'import_product_translations',
                                        language,
                                        url,
                                        1,
                                        numPages,
                                        perPage,
                                        translations,
                                        map,
                                        1,
                                        data => {
                                            numImportedEntries += data.num_imported_entries;
                                        },
                                        (currentProgress) => {
                                            progress(currentProgress);
                                        },
                                        options)
                                } else {
                                    console.log('error');
                                }
                            })
                            .then(data => {
                                if(data.success === true) {
                                    callback({
                                        success: true,
                                        num_imported_entries: numImportedEntries
                                    });
                                }
                            })
                        } else {
                            console.log('error');
                        }
                    })
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_products/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    importProductMetafields: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                let numImportedEntries = 0;
                const translations = response.translations

                API.exportProductMetafieldsForImport(
                    language,
                    null,
                    data => {
                        if(data.success === true) {
                            const url = data.jsonl_url;
                            fetch(PROTOCOL + HOST + ENDPOINT + 'import_product_metafields/count/lines?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify({url: url})
                            })
                            .then(data => data.json())
                            .then(async data => {
                                if(data.success === true) {
                                    const count = data.lines;
                                    const perPage = 60;
                                    const numPages = Math.ceil(count / perPage);
                                    const map = data.map;

                                    return  API.__importTranslations(
                                        'import_product_metafield_translations',
                                        language,
                                        url,
                                        1,
                                        numPages,
                                        perPage,
                                        translations,
                                        map,
                                        1,
                                        data => {
                                            numImportedEntries += data.num_imported_entries;
                                        },
                                        (currentProgress) => {
                                            progress(currentProgress);
                                        })
                                } else {
                                    console.log('error');
                                }
                            })
                            .then(data => {
                                if(data.success === true) {
                                    callback({
                                        success: true,
                                        num_imported_entries: numImportedEntries
                                    });
                                }
                            })
                        } else {
                            console.log('error');
                        }
                    })
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_product_metafields/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportCollections: (language, options, progress, response) => {
        let ids = [];
        let perPage = 50
        let customNumPages = 0
        let smartNumPages = 0

        let queryAppendix = '?';
        Object.keys(options).forEach(key => {
            if(options[key] === true) {
                queryAppendix = queryAppendix + `&${key}=${options[key]}`
            }
        });

        fetch(PROTOCOL + HOST + ENDPOINT + 'export_collections/count/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    customNumPages = Math.ceil(data.custom_count / perPage)
                    smartNumPages = Math.ceil(data.smart_count / perPage)

                    return API.__paginateItems('export_collections/custom/sync/start', language, 1, customNumPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false, options)
                }
            })
            .then(data => {
                return API.__paginateItems('export_collections/smart/sync/start', language, 1, smartNumPages, perPage, null, (data, currentProgress) => {
                    progress(currentProgress);
                    ids = ids.concat(data.ids)
                }, false, options)
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'export_collections/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                if(data.success) {
                    data.csv_download_url = data.csv_download_url + queryAppendix;
                    data.po_download_url = data.po_download_url + queryAppendix;
                }
                response(data)
            })
            .catch(error => response(error))
    },
    importCollections: (file, fileType, language, options, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const collectionCount = response.collections.length
                const perPage = 20
                const numPages = Math.ceil(collectionCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        collections: response.collections.slice(start, end),
                        ...options
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_collection_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_collections/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportPages: (language, options, progress, response) => {
        let ids = [];
        let queryAppendix = '?';
        Object.keys(options).forEach(key => {
            if(options[key] === true) {
                queryAppendix = queryAppendix + `&${key}=${options[key]}`
            }
        });

        fetch(PROTOCOL + HOST + ENDPOINT + 'export_pages/count/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data => data.json())
            .then(async data => {
                if(data.success === true) {
                    const count = data.count
                    const perPage = 50
                    const numPages = Math.ceil(count / perPage)

                    return API.__paginateItems('export_pages/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                        progress(currentProgress);
                        ids = ids.concat(data.ids)
                    }, false, options)
                }
            })
            .then(data => {
                return fetch(PROTOCOL + HOST + ENDPOINT + 'export_pages/sync/end/' + language + '?key=' + KEY,
                    {
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(ids)
                    })
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                if(data.success) {
                    data.csv_download_url = data.csv_download_url + queryAppendix;
                    data.po_download_url = data.po_download_url + queryAppendix;
                }
                response(data)
            })
            .catch(error => response(error))
    },
    importPages: (file, fileType, language, options, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const pageCount = response.pages.length
                const perPage = 20
                const numPages = Math.ceil(pageCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        pages: response.pages.slice(start, end),
                        ...options
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_page_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_pages/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportNavigation: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_navigation/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importNavigation: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const linkCount = response.links.length
                const perPage = 20
                const numPages = Math.ceil(linkCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        links: response.links.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_navigation_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_navigation/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportCustomContents: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_custom_contents/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importCustomContents: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const customContentCount = response.custom_contents.length
                const perPage = 20
                const numPages = Math.ceil(customContentCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        custom_contents: response.custom_contents.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_custom_content_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_custom_contents/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportNewCustomContents: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_new_custom_contents/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importNewCustomContents: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const customContentCount = response.new_custom_contents.length
                const perPage = 20
                const numPages = Math.ceil(customContentCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        new_custom_contents: response.new_custom_contents.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_new_custom_content_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_new_custom_contents/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportBlogs: (language, options, progress, response) => {
        let queryAppendix = '?';
        Object.keys(options).forEach(key => {
            if(options[key] === true) {
                queryAppendix = queryAppendix + `&${key}=${options[key]}`
            }
        });
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_blogs/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                if(data.success) {
                    data.csv_download_url = data.csv_download_url + queryAppendix;
                    data.po_download_url = data.po_download_url + queryAppendix;
                }
                response(data)
            })
            .catch(error => response(error))
    },
    importBlogs: (file, fileType, language, options, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const blogCount = response.blogs.length
                const perPage = 20
                const numPages = Math.ceil(blogCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        blogs: response.blogs.slice(start, end),
                        ...options
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_blog_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_blogs/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportArticles: (language, options, progress, response) => {
        let ids = [];
        let articles = [];
        let queryAppendix = '?';
        Object.keys(options).forEach(key => {
            if(options[key] === true) {
                queryAppendix = queryAppendix + `&${key}=${options[key]}`
            }
        });

        fetch(PROTOCOL + HOST + ENDPOINT + 'blogs/sync/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            let fetches = []
            
            data.blogs.forEach(item => {
                fetches.push(
                    _fetch(PROTOCOL + HOST + ENDPOINT + 'export_articles/' + item.id + '/count/' + language + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => data.json())
                    .then(async data => {
                        if(data.success === true) {
                            const count = data.count
                            const perPage = 50
                            const numPages = Math.ceil(count / perPage)

                            return API.__paginateItems('export_articles/' + item.id + '/sync/start', language, 1, numPages, perPage, null, (data, currentProgress) => {
                                progress(currentProgress);
                                ids = ids.concat(data.ids)
                            }, false, options)
                        }
                    })
                    .then(data => {
                        return fetch(PROTOCOL + HOST + ENDPOINT + 'export_articles/' + item.id + '/sync/end/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(ids)
                            })
                    })
                    .then(data => data.json())
                    .then(data => {
                        progress(100)
                        if(data.success) {
                            data.csv_download_url = data.csv_download_url + queryAppendix;
                            data.po_download_url = data.po_download_url + queryAppendix;
                        }
                        response(data)
                    })
                )
            })

            return Promise.all(fetches)
        })
        .catch(error => response(error));
    },
    importArticles: (file, fileType, language, options, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const articleCount = response.articles.length
                const perPage = 20
                const numPages = Math.ceil(articleCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        articles: response.articles.slice(start, end),
                        ...options
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_article_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_articles/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportNotifications: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_notifications/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importNotifications: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const notificationCount = response.notifications.length
                const perPage = 20
                const numPages = Math.ceil(notificationCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        notifications: response.notifications.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_notification_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_notifications/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportSMSNotifications: (language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_sms_notifications/sync/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importSMSNotifications: (file, fileType, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const notificationCount = response.notifications.length
                const perPage = 20
                const numPages = Math.ceil(notificationCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        notifications: response.notifications.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_sms_notification_translations/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_sms_notifications/download/' + fileType + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    exportNewTheme: (theme, language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'export_new_theme/sync/' + theme + '/' + language + '?key=' + KEY,
            {
                credentials: 'include'
            })
            .then(data =>  data.json())
            .then(data => {
                progress(100)
                response(data)
            })
            .catch(error => response(error))
    },
    importNewTheme: (file, fileType, theme, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const stringCount = response.theme_strings.length
                const perPage = 10
                const numPages = Math.ceil(stringCount / perPage)
                let fetches = []
                let currentPage = 0;

                for(let i = 0; i < numPages; i++) {
                    let start = i * perPage;
                    let end = start + perPage;
                    let data = {
                        translations: response.translations,
                        strings: response.theme_strings.slice(start, end)
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_new_theme_translations/' + theme + '/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };
        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_new_theme/download/' + fileType + '/' + theme + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    deleteV1Code: (theme, progress, onComplete) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'delete_v1/get_assets/' + theme + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(async data => {
            const numPages = data.assets.length;
            const fetches = [];
            let currentPage = 0;

            for(let i = 0; i < numPages; i++) {
                let removeLangifyAssets = 0;
                if(i >= numPages - 1) {
                    removeLangifyAssets = 1;
                }

                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'delete_v1/from_asset/' + theme + '/' + removeLangifyAssets + '?key=' + KEY,
                        {
                            credentials: 'include',
                            method: 'POST',
                            body: JSON.stringify({key: data.assets[i]})
                        })
                    .then(data => data.json())
                    .then(data => {
                        let usedProgress = Math.round(currentPage++ / (numPages) * 100)
                        progress(usedProgress);
                    })
                );
            }

            Promise.all(fetches)
            .then(data => {
                onComplete();
            })
            .catch();
        })
        .catch();
    },
    enableLogging: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'dev/logger/enable/1?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    disableLogging: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'dev/logger/enable/0?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    getTranslationProgress: (language, theme, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'stats/get_progress/' + language + '/'+ + theme + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            progress(100);
            response(data);
        })
        .catch();
    },
    getRecentlyChangedContent: (filter, language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'stats/get_recently_changed_content/' + filter + '/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100);
            response(data);
        })
        .catch();

    },
    getMoreRecentlyChangedContent: (pageLinks, language, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'stats/get_more_recently_changed_content/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            body: JSON.stringify({'page_links': pageLinks}),
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            progress(100);
            response(data);
        })
        .catch();

    },
    syncProductByShopifyId: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'sync_by_shopify_id/sync_product/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    syncCollectionByShopifyId: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'sync_by_shopify_id/sync_collection/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    syncPageByShopifyId: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'sync_by_shopify_id/sync_page/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    syncArticleByShopifyId: (id, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'sync_by_shopify_id/sync_article/' + id + '?key=' + KEY,
        {
            credentials: 'include',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    syncProductImages: (productId, languageId, progress, response) => {
        progress(0)
        fetch(PROTOCOL + HOST + ENDPOINT + 'product/images/sync/' + productId + '/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            progress(100)
            response(data);
        })
        .catch(error => response(error));
    },
    uploadMedia: (formData, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(callback) {
                callback(response);
            }
          } else {
            console.log('error');
          }
        };

        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'media/upload?key=' + KEY, true);
        xhr.send(formData);
    },
    getNewCustomContents: (language, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'new_custom_contents/get/' + language + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
      })
      .catch(error => response(error));
    },
    createNewCustomContents: async (language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'new_custom_contents/create/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteNewCustomContent: async (id, deprecated, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'new_custom_contents/delete/' + id + '/' + deprecated +  '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    saveNewCustomContents: async (language, items, progress, response) => {
        const perPage = 40;
        const numPages = Math.ceil(items.length / perPage);

        let fetches = [];
        for(let i = 0; i < numPages; i++) {
            let usedItems = [];

            for(let j = 0; j < perPage; j++) {
                let index = (i * perPage) + j;
                if(index < items.length) {
                    usedItems.push({...items[index]})
                }
            }

            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'new_custom_contents/save/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(usedItems),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    createProductCustomContents: async (product, language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'product/custom_contents/create/' + product + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteProductCustomContent: async (product, id, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'product/custom_contents/delete/'  + product + '/' + id + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    createCollectionCustomContents: async (collection, language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'collection/custom_contents/create/' + collection + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteCollectionCustomContent: async (collection, id, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'collection/custom_contents/delete/'  + collection + '/' + id + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    createPageCustomContents: async (page, language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'page/custom_contents/create/' + page + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deletePageCustomContent: async (page, id, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'page/custom_contents/delete/'  + page + '/' + id + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    createBlogCustomContents: async (blog, language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'blog/custom_contents/create/' + blog + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteBlogCustomContent: async (blog, id, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'blog/custom_contents/delete/'  + blog + '/' + id + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    createArticleCustomContents: async (article, language, items, progress, response) => {
        let i = 0;
        let j = items.length
        let fetches = []
        for(let i = 0; i < j; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'article/custom_contents/create/' + article + '/' + language + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => data.json())
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            response(data)
        })
        .catch(error => response(error))
    },
    deleteArticleCustomContent: async (article, id, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'article/custom_contents/delete/'  + article + '/' + id + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    exportStaticTheme: (themeId, languageId, progress, response) => {
        progress(0);

        fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme/' + themeId + '/sync/' + languageId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(async data => {
            let fetches = [];
            const count = data.assets.length;
            for(let i = 0; i < count; ++i) {
                fetches.push(
                    await _fetch(PROTOCOL + HOST + ENDPOINT + 'static_theme_asset/' + themeId + '/' + data.assets[i].id + '/sync/' + languageId + '?key=' + KEY,
                    {
                        credentials: 'include'
                    })
                    .then(data => {
                        progress(Math.floor(i / (count + 1) * 100));
                    })
                )
            }

            fetches.push(
                _fetch(PROTOCOL + HOST + ENDPOINT + 'export_static_theme/sync/' + themeId + '/' + languageId + '?key=' + KEY,
                {
                    credentials: 'include'
                })
                .then(data =>  data.json())
                .then(data => {
                    response(data)
                })
            )

            return Promise.all(fetches);
        })
        .catch(error => response(error));
    },
    importStaticTheme: (file, fileType, theme, language, progress, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onload = async function () {
          if(xhr.status === 200) {
            const response = JSON.parse(xhr.response);
            if(response.success == true) {
                const assetCount = response.assets.length
                let fetches = []

                for(let i = 0; i < assetCount; i++) {
                    const asset = response.assets[i];
                    let data = {
                        translations: response.translations
                    };

                    fetches.push(
                        await _fetch(PROTOCOL + HOST + ENDPOINT + 'import_static_theme_translations/' + theme + '/' + asset +'/' + language + '?key=' + KEY,
                            {
                                credentials: 'include',
                                method: 'POST',
                                body: JSON.stringify(data)
                            })
                        .then(data => data.json())
                        .then(data => {
                            let usedProgress = Math.round((i + 1) / (assetCount) * 100)
                            progress(usedProgress);

                            return data
                        })
                    )
                }
                
                Promise.all(fetches)
                .then(data => {
                    progress(100)
                    callback(data)
                })
                .catch();                
            } else {
                console.log('error');
            }
          } else {
            console.log('error');
          }
        };
        xhr.open('POST', PROTOCOL + HOST + ENDPOINT + 'import_static_theme/download/' + fileType + '/' + theme + '/' + language + '?key=' + KEY, true);
        xhr.send(file);
    },
    enableCrossDomainLinks: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/enable_cross_domain_links?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    disableCrossDomainLinks: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/disable_cross_domain_links?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    importV1ProductMetafieldTranslations: (language, product, metafields, progress, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'admin/import_v1_product_metafield_translations/' + product + '/' + language + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(metafields)
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },

    bulkCountStart: async (store, from, to, scope, subObject, gids, outdated, response) => {
			let body = {};
			if(gids.length > 0) {
				body = {
					gids: gids
				};
			}
      await _fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/count/${from}/${to}/${scope}${subObject && '/'+subObject}?key=${KEY}${outdated ? '&outdated=true' : ''}`,
      {
          credentials: 'include',
          method: 'POST',
					body: JSON.stringify(body)
      }, 1000)
      .then(data => data.json())
      .then(data => {
        response(data);
      })
      .catch(error => response(error));
    },
    bulkTranslationStart: async (store, key, response) => {
      await _fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/translate/${key}?key=${KEY}`,
          {
              credentials: 'include',
              method: 'POST'
          }, 1000)
      .then(data => data.json())
      .then(data => {
        response(data);

        /*
        if(data.success && data.key) {
          let results = null;
          API._queueProgressTasks(store, data.key, scope, res => {
            results = res;
            progress(results);
            
            if(results.task && results.task.status === 'completed') {
                response(results);
            }
          });
        } else {
          throw 'Could not get task key';
        }
        */
      })
      .catch(error => response(error));
    },
    bulkTranslationGetRunningTasks: (response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/running_tasks?key=${KEY}`,
        {
            credentials: 'include',
            method: 'GET'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    bulkTranslationClearTask: async (cacheKey) => {
        const response = await fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/running_tasks/${cacheKey}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'DELETE'
        })
        const data = await response.json();
        return data;
    },
    bulkTranslationGetWordpacks: (response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/wordpacks?key=${KEY}`,
        {
            credentials: 'include',
            method: 'GET'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    bulkTranslationGetWordpack: (hash, response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/wordpack/get/${hash}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'GET'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    bulkTranslationBuyWordpack: (hash, response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/wordpack/buy/${hash}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                returnUrl: PROTOCOL + RETURN_URL
            })
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    _queueProgressTasks: async (store, key, scope, callback) => {
        let endTaskStatus = ['completed', 'failed', 'expired'];

      return await fetch(`${PROTOCOL + HOST + ENDPOINT}bulk_translation/progress/${key}?key=${KEY}`,
          {
              credentials: 'include'
          })
        .then(data => data.json())
        .then(data => {
                //console.log(`ly_bulk_translate:${store}:${data.task.parameter.to}_${scope}`, store, data)

            //
            if(data.task && (data.task.type === 'translate' || data.task.type === 'count')) {
                window.localStorage.setItem(`ly_bulk_translate:${store}:${data.task.parameter.to}_${scope}`, JSON.stringify(data));
            }

            if(data.task && endTaskStatus.indexOf(data.task.status) === -1) {

                callback(data);
                setTimeout(() => API._queueProgressTasks(store, key, scope, callback), 1000);
            } else {

                callback(data);
            }
        })
        .catch(error => {
            callback(error)
        });
    },
    splitPOfile: (file, language, progress, response) => {
        var filename = "PO_split_parts.zip";
        fetch(PROTOCOL + HOST + ENDPOINT + 'admin/split_po/' + language + '?key=' + KEY, {
            credentials: 'include',
            method: 'POST',
            body: file,
        })
        .then(data =>  data.blob())
        .then((data) => {
            // (C1) FILE DATA IS "READY FOR USE"
            console.log(data);
        
            // (C2) TO "FORCE DOWNLOAD"
            var url = window.URL.createObjectURL(data),
            anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = filename;
            anchor.click();
        
            // (C3) CLEAN UP
            window.URL.revokeObjectURL(url);
            document.removeChild(anchor);
        })
        /*
        .then((result) => {
            console.log(result)
            if (!result.ok) {
                throw Error(result.statusText);
            }
            return result.blob(); 
        })
        .then(data => {
            //const file = window.URL.createObjectURL(data);
            //window.location.assign(file);

            
            if (data != null) {
                var url = window.URL.createObjectURL(data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            response(data);
        })
        */
        .catch(error => response(error));
    },
    getMetafield: (mf_namespace, mf_key, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset_metafield/get/' + mf_namespace + '/' + mf_key + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    getScriptSettings: (shopifyThemeId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'asset_script_settings/get/' + shopifyThemeId + '?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    getLangifyNotifications: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'langify/notifications/get?key=' + KEY,
        {
            credentials: 'include'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    setLangifyNotificationAsViewed: (notificationId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'langify/notifications/set_as_viewed/' + notificationId + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },
    setLangifyNotificationAsUnviewed: (notificationId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'langify/notifications/set_as_unviewed/' + notificationId + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch();
    },

    enableLangifyFeature: (featureName, callback) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/feature/enable/' + featureName + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            if(callback) {
                callback(data);
            }
        })
    },
    disableLangifyFeature: (featureName, callback) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'shop/feature/disable/' + featureName + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST'
        })
        .then(data => data.json())
        .then(data => {
            if(callback) {
                callback(data);
            }
        })
    },

    saveShopSetting: async (name, value) => {
        let result = await fetch(PROTOCOL + HOST + ENDPOINT + 'shop/settings?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "name": name,
                "value": value
            })
        });
        return await result.json();
    },
    deleteShopSetting: async (name) => {
        let result = await fetch(PROTOCOL + HOST + ENDPOINT + 'shop/settings/' + name + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'DELETE'
        })
        return await result.json();
    },



    // Partner Implementations
    partnerGetAll: (response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}partners?key=${KEY}`,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    partnerGetIntegration: (partner, response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}partner/${partner}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    partnerSetApiKey: (partner, apiKey, response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}partner/${partner}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "api_key": apiKey
            })
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    partnerDeleteApiKey: (partner, response) => {
        fetch(`${PROTOCOL + HOST + ENDPOINT}partner/${partner}?key=${KEY}`,
        {
            credentials: 'include',
            method: 'DELETE'
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },



    /**
     * MetaObjects
     * 
     * @Route("/v2/api/metaobjects/definitions/{perPage}", methods={"POST"})
     * @Route("/v2/api/metaobjects/search/{perPage}", methods={"POST"})
     * @Route("/v2/api/metaobject/{languageId}", methods={"POST"})
     * @Route("/v2/api/metaobject/save/{languageId}", methods={"POST"})
     */ 
    metaObjectsDefinitions: (filterObject = {}, response) => {
        let perPage = 25;
        fetch(PROTOCOL + HOST + ENDPOINT + 'metaobjects/definitions/' + perPage + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(filterObject)
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    metaObjectsSearch: (filterObject, response) => {
        /*
{
                "definition_type": "",
                "search": {
                    "display_name" : "",
                    "updated_at" : ""
                },
                "sort_key": "updated_at"
            }
        */
        let perPage = 25;
        fetch(PROTOCOL + HOST + ENDPOINT + 'metaobjects/search/' + perPage + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(filterObject)
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    metaObjectSync: (metaObjectId, languageId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'metaobject/' + languageId + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                resource_id: 'gid://shopify/Metaobject/' + metaObjectId
            })
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    metaObjectSave: async (languageId, items, progress, response) => {
        let itemNum = 0;
        let j = items.length;
        let fetches = [];
        for(let i = 0; i < items.length; i++) {
            fetches.push(
                await _fetch(PROTOCOL + HOST + ENDPOINT + 'metaobject/save/' + languageId + '?key=' + KEY,
                {
                    credentials: 'include',
                    body: JSON.stringify(items[i]),
                    method: 'POST'
                })
                .then(data => {
                    itemNum = itemNum++;
                    progress(Math.round(itemNum / j * 100), items[i]);
                    return data.json()
                })
                .then(data => {
                    let appendix = {requestBody: items[i]};
                    return {...data, ...appendix}
                })
                .catch(e => e)
            )
        }

        Promise.all(fetches)
        .then(data => {
            progress(100, null);
            response(data);
        })
        
        .catch(error => response(error));
    },


    accessUpdate: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'access/update?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },


    getMarkets: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'markets/get?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },


    screenLog: (body) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'bulk_translation/log?key=' + KEY, 
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(body)
        })
        .then(data => data.json());
    },


    getPlans: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'plans?key=' + KEY,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    changePlan: (newPlan, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'plan/' + newPlan + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
        })
        .then(data => data.json())
        .then(data => {
            console.log(data)
            if(data.success === true) {
                if(data.charge.confirmation_url) {
                    window.location.replace(data.charge.confirmation_url)
                }
            }
        })
        .catch(error => response(error));
    },

    
    freshdeskGetContact: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/contact?key=' + KEY,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    freshdeskCreateContact: (firstname, lastname, email, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/contact?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                firstname: firstname,
                lastname: lastname,
                email: email
            })
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    freshdeskGetAllTickets: (response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/tickets?key=' + KEY,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    freshdeskGetTicket: (ticketId, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/tickets/' + ticketId + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'GET',
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    freshdeskCreateTicket: (formData, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/ticket?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            header: {
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
    freshdeskReplyTicket: (ticketId, formData, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'freshdesk/tickets/' + ticketId + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            header: {
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },


    //@Route("/v2/api/available_words_log/{page}/{perPage}", methods={"POST"})
    getAvailableWordsLog: (page, perPage, response) => {
        fetch(PROTOCOL + HOST + ENDPOINT + 'available_words_log/' + page + '/' + perPage + '?key=' + KEY,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                
            })
        })
        .then(data => data.json())
        .then(data => {
            response(data);
        })
        .catch(error => response(error));
    },
 };

export default API