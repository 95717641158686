import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { UnfoldLess, UnfoldMore, ThumbUp, ThumbDown } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';


const FRONTEND_HOST = window.loadEnv("REACT_APP_FRONTEND_HOST", process.env.REACT_APP_FRONTEND_HOST);

class ChatbotWidget extends React.Component {
	constructor(props) {
		super(props)

		this.connectionAttempts = 0;
		this.idleTimeout = null;
		this.sessionId = null;

		this.state = {
			question: '',
			isExpanded: false,
			isPending: false,
			isConnecting: true,
			feedbackProvided: false,
			chatbotConversation: [],
			windowSize: 'small',
			connection: null,
		}
	}

	componentDidMount() {
		const savedConversation = JSON.parse(sessionStorage.getItem('chatbotConversation'));

		if (savedConversation) {
			this.setState({ chatbotConversation: savedConversation });
		}
	}

	handleChatbotWindowToggle() {
		if(this.state.isExpanded) {
			this.idleTimeout = setTimeout(() => {
				this.clearConversationAndConnection();
			}, 90000);
		} else {
			clearTimeout(this.idleTimeout);
		}

		this.setState({ isExpanded: !this.state.isExpanded, windowSize: 'small' });
	}

	resizeChatbotWindowToggle() {
		if (this.state.windowSize === 'small') {
			this.setState({ windowSize: 'large' });
		} else {
			this.setState({ windowSize: 'small' });
		}
	}

	scrollConversation() {
		const conversationContainer = document.getElementById("conversation-container");
		conversationContainer.scroll({
			top: conversationContainer.scrollHeight,
			behavior: 'smooth',
		});
	}

	clearConversation() {
		sessionStorage.setItem('chatbotConversation', JSON.stringify([]));
		sessionStorage.removeItem('sessionId');
		this.sessionId = null;
		this.setState({ chatbotConversation: [], feedbackProvided: false });
	}

	clearConversationAndConnection() {
		if(this.state.connection) {
			this.state.connection.close(1000, "IDLE");
		}

		sessionStorage.setItem('chatbotConversation', JSON.stringify([]));
		sessionStorage.removeItem('sessionId');
		this.connectionAttempts = 0;
		this.sessionId = null;
		this.setState({chatbotConversation: [], feedbackProvided: false, connection: null});
	}

	handleQuestionUpdate(e) {
		this.setState({ question: e.target.value })
	}

	retrying() {
		const chatbotConversation = this.state.chatbotConversation;
		chatbotConversation.push({ 'role': 'assistant', 'content': "An error occurred connecting to the server. Attempting to reconnect..." });

		sessionStorage.setItem('chatbotConversation', JSON.stringify(chatbotConversation));
		this.setState({ chatbotConversation: chatbotConversation, isPending: false });
	}

	serverErrorOccurred() {
		const chatbotConversation = this.state.chatbotConversation;
		chatbotConversation.push({ 'role': 'assistant', 'content': "Sorry, an error occurred on our end. Please try again later." });

		sessionStorage.setItem('chatbotConversation', JSON.stringify(chatbotConversation));
		this.setState({ chatbotConversation: chatbotConversation, isPending: false });
	}

	connectToServer(reattempt_connection) {
		if (!this.state.connection || reattempt_connection) {
			this.setState({ isConnecting: true });
			this.connectionAttempts += 1;
			const websocketConnection = new WebSocket("wss://" + FRONTEND_HOST + "/ws");

			websocketConnection.addEventListener("open", (event) => {
				let reconnectionString = ''
				if (this.connectionAttempts > 1) {
					reconnectionString = 'Reconnected. ';
				}
				this.connectionAttempts = 0;
				const conversation = this.state.chatbotConversation;
				if (conversation.length == 0) {
					conversation.push({ 'role': 'assistant', 'content': reconnectionString + "Hi! I'm langiBot, an AI assistant trained on langify support articles and other content. Feel free to ask me anything about langify! NOTE: To improve langibot, we collect non-personal feedback and usage information during your interactions. By continuing, you agree to our data collection practices outlined in our <a target=\"blank\" class=\"chatbotMessageLink\" href='https://langify-app.com/privacy_policy'>Privacy Policy</a>. <em>Please note that langibot is not a live-chat agent and cannot directly connect you to our support team.</em>" });
				} else {
					conversation.push({ 'role': 'assistant', 'content': reconnectionString + "How may I assist you today?" });
				}

				sessionStorage.setItem('chatbotConversation', JSON.stringify(conversation));
				this.setState({ chatbotConversation: conversation, isConnecting: false });
			});

			websocketConnection.addEventListener("message", (event) => {
				this.setState({ isPending: false })

				const payload = JSON.parse(event.data);
				let chatbotConversation = this.state.chatbotConversation;
				let chatbotConversationLength = this.state.chatbotConversation.length;
				if (chatbotConversationLength > 0 && chatbotConversation[chatbotConversationLength - 1].role == 'user') {
					chatbotConversation.push({ 'role': 'assistant', 'content': '' });
				}

				if (payload.success == false) {
					switch (payload.message) {
						case 'no-question':
							chatbotConversation[chatbotConversation.length - 1].content += "The message cannot be empty.";
							break;
						case 'inappropriate-question':
							chatbotConversation[chatbotConversation.length - 1].content += "Sorry, I am unable to answer that question. Please try asking me a different question.";
							break;
						case 'no-conversation':
						case 'server-error':
						default:
							chatbotConversation[chatbotConversation.length - 1].content += "Sorry, an error occurred on our end. Please try again later.";
					}
				} else {
					chatbotConversation[chatbotConversation.length - 1].content += payload.message;
				}

				sessionStorage.setItem('chatbotConversation', JSON.stringify(chatbotConversation));
				this.setState({ chatbotConversation: chatbotConversation });
			});

			websocketConnection.addEventListener("error", (event) => {
				console.log(event);
				if(this.state.connection.readyState > WebSocket.OPEN) {
					this.state.connection.close();
				}
			});

			websocketConnection.addEventListener("close", (event) => {
				if(event.code != 1000) {
					if (this.connectionAttempts == 1) {
						this.retrying();
					}

					if (this.connectionAttempts < 4) {
						setTimeout(() => {
							this.connectToServer(true);
						}, 5000);
					} else {
						this.serverErrorOccurred();
					}
				}
			});

			this.setState({ connection: websocketConnection });
		}
	}

	submitQuestion(event, retry) {
		event.preventDefault();

		if (!retry) {
			const questionInput = document.getElementById('chatbot-question-input');
			const question = questionInput.value;
			questionInput.value = '';

			const chatbotConversation = this.state.chatbotConversation;
			chatbotConversation.push({ 'role': 'user', 'content': question });
			this.setState({ chatbotConversation: chatbotConversation, question: '', isPending: true, feedbackProvided: false });
		}

		if (this.state.connection && this.state.connection.readyState == WebSocket.OPEN) {
			let chatbotUserId = localStorage.getItem('chatbotUserId');
			if(!chatbotUserId) {
				chatbotUserId = crypto.randomUUID();
				localStorage.setItem('chatbotUserId', chatbotUserId);
			}

			this.sessionId = sessionStorage.getItem('sessionId');
			if(!this.sessionId) {
				this.sessionId = crypto.randomUUID();
				sessionStorage.setItem('sessionId', this.sessionId);
			}

			this.state.connection.send(JSON.stringify({ 'question': this.state.chatbotConversation[this.state.chatbotConversation.length - 1].content, 'conversation': this.state.chatbotConversation, 'userId': chatbotUserId, 'sessionId': this.sessionId }));
		}

	}

	submitFeedback(wasHelpful) {
		this.setState({ feedbackProvided: true });
		fetch("https://" + FRONTEND_HOST + "/feedback", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				conversation: this.state.chatbotConversation,
				wasHelpful: wasHelpful
			})
		})
			.catch((error) => {
				console.log(error);
			});
	}

	renderChatbotWidget() {
		const { classes } = this.props;

		return (
			<IconButton
				onClick={() => this.handleChatbotWindowToggle()}
				className={classes.chatbotWidget}
				size='medium'
			>
				<SmsRoundedIcon />
			</IconButton>
		)
	}

	renderChatbotWindow() {
		const { classes } = this.props;
		const { chatbotConversation, isPending, isConnecting, feedbackProvided, question, windowSize } = this.state;
		this.connectToServer(false);

		return (
			<Box
				width={windowSize === 'small' ? '320px' : '410px'}
				height={windowSize === 'small' ? '500px' : '640px'}
				className={classes.chatbotWindow}
			>
				<Box
					className={classes.chatbotHeaderContainer}
				>
					<IconButton
						className={classes.resizeIcon}
						onClick={() => this.resizeChatbotWindowToggle()}
						title="Resize Window"
					>
						{windowSize === 'small' ?
							<UnfoldMore className={classes.botIcon} />
							:
							<UnfoldLess className={classes.botIcon} />
						}
					</IconButton>

					<Box
						className={classes.langibotTitle}
					>
						<img src='/langibot-logo.jpg' className={classes.langibot} />
						<Box><h1>langiBot</h1></Box>
					</Box>

					<IconButton
						className={classes.closeIcon}
						onClick={() => this.handleChatbotWindowToggle()}
						title="Close Window"
					>
						<CloseIcon className={classes.botIcon} />
					</IconButton>
				</Box>

				<Divider />

				<Box
					className={classes.chatbotMessagesContainer}
					id="conversation-container"
					onLoad={() => this.scrollConversation()}
				>
					{chatbotConversation.map((element, index) => {
						if (element.role === 'user') {
							return (
								<Box
									className={classNames(classes.userMessageContainer, classes.messageContainer)}
									key={crypto.randomUUID()}
								>
									<Box
										className={classNames(classes.message, classes.userMessage)}
									>
										{element.content}
									</Box>
								</Box>
							)
						} else {
							return (
								<Box
									className={classNames(classes.botMessageContainer, classes.messageContainer)}
									key={crypto.randomUUID()}
								>
									<img src='/langibot-logo.jpg' className={classes.langibotMessage}></img>
									<Box
										className={classNames(classes.message, classes.botMessage)}
										dangerouslySetInnerHTML={{ __html: element.content }}
									>
									</Box>
								</Box>
							)
						}
					})}

					{isPending &&
						<Box
							className={classNames(classes.botMessageContainer, classes.messageContainer)}
						>
							<img src='/langibot-logo.jpg' className={classes.langibotMessage}></img>
							<CircularProgress
								className={classes.botMessagePlaceholder}
							/>
						</Box>
					}

					{!isPending && !isConnecting && (chatbotConversation.length > 1 && chatbotConversation[chatbotConversation.length - 2].role == 'user') &&
						<>
							{feedbackProvided ?
								<Box
									className={classes.feedbackContainer}
								>
									<Box
										className={classes.feedbackMessage}
									>
										Thank you for your feedback!
									</Box>
								</Box>
								:
								<Box
									className={classes.feedbackContainer}
								>
									<Box
										className={classes.feedbackMessage}
									>Was this conversation helpful?</Box>
									<IconButton
										onClick={() => this.submitFeedback(1)}
										title="Helpful"
									>
										<ThumbUp className={classes.feedbackIcons} />
									</IconButton>
									<IconButton
										onClick={() => this.submitFeedback(0)}
										title="Unhelpful"
									>
										<ThumbDown className={classes.feedbackIcons} />
									</IconButton>
								</Box>
							}
						</>
					}

				</Box>

				<form className={classes.messageInputContainer} onSubmit={(event) => this.submitQuestion(event, false)}>
					<TextField
						id="chatbot-question-input"
						placeholder="Write your message"
						variant="outlined"
						className={classes.messageInput}
						disabled={isPending || isConnecting}
						onChange={(e) => this.handleQuestionUpdate(e)}
					/>
					<IconButton
						className={classes.messageInputContainerButton}
						onClick={() => this.clearConversation()}
						disabled={chatbotConversation.length == 0 || isPending || isConnecting}
						title="Clear Conversation"
					>
						<RotateLeftIcon />
					</IconButton>
					<IconButton
						className={classes.messageInputContainerButton}
						type='submit'
						disabled={question.length == 0 || isPending || isConnecting}
						title="Send Message"
					>
						<img src='/arrow-right.svg' />
					</IconButton>
				</form>
			</Box>
		)
	}

	render() {
		const { isExpanded } = this.state

		return (
			<Box>
				{isExpanded && this.renderChatbotWindow()}
				{this.renderChatbotWidget()}
			</Box>
		)
	}
}

const styles = chatbot => ({
	chatbotWidget: {
		position: 'fixed',
		bottom: 13,
		right: 110,
		zIndex: 999,
		ariaLabel: 'chatbot',
		color: 'white',
		backgroundColor: '#5C6BC0',
		'&:hover': {
			backgroundColor: '#30398B'
		},
	},
	chatbotWindow: {
		position: 'fixed',
		bottom: '60px',
		right: '140px',
		zIndex: 999,
		padding: '10px 20px',
		transition: 'width 0.5s, height 0.5s',
		backgroundColor: 'white',
		color: 'black',
		borderRadius: '10px',
		boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
		display: 'flex',
		flexDirection: 'column',
	},
	botIcon: {
		fontSize: 22,
	},
	chatbotHeaderContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	resizeIcon: {
		marginLeft: '-12px',
	},
	closeIcon: {
		marginRight: '-12px',
	},
	langibot: {
		width: '40px',
		height: '40px',
		marginRight: '12px',
		borderRadius: '20px',
	},
	langibotTitle: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	chatbotMessagesContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		overflow: 'auto',
		scrollbarWidth: 'none',
	},
	messageContainer: {
		display: 'flex',
		marginTop: '15px',
	},
	botMessageContainer: {
		flexFlow: 'row wrap',
		alignItems: 'flex-end',
	},
	userMessageContainer: {
		flexFlow: 'row-reverse wrap',
	},
	message: {
		padding: '15px',
		maxWidth: '80%',
		color: 'white',
	},
	userMessage: {
		backgroundColor: '#5C6BC0',
		borderRadius: '30px 0px 20px 30px',
	},
	botMessage: {
		backgroundColor: '#30398B',
		borderRadius: '20px 30px 30px 0px',
		'& .chatbotMessageLink': {
			color: 'lightskyblue',
		},
	},
	langibotMessage: {
		width: '28px',
		height: '28px',
		marginRight: '4px',
		marginBottom: '-15px',
		borderRadius: '15px',
	},
	botMessagePlaceholder: {
		color: '#30398B',
		width: '80%',
	},
	messageInputContainer: {
		display: 'flex',
		boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
		borderRadius: '25px',
	},
	feedbackContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '12px',
		marginBottom: '12px',
	},
	feedbackMessage: {
		color: '#484D9C',
		marginTop: '12px',
	},
	feedbackIcons: {
		color: '#484D9C',
		fontSize: '15px',
	},
	feedbackThankYou: {
		marginBottom: '12px',
	},
	messageInput: {
		flexGrow: 1,
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: 'none',
			},
		},
		'& .MuiInputLabel-root, .MuiOutlinedInput-input': {
			color: '#484D9C',
			top: '-3px',
		},
	},
	messageInputContainerButton: {
		color: '#484D9C',
		padding: '8px',
	},
});


export default withStyles(styles)(withTranslation()(withSnackbar(ChatbotWidget)));