import React, { useState, useEffect } from 'react';
import { Typography, Fade } from '@material-ui/core';

const FadingTextComponent = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <Fade in={true} timeout={1000}>
      <Typography variant="body2">{texts[currentIndex]}</Typography>
    </Fade>
  );
};

export default FadingTextComponent;