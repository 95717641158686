import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Faq from './pages/faq';
import Documentation from './pages/documentation';

const styles = theme => ({

});

class Static extends React.Component {

  pages = {
    privacy: Privacy,
    terms: Terms,
    faq: Faq,
    documentation: Documentation,
  }

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    const { t, classes } = this.props
    const { } = this.state

    if(this.pages[this.props.match.params.id]) {
      const Component = this.pages[this.props.match.params.id]
      return <Component />;
    }
    
    return <React.Fragment>
      Page not found
    </React.Fragment>
  }
}

export default withStyles(styles)(withTranslation()(Static))