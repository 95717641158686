import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { t } from 'i18next';

import API from '../api/api';

const useStyles = makeStyles({
  enableIntegrationBtn: {
    color: 'white',
    backgroundColor: 'green',
    borderColor: 'green',
    '&:hover': {
      backgroundColor: 'darkgreen',
      borderColor: 'darkgreen',
    }
  },
  disableIntegrationBtn: {
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'red',
    '&:hover': {
      backgroundColor: 'darkred',
      borderColor: 'darkred',
    }
  },
  removeBtn: {
    marginRight: 'auto',
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'red',
    '&:hover': {
      backgroundColor: 'darkred',
      borderColor: 'darkred',
    }
  },
  apiKeyInputContainer: {
    position: 'relative'
  },
  btnIcon: {
    marginRight: 8,
  },
  loadingLayer: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default function PartnerIntegrationsSettings(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [apiKey, setApiKey] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // componentDidMount
  useEffect(() => {
    
  }, [apiKey])  

  const handleClickOpen = () => {
    setLoading(true);
    setOpen(true);
    API.partnerGetIntegration(props.partnerHandle, (res) => {
      //console.log(res, props.activeIntegration, props.partnerIntegration);
      if(res.integration && res.integration.data) {
        setApiKey(res.integration.data.api_key)
      }
      setLoading(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeApiKey = (event) => {
    setApiKey(event.target.value);
    setError(null);
  };

  const handleSaveApiKey = (event) => {
    setLoading(true);
    setError(null);
    API.partnerSetApiKey(props.partnerHandle, apiKey, (res) => {
      setLoading(false);
      if(res.success === true) {
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } else {
        setError(res.message);
      }
      setLoading(false);
    });
  };

  const handleDeleteApiKey = (event) => {
    setApiKey('');
    setLoading(true);
    setError(null);
    setLoading(true);
    API.partnerDeleteApiKey(props.partnerHandle, (res) => {
      if(res.success === true) {
        setApiKey('');
        setOpen(false);
      } else {
        setError(res.message);
      }
      setLoading(false);
    });
  };


  return (
    <div>
      <Button 
        variant="contained" 
        color={props.activeIntegration ? 'default' : 'secondary'} 
        onClick={handleClickOpen}
        size="small"
        disabled={false}
        className={props.activeIntegration ? null : classes.enableIntegrationBtn}
      >
        {props.activeIntegration ? t('partnerIntegrations.settingsBtn') : t('partnerIntegrations.enableBtn')}
      </Button>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('partnerIntegrations.apiDialog.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('partnerIntegrations.apiDialog.description', {appName: props.partnerData.title})}
            </DialogContentText>
            <div className={classes.apiKeyInputContainer}>
              <TextField
                autoFocus
                margin="dense"
                id="api_key"
                label={t('partnerIntegrations.apiDialog.inputLabel')}
                type="text"
                placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                value={apiKey}
                onChange={handleChangeApiKey}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={error ? true : false}
                helperText={error ? error : null}
              />      
            </div>
            {loading && 
              <div className={classes.loadingLayer}>loading...</div>
            }
          </DialogContent>
          <DialogActions>
            {apiKey &&
              <Button onClick={handleDeleteApiKey} disabled={apiKey === ''} color="primary" className={classes.removeBtn} variant="contained">
                <HighlightOffOutlinedIcon className={classes.btnIcon} /> {t('partnerIntegrations.apiDialog.deleteBtn')}
              </Button>
            }
            <Button onClick={handleClose} color="default" variant="outlined">
              {t('system.cancel')}
            </Button>
            <Button onClick={handleSaveApiKey} disabled={false} color="secondary" variant="contained">
              <DoneOutlinedIcon className={classes.btnIcon} /> {t('partnerIntegrations.apiDialog.saveBtn')}
            </Button>
          </DialogActions>

          {loading &&
            <div className={classes.loadingOverlay}>
              <CircularProgress />
            </div>
          }
        </Dialog>

    </div>
  );
}