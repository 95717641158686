import React, { useState, useEffect } from 'react';
import { enableCrossDomainLinks, disableCrossDomainLinks } from '../modules/shop';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import { Alert, AlertTitle } from '@material-ui/lab';
import { compare } from 'compare-versions';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import DontAskAgainDialog from './DontAskAgainDialog.js';
import LoadingIndicator from './LoadingIndicator';
import API from '../api/api';
import ErrorBoundary from './ErrorBoundary';


function decimalToSemanticVersion(version) {
  var version = version.toString();
  if(version.indexOf('.') >= 0) {
    return version;
  }

  var semanticFormat = (version > 10) ? (version/10).toString() : version.toString();

  if(semanticFormat.split('.').length === 1) semanticFormat += '.0.0';
  if(semanticFormat.split('.').length === 2) semanticFormat += '.0';
  return semanticFormat;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: -16,
    top: -8,
    color: theme.palette.grey[500],
  },
  backdrop: {
    zIndex: 10,
  },
  backdropAlert: {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 24px',
  }
}));
const UpdateRequiredNotice = (props) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const [show, setShow] = useState(true);
  const [scriptSettings, setScriptSettings] = useState(null);
  const [useDomainLinks, setUseDomainLinks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmLoadDefaultDialog, setShowConfirmLoadDefaultDialog] = useState(false);
  const [showCrossDomainLinksDialog, setShowCrossDomainLinksDialog] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentTheme = props.themes.themes.find(x => x.id == props.editTheme);
    if(currentTheme) {
      API.getScriptSettings(
        currentTheme.shopify_id,
        response => {
          if(response.success == true) {
            setScriptSettings(response.script_settings);
          }
      });
      API.getMetafield('langify_v2', 'cross_domain_links', (data) => {
        if(data.success && data.result && data.result.value) {
          setUseDomainLinks(true);
        }
      });      
    }
  }, []);

  const handleToggleBtn = () => {
    setCollapsed(!collapsed);
  }

  const handleUpdate = () => {
    setLoading(true);
    API.addV3Scripts(props.editTheme, () => {
      setLoading(false);
      setShow(false);
    });
  }

  const restoreFromLocalAsset = () => {
    setLoading(true);

    let fileNames = ['snippets/ly-core-scripts.liquid', 'snippets/ly-switcher-factory.liquid'];
    let datas = [];
    for(var i=0; i<fileNames.length; i++) {
      datas.push({key: fileNames[i].replace('snippets/', '')});
    }

    API.restoreFromLocalAsset(
      props.editTheme,
      datas,
      (res) => {
        setLoading(false);
        setShow(false);
      }
    ); 
  }

  const onCloseLoadDefaultDialog = () => {
    setShowConfirmLoadDefaultDialog(false);
  }
  const onSubmitConfirmLoadDefaultDialog = () => {
    restoreFromLocalAsset();
  }

  const onCloseCrossDomainLinksDialog = () => {
    setShowCrossDomainLinksDialog(false);
  }

  const customized = (scriptSettings && scriptSettings.customized) && (scriptSettings.customized['snippets/ly-core-scripts.liquid'] || scriptSettings.customized['snippets/ly-switcher-factory.liquid']) ? true : false;
  
  const _isOutdated = () => {
    let outdated = false;
    Object.entries(props.requiredVersions).map((item, index) => {
      if (!outdated && scriptSettings && scriptSettings.versions && scriptSettings.versions.current && scriptSettings.versions.current[item[0]]) {
        if(compare(decimalToSemanticVersion(scriptSettings.versions.current[item[0]]), decimalToSemanticVersion(item[1]), '<')) {
          outdated = true;
        }
      }
    });
    return outdated;
  }

  return(<React.Fragment>
    {_isOutdated() && props.editTheme && show && 
      <React.Fragment>
        {props.backdrop &&
          <Backdrop className={classes.backdrop} open={_isOutdated} onClick={handleToggleBtn}></Backdrop>
        }
        <Alert severity="error" 
          gutterBottom
          className={props.backdrop ? classes.backdropAlert : null}
          style={{marginBottom: props.noMargin ? 0 : 16}} 
          action={
            <Button 
              onClick={customized ? () => {setShowConfirmLoadDefaultDialog(true)} : handleUpdate} 
              disabled={loading ? true : false} 
              variant='text' 
              color="inherit" 
              size="small">
                {loading ? <React.Fragment>updating <CircularProgress size={20} /></React.Fragment> : 'update'}
            </Button>
          }>
          
          <React.Fragment>
            <strong>{props.title ? props.title : 'IMPORTANT: ACTION REQUIRED!!!'}</strong><br /> 
            {props.subtitle ? props.subtitle : `You have an outdated version of langify\'s core and switcher files on your currently selected theme.\n
            Please press the "UPDATE" button in order that all features continue to work.\n`}          
          </React.Fragment>

          
          {props.moreInfoContent &&
            <React.Fragment>
              <Collapse in={!collapsed}>
                {props.moreInfoContent}   
              </Collapse>
              <a onClick={handleToggleBtn} variant="text" color="inherit" size="small" style={{cursor: 'pointer', textDecoration: 'underline'}}>
                <strong>{collapsed ? 'Show details' : 'Hide details'}</strong>
              </a>              
            </React.Fragment>
          }
          
        </Alert>
        
        <LoadingIndicator
          pending={loading}
          saving={false}
          progress={0}
          withLogo
        />
        <DontAskAgainDialog
          context="ConfirmLoadDefault"
          open={showConfirmLoadDefaultDialog}
          onClose={onCloseLoadDefaultDialog}
          onSubmit={onSubmitConfirmLoadDefaultDialog}
          title={t('themeSettings.customizeConfirmLoadTitle')}
          content={t('themeSettings.customizeConfirmLoadInfo')}
        />
      </React.Fragment>
      }
      <Dialog
        open={showCrossDomainLinksDialog}
        onClose={onCloseCrossDomainLinksDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">It's almost done
          <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseCrossDomainLinksDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It looks like you are using langify's "Cross Domain Links" feature. Please follow the steps below:
            <ol>
              <li>{'Go to the "Switcher Configurator -> Domains" section.'}</li>
              <li>Check and correct the settings if needed.</li>
              <li>Save the switcher configuration.</li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>Cancel</Button>
          <Button onClick={onCloseCrossDomainLinksDialog} color="secondary" variant="contained" autoFocus>
            Understood
          </Button>            
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}


const mapStateToProps = ({ shop, themes }) => ({
  shop_version: shop.shop.version,
  shop: shop.shop,
  editTheme: themes.edit_theme,
  themes: themes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enableCrossDomainLinks,
      disableCrossDomainLinks
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateRequiredNotice);