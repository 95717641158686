import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  triggerBtn: {
    position: 'absolute',
    top: 12,
    right: -8
  },
  popper: {
    zIndex: 100,
    marginLeft: -19,
    marginRight: 19,
    paddingTop: 8,
  },
  tooltip: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#cfd8dc'
  }
});

class InputInformation extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? 'simple-popper' : null;

    return (
      <React.Fragment>
        <IconButton className={classes.triggerBtn} aria-describedby={id} variant="contained" onClick={this.handleClick}>
          <Icon>info</Icon>
        </IconButton>
        <Popper id={id} 
          className={classes.popper}
          open={open} 
          anchorEl={anchorEl} 
          transition
          disablePortal
          //modifiers={{
            //arrow: {
              //enabled: true,
              //element: arrowRef,
            //}
          //}}
          >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.tooltip}>
                {this.props.content}
              </Paper>
            </Fade>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

InputInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputInformation);