import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import NotificationEditor from '../../components/NotificationEditor';


const styles = theme => ({

});

class Notifications extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pending: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({pending: false})
    }, 1500)
  }

  render() {
    const { classes } = this.props;

    if(this.state.pending === true) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Notifications
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={true}
          withLogo
        />
      </React.Fragment>  
    }

    return(
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Notifications
        </Typography>
        <Divider className="divider-white" />

        <NotificationEditor />
      </React.Fragment>  
    )
  }

}

export default withStyles(styles)(Notifications);