import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_COLLECTIONS_PENDING = 'SYNC_COLLECTIONS_PENDING'
const SYNC_COLLECTIONS_PROGRESS = 'SYNC_COLLECTIONS_PROGRESS'
const SYNC_COLLECTIONS_RESPONSE = 'SYNC_COLLECTIONS_RESPONSE'
const SYNC_COLLECTION_PENDING = 'SYNC_COLLECTION_PENDING'
const SYNC_COLLECTION_RESPONSE = 'SYNC_COLLECTION_RESPONSE'
const SAVE_COLLECTIONS_PENDING = 'SAVE_COLLECTIONS_PENDING'
const SAVE_COLLECTIONS_PROGRESS = 'SAVE_COLLECTIONS_PROGRESS'
const SAVE_COLLECTIONS_RESPONSE = 'SAVE_COLLECTIONS_RESPONSE'
const SYNC_COLLECTION_METAFIELDS_PENDING = 'SYNC_COLLECTION_METAFIELDS_PENDING'
const SYNC_COLLECTION_METAFIELDS_PROGRESS = 'SYNC_COLLECTION_METAFIELDS_PROGRESS'
const SYNC_COLLECTION_METAFIELDS_RESPONSE = 'SYNC_COLLECTION_METAFIELDS_RESPONSE'
const CREATE_COLLECTION_CUSTOM_CONTENTS_PENDING = 'CREATE_COLLECTION_CUSTOM_CONTENTS_PENDING'
const CREATE_COLLECTION_CUSTOM_CONTENTS_PROGRESS = 'CREATE_COLLECTION_CUSTOM_CONTENTS_PROGRESS'
const CREATE_COLLECTION_CUSTOM_CONTENTS_RESPONSE = 'CREATE_COLLECTION_CUSTOM_CONTENTS_RESPONSE'
const DELETE_COLLECTION_CUSTOM_CONTENT_PENDING = 'DELETE_COLLECTION_CUSTOM_CONTENT_PENDING'
const DELETE_COLLECTION_CUSTOM_CONTENT_PROGRESS = 'DELETE_COLLECTION_CUSTOM_CONTENT_PROGRESS'
const DELETE_COLLECTION_CUSTOM_CONTENT_RESPONSE = 'DELETE_COLLECTION_CUSTOM_CONTENT_RESPONSE'
const EXPORT_COLLECTIONS_PENDING = 'EXPORT_COLLECTIONS_PENDING'
const EXPORT_COLLECTIONS_PROGRESS = 'EXPORT_COLLECTIONS_PROGRESS'
const EXPORT_COLLECTIONS_RESPONSE = 'EXPORT_COLLECTIONS_RESPONSE'
const IMPORT_COLLECTIONS_PENDING = 'IMPORT_COLLECTIONS_PENDING'
const IMPORT_COLLECTIONS_PROGRESS = 'IMPORT_COLLECTIONS_PROGRESS'
const IMPORT_COLLECTIONS_RESPONSE = 'IMPORT_COLLECTIONS_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'
const CLEAR_EXPORT_URLS = 'CLEAR_EXPORT_URLS'

const initialState = {
  sync_collections_pending: true,
  sync_collection_pending: false,
  sync_collection_metafields_pending: false,
  create_collection_custom_contents_pending: false,
  delete_collection_custom_content_pending: false,
  save_collections_pending: false,
  export_collections_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_collections_pending: false,
  imported_entries: 0,
  progress: 0,
  error: false,
  collections: [],
}

function validateCollection(data, origin) {
  if (typeof data.general_errors !== 'undefined' && typeof data.general_errors.errors !== 'undefined') {
    data.general_errors.errors.forEach(item => {
      switch(item.object_key) {
        case 'handle' :
          //data.target.handle = origin.target.handle;
          break;
      }
    })
  }
  return data;
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case SYNC_COLLECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_collections_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_COLLECTIONS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_COLLECTIONS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_collections_pending: false,
          error: false,
          collections: action.response.collections
        }
      } else {
        return {
          ...state,
          sync_collections_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case SYNC_COLLECTION_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_collection_pending: true,
        error: false,
      }
    }
    case SYNC_COLLECTION_RESPONSE: {
      if(action.response.success === true) {
        let newCollections = state.collections.slice()
        let collectionToUpdate = newCollections.find(x => x.id == action.response.collection.id)
        
        collectionToUpdate.synchronized = true;

        collectionToUpdate.progress = action.response.collection.progress

        collectionToUpdate.source.title = action.response.collection.source.title
        collectionToUpdate.source.body_html = action.response.collection.source.body_html
        collectionToUpdate.source.handle = action.response.collection.source.handle
        collectionToUpdate.source.page_title = action.response.collection.source.page_title
        collectionToUpdate.source.page_description = action.response.collection.source.page_description
        collectionToUpdate.source.image = action.response.collection.source.image
        
        collectionToUpdate.target.title = action.response.collection.target.title
        collectionToUpdate.target.body_html = action.response.collection.target.body_html
        collectionToUpdate.target.handle = action.response.collection.target.handle
        collectionToUpdate.target.page_title = action.response.collection.target.page_title
        collectionToUpdate.target.page_description = action.response.collection.target.page_description
        collectionToUpdate.target.image = action.response.collection.target.image

        collectionToUpdate.custom_contents = action.response.collection.custom_contents.slice()
        collectionToUpdate.customSeo = action.response.collection.custom_seo
        
        return {
          ...state,
          collections: newCollections,
          sync_collection_pending: false
        }
      } else {
        return {
          ...state,
          sync_collection_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          },
        }
      }
    }
    case SAVE_COLLECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_collections_pending: true,
        error: false,
      }
    }
    case SAVE_COLLECTIONS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_COLLECTIONS_RESPONSE: {
      let error = false;
      let newCollections = state.collections.slice();
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let newCollection = newCollections.find(x => x.id == response.collection.id)
          let collection = validateCollection(response.collection,newCollection)
          newCollection.progress = collection.progress
          // commented out so source is not overwritten if backend returns insufficient data
          // newCollection.source = {...collection.source}
          newCollection.target = {...collection.target}
          newCollection.metafields = collection.metafields.slice()
          newCollection.custom_contents = collection.custom_contents.slice()
          newCollection.errors = collection.general_errors.errors;
        }
      })
      return {
        ...state,
        collections: newCollections,
        save_collections_pending: false,
        error: error
      }
    }
    case SYNC_COLLECTION_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_collection_metafields_pending: true,
        error: false,
      }
    }
    case SYNC_COLLECTION_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_COLLECTION_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newCollections = state.collections.slice()
        let collectionToUpdate = newCollections.find(x => x.id == action.response.collection_id)
        
        collectionToUpdate.metafields_synchronized = true;
        collectionToUpdate.metafields = action.response.metafields.slice()

        return {
          ...state,
          sync_collection_metafields_pending: false,
          collections: newCollections,
          error: false
        }
      } else {
        return {
          ...state,
          sync_collection_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 2,
          },
        }
      }
    }
    case CREATE_COLLECTION_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_collection_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_COLLECTION_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_COLLECTION_CUSTOM_CONTENTS_RESPONSE: {
      let newCollections = state.collections.slice()
      let error = false;

      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let collectionToUpdate = newCollections.find(x => x.id == response.collection_id)
          collectionToUpdate.custom_contents.push({
            id: response.custom_content.id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          });
        }
      }

      return {
        ...state,
        collections: newCollections,
        create_collection_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_COLLECTION_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_collection_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_COLLECTION_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_COLLECTION_CUSTOM_CONTENT_RESPONSE: {
      let newCollections = state.collections.slice()
      let error = false;

      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        let collectionToUpdate = newCollections.find(x => x.id == action.response.collection_id)
        collectionToUpdate.custom_contents = collectionToUpdate.custom_contents.filter(x => x.id != action.response.id)
      }

      return {
        ...state,
        collections: newCollections,
        delete_collection_custom_content_pending: false,
        error: error
      }
    }
    case EXPORT_COLLECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        export_collections_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_COLLECTIONS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_COLLECTIONS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_collections_pending: false,
          export_po_download_url: _exportDownloadLinkDecorator(action.response.po_download_url, action.options),
          export_csv_download_url: _exportDownloadLinkDecorator(action.response.csv_download_url, action.options),
        }
      } else {
        return {
          ...state,
          progress: 100,
          export_collections_pending: false,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case IMPORT_COLLECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        import_collections_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_COLLECTIONS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_COLLECTIONS_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_collections_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_collections_pending: false,
          imported_entries: importedEntries,
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    case CLEAR_EXPORT_URLS: {
      return {
        ...state,
        progress: 0,
        export_collections_pending: false,
        export_csv_download_url: null,
        export_po_download_url: null,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncCollections = (language, sync_reset, callback) => {  
  return dispatch => {
    dispatch({
      type: SYNC_COLLECTIONS_PENDING
    })

    API.syncCollections(
      language,
      progress => {
        dispatch({
          type: SYNC_COLLECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_COLLECTIONS_RESPONSE,
          response: response,
          action: () => syncCollections(language, sync_reset, callback)
        });
        if(callback) {
          callback(response);
        }
      },
      sync_reset
    )
  }
}

export const syncCollection = (collectionId, languageId) => {  
  return dispatch => {
    dispatch({
      type: SYNC_COLLECTION_PENDING
    })

    API.syncCollection(
      collectionId,
      languageId,
      response => {
        dispatch({
          type: SYNC_COLLECTION_RESPONSE,
          response: response,
          action: () => syncCollection(collectionId, languageId)
        })
      }
    )
  }
}

export const saveCollections = (language, items, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_COLLECTIONS_PENDING
    })

    API.saveCollections(
      language, 
      items,
      force,
      progress => {
        dispatch({
          type: SAVE_COLLECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_COLLECTIONS_RESPONSE,
          response: response,
          items: items,
          action: () => saveCollections(language, items, force)
        })
      })
  }
}

export const syncCollectionMetafields = (collection, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_COLLECTION_METAFIELDS_PENDING
    })

    API.syncCollectionMetafields(
      collection,
      language,
      progress => {
        dispatch({
          type: SYNC_COLLECTION_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_COLLECTION_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncCollectionMetafields(collection, language)
        }
      )
    })
  }
}

export const createCollectionCustomContents = (collection, language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_COLLECTION_CUSTOM_CONTENTS_PENDING
    })

    API.createCollectionCustomContents(
      collection,
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_COLLECTION_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_COLLECTION_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createCollectionCustomContents(collection, language, items)
        })
      })
  }
}

export const deleteCollectionCustomContent = (collection, ids) => {
  return dispatch => {
    dispatch({
      type: DELETE_COLLECTION_CUSTOM_CONTENT_PENDING
    })

    API.deleteCollectionCustomContent(
      collection,
      ids,
      progress => {
        dispatch({
          type: DELETE_COLLECTION_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_COLLECTION_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deleteCollectionCustomContent(collection, ids)
        })
      })
  }
}

export const exportCollections = (language, options) => {
  return dispatch => {
    dispatch({
      type: EXPORT_COLLECTIONS_PENDING
    })

    API.exportCollections(
      language,
      options,
      progress => {
        dispatch({
          type: EXPORT_COLLECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_COLLECTIONS_RESPONSE,
          response: response,
          options: options,
          action: () => exportCollections(language, options)
        }
      )
    })
  }
}

export const importCollections = (file, fileType, language, options) => {
  return dispatch => {
    dispatch({
      type: IMPORT_COLLECTIONS_PENDING
    })

    API.importCollections(
      file,
      fileType,
      language,
      options,
      progress => {
        dispatch({
          type: IMPORT_COLLECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_COLLECTIONS_RESPONSE,
          response: response,
          action: () => importCollections(file, fileType, language, options)
        }
      )
    })
  }
}

export const clearExportUrls = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_EXPORT_URLS
    });
  }
}