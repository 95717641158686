import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

const SolutionsArticleLink = ({ url, breadcrumbs, rootStyle, iconStyle }) => {
  const classes = useStyles();

  const handleIconClick = () => {
    window.open(url, '_blank');
  };

  return (
    <span style={rootStyle ? rootStyle : {
      position: 'absolute',
      top: 12,
      right: 12,
    }}>
      <div style={{position: 'relative'}}>
        <Tooltip classes={{ tooltip: classes.customWidth }} 
          title={
            <Typography variant="caption">
              {/* Solutions {` > ${breadcrumbs}`}*/}
              Get more information in this solutions article:<br /> 
              <OpenInNewRoundedIcon style={{fontSize: 14, marginTop: -1}} /> {url.replace(/(support\/solutions\/articles\/)[0-9]+(-)/gm, '...')}
            </Typography>
          } 
          aria-label="More information" 
          arrow
        >
          <span className='pulse-button'
            aria-label="Info"
            onClick={handleIconClick}
          >
            <HelpRoundedIcon style={iconStyle ? iconStyle : {
              fontSize: 'inherit'
            }}/>
          </span>
        </Tooltip>          
      </div>
    </span>
  );
};

SolutionsArticleLink.propTypes = {
  url: PropTypes.string.isRequired,
  rootStyle: PropTypes.object,
  iconStyle: PropTypes.object,
};

export default SolutionsArticleLink;
