import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import classNames from 'classnames';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';

import LoadingIndicator from '../../components/LoadingIndicator';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    marginTop: 16,
    marginBottom: 16
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  TimelineOppositeContent: {
    width: 200,
  }
}));

function RightAlignedTimeline() {
  const classes = useStyles();

  return (
    <Timeline>
      
      <TimelineItem className="changelog"> 
        <TimelineOppositeContent>
          <Typography variant="body2" style={{color: 'white'}}>
            February
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined">
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h5" style={{color: 'white'}} >
            v2.0.4
          </Typography>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">Code</Typography>
            <Typography>
              - Add a yearly subscription plan.<br />
              - Support of emoji icons in the translation fields.<br />
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      
      <TimelineItem className="changelog"> 
        <TimelineOppositeContent>
          <Typography variant="body2">
            Jan. 25, 2021
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="textSecondary">
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h4" color="secondary">
            v2.0.3
          </Typography>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">Code</Typography>
            <Typography>
              - Added an export all button.<br />
              - Fixes in the search feature.<br />
              - Better error handling and special character encoding in the image uploader.<br />
              - Several fixes in the richtext editor.<br />
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      
      <TimelineItem className="changelog"> 
        <TimelineOppositeContent>
          <Typography variant="body2">
            Jan. 11, 2021
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h5" color="textSecondary">
            v2.0.2
          </Typography>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">Code</Typography>
            <Typography>
              - Lazyloading for asynchronous custom content translation.<br />
              - Added image fields for products, collections,.<br />
              - Function to re-save all products at once on the Products page.<br />
              - Auto-translate function for single fields.<br />
              - Fixes in the metafield translations.<br />
              - New screenshot generation service.<br />
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      
      <TimelineItem className="changelog"> 
        <TimelineOppositeContent>
          <Typography variant="body2">
            Nov. 18, 2020
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h5" color="textSecondary">
            v2.0.1
          </Typography>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">Code</Typography>
            <Typography>
              - Several fixes for static translations.<br />
              - Errorhandling for notifications with a liquid syntax error.<br />
              - Theme progress is not calculated correctly in the stats panel on the <br />
              - Fixed variants are 'undefined' bug.<br />
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

    </Timeline>
  );
}

const styles = theme => ({
  root: {
    
  },
  headerBanner: {
    textAlign: 'center',
    height: 200, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  TimelineOppositeContent: {

  }
});


class Changelog extends React.Component {
  
  state = {
    open: false,
  };

  componentDidMount() {
    const logo = document.querySelectorAll('#langify g > path')
    for(let i = 0; i < logo.length; i++) {
      console.log(logo[i].getTotalLength())
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>


        <LoadingIndicator
          pending={open}
          progress={50}
          withLogo
        />

        <div className={classNames('header-banner', classes.headerBanner)}>
          <div className={classes.headerBannerInner}>
            <Typography variant="h3" component="h2" gutterBottom> 
                Changelog
            </Typography>
          </div>
        </div>
        <Container maxWidth="md">
          <RightAlignedTimeline></RightAlignedTimeline>
        </Container>
      </div>
    );
  }
}
Changelog.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRoot(withStyles(styles)(Changelog));
