import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Stepper from '@material-ui/core/Stepper';
import Divider from '@material-ui/core/Divider';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import RichTextEditor from './RichTextEditor'
import LoadingIndicator from './LoadingIndicator';
import { TextField } from '@material-ui/core';


class NotificationEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      languages: props.languages,
      sourceNotificationTitle: '',
      sourceNotificationBody: '',
      notificationTitle: '',
      notificationBody: '',
      notificationType: 'normal'
    };
  }

  componentDidMount() {
    
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));

    this.setDefaulData();
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    const newData = {};
    this.props.languages.languages.forEach(val => {
      newData[`${val.id}NotificationTitle`] = '';
      newData[`${val.id}NotificationBody`] = '';
    });
    this.setState({
      activeStep: 0,
      sourceNotificationTitle: '',
      sourceNotificationBody: '',
      ...newData
    });
  };

  handleActive = (index) => {
    if(this.state.sourceNotificationTitle !== '' && this.state.sourceNotificationBody !== '') {
      this.setState({
        activeStep: index,
      });

      this.setDefaulData();
    }
  };

  handleChange = (name, id) => event => {
    this.setState({
      [name]: event.target.value
    });
  }

  setDefaulData = () => {
    const newData = {};
    this.props.languages.languages.forEach(lang => {
      if(!lang.base) {
        if(typeof this.state[`${lang.id}NotificationTitle`] === 'undefined') newData[`${lang.id}NotificationTitle`] = this.state.sourceNotificationTitle;
        if(typeof this.state[`${lang.id}NotificationBody`] === 'undefined') newData[`${lang.id}NotificationBody`] = this.state.sourceNotificationBody;
      }
    });
    this.setState({
      ...newData
    });
  }

  handleChangeType = event => {
    this.setState({ notificationType: event.target.value });
  };

  handleGenerateTemplate = () => {
    let notificationTitle, notificationBody;
    notificationTitle = notificationBody = (this.state.notificationType === 'normal') ? `{% case attributes.language %}` : `{% assign language = customer.tags | join: '' | split: 'ly#' %}{% case language[1] %}`;

    this.props.languages.languages.forEach(val => {
      if(!val.base) { 
        notificationTitle += `{% when 'ly${val.id}' %}${this.state[val.id + 'NotificationTitle']}`;
        notificationBody += `{% when 'ly${val.id}' %}${this.state[val.id + 'NotificationBody']}`;
      }
    });
    
    notificationTitle += `{% else %}${this.state['sourceNotificationTitle']}{% endcase %}`;
    notificationBody += `{% else %}${this.state['sourceNotificationBody']}{% endcase %}`;

    this.setState({
      notificationTitle: notificationTitle,
      notificationBody: notificationBody,
      activeStep: this.props.languages.languages.length + 1,
    });
  }

  renderStep = (step, index) => {
    const { classes, languages } = this.props;
    const { sourceNotificationTitle, sourceNotificationBody, activeStep } = this.state;

    const isLanguage = (typeof step === 'object');
    const namePrefix = step.id ? step.id : 'source';
    const titleState = this.state[`${namePrefix}NotificationTitle`];
    const bodyState = this.state[`${namePrefix}NotificationBody`];

    return(
      <Step key={step}>
        <StepLabel onClick={() => this.handleActive(index)}>
          {isLanguage ? `Translate: ${step.name}` : `Paste a Shopify notification template here`}
        </StepLabel>
        <StepContent>
          <TextField
            value={titleState}
            onChange={this.handleChange(`${namePrefix}NotificationTitle`, step.id ? step.id : null)}
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {
                root: classNames(classes.textField, classes.disabledField),
                multiline: classes.textField,
                input: classes.inputField,
                inputMultiline: classes.inputField,
              }
            }}
          />
          <TextField
            value={bodyState}
            onChange={this.handleChange(`${namePrefix}NotificationBody`, step.id ? step.id : null)}
            variant="outlined"
            multiline
            rows={15}
            fullWidth
            InputProps={{
              classes: {
                root: classNames(classes.textField, classes.disabledField),
                multiline: classes.textField,
                input: classes.inputField,
                inputMultiline: classes.inputField,
              }
            }}
          />

          <div className={classes.actionsContainer}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
                disabled={!(this.state.sourceNotificationTitle !== '' && this.state.sourceNotificationBody !== '')}
              >
                {activeStep === languages.languages.length - 1 ? 'Finish' : 'Next Step'}
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
    );
  }

  render() {
    const { classes, languages } = this.props;
    const { activeStep } = this.state;

    return (
      <Paper id="ItemsList" className={classes.root} elevation={1}>
        <Toolbar>
          Notification Generator
          {(this.state.sourceNotificationTitle !== '' && this.state.sourceNotificationBody !== '') &&
            <Button onClick={this.handleReset} className={classNames(classes.reset)}>
              Reset
            </Button>
          }
        </Toolbar>
        <Divider />
        <Stepper activeStep={activeStep} orientation="vertical">
          {this.renderStep('initial', 0)}
          {languages.languages.map((language, index) => {
            return !language.base ? this.renderStep(language, index) : null;
          })}
          <Step key={'finalize'}>
            <StepLabel onClick={() => this.handleActive(languages.languages.length)}>Finish the notification translation</StepLabel>
            <StepContent>
              <div className={classes.actionsContainer}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup
                    aria-label="NotificationType"
                    name="notificationType"
                    className={classes.group}
                    value={this.state.notificationType}
                    onChange={this.handleChangeType}
                  >
                    <FormControlLabel value="normal" control={<Radio />} label="Regular Notification" />
                    <FormControlLabel value="customer" control={<Radio />} label="Customer Account Notification" />
                  </RadioGroup>
                </FormControl>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.handleGenerateTemplate}
                    className={classes.button}
                  >
                    Generate Notification Template
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>     
        </Stepper>
        <Divider/>  
        {activeStep === languages.languages.length + 1 && (
          <Paper square elevation={0} className={classes.resetContainer}> 
            <div className={classNames('themes-section', classes.contentContainer)}>
              <TextField
                value={this.state.notificationTitle}
                onChange={() => {}}
                variant="outlined"
                rows={1}
                fullWidth
                disabled
                InputProps={{
                  classes: {
                    root: classNames(classes.textField, classes.disabledField),
                    multiline: classes.textField,
                    input: classes.inputField,
                    inputMultiline: classes.inputField,
                  }
                }}
              />
              <TextField
                value={this.state.notificationBody}
                onChange={() => {}}
                variant="outlined"
                multiline
                rows={15}
                fullWidth
                disabled
                InputProps={{
                  classes: {
                    root: classNames(classes.textField, classes.disabledField),
                    multiline: classes.textField,
                    input: classes.inputField,
                    inputMultiline: classes.inputField,
                  }
                }}
              />
            </div>
          </Paper>
        )}
      </Paper>
    );
  }
}

NotificationEditor.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  languages: state.languages,
})

const styles = theme => ({
  root: {
    width: '100%',
    //marginTop: theme.spacing(3),
    marginTop: 20,
  },
  button: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  reset: {
    position: 'absolute',
    right: 24
  },
  actionsContainer: {
    //marginBottom: theme.spacing(2),
    //textAlign: 'right',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  textField: {
    margin: '12px 0',
    padding: 0,
  },
  inputField: {
    padding: '12px 14px !important',
    fontSize: '14px !important',
    boxSizing: 'border-box',
  },
});

export default withRouter(connect(
  mapStateToProps,
  null
)(withStyles(styles)(NotificationEditor)));