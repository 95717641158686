import React from 'react';
import { syncNavigation, saveNavigation, exportNavigation, importNavigation, clearExportUrls, resetErrors } from '../../modules/navigation'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import DontAskAgainDialog from '../../components/DontAskAgainDialog.js';
import OptionsMenu from '../../components/OptionsMenu';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: '#ccc',
    top: '4px',
    '&:hover': {
      backgroundColor: 'rgba(26, 35, 126, 0.5)',
      borderColor: "#ccc",
    }
  }
});

class Navigation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      showClearAllNotice: false,
      forceOpenExportDialog: false,
      forceOpenImportDialog: false,
    }
  }

  componentDidMount() {
    this.props.syncNavigation(this.props.editLanguage)

    if(this.props.navigation.export_po_download_url || this.props.navigation.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncNavigation(newLanguage)
      return
    }

    if(this.props.navigation.import_navigation_pending === true && newProps.navigation.import_navigation_pending === false) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }

    const newData = [];
    for(let key in newProps.navigation.links) {
      const item = newProps.navigation.links[key];
      newData.push({
        type: 'navigation',
        id: item.id,
        resource_ids: item.resource_ids,
        progress: item.progress,
        title: item.source,
        info: '',
        fields: [{
          id: 'title',
          source: item.source,
          target: item.target || ''
        }]
      })
    }

    this.setState({data:newData});
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        resource_ids: item.resource_ids,
        title: item.fields.find(x => x.id === 'title').target
      });
    })

    this.props.saveNavigation(this.props.editLanguage, usedItems)
  }

  onResaveAll = () => {
    const items = this.state.data;
    let usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        resource_ids: item.resource_ids,
        title: item.fields.find(x => x.id === 'title').target
      });
    })

    this.props.saveNavigation(this.props.editLanguage, usedItems)
  }

  onClearAll = () => {  
    this.setState({ showClearAllNotice: true });
  }
  clearAll = () => {
    let newItems = this.state.data.slice();
    let usedItems = [];
    newItems.forEach(item => {
      item.fields[0].target = '';
      item.progress = 0;
      usedItems.push({
        id: item.id,
        resource_ids: item.resource_ids,
        title: item.fields.find(x => x.id === 'title').target
      });
    })

    this.setState({data: newItems});
    this.props.saveNavigation(this.props.editLanguage, usedItems);
  }
  cancelClearAll = () => {
    this.setState({ showClearAllNotice: false });
  }

  onImport = (e) => {
    this.setState({forceOpenImportDialog: true}, () => {
      this.setState({forceOpenImportDialog: false});
    });   
    this.setState({ optionsMenuAnchorEl: null });
  }

  onExport = (e) => {
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });   
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  render() {
    const { t, classes } = this.props

    if(this.props.navigation.sync_navigation_pending === true || (this.props.navigation.error && this.props.navigation.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.navigation.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={this.props.navigation.sync_navigation_pending || this.props.navigation.save_navigation_pending}
          progress={this.props.navigation.progress}
          error={this.props.navigation.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.navigation.title')}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          onClearAll={this.onClearAll}
          onResaveAll={this.onResaveAll}
          shopPlan={this.props.shopPlan}
        />
      </Typography>

      <Divider className="divider-white" />
      <ItemsList 
        type="navigation"
        pagination={true}
        isPending={
          this.props.navigation.sync_navigation_pending ||
          this.props.navigation.save_navigation_pending ||
          this.props.navigation.export_navigation_pending ||
          this.props.navigation.import_navigation_pending
        }
        isSaving={
          this.props.navigation.save_navigation_pending
        }
        onSave={this.onSave}
        data={this.state.data}
        allowAutoTranslation={true}
        error={this.props.navigation.error}
        errorLockCollapse={this.state.errorLockCollapse}
        emptyMessage={
          <EmptyView 
            title={t('pages.navigation.noContent')}
            bottom={<Button href={`https://${this.props.shopUrl}/admin/menus`} target="_blank" variant="contained" color="secondary">{t('system.createOneHere')}</Button>}
          />
        }
      />
      <LoadingIndicator
        pending={this.props.navigation.sync_navigation_pending || this.props.navigation.save_navigation_pending || this.props.navigation.export_navigation_pending || this.props.navigation.import_navigation_pending}
        loadingType={this.props.navigation.save_navigation_pending ? 'saving' : 'loading'}
        progress={this.props.navigation.progress}
        error={this.props.navigation.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        //showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.navigation.imported_entries}
        export_po_download_url={this.props.navigation.export_po_download_url}
        export_csv_download_url={this.props.navigation.export_csv_download_url}
        forceOpenExportDialog={this.state.forceOpenExportDialog}
        forceOpenImportDialog={this.state.forceOpenImportDialog}
        exportFunc={(options) => { this.props.exportNavigation(this.props.editLanguage, options) }}
        importFunc={(formData, fileType, options) => { this.props.importNavigation(formData, fileType, this.props.editLanguage) }}
        syncFunc={() => { this.props.syncNavigation(this.props.editLanguage)}}
        clearFunc={this.props.clearExportUrls}
        pending={this.props.navigation.export_navigation_pending}
        scope={'navigation'}
        additionalFields={[]}
      />

      <DontAskAgainDialog
        context="ClearAllTranslations"
        open={this.state.showClearAllNotice}
        onClose={this.cancelClearAll}
        onCancel={this.cancelClearAll}
        onSubmit={this.clearAll}
        title="Really clear all translations?"
        content="This action will clear all translations and can't be un-done."
        initialChecked={false}
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ navigation, shop }) => ({
  navigation: navigation,
  editLanguage: shop.shop.edit_language,
  shopPlan: shop.shop.charge.name,
  shopUrl: shop.shop.url,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncNavigation,
      saveNavigation,
      exportNavigation,
      importNavigation,
      resetErrors,
      clearExportUrls
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Navigation))))