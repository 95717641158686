import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import CodeIcon from '@material-ui/icons/Code';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const ConditionalQuerySection = ({ languages, classes, t }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [copied, setCopied] = useState(false);

  const handleToggleBtn = () => {
    setCollapsed(!collapsed);
  }

  const getCode = () => {
    let codeStr = '';
    languages.forEach((lang, index) => {
      if(index === 0) {
        codeStr += `{% if request.locale.iso_code == '${lang.code}' %}\n    // ${lang.name.toUpperCase()} CODE HERE ...\n`;
      }
      if(index !== 0 && index !== languages.length-1) { 
        codeStr += `{% elsif request.locale.iso_code == '${lang.code}' %}\n    // ${lang.name.toUpperCase()} CODE HERE ...\n`;
      }
      if(index === languages.length-1) {
        codeStr += `{% else %}\n    // ${lang.name.toUpperCase()} CODE HERE ...\n{% endif %}`;
      }
    });
    return codeStr;
  }

  return(<Paper className={classes.infoBox} elevation={0}>
    <Typography variant="h6" className={classes.infoText} style={{ margin: '4px 0 0 0', fontSize: 16, position: 'relative' }}>
      <WbIncandescentOutlinedIcon style={{ fontSize: 24, margin: '0 8px 4px 0' }} /> 
      <Trans i18nKey={'pages.theme.developerHint'}></Trans>
      <Button onClick={handleToggleBtn} variant="contained" size="small" color="secondary" className={classes.toggleCode}>{collapsed ? t('pages.theme.showCode') : t('pages.theme.hideCode')} <CodeIcon size="small" style={{marginLeft: 4}}/></Button>
    </Typography>
    <Collapse in={!collapsed}>
      <CopyToClipboard text={getCode()} onCopy={() => { setCopied(true); setTimeout(() => { setCopied(false); }, 3000)}}>
        <div className={classes.codeContainer}>
          <Typography className={classes.codeText} component="span" fullwidth="true">{getCode()}</Typography>
          <Fade in={copied}>
            <strong style={{position: 'absolute', borderRadius: 4, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,.75)', alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: 16, fontWeight: 'bold'}}>{t('pages.theme.copied')}</strong>
          </Fade>
        </div>
      </CopyToClipboard>
    </Collapse>
  </Paper>)
}

const styles = theme => ({
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 16px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText: {
    color: '#5c6bc0',
    paddingRight: 135,
  },
  codeContainer: { 
    position: 'relative', 
    margin: '16px 0 4px 0', 
    cursor: 'pointer',
    padding: 8,
    backgroundColor: '#fff',
    border: 'solid 1px #ccc',
    borderRadius: 4
  },
  codeText: {
    fontSize: 12,
    whiteSpace: 'pre',
  },
  toggleCode: {
    position: 'absolute',
    top: -4,
    right: 0,
  }
});

export default withStyles(styles)(withTranslation()(ConditionalQuerySection));