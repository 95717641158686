import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import 'react-virtualized/styles.css'; // only needs to be imported once
import IconButton from '@material-ui/core/IconButton';
import RichTextEditor from '../components/RichTextEditor'
import ImageField from './ImageField'
import CollapsableFields from './CollapsableFields'
import CustomFields from './CustomFields'
import SeoFields from './SeoFields'
import { updateAutoTranslations } from '../modules/shop'
import Tooltip from '@material-ui/core/Tooltip';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation, withTranslation } from 'react-i18next';
import DontAskAgainDialog from './DontAskAgainDialog.js';
import JsonEditor from './JsonEditor';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { event } from 'jquery';
import SourceToTargetMenu from './SourceToTargetMenu';
import { cyrb53 } from '../helper';

const REACT_APP_ENABLE_AUTO_TRANSLATIONS = window.loadEnv("REACT_APP_ENABLE_AUTO_TRANSLATIONS", process.env.REACT_APP_ENABLE_AUTO_TRANSLATIONS);

class TranslationField extends React.Component {
  hasMounted = false;
  mouseIsOver = false;

  constructor(props) {
    super(props);

    this.state = {
      usedText: props.target,
      error: props.error,
      error_message: '',
      hadError: false,
    }
  }

  componentDidMount = () => {
    this.hasMounted = true;

    if(this.props.onLoaded) {
      setTimeout(() => {
        if(this.hasMounted) {
          this.props.onLoaded();
        }
      }, 100);      
    }
  }

  componentWillUnmount = () => {
    this.hasMounted = false;
  }

  componentWillReceiveProps(newProps) {
    const wasPending = this.props.isPending;
    const isPending = newProps.isPending;

    if(wasPending === true && isPending === false) {
      this.setState({
        //hadError: false,
      });
    }

    if(wasPending === true && isPending === false || newProps.target !== this.state.usedText) {
      this.setState({
        usedText:newProps.target,
      });
    }

    if(newProps.forceUpdate === false && this.props.forceUpdate === true) {
    //if(newProps.forceUpdate !== this.props.forceUpdate) {
      let data = {
        id: this.props.id,
        changed: this.props.target != newProps.value,
        initial: this.props.initial || '',
        target: newProps.target,
        parentField: newProps.parentField,
      }
      this.handleChange(null, data);
    }

    if(newProps.error !== this.props.error) {
      this.setState({
        error: newProps.error,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(cyrb53(this.props) !== cyrb53(prevProps)) {
      this.initErrors();
    }
  }

  handleChange = (event, data) => {
    let passThroughData = !event ? data : {
      id: this.props.id,
      changed: this.props.target != event.target.value,
      initial: this.props.target,
      target: event.target.value,
      error: false,
    };
    this.setState({
      usedText: !event ? data.target : event.target.value,
      //hadError: this.props.error ? true : false,
    });
    this.props.onChange(event, passThroughData);
  };

  selectField = (itemId, fieldId, parentField) => {
    if(parentField === undefined) {
      parentField = this.props.parentField;
    }
    if(parentField === 'json-editor') {
      return;
    }

    let hash = fieldId;

    if(parentField) {
      hash = parentField + ':' + hash;
      if(this.props.onSubfieldSelected) {
        this.props.onSubfieldSelected(itemId, fieldId, parentField);
      }
    } else if(this.props.onFieldSelected) {
      this.props.onFieldSelected(itemId, fieldId);
    }

    window.location.hash = hash;
  }

  handleRestore = (event) => {
    let restoreValue = '';
    //debugger;
    
    if(this.props.target != this.props.source) {
      const itemId = this.props.parentField;
      const fieldId = this.props.id;
      const subfieldsId = this.props.parentField;

      let discardKey = `${itemId}-${fieldId}`;
      if(subfieldsId) {
        discardKey = `${itemId}-${subfieldsId}-${fieldId}`;
      }

      if(window.restoreFieldsData[discardKey]) {
        restoreValue = window.restoreFieldsData[discardKey];
        delete window.restoreFieldsData[discardKey];
      }



      this.setState({
        //usedText: this.props.restore,
        usedText: restoreValue,
        //hadError: true,
      })

      this.props.onChange(null, {
        id: this.props.id,
        changed: false,
        initial: restoreValue,
        target: restoreValue,
        parentField: this.props.parentField,
      })
    }
  }

  hasErrors() {
    if (typeof this.props.errors !== 'undefined' && this.props.errors !== false) return true;
    return false;
  }

  initErrors() {
    const { t } = this.props;
    if(this.props.errors && this.props.errors.validation_errors) {
      let reason = [];
      Object.keys(this.props.errors.validation_errors).forEach(key => {
        switch(key) {
          case "error_handle_taken" : 
            reason.push(t('itemsList.errors.handleHasBeenTaken', { object_value: this.props.errors.object_value }));
            break;
          case "error_handle_adjusted" :
            reason.push(t('itemsList.errors.errorHandleAdjusted', { object_value: this.props.errors.object_value }));
            break;
          case "error_handle_taken_and_adjusted" :
            reason.push(t('itemsList.errors.handleHasBeenTakenAndAdjusted', { object_value: this.props.errors.object_value }));
            break;
        }
      })
      this.setState({
        error_message: reason.join(' - '),
      });
    } else if (this.state.error_message !== '') {
      this.setState({
        error_message: ''
      });
    }
  }

  render() {
    let root = this;

    const {
      loadingTranslations,
      loadingTranslationsError,
      //hadError,
    } = this.state;
    const { 
      t,
      classes, 
      id,
      itemId,
      multiline,
      fields,
      deletable,
      to,
      source,
      target,
      saveErrors,
      parentField
    } = this.props;

    if(this.props.isHidden) {
      return null
    }

    let usedLabel = ''
    if(id === 'seofields') {
      usedLabel = 'SEO Fields'
    } else if(id === 'variants') {
      usedLabel = 'Variants'
    } else if(id === 'option_names') {
      usedLabel = 'Option Names'
    } else if(id === 'metafields') {
      usedLabel = 'Metafields'
    } else if(id === 'variant_metafields') {
      usedLabel = 'Variant Metafields'
    } else if(id === 'images') {
      usedLabel = 'Images'
    } else if(id === 'customfields') {
      usedLabel = 'Custom'
    }

    const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;
    const fromLanguage = this.props.languages.languages.find(x => x.base == true);
    const toLanguage = this.props.languages.languages.find(x => x.id == this.props.shop.shop.edit_language);

    const rightPadding = deletable === true ? "38px": "0px";
    const rightArrowMargin = deletable === true ? "9px": "11px";
    const leftArrowMargin = deletable === true ? "-30px": "inherit";

    const getEditorVersion = () => {
      //return 'v2';
      if(this.props.shop.shop.langify_features && this.props.shop.shop.langify_features.find(x => x.name === 'editor_v2') && this.props.shop.shop.langify_features.find(x => x.name === 'editor_v2').is_enabled === true) {
        return 'v2';
      } else {
        return 'v1';
      }
    }

    let type = this.props.type;
    if(typeof source === 'string' && this.props.shop.shop.langify_features && this.props.shop.shop.langify_features.find(x => x.name === 'html_text_2_rich_text') && this.props.shop.shop.langify_features.find(x => x.name === 'html_text_2_rich_text').is_enabled === true) {
      const htmlContent = source.match(/^<(p|h1|h2|h3|h4|h5|div|span|ul|script).*>(.*)<\/(p|h1|h2|h3|h4|h5|div|span|ul|script)>$/gim);
      if(type === 'text' && htmlContent && htmlContent.length > 0) {
        type = 'richtext';
      }      
    }

    const open = Boolean(this.state.anchorEl);

    return (
      <React.Fragment>
      <React.Fragment>
        {(type !== 'subfields') &&
          <Grid container spacing={3} style={{paddingRight:rightPadding}} className={classNames('field-row', classes.root, `${type}`)}>
            {!fields && type != 'image' &&
              <SourceToTargetMenu
                data={this.props}
                from={this.props.from}
                to={this.props.to}
                onChange={this.props.onChange}
                parentField={this.props.parentField}
                deletable={deletable}
              />
            }
            {(type === 'text' || !type) &&
              <React.Fragment>
                {deletable === true &&
                  <div style={{ position: "absolute", right: "-4px" }}>
                    <Tooltip title="Delete" arrow>
                      <IconButton aria-label="Delete" onClick={() => { this.setState({ showDeleteNotice: true }); } }>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <DontAskAgainDialog
                      context="DeleteCustomContent"
                      open={this.state.showDeleteNotice}
                      onClose={() => { this.setState({ showDeleteNotice: false }); } }
                      onCancel={() => { this.setState({ showDeleteNotice: false }); } }
                      onSubmit={e => { this.setState({ showDeleteNotice: false }); this.props.onDeleteItem(this.props.id); } }
                      title={t('customContents.deleteNoticeTitle')}
                      content={t('customContents.deleteNoticeDescription')}
                      initialChecked={false} />

                  </div>}
                <Grid item xs={12} md={6} classes={{ item: classes.gridOdd }} onClick={() => this.selectField(itemId, id)}>
                  <TextField
                    label={(this.props.label && this.props.label.length >= 50) ? <Tooltip title={this.props.label} classes={{ arrow: classes.labelTooltipArrow, popper: classes.labelTooltip, tooltip: classes.noMaxWidth }} placement="bottom" arrow><span>{truncate(this.props.label, 50)}</span></Tooltip> : this.props.label}
                    disabled
                    multiline={this.props.multiline}
                    minRows={this.props.rows}
                    maxRows={this.props.rows ? this.props.rows : 1}
                    fullWidth
                    value={this.props.source ? this.props.source : ''}
                    margin="none"
                    variant="outlined"
                    InputLabelProps={{
                      style: { pointerEvents: "auto" },
                      shrink: true,
                      classes: {
                        root: classes.label
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classNames(classes.textField, classes.disabledField),
                        multiline: classes.textField,
                        input: classes.inputField,
                        inputMultiline: classes.inputField,
                      }
                    }}
                    inputProps={{
                      dir: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                    }}
                    className={classNames(classes.textField, classes.targetField)} />
                </Grid>
                <Grid item xs={12} md={6} classes={{ item: classes.gridEven }} onClick={() => this.selectField(itemId, id)}>
                  <TextField
                    multiline={this.props.multiline}
                    minRows={this.props.rows}
                    maxRows={this.props.rows ? this.props.rows : 1}
                    fullWidth
                    placeholder={id == 'handle' && !this.state.usedText ? this.props.source : null}
                    value={this.state.usedText ? this.state.usedText : ''}
                    onChange={this.handleChange}
                    margin="none"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        root: classes.textField,
                        multiline: classes.textField,
                        input: classes.inputField,
                        inputMultiline: classes.inputField,
                      }
                    }}
                    inputProps={{
                      maxLength: this.props.maxLength ? this.props.maxLength : null,
                      dir: (toLanguage && toLanguage.rtl) ? 'rtl' : 'ltr',
                    }}
                    className={classes.textField}
                    focused={id == this._getFieldIdByHash()}
                    error={this.props.error}
                    helperText={(this.props.error && window.restoreFieldsData[`${itemId}-${parentField && parentField + '-'}${id}`] !== this.state.usedText) ? 
                      <span onClick={this.handleRestore}
                        style={{cursor: 'pointer', position: 'absolute', bottom: -18, left: 0, zIndex: 1}}>
                        <ReplayRoundedIcon style={{marginTop: -2, fontSize: 14}} /> Restore value
                      </span> : null}
                  />
                  {(this.props.maxLength && this.state.usedText !== null) &&
                    <Typography variant="caption" className={classes.maxCharCount}>{`${this.state.usedText.length} of ${this.props.maxLength} characters used`}</Typography>
                  }
                </Grid>    

                {this.props.parentField !== 'json-editor' &&
                  <JsonEditor
                    source={this.props.source}
                    target={this.state.usedText ? this.state.usedText : ''}
                    id={this.props.id}
                    parentField={this.props.parentField}
                    isPending={this.props.isPending}
                    onChange={this.handleChange} 
                  />
                }
              </React.Fragment>
            }
            {type === 'richtext' &&
              <React.Fragment>
                <Grid item xs={12} md={6} classes={{item: classes.gridOdd}} onClick={() => this.selectField(itemId, id)} className={'ly-richtext-editor-source'}>
                  <RichTextEditor
                    id={`${itemId}-${id}-source`}
                    label={this.props.label}
                    version={getEditorVersion()}
                    config={getEditorVersion() === 'v2' ? {
                        editor: {
                          direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                          notranslate: true,
                        },
                        toolbar: false,
                        topbar: false,
                        plugins: ['readonly'],
                        buttons: {
                          addbar: [],
                          context: [],
                          topbar: [],
                        }
                      }
                    :
                      {
                        direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                        buttons: ['html'],
                        plugins: [],
                      }
                    }
                    value={this.props.source}
                    sourceData={this.props.source}
                    isPending={this.props.isPending} 
                    readOnly={true}
                    onLoaded={this.props.onLoaded ? this.props.onLoaded : null}
                    densed
                  />
                </Grid>             
                <Grid item xs={12} md={6} classes={{item: classes.gridEven}} onClick={() => this.selectField(itemId, id)} className={'ly-richtext-editor-target'}>
                  <RichTextEditor
                    id={`${itemId}-${id}-target`}
                    version={getEditorVersion()}
                    config={getEditorVersion() === 'v2' ? {
                        placeholder: t('itemsList.richtextPlaceholder'),
                        editor: {
                          direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                          notranslate: true,
                        },
                      }
                    :
                      {
                        placeholder: t('itemsList.richtextPlaceholder'),
                        direction: (toLanguage && toLanguage.rtl) ? 'rtl' : 'ltr',
                        //focus: false,
                      }
                    }
                    sourceData={this.props.source}
                    value={this.state.usedText}
                    isPending={this.props.isPending} 
                    onChange={this.handleChange}
                    onLoaded={this.props.onLoaded ? this.props.onLoaded : null}
                    focused={id == this._getFieldIdByHash()}
                    densed
                    error={this.state.error}
                    helperText={(this.state.error && this.props.restore !== this.state.usedText) ? 
                      <span onClick={this.handleRestore}
                        style={{cursor: 'pointer', position: 'absolute', bottom: -18, left: 0, zIndex: 1}}>
                        <ReplayRoundedIcon style={{marginTop: -2, fontSize: 14}} /> Restore value
                      </span> : null}
                  />
                  {(this.props.maxLength && this.state.usedText) &&
                    <Typography variant="caption" className={classes.maxCharCount}>{`${this.state.usedText.length} of ${this.props.maxLength} characters used`}</Typography>
                  }
                </Grid>
              </React.Fragment>
            } 
            {type === 'image' &&
              <ImageField 
                source={this.props.source}
                target={this.state.usedText ? this.state.usedText : ''}
                itemId={this.props.id}
                isPending={this.props.isPending}
                onChange={this.handleChange}
                disabled={this.props.shop.shop.charge.name === 'free'}
                locked={this.props.shop.shop.charge.name === 'free'}
              />
            } 
          </Grid>
        }
        {(type === 'subfields' && id !== 'seofields' && id !== 'customfields') &&
          <CollapsableFields 
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            forceOpen={this.props.forceOpen}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            emptyMessage={this.props.emptyMessage}
            from={this.props.from}
            to={this.props.to}
            disabled={(this.props.shop.shop.charge.name === 'free' && id === 'images')}
            locked={(this.props.shop.shop.charge.name === 'free' && id === 'images')}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />
        }
        {(type === 'subfields' && id === 'customfields') &&
          <CustomFields 
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            emptyMessage={this.props.emptyMessage}
            from={this.props.from}
            to={this.props.to}
            filterQuery={this.props.filterQuery}
            disabled={this.props.shop.shop.charge.name === 'free'}
            locked={this.props.shop.shop.charge.name === 'free'}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onAddItem={this.props.onAddItem}
            onDeleteItem={this.props.onDeleteItem}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />
        }
        {(type === 'subfields' && id === 'seofields') &&
          <SeoFields
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            from={this.props.from}
            to={this.props.to}
            rawData={this.props.rawData}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
            getFieldIdByHash={this._getFieldIdByHash}
            selectField={this.selectField}
            languages={this.props.languages}
            onLoaded={this.props.onLoaded}
          />
        }
        
        <Snackbar open={loadingTranslationsError} autoHideDuration={6000} onClose={() => this.setState({loadingTranslationsError: false})}>
          <MuiAlert onClose={() => this.setState({loadingTranslationsError: false})} severity="error" elevation={6} variant="filled">
            {t('system.whoopsMessage')}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>

        {this.hasErrors() === true  &&
          <Grid container direction="row-reverse">
            <Grid item xs={12} md={6} classes={{ item: classes.gridEven }} style={{ padding: "0 12px" }}>
              <Typography variant="caption" className={classes.informationLine}>{this.state.error_message}</Typography>
            </Grid>
          </Grid>
        }
      </React.Fragment>
    );
  }

  _getFieldIdByHash = () => {
    let hash = window.location.hash.substring(1);
    if(hash.indexOf(':') > -1) {
      hash = hash.split(':')[1];
    }
    return hash;
  }
}
TranslationField.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  languages: languages,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAutoTranslations
    },
    dispatch
  )

const styles = theme => ({
  root: {
    //backgroundColor: '#ccc !important',
    //paddingLeft: 16,
    position: 'relative !important',
    margin: '0 -12px !important',
    '&.image': {
      display: 'inline-block',
      width: 'auto',
      margin: '0 !important',
      paddingRight: 24,
    }
  },
  textField: {
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
  },
  inputField: {
    padding: '12px 14px !important',
    fontSize: '14px !important',
    //boxSizing: 'border-box',
    height: 'auto !important',
  },
  targetField: {
    borderStyle: 'dashed !important',
  },
  disabledField: {
    backgroundColor: '#fafafa',
  },
  gridItem: {
    margin: '0 -12px !important',
  },
  gridOdd: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0 !important',
      '&>div': {
        marginBottom: 0,
        '& fieldset': {
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginBottom: '0 !important',
        '& .redactor-box': {
          borderBottom: 'none',
          borderBottomLeftRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
        }
      },
    },
  },
  gridEven: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      '&>div': {
        marginTop: -1,
        '& fieldset': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginTop: -1,
        '& .redactor-box': {
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '0 !important',
        }
      },
    },
  },
  arrow: {
    position: 'absolute',
    cursor: 'pointer',
    left: '50%',
    top: '50%',
    margin: '-15px 0 0 -11px',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& svg': {
      //fontSize: 22,
    }
  },
  imageContainer: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
  },
  noTranslationFieldsMsg: {
    textAlign: 'center',
    padding: '50px 0'
  },
  infoField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f1f8e9',
    color: '#33691e',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#33691e',
      borderColor: '#33691e',
    }
  },
  warningField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#feefb3',
    color: '#9f6000',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#9f6000',
      borderColor: '#9f6000',
    }
  },
  autoTranslationProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  closeIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    right: 4,
    top: 10
  },
  label: {
    backgroundColor: '#fafafa',
  },
  errorLine: {
    /*position: 'absolute',*/
    left: 12,
    bottom: -8,
    color: 'red',
  },
  informationLine: {
    left: 12,
    bottom: -8,
    color: '#606060',
  },
  maxCharCount: {
    position: 'absolute',
    right: 12,
    bottom: -8,
    color: 'rgb(153, 153, 153)',
  },
  noMaxWidth: {
    maxWidth: 'none',
    marginLeft: 50,
  },
  labelTooltip: {
    marginLeft: 50,
  },
  labelTooltipArrow: {
    marginLeft: 50,
  },
});
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TranslationField))));