import React, { Component } from 'react';
import $ from 'jquery';
import API from '../api/api';
import ErrorBoundary from './ErrorBoundary';

window.$ = window.jQuery = $;

class Redactor extends React.Component {

  componentDidMount() {
    let root = this;

    const {
      id,
      data,
      defaultValue,
      readOnly,
      onStarted,
      onChange,
      onFocus,
      onBlur,
      onKeydown,
      onKeyup,
      onSourceOpened,
      onSourceClosed,
      onSourceChanged,
      onEditorDestroyed
    } = this.props

    const defaultConfig = {
      autoparse: false,
      air: false,
      toolbarExternal: `#${this.props.id}-toolbar`,
      removeScript: false,
      removeComments: false,
      pasteClean: false, 
      pastePlainText: true,
      grammarly: false,
      imageFigure: false,
      replaceTags: false,
      cleanOnEnter: false,
      cleanInlineOnEnter: false,
      plugins: ['alignment', 'fontcolor', 'fontsize', 'table', 'imagemanager', 'video', 'pastemanager'],
      buttons: ['html', 'format', 'bold', 'italic', 'underline', 'fontsize', 'lists', 'alignment', 'link', 'table', 'image', 'video', 'undo', 'redo'],
      imageUpload: function(data, files, e, upload) {
        return new Promise(function(resolve, reject) {
          var formData = new FormData();        
          var file = files[0];
          formData.append('file', file);

          var xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          xhr.onreadystatechange = function(e) {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(xhr.response);
              } else {
                reject(xhr.status);
              }
            }
          }
          xhr.open('POST', API.createURL('deprecated_image_upload'), true);
          xhr.send(formData);
        }).then(function(response) {
          // success
          if(response.error) {
            alert(response);
          } else {
            upload.complete(response);
          }
        }).catch(function(response) {
          // fail
          upload.complete(response);
        });
      },
      imageManagerJson: API.createURL('deprecated_get_images/' + `${this.props.id}`.split('-')[0]),
      imageResizable: true,
      imagePosition: true,
      toolbarFixed: true,
      toolbarFixedTopOffset: 100,
      minHeight: '300px',
      maxHeight: '300px',
      callbacks: {
        started: this.handleStarted,
        focus: onFocus ? onFocus : null,
        blur: onBlur ? onBlur : null,
        changed: this.handleChange,
        keydown: onKeydown ? onKeydown : null,
        keyup: onKeyup ? onKeyup : null,
        source: {
          opened: onSourceOpened ? onSourceOpened : null,
          closed: onSourceClosed ? onSourceClosed : null,
          changed: this.handleSourceChanged,
        },
        image: {
          uploadError: function(response) {
            alert(response.message);
          }
        },
        upload: {
          error: function(response) {
            alert(response.message);
          }
        },
        noeditor: {
          destroyed: function() {
            if(onEditorDestroyed) {
              onEditorDestroyed();
            }
          }
        },
        modal: {
          open: function(modal, form, e) {
            console.log(modal, form, e);
            if(modal === 'image') {
              root.props.onUseImageUpload();
            }
          }
        }
      },
      initCallback: function() {
        // Change tag of the Bold block module to <b> like it is in Shopify
        var button = this.toolbar.getButton('bold');
        button.setApi('module.block.format', { tag: 'b' });
      }
    }
    this.config = {...defaultConfig, ...this.props.config}

    this.$el = $('#'+id);
    this.$el.redactor(this.config);
  }

  componentWillReceiveProps(newProps) {    
    if (newProps.defaultValue !== this.props.defaultValue) {
      if(this.$el && this.$el.redactor('source.getCode') !== newProps.defaultValue) {
        this.$el.redactor('source.setCode', newProps.defaultValue);
        this.$el.redactor('module.source.sync');
      }
    }
    // Read-only
    if(this.props.readOnly) {
      if(this.$el) {
        this.$el.redactor('enableReadOnly');
      }
    }
  }

  componentWillUnmount() {
    if(this.$el) {
      this.$el.redactor('destroy');
    }
  }

  handleChange = (data) => {
    // Own stuff here...
    if(this.props.onChange && data !== this.props.defaultValue) {
      this.props.onChange(data);
    }
  }

  handleSourceChanged = (data) => {
    // Own stuff here...
    if(this.props.onSourceChanged) {
      this.props.onSourceChanged(data);
    }
  }

  handleStarted = () => {
    // Own stuff here...
    if(this.props.onStarted) {
      this.props.onStarted();
    }
  }


  render() {
    return (
      <ErrorBoundary>    
        <div id={`${this.props.id}-toolbar`} className={'toolbar-wrapper'}></div>
        <textarea id={`${this.props.id}`} 
          ref={el => this.el = el}
          defaultValue={this.props.defaultValue}
        />  
      </ErrorBoundary>
    )
  }
}

export default Redactor;