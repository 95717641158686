import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
  Button, 
  Divider,
  Hidden, 
  IconButton, 
  Tooltip, 
  Toolbar, 
  Typography, 
  CircularProgress, 
  Paper, 
  Card, 
  CardContent, 
  CardMedia,
  CardActionArea,
  Table, 
  TableRow, 
  TableBody, 
  TableCell, 
  TableContainer, 
  Grid, 
  Tabs,
  Tab,
  Fade,
  FormControl, 
  FormControlLabel, 
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import OfflineBoltRoundedIcon from '@material-ui/icons/OfflineBoltRounded';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import OfflinePinRoundedIcon from '@material-ui/icons/OfflinePinRounded';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import classNames from 'classnames';

import IOSSwitch from "./IOSSwitch";
import { ManuallyAddLangifyCode } from './ThemeAppExtension.js';
import { getPlans, changePlan } from "../modules/plans";
import API from '../api/api';
import { Trans, useTranslation } from 'react-i18next';
import { PieChart, Pie } from 'recharts';
import { formatLargeNumber } from '../helper.js';




const _plans = ['free', 'basic', 'growth', 'premium'];




const useStyles = makeStyles((theme) => ({
  h4: {
    color: '#000000',
  },
  customTooltip: {
    backgroundColor: '#ffaf23',
    borderColor: '#ffaf23',
    color: 'white',
    border: '1px solid black',
    padding: theme.spacing(1),
    fontSize: '1rem',
    '& *': {
      textShadow: '0 0 2px rgba(0,0,0,0.5)',
    }
  },
  tooltip: {
    backgroundColor: '#ffaf23',
    boxShadow: theme.shadows[5],
  },
  tooltipButton: {
    color: '#ffffff',
    borderColor: '#ffffff',
    marginTop: 8,
  },
  arrow: {
    fontSize: 24,
    color: '#ffaf23',
  },
  toolbar: {
    marginBottom: 24,
    backgroundColor: '#cecece',
    color: 'initial',
    cursor: 'pointer',
    opacity: .75,
    borderRadius: 4,
    transition: 'opacity .5s ease, background-color .5s ease, color .5s ease, filter .5s ease',
    filter: 'brightness(1)',
    '& h3, & .MuiButton-root': {
      color: '#000000',
      fontWeight: '400 !important',
      borderColor: '#000000'
    },
    '&:hover, &.hover': {
      backgroundColor: '#ffaf23',
      color: '#fff',
      opacity: 1,
      filter: 'brightness(0.5)'
    },
    '&:hover h3, &.hover h3, &.hover .MuiButton-root': {
      color: 'white',
      borderColor: '#ffffff'
      //textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)'
    },
  },
  updatePlanBtn: {
    position: 'absolute',
    right: 16,
  },
  iconWrapper: {
    position: 'relative',
    marginRight: 12,
    fontSize: '2rem'
  },
  icon: {
    marginRight: theme.spacing(2),
    //opacity: .5,
    cursor: 'pointer'
  },
  iconSVG: {
    color: '#ffaf23',
    fontSize: '2rem',
  },
  startPos: {
    position: 'absolute',
    zIndex: 100,
    left: 0,
  },
  endPos: {
    position: 'absolute',
    zIndex: 100,
    right: 0,
  },
  overlayed: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'right',
    zIndex: 1
  },
  chartContainer: {
    marginLeft: -100,
  },

  radarChart: {
    padding: '10px 0',
    '& text': {
      fontSize: '.8rem',
    },
    '& .recharts-surface': {
      height: '230px !important',
      overflow: 'initial'
    }
  },


  autoTransText: {
    color: '#999 !important',
    fontSize: '1.75rem',
    fontWeight: '400 !important',
  },


  media: {
    width: '100%',
    height: 200,
    backgroundColor: '#fff',
  },

  card: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#cecece',
    opacity: 0.75,
    position: 'relative',
    padding: '16px !important',
    transition: 'opacity .5s ease, background-color .5s ease, color .5s ease, filter .5s ease',
    filter: 'brightness(1)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
    '&:hover, &.hover': {
      backgroundColor: '#ffaf23',
      color: '#fff',
      opacity: 1,
      filter: 'brightness(0.5)',
      '& *': {
        color: '#ffffff !important'
      }
    },
  },
  statistics: {
    padding: '16px 0 0 0 !important',
  },
  fluidContent: {
    padding: '0 !important',
  },
  cardHeadline: {
    margin: '.5rem 0',
    fontWeight: 400,
    color: '#000 !important', // '#1a237e !important',
  },
  cardHeadline2: {
    fontSize: '1.8rem',
    fontWeight: 400,
    color: '#000 !important',
  },
  cardBody: {
    margin: '20px 0 0 0',
    color: '#999'
  },
  cardBtn: {
    marginTop: '.5rem 0',
    color: '#fff',
  },
  progressNumContent: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    textAlign: 'center',
    width: 300,
  },
  progressNum: {
    color: '#000000 !important',
    fontWeight: '300 !important',
  },
  progressNumText: {
    color: '#999 !important',
    fontWeight: '400 !important',
  },
}));



export const CustomTooltip = ({ title, features, neededPlan, children, isBlock, placement, overlayed, onClose }) => {
  const { t } = useTranslation();  // i18n hook
  const classes = useStyles();
  const currentPlan = useSelector((state) => state.shop.shop.charge.name);

  let theOffset = '0, 0';
  switch(placement) {
    case 'left': theOffset = '0, -100'; break;
    case 'right': theOffset = '0, 100'; break;
    case 'top': theOffset = '0, -25'; break;
    case 'bottom': theOffset = '0, -25'; break;
  }

  let isDisabled = true;
  const _checkDisabled = () => {
    const neededPlanIndex = _plans.findIndex(x => x === neededPlan);
    let disabledPlans = _plans.slice(neededPlanIndex-1);
    console.log(disabledPlans, disabledPlans.includes(currentPlan))
    return true;
    return disabledPlans.includes(currentPlan);
  }
  
  const handleClose = (e) => {
    if(onClose) onClose(e);
  }
  
  const renderTooltip = () => {
    return <Tooltip
      //disabled={_checkDisabled()}
      title={//_checkDisabled() ? null :
        <div className={classNames(classes.customTooltip)}>
          <Typography variant="h6">
            <strong>
              {neededPlan === 'basic' && <OfflineBoltRoundedIcon className={classes.iconWrapper} />}
              {neededPlan === 'growth' && <StarsRoundedIcon className={classes.iconWrapper} />}
              {neededPlan === 'premium' && <VerifiedUserIcon className={classes.iconWrapper} />}
              {title}
            </strong>
          </Typography>
          {features &&
            <>
              <Typography variant="body1">
                {neededPlan === 'premium' && t('plans.customTooltip.upgradeToPremium')}
                {neededPlan && neededPlan !== 'premium' && t('plans.customTooltip.upgradeToSpecificPlan', { plan: neededPlan })}
                {!neededPlan && t('plans.customTooltip.upgradeToNextPlan')}
              </Typography>
              <ul style={{paddingLeft: 16}}>
                {features.map((feature) => {
                  return <Typography component={'li'} key={`featurelist-${feature}`} variant="body1">{feature}</Typography>
                })}
              </ul>
            </>
          }
          {!features &&  
            <Typography variant="body1">{t('plans.customTooltip.updateToNextPlan')}</Typography>
          }
          <Typography>
            <Button className={classes.tooltipButton} component={Link} to="/profile/plans" variant="outlined" size="small">
              {t('plans.customTooltip.updatePlanButton')}
            </Button>
          </Typography>
        </div>
      }
      PopperProps={overlayed ? 
        {
          modifiers: {
            offset: {
              enabled: true,
              offset: theOffset,
            },
          },
        }
      :
        null
      }
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      onAbort={handleClose}
      onClose={handleClose}
      arrow
      placement={placement ? placement : 'top'}
      interactive
    >
      {isBlock ? 
        <div style={{ display: isBlock ? 'block' : 'inline-block' }}>
          {children}
        </div>
      :
        children
      }
    </Tooltip>
  }

  return <React.Fragment>
    {isBlock ? 
      <div style={{ display: isBlock ? 'block' : 'inline-block' }}>
        {renderTooltip()}
      </div>
    :
      renderTooltip()
    }
  </React.Fragment>
};


export const MenuItemDummy = ({}) => {
  const classes = useStyles();

  useEffect(() => {
      
  }, []);

  return <>

  </>
}


export const UpgradePlanHint = ({features, position, placement, top, neededPlan, isBlock, isOverlay}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
      
  }, []);

  return <>
    <CustomTooltip 
      title="Plan update needed"
      features={features}
      placement={placement}
      style={{position: 'relative'}}
      neededPlan={neededPlan}
      isBlock={isBlock}
    >
      <div className={
          classNames(
            classes.icon, 
            position === 'end' ? classes.endPos : classes.startPos,
            isOverlay ? classes.overlayed : null
          )
        } 
        style={{
          top: top ? top : 'auto'
        }}>

        <LockOutlinedIcon style={{fontSize: '1.2rem', opacity: .75}} />

        {neededPlan === 'basic' && <OfflineBoltRoundedIcon className={classes.iconSVG} />}
        {neededPlan === 'growth' && <StarsRoundedIcon className={classes.iconSVG} />}
        {neededPlan === 'premium' && <VerifiedUserIcon className={classes.iconSVG} />}   
      </div>
    </CustomTooltip>
  </>
}


export const AutoTranslationsDummy = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const availableWords = useSelector((state) => state.shop.shop.auto_translations.available_words);
  const totalUsedWords = useSelector((state) => state.shop.shop.auto_translations.total_used_words);
  const wordsLeft = useSelector((state) => state.shop.shop.auto_translations.availableWords);

  const getFontSize = (str) => {
    let res = 2.75;
    if(str.length === 7) res = 2.5;
    else if(str.length === 8) res = 2.25;
    else if(str.length === 9) res = 2;
    else if(str.length > 9) res = 1.75;
    return res;
  }

  return <Grid item xs={12} md={8}>
    <CustomTooltip 
      title={t('plans.autoTranslationsDummy.tooltipTitle', 'Plan update needed')}
      features={[
        t('plans.autoTranslationsDummy.feature1'),
        t('plans.autoTranslationsDummy.feature2')
      ]}
      placement={'left'}
      neededPlan={'basic'}
      isBlock
      overlayed
      onClose={() => setIsHovered(false)}
    >
      <Card id="autoTranslationTile" 
        className={classes.card} 
        onMouseEnter={() => setIsHovered(true)}
        //onMouseOut={() => setIsHovered(false)}
      >
        <CardContent className={classNames(classes.cardContent, classes.fluidContent, isHovered && 'hover')}>
          <Typography variant="subtitle1" style={{marginLeft: 24, position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
            {t('autoTranslationsTile.title')}
          </Typography>
          <Divider style={{marginTop: 16, marginBottom: 0}} />  
          <div style={{display: 'flex', 
            //flexDirection: 'row-reverse', 
            position: 'relative', minHeight: 250}}>
              <div style={{minWidth: 300, minHeight: 250, position: 'relative'}}>
                <PieChart width={300} height={250} style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
                  <Pie data={[{"name": "Translated Fields", "value": 2140 }]} endAngle={300} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={96} outerRadius={100} fill="#000000" />
                </PieChart>
            <Fade in={true}>
            <div className={classes.progressNumContent}>                          
              <Typography variant="h2" classes={{root: classes.progressNum}}
                style={{
                  fontSize: `${getFontSize(formatLargeNumber(availableWords ? availableWords : 10000))}rem`
                }}>
                {formatLargeNumber(availableWords ? availableWords : 10000)}</Typography>
              <Typography variant="h5">{t('autoTranslationsTile.wordsLeft')}</Typography>
            </div>            
          </Fade> 
        </div>
          
        <div style={{textAlign: 'center', padding: '16px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <Typography variant="h5" className={classes.h4} style={{margin: '0 0 12px 0'}}>
            <TranslateOutlined style={{marginRight: 12, fontSize: 35, verticalAlign: 'top' }} />
            {t('autoTranslationsTileDummy.title')}
          </Typography>
          <Divider variant="middle" />
          <Typography variant="subtitle1" style={{marginBottom: 24, color: '#999', fontSize: '1.4rem', fontWeight:400, lineHeight: '1.4rem'}}>
            <small>
              <Trans i18nKey='autoTranslationsTileDummy.usedWordsInfo' values={{availableWords: formatLargeNumber(availableWords ? availableWords : "10000"), totalUsedWords: formatLargeNumber(totalUsedWords ? totalUsedWords : "0") }}>
                {{availableWords}}
                {{totalUsedWords}}
              </Trans>
            </small>
          </Typography>
          </div>   
        </div>
      </CardContent>
    </Card>
  </CustomTooltip>
</Grid>
}


export const SwitcherConfiguratorDummy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper elevation={1}>
      <CustomTooltip 
        title={t('plans.switcherConfiguratorDummy.tooltipTitle', 'Plan update needed')}
        features={[
          t('plans.switcherConfiguratorDummy.feature1', 'Language, country and currency switchers'),
          t('plans.switcherConfiguratorDummy.feature2', 'Different switcher templates'),
          t('plans.switcherConfiguratorDummy.feature3', 'Automatic language detection'),
          t('plans.switcherConfiguratorDummy.feature4', 'Language recommendation dialog')
        ]}
        placement='bottom'
        isBlock
        overlayed
        neededPlan='basic'
        onClose={() => setIsHovered(false)}
      >
        <Toolbar 
          className={classNames(classes.toolbar, isHovered && 'hover')}
          onMouseEnter={() => setIsHovered(true)}
        >
          <h3>
            <LockOutlinedIcon className={classes.icon} /> {t('plans.switcherConfiguratorDummy.header', 'Switcher Configurator')}
          </h3>
          <Button 
            component={Link} 
            to="/profile/plans" 
            variant="outlined" 
            className={classes.updatePlanBtn}
          >
            {t('plans.switcherConfiguratorDummy.updatePlanButton', 'Update Plan')}
          </Button>
        </Toolbar>
      </CustomTooltip>
    </Paper>
  );
};


export const ThemeSettingsDummy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const foundCodeInTheme = useSelector((state) => state.themeAppExtension.langify_app_embed_code_in_theme);

  return (
    <Paper elevation={1}>
      <CustomTooltip 
        title={t('plans.themeSettingsDummy.tooltipTitle', 'Plan update needed')}
        features={[
          t('plans.themeSettingsDummy.feature1', 'Custom Content settings'),
          t('plans.themeSettingsDummy.feature2', 'Link-Correction')
        ]}
        placement='bottom'
        isBlock
        overlayed
        neededPlan='basic'
        onClose={() => setIsHovered(false)}
      >
        <Toolbar 
          className={classNames(classes.toolbar, isHovered && 'hover')}
          onMouseEnter={() => setIsHovered(true)}
        >
          <h3>
            <LockOutlinedIcon className={classes.icon} /> {t('plans.themeSettingsDummy.header', 'Theme settings')}
          </h3>
          <Button 
            component={Link} 
            to="/profile/plans" 
            variant="outlined" 
            className={classes.updatePlanBtn}
          >
            {t('plans.themeSettingsDummy.updatePlanButton', 'Update Plan')}
          </Button>
        </Toolbar>
      </CustomTooltip>
      <ManuallyAddLangifyCode 
        publish={false}
        showAlways={true}
        showInline={true}
        hideHeadline={true}
        hideDescription={true}
        forceHide={!foundCodeInTheme}
        style={{marginTop: -24, paddingLeft: 8}}
      />
    </Paper>
  );
};





/** ---------------------------------------------------------------------------------------------
 * PLAN SELECT COMPONENT
 * 
 * --------------------------------------------------------------------------------------------- */
const planSelectStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        margin: theme.spacing(1),
        width: `calc(20% - ${theme.spacing(2)}px)`,
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& > *': {
        margin: theme.spacing(1),
        width: `calc(25% - ${theme.spacing(2)}px)`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        margin: theme.spacing(1),
        width: `calc(100% - ${theme.spacing(2)}px)`,
      },
    },
  },
  gridItem: {
    position: 'relative',
    margin: theme.spacing(1),
    width: `calc(20% - ${theme.spacing(2)}px)`,
    '&.first': {
      marginLeft: '20%',
      backgroundColor: '#fff',
      borderRadius: 8,
      '& > .inner': {
        width: '100%',
        height: '100%',
        opacity: .75,
        '& > *': {
          width: '100%',
          height: '100%',
          //opacity: .5,
        }
      }
    },
  },
  card: {
    //minWidth: 275,
    //width: '25%'
    //marginBottom: 24,
    '&.current': {
      border: `solid 4px ${theme.palette.custom.main}`,
      borderRadius: 8,
      marginTop: -4,
      '& .MuiCardMedia-root': {
        filter: 'hue-rotate(160deg) brightness(1.75)',
      }
    },
    '&.best-deal': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }
  },
  cardContent: {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection:  'column',
  },
  cardHead: {
    textAlign: 'center',
  },
  media: {
    width: 150,
    height: 150,
    margin: 'auto'
  },
  fixedCard: {
    position: 'absolute',
    left: 0,
    top: 24,
    bottom: 16,
    margin: '8px 0',
    width: `calc(25% - ${theme.spacing(1)}px)`,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(20% - 16px)',
      left: 8,
    },
  },
  cardsContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'visible',
    position: 'relative',
    paddingTop: 24,
    '& > .innerContainer': {
      width: '125%',
      left: '25%',
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 16
    },
    [theme.breakpoints.up('lg')]: {
      '& > .innerContainer': {
        width: '100%',
        left: '20%',
      },
    },
  },
  bestDeal: {
    position: 'absolute',
    top: -32,
    left: 0,
    width: '100%',
    fontWeight: 500,
    backgroundColor: '#ffab00',
    color: '#ffffff',
    padding: 6,
    textAlign: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  title: {
    textAlign: 'center',
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    marginTop: theme.spacing(2),
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.custom.main,
      borderColor: theme.palette.custom.main,
    },
    '&.green:hover': {
      backgroundColor: 'green',
      borderColor: 'darkgreen',
    },
    '&.red:hover': {
      backgroundColor: 'red',
      borderColor: 'darkred',
    }
  },
  tableContainer: {
    marginLeft: -16,
    marginRight: -16,
    width: 'auto',
  },
  table: {
    marginBottom: 16,
    '&.featureTable': {
      '& th': {
        textAlign: 'right'
      },
      '& th.featureLabel': {
        textAlign: 'left'
      }
    },
    '& .featurelist': {
      marginTop: 8
    }
  },
  checkIcon: {
    margin: -3,
    color: 'green'
  },
  noCheckIcon: {
    margin: -3,
    color: 'red'
  },
  alternateRow: {
    backgroundColor: theme.palette.action.hover,
  },

  singleline: {
    whiteSpace: 'nowrap',
  },

  anualSwitch: {
    display: 'flex',
    flexDirection: 'row',
    verticalAlign: 'top',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'nowrap',
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  loadingSpinnerContainer: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    paddingTop: '50%',
    paddingLeft: '50%',
    position: 'absolute',
    zIndex: 10,
    background: 'transparent'
  },
  mobileTabs: {
    background: '#fff',
    borderRadius: 4,
    '& .MuiTab-root': {
      minWidth: 'auto'
    }
  },
  downgradeButton: {
    color: '#fff',
    backgroundColor: 'red',
    borderColor: 'darkred', 
    '&:hover': {
      backgroundColor: 'darkred'
    }
  }
}
));


const RedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'red',
    color: 'white',
    fontSize: 14,
  },
  arrow: {
    color: 'red',
  },
}))(({ classes, title, disabled, children, ...props }) => {
  // Wenn disabled true ist, returne nur die children ohne Tooltip
  if (disabled) {
    return children;
  }

  // Andernfalls returne die children innerhalb des Tooltips
  return (
    <Tooltip title={title} classes={classes} {...props}>
      {children}
    </Tooltip>
  );
});;

export const PlanSelect = () => {
  const classes = planSelectStyles();
  const { t } = useTranslation();
  const plans = useSelector((state) => state.plans.plans);
  const featureList = useSelector((state) => state.plans.featureList);
  const shopPlan = useSelector((state) => state.shop.shop.charge.name);
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anualPayment, setAnualPayment] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [showFullList, setShowFullList] = useState(false);
  const [showDowngradeWarning, setShowDowngradeWarning] = useState(false);
  const [loosingFeatures, setLoosingFeatures] = useState([]);
  const [newPlanId, setNewPlanId] = useState(null);

  const scrollContainer = useRef(null);
  const innerContainer = useRef(null);

  const _features = {
    'free': [], 
    'basic': [
      t('plans.loosingFeatures.automaticTranslations'), 
      t('plans.loosingFeatures.thirdPartyAppTranslation'), 
      t('plans.loosingFeatures.switcherConfigurator'), 
      t('plans.loosingFeatures.languageDetectionRecommendation'), 
      t('plans.loosingFeatures.exportImportFeature'), 
      t('plans.loosingFeatures.translationLanguagesRestriction')
    ], 
    'growth': [
      t('plans.loosingFeatures.bulkTranslation')
    ], 
    'premium': [
      t('plans.loosingFeatures.prioritySupport')
    ]
  };


  // Funktion zur Höhenanpassung
  const adjustCardHeadHeights = () => {
    const cardHeads = document.querySelectorAll('.card-head');

    let maxHeight = 0;

    // Finde die maximale Höhe
    cardHeads.forEach(cardHead => {
      const height = cardHead.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    // Setze die maximale Höhe für alle cardHead-Elemente
    cardHeads.forEach(cardHead => {
      cardHead.style.height = maxHeight + 'px';
    });
  };
  
  useEffect(() => {

    // Höhenanpassung nach dem Rendern
    adjustCardHeadHeights();

    // Optional: Hinzufügen eines Event-Listeners, um die Höhe bei einem Fenster-Resize anzupassen
    window.addEventListener('resize', adjustCardHeadHeights);

    // Cleanup-Funktion
    return () => {
      window.removeEventListener('resize', adjustCardHeadHeights);
    };
  }, []);

  useEffect(() => {
    if (featureList.length === 0) {
      dispatch(getPlans(() => {
        setLoaded(true);
        setTimeout(() => { 
          adjustCardHeadHeights();
        }, 100);
      }));
    } else {
      setLoaded(true);
    }

    setTimeout(() => {
      if (innerContainer?.current) {
        scrollContainer.current.style.overflow = 'hidden';
        scrollContainer.current.scroll({ left: (scrollContainer.current.clientWidth * 0.5) });
        setTimeout(() => {
          if (scrollContainer?.current?.style) {
            scrollContainer.current.style.overflow = 'auto';
          }
        }, 1000);
      }
    }, 0);
  }, [dispatch, featureList.length]);

  const handleShowFullFeatureList = () => setShowFullList(true);
  const handleHideFullFeatureList = () => setShowFullList(false);
  const toggleAnualPayment = () => setAnualPayment(!anualPayment);

  const handleChangePlan = (newPlan) => {
    const newPlanIndex = _plans.findIndex(x => x === newPlan.toLowerCase());
    const oldPlanIndex = _plans.findIndex(x => x === shopPlan.toLowerCase());
    const isDowngrade = (newPlanIndex < oldPlanIndex);

    if (isDowngrade) {
      let newLoosingFeatures = [];
      for (let i = newPlanIndex + 1; i <= oldPlanIndex; i++) {
        newLoosingFeatures = [...newLoosingFeatures, ..._features[_plans[i]]];
      }
      setLoosingFeatures(newLoosingFeatures);
      setShowDowngradeWarning(true);
      setNewPlanId(newPlan);
    } else {
      setIsLoading(true);
      dispatch(changePlan(newPlan));
    }
  }

  const handleDowngradeConfirmed = () => {
    setIsLoading(true);
    dispatch(changePlan(newPlanId));
    setShowDowngradeWarning(false);
    setLoosingFeatures([]);
  }

  const handleChangeTab = (e, index) => setActiveTab(index);

  const _getButtonColor = (plan) => {
    if (plan.current) return 'yellow';
    if (plan.downgradable) return 'red';
    if (plan.upgradeable) return 'green';
    return 'default';
  }

  const renderLeftTableCard = () => {
    if (featureList.length === 0) return null;
    const planFeatures = showFullList ? featureList['Free'] : featureList['Free'].filter(x => x.tease);
    return (
      <Card className={classNames(classes.card, classes.fixedCard)} elevation={8}>
        <CardActionArea>
          <CardContent>
            <div className={classNames(classes.cardHead, 'card-head')} style={{ marginBottom: 8 }}>
              <CardMedia
                //component="img"
                className={classes.media}
                image={`/files/images/price.webp`}
                onLoad={adjustCardHeadHeights()}
              />
              <Typography className={classes.title} style={{ marginBottom: 22 }} color="primary" variant="h6" gutterBottom>
                <Trans i18nKey={'plans.planSelect.forEveryone'}>PLANS FOR<br/>EVERYONE</Trans>
              </Typography>
              {anualPayment && (
                <Typography className={classes.discount} color="textSecondary" variant="h5" gutterBottom>
                  {t('plans.planSelect.discount', { discount: plan.anualDiscount })}
                </Typography>
              )}
            </div>
            <TableContainer className={classes.tableContainer} component="div">
              <Table className={classes.table} aria-label="featurelist table">
                <TableBody>
                  {planFeatures.map((feature, index) => (
                    <TableRow
                      key={`feature-label-${feature.key}`}
                      className={index % 2 === 0 ? classes.alternateRow : ''}
                    >
                      <TableCell className={classes.singleline} component="th" scope="row" size="small">
                        {t(`plans.features.${feature.key}`)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  const renderCard = (plan, showFeatureLabel = false) => {
    if (!plan) return null;
    const planFeatures = showFullList ? featureList[plan.name] : featureList[plan.name].filter(x => x.tease);

    let days = 0; let hours = 0; let minutes = 0;
    if(plan.downgradable && plan.downgradable.nextDowngrade) {
      const now = new Date();
      const future = new Date(plan.downgradable.nextDowngrade);
      const timeDiff = future - now;
      days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));      
    }

    return (
      <RedTooltip 
        disabled={!plan.downgradable?.nextDowngrade || (days <= 0 && hours <= 0 && minutes <= 0)} 
        title={<React.Fragment>
            <Typography>{`Next downgrade possible in:`}</Typography>
            <Typography>{`${days} days ${hours} hours ${minutes} minutes`}</Typography>
          </React.Fragment>
        } 
        arrow
      >
        <Card className={classNames(classes.card, plan.current && 'current', plan.name === 'Growth' && 'best-deal')}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <div className={classNames(classes.cardHead, 'card-head')}>
                <CardMedia
                  //component="img"
                  className={classes.media}
                  image={`/files/images/${plan.name.toLowerCase()}.webp`}
                  title=""
                  onLoad={adjustCardHeadHeights}
                />
                <Typography className={classNames(classes.singleline, classes.title)} color="textSecondary" variant="h4" gutterBottom>
                  {plan.name}
                </Typography>
                <Typography className={classNames(classes.singleline, classes.price)} color="textSecondary" variant="h6" gutterBottom>
                  <strong>$ {parseFloat(plan.price).toFixed(2)}</strong>
                  <small>
                    &nbsp;/ {!anualPayment ? t('plans.planSelect.monthly') : t('plans.planSelect.yearly')}
                  </small>
                </Typography>
                {anualPayment && (
                  <Typography className={classes.discount} color="textSecondary" variant="h5" gutterBottom>
                    {plan.anualDiscount}%
                  </Typography>
                )}
                {plan.description &&
                  <Typography variant="subtitle1" alignContent="center" gutterBottom>
                    {plan.description}
                  </Typography>
                }
              </div>
              <TableContainer className={classes.tableContainer} component="div">
                <Table className={classNames(classes.table, 'featureTable')} aria-label="plan features table">
                  <TableBody>
                    {planFeatures.map((feature, index) => (
                      <TableRow
                        key={`feature-${feature.key}`}
                        className={classNames(
                          'tease',
                          !feature.tease && 'hide',
                          index % 2 === 0 ? classes.alternateRow : ''
                        )}
                        style={{
                          fontWeight: feature.tease ? 'bold' : 'normal',
                        }}
                      >
                        {showFeatureLabel && (
                          <TableCell className={classNames('featureLabel', feature.tease && classes.bold)} alignContent="start" component="th" scope="row" size="small">
                            <strong>{feature.name}</strong>
                          </TableCell>
                        )}
                        <TableCell className={classNames(!feature.tease && 'hide', feature.tease && classes.bold)} component="th" scope="row" size="small">
                          <strong>
                            {(typeof feature.value === 'string' || typeof feature.value === 'number') && feature.value}
                            {typeof feature.value === 'boolean' && feature.value === true && <CheckIcon className={classes.checkIcon} />}
                            {typeof feature.value === 'boolean' && feature.value === false && <CloseIcon className={classes.noCheckIcon} />}
                          </strong>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Button onClick={showFullList ? handleHideFullFeatureList : handleShowFullFeatureList}>
                  {showFullList ? t('plans.planSelect.showLess') : t('plans.planSelect.showFullList')}
                </Button>
              </TableContainer>
              <Button
                variant={plan.current ? 'outlined' : 'contained'}
                color={plan.key === shopPlan ? 'primary' : 'secondary'}
                size={plan.key === shopPlan ? 'medium' : 'small'}
                disabled={
                  plan.current ||
                  (plan.downgradable && !plan.downgradable.permitted) ||
                  (plan.upgradeable && !plan.upgradeable.permitted)
                }
                onClick={plan.key !== shopPlan ? () => handleChangePlan(plan.key) : null}
                className={classNames(_getButtonColor(plan), classes.button, classes.singleline)}
                fullWidth
              >
                {plan.current ? t('plans.planSelect.currentPlan') : plan.downgradable ? t('plans.planSelect.downgradePlan') : t('plans.planSelect.upgradePlan')}
              </Button>
            </CardContent>
          </CardActionArea>
        </Card>        
      </RedTooltip>

    );
  }

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div ref={scrollContainer} className={classes.cardsContainer}>
          <Grid ref={innerContainer} className="innerContainer">
            {plans && plans.map((plan, index) => (
              <Grid item key={`plan-${index}`} className={classNames(classes.gridItem, index === 0 ? 'first' : null)}>
                {plan.name === 'Growth' && 
                  <div className={classes.bestDeal}>
                    BEST DEAL
                  </div>
                }
                <div className="inner">
                  {renderCard(plan, false)}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        {renderLeftTableCard()}
      </Hidden>

      <Hidden mdUp>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.mobileTabs}
        >
          {plans.length > 0 && plans.map((plan, index) => (
            <Tab label={plan.name} value={index} key={`plan-mobile-${index}`} />
          ))}
        </Tabs>
        {renderCard(plans[activeTab], true)}
      </Hidden>

      {(!loaded || isLoading) && (
        <div className={classes.loadingSpinnerContainer}>
          <CircularProgress className={classes.loadingSign} size={25} />
        </div>
      )}

      <Dialog open={showDowngradeWarning}>
        <DialogTitle>{t('plans.planSelect.downgradeWarningTitle')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('plans.planSelect.downgradeWarningMessage')}
          </Typography>
          {loosingFeatures.map((feature, index) => (
            <Typography variant="body1" key={index} component={'li'}>
              {feature}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDowngradeWarning(false)} variant="outlined" color="default">
            {t('plans.planSelect.cancelButton')}
          </Button>
          <Button onClick={handleDowngradeConfirmed} variant="contained" className={classNames(classes.downgradeButton)}>
            {t('plans.planSelect.confirmDowngradeButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}