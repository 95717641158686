import React from 'react';
import { syncImages, resetErrors } from '../../modules/images';
import withRouter from 'with-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ItemsList from '../../components/ItemsList';
import ItemsListSkeleton from '../../components/ItemsListSkeleton';
import EmptyView from '../../components/EmptyView';
import LoadingIndicator from '../../components/LoadingIndicator';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const styles = theme => ({
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText: {
    color: '#5c6bc0',

  },
});

class Images extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      errorLockCollapse: false
    }
  }

  componentDidMount() {
    this.props.syncImages(this.props.themes.edit_theme, this.props.editLanguage)
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncImages(this.props.themes.edit_theme, newLanguage)
      return
    }

    const currentTheme = this.props.themes.edit_theme;
    const newTheme = newProps.themes.edit_theme;
    if(currentTheme != newTheme) {
      this.props.syncImages(newTheme, this.props.editLanguage)
      return
    }

    if(this.props.images.sync_images_pending === true && newProps.images.sync_images_pending === false) {
      const newImages = [];
      newProps.images.images.forEach(image => {
        newImages.push({
          id: image.id,
          title: image.name,
          type: image.type,
          fields: [{
            id: 'image',
            type: 'image',
            source: image.source.url,
            target: image.target.url || ''
          }]
        })
      })

      this.setState({images:newImages});
    }
  }

  onSave = items => {
  }

  render() {
    const { classes } = this.props

    if(this.props.images.sync_images_pending === true || (this.props.images.error && this.props.images.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Images
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={this.props.images.sync_images_pending}
          progress={this.props.images.progress}
          error={this.props.images.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        Images
      </Typography>
      <Divider className="divider-white" />
      <Paper className={classes.infoBox} elevation={0}>
        <Typography variant="h6" className={classes.infoText} style={{ margin: '4px 0 0 0', fontSize: 16 }}><InfoOutlinedIcon style={{ fontSize: 24, margin: '0 8px 4px 0' }} /> 
          Images also can be translated easily with custom contents. <a href="http://support.langify-app.com/support/solutions/articles/11000082046-image-translations-custom-" target="blank" className={classes.infoText} style={{fontWeight: 'bold', textDecoration: 'none'}}>Read more...</a>
        </Typography>
      </Paper>
      <EmptyView
        title="No images found in the theme"
      />
      
      {false && 
        <ItemsList 
          type="page"
          pagination={true}
          isPending={false}
          isSaving={false}
          onSave={this.onSave}
          data={this.state.images}
          error={this.props.images.error}
          errorLockCollapse={this.state.errorLockCollapse}
          emptyMessage={
            <EmptyView
              title="No images found in /assets folder"
            />
          }
        />
      }
      <LoadingIndicator
        pending={this.props.images.sync_images_pending}
        progress={this.props.images.progress}
        error={this.props.images.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ images, shop, themes }) => ({
  images: images,
  editLanguage: shop.shop.edit_language,
  themes: themes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncImages,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Images)))