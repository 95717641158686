import React from 'react';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import LoadingIndicator from '../../components/LoadingIndicator';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import Collapse from '@material-ui/core/Collapse';
import InputInformation from '../../components/InputInformation';
import { getLanguages, createLanguage, saveLanguage, deleteLanguage, restoreLanguage, publishLanguage, unpublishLanguage, openLanguageEditor, closeLanguageEditor } from '../../modules/languages';
import { updateSwitcherLanguages, getSwitcherConfig } from '../../modules/switcherConfigurator';
import EmptyView from '../../components/EmptyView';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import CollapsableSection from '../../components/CollapsableSection';
import Tooltip from '@material-ui/core/Tooltip';
import API from '../../api/api';
import LanguagePreviewLink from '../../components/LanguagePreviewLink';

class LanguagesSection extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      domainSwitcherInfoDialog: false,
      deleteLanguageDialog: false,
      deleteLanguageId: -1,
      data: {},
      tmpData: {},
    }

    this.handleCreateLanguage = this.handleCreateLanguage.bind(this);
    this.handleEditLanguage = this.handleEditLanguage.bind(this);
    this.handleCloseLanguageDialog = this.handleCloseLanguageDialog.bind(this);
    this.closeDeleteLanguageDialog = this.closeDeleteLanguageDialog.bind(this);
    this.showDeleteLanguageDialog = this.showDeleteLanguageDialog.bind(this);
    this.deleteLanguage = this.deleteLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveLanguage = this.handleSaveLanguage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps(newProps) {
    if(!newProps.editTheme) {
      this.setState({open: true});
    }
  }

  handleCreateLanguage() {

    this.setState({ 
      //languageEditorOpen: true,
      //open: false,
      createLanguage: true,
      editLanguage: false,
      codeError: false,
      nameError: false,
      tmpData: {
        id: false,
        name: '',
        base: false,
        code: '',
        code_id: -1,
        domain: '',
        published: false,
        rtl: false,
        removed_on_shopify: false
      },
      data: {
        id: false,
        name: '',
        base: false,
        code: '',
        code_id: -1,
        domain: '',
        published: false,
        rtl: false,
        removed_on_shopify: false
      }
    });

    this.props.openLanguageEditor();
  }

  handleToggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  closeDomainSwitcherInfoDialog = () => {
    this.setState({
      domainSwitcherInfoDialog: false
    });
  }

  handleEditLanguage(data) {
    this.setState({ 
      //languageEditorOpen: true,
      createLanguage: false,
      editLanguage: true,
      data: data,
      tmpData: data, 
    });

    this.props.openLanguageEditor();
  }

  handleCloseLanguageDialog() {
    this.setState({ 
      //languageEditorOpen: false,
      createLanguage: false,
      editLanguage: false,
      nameError: false,
      codeError: false
    });

    this.props.closeLanguageEditor();
  }

  closeDeleteLanguageDialog() {
    this.setState({
      deleteLanguageId: -1,
      deleteLanguageDialog: false
    })
  }

  showDeleteLanguageDialog(id) {
    this.setState({
      deleteLanguageId: id,
      deleteLanguageDialog: true,
      createLanguage: false,
      editLanguage: false
    })

    this.props.closeLanguageEditor();
  }

  deleteLanguage() {
    const languageToDelete = this.props.languages.languages.find(x => x.id === this.state.deleteLanguageId)
    if(!languageToDelete) {
      this.closeDeleteLanguageDialog()
      return
    }

    this.closeDeleteLanguageDialog()
    this.props.deleteLanguage(languageToDelete.id)

    let newLanguages = this.props.languages.languages.slice();
    newLanguages.splice(this.props.languages.languages.findIndex(x => x.id === languageToDelete.id), 1);
    this.props.updateSwitcherLanguages(newLanguages, this.props.editTheme, this.props.themes.themes);
  }

  restoreLanguage(id)  {
    this.props.restoreLanguage(id)
  }

  handleChange = name => event => {
    let inputType = event.target.type;
    let newData = Object.assign({}, this.state.tmpData);
    let codeError = this.state.codeError;
    let nameError = this.state.nameError;
    if(name === 'code') {
      const languageCode = this.props.languages.available_language_codes.find(x => x.id === event.target.value)
      if(languageCode) {
        newData.rtl = ['ar','am','ff','he','ku','fa','ur'].includes(languageCode.code);
        codeError = false
        newData['code_id'] = languageCode.id
        newData['code'] = languageCode.code
        newData.name = languageCode.name
      }
    } else {
      newData[name] = event.target.value;
      nameError = newData.name.trim() == ''
    }
    this.setState({ ...this.state, tmpData: newData, codeError: codeError, nameError: nameError });
  };

  handleSaveLanguage() {
    const codeError = this.state.tmpData.code_id == -1;
    const nameError = this.state.tmpData.name.trim() == '';

    if(codeError || nameError) {
      this.setState({codeError: codeError, nameError: nameError})

      return;
    }

    this.setState({codeError: false})

    let newData = Object.assign({}, this.state.tmpData);
    this.setState({ data: newData })
    this.props.closeLanguageEditor();
    if(this.state.createLanguage) {
      this.props.createLanguage(newData); 
    } else {
      this.props.saveLanguage(this.state.data.id, newData);
    }
    let newLanguages = this.props.languages.languages;
    if(!this.props.languages.languages.find(x => x.code === newData.code)) {
      newLanguages.push({
        code: newData.code,
        name: newData.name,
      });
    }

    
    this.props.updateSwitcherLanguages(newLanguages, this.props.editTheme, this.props.themes.themes);
  }

  handlePublishLanguage(data) {
    let newLanguages = this.props.languages.languages.slice();
    if(data.published === true) {
      this.props.unpublishLanguage(data.id);
      newLanguages.find(x => x.id === data.id).published = false;
    } else {
      this.props.publishLanguage(data.id);
      newLanguages.find(x => x.id === data.id).published = true;
    }
    this.props.updateSwitcherLanguages(newLanguages, this.props.editTheme, this.props.themes.themes);
  }

  handleSubmit = () => {
    this.handleSaveLanguage();
  }



  renderRow = (row) => {
    const { t, classes } = this.props;

    let usedPreviewLink = <Tooltip title="Cannot be viewed before assigned to at least one domain or market" arrow="true"><span className={classes.externalLinkDisabled}>View <OpenInNewIcon style={{fontSize:'18px',color:'#666'}}/></span></Tooltip>;
    if(row.published == false || (row.assigned_to_domains && row.assigned_to_domains.length > 0)) {
      let usedDomain = (row.assigned_to_domains && row.assigned_to_domains.length > 0) ? row.assigned_to_domains[0] : {'host':this.props.shop.shop.url, 'is_default': row.base};
      let validLink = 'https://' + usedDomain.host;
      if(usedDomain.is_default == false) {
        validLink += '/' + row.code;
      }

      usedPreviewLink = <Tooltip title="You must be logged in shopify to preview un-published languages" arrow={true}><a className={classes.externalLink} href={validLink} target="_blank">{t('languagesSection.view')} <OpenInNewIcon style={{fontSize:'18px',color:'#1a237e'}}/></a></Tooltip>
    }

    return (
      <TableRow key={row.id}>
        <TableCell width={20} style={{paddingLeft: 24, paddingTop: 6, paddingBottom: 6}}>
          <FormControlLabel control={
            <Switch 
              value={row.published} 
              checked={row.published} 
              onChange={() => this.handlePublishLanguage(row)} 
              disabled={row.base}
            />
          } label="" />
        </TableCell>
        <TableCell padding="none">
          <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${row.code}.svg')`}} />
          {row.name} {(row.base) && '(primary)'}
        </TableCell>

        <TableCell padding="none">
          {this.props.languages.publish_language_requesting == false && row.published == true && row.assigned_to_domains && row.assigned_to_domains.length <= 0 ? (<span className={classes.noDomainWarning}>Not assigned to a domain or market</span>) : (null)}
        </TableCell>

        <Hidden smDown>
          <TableCell align="left" padding="none">ly{row.id}</TableCell>
          <TableCell align="left" padding="none">{row.code}</TableCell>
        </Hidden>
        <TableCell align="right" padding="none" style={{paddingRight: 24}}>
          {/*
          TODO: Commented out until we found a solution to create correct urls for markets-subfolders configuration

          {usedPreviewLink}
          */}
          {/*<LanguagePreviewLink />*/}
          <Link style={{marginLeft:'20px', 'cursor':'pointer'}} onClick={() => {this.handleEditLanguage(row)}}>{t('languagesSection.edit')}</Link>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { t, classes } = this.props;
    const { open } = this.state;
    let numLanguages = this.props.languages.languages.length-1;
    if(this.props.shop.shop.charge.name === 'free' && numLanguages > 5) {
      numLanguages = 5;
    }

    // Check and validate the domain settings and display a notification if action is required
    let showDomainEnableLanguageInfoMessage = '';
    let showDomainEnableLanguageMissingCount = 0;

    if(this.props.languages.publish_language_requesting == false) {
      this.props.languages.languages.forEach(language => {
        if(language.published && (language.assigned_to_domains && language.assigned_to_domains.length <= 0)) {
          showDomainEnableLanguageMissingCount++;
        }
      })
    }

    if(showDomainEnableLanguageMissingCount > 0) {
      showDomainEnableLanguageInfoMessage = (
      <span style={{ paddingLeft: 36, marginTop: -24, color: 'rgb(244, 67, 54)', display: 'block' }}>
        {showDomainEnableLanguageMissingCount > 1 ?
          <Trans i18nKey="languagesSection.alertAssignDomains">
            <a href={'https://' + this.props.shop.shop.url + '/admin/settings/markets/'} target="_blank" style={{color: 'rgb(244, 67, 54)'}}></a>
            <a href={'https://' + this.props.shop.shop.url + '/admin/settings/domains/'} target="_blank" style={{color: 'rgb(244, 67, 54)'}}></a>
            {{showDomainEnableLanguageMissingCount}}
          </Trans>
          :
          <Trans i18nKey="languagesSection.alertAssignDomain">
            <a href={'https://' + this.props.shop.shop.url + '/admin/settings/markets/'} target="_blank" style={{color: 'rgb(244, 67, 54)'}}></a>
            <a href={'https://' + this.props.shop.shop.url + '/admin/settings/domains/'} target="_blank" style={{color: 'rgb(244, 67, 54)'}}></a>
            {{showDomainEnableLanguageMissingCount}}
          </Trans>
        }
      </span>
      )
    }

    return (
      <div>
        <div variant="body1" className={classNames('languages-section', classes.contentContainer)}>
          <CollapsableSection
            deeplink="languages"
            initialOpen={open}
            className={classes.collapsableSection}
            title={<Trans i18nKey={this.props.shopPlan === 'free' ? 'languagesSection.titleFreePlan' : 'languagesSection.title'}>
              {{numLanguages}}
            </Trans>}
            ctaBtnHide={false}
            ctaBtnText={t('languagesSection.addNewLanguage')}
            ctaBtnProps={{
              disabled: (this.props.shopPlan === 'free' && this.props.languages.languages.length > 5) || (this.props.shopPlan !== 'free' && this.props.languages.languages.length > 20),
            }}
            noticeShow={showDomainEnableLanguageMissingCount > 0}
            noticeType={'error'}
            noticeText={showDomainEnableLanguageInfoMessage}
            ctaBtnAction={this.handleCreateLanguage}
            openBtnText={t('languagesSection.configure')}
            cancelBtnText={t('languagesSection.cancel')}
            pending={false}
            content={this.props.languages.languages.length > 1 ? 
              <Table className={classes.table}>
                <TableHead>
                  <TableRow style={{height: 40}}>
                    <TableCell padding="none" variant="head" style={{paddingLeft: 24}}>{t('languagesSection.active')}</TableCell>
                    <TableCell padding="none" variant="head">{t('languagesSection.language')}</TableCell>
                    <TableCell padding="none" variant="head"></TableCell>
                    <Hidden smDown>
                      <TableCell align="left" padding="none">{t('languagesSection.id')}</TableCell>
                      <TableCell align="left" padding="none">{t('languagesSection.code')}</TableCell>
                    </Hidden>
                    <TableCell align="right" padding="none" variant="head" style={{paddingRight: 24}}>{t('languagesSection.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.languages.languages.filter(x => x.base).map(row => {
                    return this.renderRow(row);
                  })}
                  {this.props.languages.languages.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map((row, index) => {

                    // Free Plan language limit
                    if(this.props.shop.shop.charge.name === 'free' && index >= 5) {
                      return null;
                    }

                    if(!row.base && !row.removed_on_shopify) {
                      return this.renderRow(row);
                    }
                  })}
                  {this.props.languages.languages.filter(x => x.removed_on_shopify).map(row => {
                    return (
                      <TableRow key={row.id} selected={(!row.published)} className={classNames('languages-section', classes.removedLanguageRow)}>
                        <TableCell width={20} style={{paddingLeft: 24, paddingTop: 6, paddingBottom: 6}}>
                          <FormControlLabel control={
                            <Switch 
                              value={row.published} 
                              checked={false} 
                              onChange={() => this.handlePublishLanguage(row)} 
                              disabled={true}
                            />
                          } label="" />
                        </TableCell>
                        <TableCell className={classNames('languages-section', classes.removedLanguageTitle)} padding="none">
                          <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${row.code}.svg')`}} />
                          {row.name} {t('languagesSection.alertRemovedOnShopify')}
                        </TableCell>
                        <TableCell padding="none" variant="head"></TableCell>
                        <Hidden smDown>
                          <TableCell className={classNames('languages-section', classes.removedLanguageTitle)} padding="none">ly{row.id}</TableCell>
                          <TableCell className={classNames('languages-section', classes.removedLanguageTitle)} padding="none">{row.code}</TableCell>
                        </Hidden>
                        <TableCell align="right" padding="none" style={{paddingRight: 24}}>
                          <Link onClick={() => {this.showDeleteLanguageDialog(row.id)}}>{t('languagesSection.delete')}</Link>
                          <Link className={classNames('languages-section', classes.removedLanguageLink)} onClick={() => {this.restoreLanguage(row.id)}}>{t('languagesSection.restore')}</Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            :
              <EmptyView
                title={t('languagesSection.emptyMessage')}
                description=""
                bottom={
                  <Button
                    variant="contained" 
                    color="secondary"
                    size="medium"
                    className={classes.emptyViewButton}
                    onClick={this.handleCreateLanguage}
                    >
                    {t('languagesSection.addNewLanguage')}
                  </Button>
                }
              />
            }
          />

        </div>

        <Dialog
          onClose={this.closeDeleteLanguageDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.deleteLanguageDialog}
          fullWidth
          maxWidth="sm"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
          }}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.closeDeleteLanguageDialog}>
            <Typography variant="h5" component="span">{t('languagesSection.deleteLanguage')}</Typography>
          </DialogTitle>
          <DialogContent>
            <p>{t('languagesSection.alertDeleteLanguage')}</p>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.closeDeleteLanguageDialog} color="default" size="medium">
              {t('languagesSection.cancel')}
            </Button>
            <Button variant="outlined" onClick={this.deleteLanguage} color="default" size="medium">
              {t('languagesSection.delete')}
            </Button>
          </DialogActions>
        </Dialog>
        

        <Dialog
          onClose={this.handleCloseLanguageDialog}
          aria-labelledby="customized-dialog-title"
          open={this.props.languages.language_editor_is_open}
          fullWidth
          maxWidth="sm"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
           }
          }
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            onError={errors => console.log(errors)}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleCloseLanguageDialog}>
              {this.state.createLanguage ? t('languagesSection.createNewLanguage') : t('languagesSection.editLanguage')}
            </DialogTitle>
            <DialogContent>

              {
                this.state.createLanguage ? 
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink={true} error={this.state.codeError}>{t('languagesSection.languageFieldLabel')}</InputLabel>
                  <Select
                    value={this.state.tmpData.code_id}
                    disabled={!this.state.createLanguage}
                    onChange={this.handleChange('code')} 
                  >
                  <MenuItem value="-1" disabled><span style={{color: 'rgba(0, 0, 0, 0.45)'}}>{t('languagesSection.languageFieldPlaceholder')}</span></MenuItem>
                    {
                      this.props.languages.available_language_codes.map((item) => {
                        return <MenuItem key={item.id} value={item.id}>{item.name} ({item.code})</MenuItem>
                      })
                    }
                  </Select>
                  {this.state.codeError ? (<FormHelperText error={true}>{t('languagesSection.requiredField')}</FormHelperText>) : (<FormHelperText>{t('languagesSection.languageFieldInfo')}</FormHelperText>)}
                </FormControl>
              : null
              }

              <FormControl fullWidth>
                <TextField
                  id="outlined-full-width"
                  label={t('languagesSection.nameFieldLabel')}
                  placeholder={t('languagesSection.nameFieldPlaceholder')}
                  name="name"
                  error={this.state.nameError}
                  value={this.state.tmpData.name}
                  helperText={this.state.nameError ? t('languagesSection.requiredField') : t('languagesSection.nameFieldInfo')}
                  fullWidth
                  margin="normal"
                  onChange={this.handleChange('name')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>

              {(!this.state.createLanguage && !this.state.data.base) &&
                <div style={{margin: '12px 0'}}>
                  <Link onClick={() => this.showDeleteLanguageDialog(this.state.data.id)} color="error">
                    {t('languagesSection.deleteLanguage')}
                  </Link>  
                </div>
              }

            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={this.handleCloseLanguageDialog} color="default" size="medium">
                {t('languagesSection.cancel')}
              </Button>
              <Button variant="contained" 
                //onClick={this.handleSaveLanguage} 
                type="submit" color="secondary" size="medium">
                {t('languagesSection.saveChanges')}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>

        <LoadingIndicator
          pending={this.props.languages.publish_language_requesting || this.props.languages.unpublish_language_requesting || this.props.languages.delete_language_requesting || this.props.languages.restore_language_requesting || this.props.languages.create_language_requesting || this.props.languages.save_language_requesting}
          saving={true}
          progress={0}
          withLogo
        />
      </div>
    );
  }
}

const styles = (theme, state) => ({
  collapseToggler: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400 !important',
  },
  icon: {
    marginRight: theme.spacing(2),
    opacity: .5,
  },
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
  },
  table: {
    //minWidth: 700,
  },
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  removedLanguageRow: {
    background: 'rgba(0, 0, 0, 0.1) !important;'
  },
  removedLanguageTitle: {
    color: '#CC0000'
  },
  removedLanguageLink: {
    paddingLeft: '10px'
  },
  externalLink: {
    color: '#1a237e',
    textDecoration: 'none',
    "&:visited": {
      color: '#1a237e',
      textDecoration: 'none'
    },
    "&:hover": {
      color: '#1a237e',
      textDecoration: 'underline'
    },
    "&:active": {
      color: '#1a237e',
      textDecoration: 'none'
    },
  },
  externalLinkDisabled: {
    color: '#666',
  },
  noDomainWarning: {
    background: "#cc0000",
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
    color: "#fff"
  }
});

LanguagesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({languages, themes, shop, switcherConfigurator}) => ({
  languages: languages,
  themes: themes,
  editTheme: themes.edit_theme,
  shop: shop,
  crossDomainLinksEnabled: shop.shop.cross_domain_links_enabled,
  switcherConfig: switcherConfigurator.switcherConfig,
  shopPlan: shop.shop.charge.name
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLanguages,
      createLanguage,
      saveLanguage,
      deleteLanguage,
      restoreLanguage,
      publishLanguage,
      unpublishLanguage,
      openLanguageEditor,
      closeLanguageEditor,
      updateSwitcherLanguages,
      getSwitcherConfig,
    },
    dispatch
  )
  
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(LanguagesSection))))
