import API from '../api/api'

const SYNC_THEME_PENDING = 'SYNC_THEME_PENDING'
const SYNC_THEME_PROGRESS = 'SYNC_THEME_PROGRESS'
const SYNC_THEME_RESPONSE = 'SYNC_THEME_RESPONSE'
const SYNC_CHECKOUT_PENDING = 'SYNC_CHECKOUT_PENDING'
const SYNC_CHECKOUT_PROGRESS = 'SYNC_CHECKOUT_PROGRESS'
const SYNC_CHECKOUT_RESPONSE = 'SYNC_CHECKOUT_RESPONSE'
const SAVE_CHECKOUT_PENDING = 'SAVE_CHECKOUT_PENDING'
const SAVE_CHECKOUT_PROGRESS = 'SAVE_CHECKOUT_PROGRESS'
const SAVE_CHECKOUT_RESPONSE = 'SAVE_CHECKOUT_RESPONSE'
const SYNC_SECTIONS_PENDING = 'SYNC_SECTIONS_PENDING'
const SYNC_SECTIONS_PROGRESS = 'SYNC_SECTIONS_PROGRESS'
const SYNC_SECTIONS_RESPONSE = 'SYNC_SECTIONS_RESPONSE'
const SAVE_SECTIONS_PENDING = 'SAVE_SECTIONS_PENDING'
const SAVE_SECTIONS_PROGRESS = 'SAVE_SECTIONS_PROGRESS'
const SAVE_SECTIONS_RESPONSE = 'SAVE_SECTIONS_RESPONSE'
const SYNC_ASSET_PENDING = 'SYNC_ASSET_PENDING'
const SYNC_ASSET_PROGRESS = 'SYNC_ASSET_PROGRESS'
const SYNC_ASSET_RESPONSE = 'SYNC_ASSET_RESPONSE'
const SAVE_ASSET_PENDING = 'SAVE_ASSET_PENDING'
const SAVE_ASSET_PROGRESS = 'SAVE_ASSET_PROGRESS'
const SAVE_ASSET_RESPONSE = 'SAVE_ASSET_RESPONSE'


const initialState = {
  sync_theme_pending: true,
  sync_checkout_pending: false,
  save_checkout_pending: false,
  checkout_synchronized: false,
  sync_sections_pending: false,
  save_sections_pending: false,
  sections_synchronized: false,
  sync_asset_pending: false,
  save_asset_pending: false,
  progress: 0,
  error: false,
  assets: [],
  checkout: [],
  sections: []
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case SYNC_THEME_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_theme_pending: true
      }
    }
    case SYNC_THEME_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_THEME_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          checkout_synchronized: false,
          sections_synchronized: false,
          sync_theme_pending: false,
          error: false,
          assets: action.response.assets
        }
      } else {
        return {
          ...state,
          sync_theme_pending: false,
          error: true
        }
      }
      return {
        ...state,
        sync_theme_pending: false
      }
    }
    case SYNC_ASSET_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_asset_pending: true
      }
    }
    case SYNC_ASSET_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_ASSET_RESPONSE: {
      if(action.response.success === true) {
        let newAssets = state.assets.slice()
        let assetToUpdate = newAssets.find(x => x.id == action.response.asset)
        
        assetToUpdate.synchronized = true;

        assetToUpdate.assetStrings = action.response.strings
      
        return {
          ...state,
          assets: newAssets,
          sync_asset_pending: false
        }
      } else {
        return {
          ...state,
          sync_asset_pending: false,
          error: true
        }
      }
    }
    case SAVE_ASSET_PENDING: {
      return {
        ...state,
        progress: 0,
        save_asset_pending: true
      }
    }
    case SAVE_ASSET_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_ASSET_RESPONSE: {
      let isOk = true;
      let newAssets = state.assets.slice()

      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false;
        } else {
          let assetToUpdate = newAssets.find(x => x.id == response.asset)
          assetToUpdate.assetStrings = response.strings
        }

      })

      if(isOk === true) {
        return {
          ...state,
          assets: newAssets,
          save_asset_pending: false,
          error: false
        }
      } else {
        return {
          ...state,
          save_asset_pending: false,
          error: true
        }
      }

      return {
        ...state
      }
    }
    case SYNC_CHECKOUT_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_checkout_pending: true
      }
    }
    case SYNC_CHECKOUT_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_CHECKOUT_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_checkout_pending: false,
          error: false,
          checkout_synchronized: true,
          checkout: action.response.checkout
        }
      } else {
        return {
          ...state,
          sync_checkout_pending: false,
          error: true
        }
      }
      return {
        ...state,
        sync_checkout_pending: false
      }
    }
    case SAVE_CHECKOUT_PENDING: {
      return {
        ...state,
        progress: 0,
        save_checkout_pending: true
      }
    }
    case SAVE_CHECKOUT_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_CHECKOUT_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          save_checkout_pending: false,
          error: false,
          checkout: action.response.checkout
        }
      } else {
        return {
          ...state,
          save_checkout_pending: false,
          error: true
        }
      }
      return {
        ...state,
        save_checkout_pending: false
      }
    }
    case SYNC_SECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_sections_pending: true
      }
    }
    case SYNC_SECTIONS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_SECTIONS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_sections_pending: false,
          error: false,
          sections_synchronized: true,
          sections: action.response.sections
        }
      } else {
        return {
          ...state,
          sync_sections_pending: false,
          error: true
        }
      }
      return {
        ...state,
        sync_sections_pending: false
      }
    }
    case SAVE_SECTIONS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_sections_pending: true
      }
    }
    case SAVE_SECTIONS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SECTIONS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          save_sections_pending: false,
          error: false,
          sections: action.response.sections
        }
      } else {
        return {
          ...state,
          save_sections_pending: false,
          error: true
        }
      }
      return {
        ...state,
        save_sections_pending: false
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const syncTheme = (themeId, progress) => {  
  return dispatch => {
    dispatch({
      type: SYNC_THEME_PENDING
    })

    API.syncTheme(
      themeId,
      progress => {
        dispatch({
          type: SYNC_THEME_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_THEME_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const syncAsset = (themeId, assetId, languageId, progress) => {  
  return dispatch => {
    dispatch({
      type: SYNC_ASSET_PENDING
    })

    API.syncAsset(
      themeId,
      assetId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_ASSET_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_ASSET_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const saveAsset = (themeId, languageId, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_ASSET_PENDING
    })

    API.saveAsset(
      themeId,
      languageId,
      items,
      progress => {
        dispatch({
          type: SAVE_ASSET_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_ASSET_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const syncCheckout = (themeId, languageId, progress) => {  
  return dispatch => {
    dispatch({
      type: SYNC_CHECKOUT_PENDING
    })

    API.syncCheckout(
      themeId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_CHECKOUT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_CHECKOUT_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const saveCheckout = (themeId, languageId, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_CHECKOUT_PENDING
    })

    API.saveCheckout(
      themeId,
      languageId,
      items,
      progress => {
        dispatch({
          type: SAVE_CHECKOUT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_CHECKOUT_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const syncSections = (themeId, languageId, progress) => {  
  return dispatch => {
    dispatch({
      type: SYNC_SECTIONS_PENDING
    })

    API.syncSections(
      themeId,
      languageId,
      progress => {
        dispatch({
          type: SYNC_SECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_SECTIONS_RESPONSE,
          response: response
        })
      }
    )
  }
}

export const saveSections = (themeId, languageId, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SECTIONS_PENDING
    })

    API.saveSections(
      themeId,
      languageId,
      items,
      progress => {
        dispatch({
          type: SAVE_SECTIONS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SECTIONS_RESPONSE,
          response: response
        })
      }
    )
  }
}