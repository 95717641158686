import API from '../api/api'
import { deepMerge } from '../helper'

const GET_AVAILABLE_WORDS_LOG_PENDING = 'GET_AVAILABLE_WORDS_LOG_PENDING';
const GET_AVAILABLE_WORDS_LOG_RESPONSE = 'GET_AVAILABLE_WORDS_LOG_RESPONSE';
const CHANGE_PLAN_PENDING = 'CHANGE_PLAN_PENDING';
const CHANGE_PLAN_RESPONSE = 'CHANGE_PLAN_RESPONSE';

const initialState = {
	get_available_words_log_pending: false,
  available_words_log: [],
	errorMessage: false,
	page: 0,
}


// REDUCER
export default (state = initialState, action) => {
	switch(action.type) {

		case GET_AVAILABLE_WORDS_LOG_PENDING: {
			return {
				...state,
				get_available_words_log_pending: true,
			}
		}
		case GET_AVAILABLE_WORDS_LOG_RESPONSE: {
			console.log(action.response)
			if(action.response.log) {
				const newAvailableWordsLog = action.response.log.slice();
				return {
					...state,
					...action.response,
					get_available_words_log_pending: false,
					available_words_log: [...state.available_words_log, ...newAvailableWordsLog],
					page: action.response.currentPage
				}
			} else {
        return {
          ...state,
          get_available_words_log_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
			}
		}

		default:
			return state
	}
}



export const getAvailableWordsLog = (callback) => { 
	return (dispatch, getState) => {
		const state = getState();
		console.log(state)

		if(state.wordslog.totalPages && state.wordslog.page && state.wordslog.totalPages === state.wordslog.page) {
			return false;
		}

		dispatch({
			type: GET_AVAILABLE_WORDS_LOG_PENDING,
		})

		API.getAvailableWordsLog(
			state.wordslog.page + 1,
			10,
			response => {
				dispatch({
					type: GET_AVAILABLE_WORDS_LOG_RESPONSE,
					response: response,
					action: () => getAvailableWordsLog(callback)
				});
				if(callback) {
					callback(response);
				}
		})
	}
}