import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { Typography, Button, Link, Collapse, Paper, Box, Grow, Tooltip, Divider, Stepper, Step, StepLabel, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { publishTranslations, unpublishTranslations } from '../modules/themes';
import { syncThemeAppExtension, checkThemePublishState } from '../modules/themeAppExtension';



/**
 * 
 * @param {*} param0 
 * @returns 
 */
const LangifyActivation = ({ activate, myshopifyDomain, template, handle }) => {
  const classes = useStyles();
  const { t } = useTranslation(); 
  const [loading, setLoading] = useState(false);

  const edit_theme = useSelector((state) => state.themes.edit_theme);
  const themes = useSelector((state) => state.themes.themes);
  const uuid = useSelector((state) => state.themeAppExtension.uuid);
  const disabled = useSelector((state) => state.themeAppExtension.disabled);
  const langify_code_in_theme = useSelector((state) => state.themeAppExtension.langify_code_in_theme);
  const langify_app_embed_code_in_theme = useSelector((state) => state.themeAppExtension.langify_app_embed_code_in_theme);
  const settings = useSelector((state) => state.themeAppExtension.settings);
  const dispatch = useDispatch();
  

  useEffect(() => {
    return function() {
      document.removeEventListener('visibilitychange', handleBrowserTabFocus);
    }
  }, [])

  const handleBrowserTabFocus = () => {
    if (document.visibilityState === 'visible') {
      setLoading(true);
      dispatch(syncThemeAppExtension(edit_theme, (res) => {
        dispatch(checkThemePublishState(edit_theme, (res2) => {

          setLoading(false);
        }));
      }));
    }
  }

  const handleButtonClick = () => {
    const editTheme = themes.find(x => x.id == edit_theme).shopify_id || 'current';
    const editorUrl = `https://${myshopifyDomain}/admin/themes/${editTheme}/editor?context=apps${template ? '&template=' + template : ''}${uuid ? '&activateAppId=' + uuid : ''}/${handle}`;

    //navigator.clipboard.writeText(editTheme);

    var textToCopy = editTheme;
    var textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    // 
    document.removeEventListener('visibilitychange', handleBrowserTabFocus);
    document.addEventListener('visibilitychange', handleBrowserTabFocus);
    window.open(editorUrl, '_blank');
    //console.log(editorUrl)
  };

  const renderCustomizeThemeDeepLink = <Button variant="contained" color="secondary" size="medium" onClick={handleButtonClick} className={classes.deeplinkBtn}>
    Open Customize Theme Editor <OpenInNewIcon className={classes.icon} />
  </Button>

  const renderCustomizeThemeIdDeepLink = <Button variant="outlined" size="small" onClick={handleButtonClick} className={classes.deeplinkBtn}>
    Open Customize Theme Editor <OpenInNewIcon className={classes.icon} />
  </Button>

  const showThemeIdAlert = () => {
    const editTheme = themes.find(x => x.id == edit_theme);
    let _return = false;
    if(settings.theme_id === '')
      _return = 'empty';
    if(settings.theme_id !== '') {
      if(editTheme && editTheme.shopify_id && (editTheme.shopify_id != settings.theme_id)) {
        _return = 'different';
      }
    }
    return _return;
  }

  return (
    <div>
      {activate &&
        <React.Fragment>
          <Typography variant="h5" component="h3" className={classes.headline}>
            {t('themeAppExtension.themeSettings.publishButton')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Trans i18nKey={'themeAppExtension.themeSettings.langifyNotEnabledMessage'} />
            <br/>
            <br/>
            <Trans i18nKey={'themeAppExtension.themeSettings.activationSteps'} />
            <br/>
            <br/>
            <React.Fragment>
              {t('themeAppExtension.themeSettings.directButtonMessage')}
              <br/>
              {renderCustomizeThemeDeepLink}
            </React.Fragment>
            <br/>
            <br/>
            {t('themeAppExtension.themeSettings.recommendedSettingsMessage')}
            <br/>
            <br/>
            {t('themeAppExtension.themeSettings.getDetailsLinkMessage')}
            <br/>
            <a href="https://support.langify-app.com/support/solutions/articles/11000124801-publishing-langify" target="_blank">Publishing langify</a>
          </Typography>
        </React.Fragment>
      }
      {!activate &&
        <React.Fragment>

          <Typography variant="h5" component="h3" className={classes.headline}>
            <CheckCircleOutlineOutlinedIcon className={classNames(classes.publishCheckMark, (typeof uuid === 'string' && disabled === false) ? 'green' : null)} /> {t('themeAppExtension.themeSettings.langifyPublishedMessage')} 
          </Typography>

          {showThemeIdAlert() !== false &&
            <Alert severity="error" style={{marginBottom: 12}}>
              <Trans i18nKey={`themeAppExtension.themeSettings.themeIdAlerts.${showThemeIdAlert()}`}>
                <strong>Provide Theme ID</strong><br/>Please provide your theme ID in langify's app embed block. The button below will copy the correct theme ID into your clipboard and brings you there directly:<br/>
              </Trans>
              {renderCustomizeThemeIdDeepLink}
            </Alert>
          }

          <Typography variant="body1" gutterBottom>
            {/*  (
            {(isPublished || isPublishedInTheme) && t('themeAppExtension.themeSettings.publishedInTheme')}
            {(uuid && !disabled) && t('themeAppExtension.themeSettings.publishedAppEmbedBlock')}
            ) */}
            {t('themeAppExtension.themeSettings.deactivateInstructions')}
            <br/>
            <br/>
            <React.Fragment>
              {t('themeAppExtension.themeSettings.directButtonMessage')}
              <br/>
              {renderCustomizeThemeDeepLink}
            </React.Fragment>
          </Typography>        
        </React.Fragment>
      }
      {(loading) &&
        <div className={classes.loadingLayer}>
          <span className={classes.loadingSpinner}>
            <CircularProgress />
          </span>
        </div>
      }
    </div>
  );
};
export default LangifyActivation;



/**
 * 
 */
export const ManuallyAddLangifyCode = ({forceHide, publish, showAlways, showInline, showCheckMark, hideHeadline, hideDescription, style}) => {
  const lyDelimiter = '{% comment %}ly_liquid_scripts_begin{% endcomment %}{% capture ly_liquid_content %}{% comment %}ly_liquid_scripts_end{% endcomment %}<!doctype html>';
  const lyPolyfillCodeDelimiter = '{% comment %}ly_liquid_scripts_begin{% endcomment %}{% endcapture %}{%- comment -%}Version';
  let oldTemplateData = null;

  const { t } = useTranslation();
  const classes = useStyles();
  const [started, setStarted] = useState(false);
  const [hidden, setHidden] = useState(forceHide);
  const [publishing, setPublishing] = useState(publish);
  const [activeStep, setActiveStep] = React.useState(0);
  const [keepOpen, setKeepOpen] = React.useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const synced = useSelector((state) => state.themeAppExtension.synced);
  const uuid = useSelector((state) => state.themeAppExtension.uuid);
  const disabled = useSelector((state) => state.themeAppExtension.disabled);
  const langify_code_in_theme = useSelector((state) => state.themeAppExtension.langify_code_in_theme);
  const langify_app_embed_code_in_theme = useSelector((state) => state.themeAppExtension.langify_app_embed_code_in_theme);
  const sync_theme_app_extension_pending = useSelector((state) => state.themeAppExtension.sync_theme_app_extension_pending);
  const themes = useSelector((state) => state.themes.themes);
  const edit_theme = useSelector((state) => state.themes.edit_theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBrowserTabFocus = () => {
      if (document.visibilityState === 'visible' && activeStep === 1) {
        setLoading(true);
        setCopied(false);
        dispatch(checkThemePublishState(edit_theme, (res) => {
          // Check if DAU pasted the entire code at the end of the theme.liquid instead of replacing the code
          if(oldTemplateData) {
            const oldCode = publish ? oldTemplateData.theme_liquid_value_published : oldTemplateData.theme_liquid_value_unpublished;
            if(_checkDoubleSize(oldCode, res.value.value)) {
              setError(true); 
              setErrorMsg(t('themeAppExtension.manuallyAddLangifyCode.doubledThemeFileSizeError')); 
            }            
          }

          if(publish) {
            if(res.value.value.indexOf(lyPolyfillCodeDelimiter) > -1 ) {
              setActiveStep(2);
              setKeepOpen(true);
              document.removeEventListener('visibilitychange', handleBrowserTabFocus);
            }      
          } else {
            if(res.value.value.indexOf(lyPolyfillCodeDelimiter) === -1 ) {
              setActiveStep(2);
              setKeepOpen(true);
              document.removeEventListener('visibilitychange', handleBrowserTabFocus);
            }   
          }
          setLoading(false);
        }));
      }
    }
    document.addEventListener('visibilitychange', handleBrowserTabFocus);
    return function() {
      document.removeEventListener('visibilitychange', handleBrowserTabFocus);
    }
  }, [activeStep])

  useEffect(() => {
    //debugger;
    if(synced === false && sync_theme_app_extension_pending === false && _isValidEditThemeId()) {
      dispatch(syncThemeAppExtension(edit_theme));
      dispatch(checkThemePublishState(edit_theme), (res) => {
        //console.log(res, '####')
      });
    }
    if(!keepOpen) {
      setPublishing(publish);
    }
    setHidden(forceHide);
    //console.log({publish, publishing})
  }, [synced, sync_theme_app_extension_pending, publish, keepOpen, forceHide])

  const handleBack = (e) => {
    e.stopPropagation();
    setStarted(false);
  }

  const handleStart = (e) => {
    e.stopPropagation();
    setStarted(true);
  }

  const _isValidEditThemeId = () => {
    return themes.find(x => x.id === edit_theme) ? true : false;
  }

  const _checkDoubleSize = (str1, str2) => {
    const size1 = str1.length;
    const size2 = str2.length;

    // Berechnung der Größe um 5% zu erweitern
    const lowerBound = size1 * 1.95;
    const upperBound = size1 * 2.05;

    // Überprüfen, ob size2 zwischen den Grenzen liegt
    return size2 >= lowerBound && size2 <= upperBound;
  }
  
  const handleGenerateCode = () => {
    setGenerating(true);

    dispatch(publishTranslations(edit_theme, false, (res) => {
      if(res.status === false) {
        setActiveStep(0);  
        setStarted(false);   
        setError(true); 
        setErrorMsg(res.message); 
      } else {
        oldTemplateData = res;
        setTemplateData(res);
        setActiveStep(1);        
      }
      setGenerating(false);

      console.log(oldTemplateData)
    }));
  }

  const handleOpenTemplate = () => {
    const url = templateData.theme_liquid_path;
    window.open(url, '_blank');
  }

  const handleCopyToClipboard = () => {
    var textToCopy = templateData.theme_liquid_value_published;
    if(publish === false) {
      textToCopy = templateData.theme_liquid_value_unpublished;
    }

    var textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);

    setCopied(true);
    /*
    setTimeout(() => {
      setCopied(false);
    }, 3000);
    */
  }

  const handleFinished = () => {
    setKeepOpen(false);
    setStarted(false);
    setActiveStep(0);
  }

  const handleCancel = () => {
      setKeepOpen(false);
      setStarted(false);
      setActiveStep(0);
      setError(false);
      setErrorMsg('');
  }

  const steps = [
    publishing ? t('themeAppExtension.manuallyAddLangifyCode.generateCode') : t('themeAppExtension.manuallyAddLangifyCode.unpublishGenerateCode'),
    t('themeAppExtension.manuallyAddLangifyCode.replaceTemplateCode'),
    t('themeAppExtension.manuallyAddLangifyCode.finish')
  ]

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Typography>
            {publishing && t('themeAppExtension.manuallyAddLangifyCode.generateCodeDescription')}
            {!publishing && t('themeAppExtension.manuallyAddLangifyCode.unpublishGenerateCodeDescription')}
            <br />
            <Button onClick={handleGenerateCode} disabled={generating} className={classes.stepperBtn} variant="contained" color="secondary">
              {generating ? t('themeAppExtension.manuallyAddLangifyCode.generating') : t('themeAppExtension.manuallyAddLangifyCode.generateCodeBtn')}
            </Button>
            <Button onClick={handleCancel} className={classes.stepperBtn} variant="outlined" color="secondary">
              {t('system.cancel')}
            </Button>
          </Typography>
        );
      case 1:
        return (
          <Typography>
            {t('themeAppExtension.manuallyAddLangifyCode.replaceTemplateCodeDescription')}<br />
            <Button onClick={handleCopyToClipboard} className={classes.stepperBtn} variant="contained" color={copied ? 'default' : 'secondary'}>
              <FileCopyOutlinedIcon style={{ marginRight: 8, fontSize: 16 }} /> {t('themeAppExtension.manuallyAddLangifyCode.copyToClipboard')}
            </Button>
            <Button onClick={handleOpenTemplate} className={classes.stepperBtn} variant="contained" color="secondary">
              <OpenInNewOutlinedIcon style={{ marginRight: 8, fontSize: 16 }} /> {t('themeAppExtension.manuallyAddLangifyCode.openTemplate')}
            </Button>
            <Button onClick={handleCancel} className={classes.stepperBtn} variant="outlined" color="secondary">
              {t('system.cancel')}
            </Button>
          </Typography>
        );
      case 2:
        return (
          <Typography>
            {t('themeAppExtension.manuallyAddLangifyCode.finishDescription')}<br />
            <Button onClick={handleFinished} className={classes.stepperBtn} variant="contained" color="secondary">
              {t('themeAppExtension.manuallyAddLangifyCode.done')}
            </Button>
          </Typography>
        );
      default:
        return t('themeAppExtension.manuallyAddLangifyCode.unknownStep');
    }
  };

  return <div className={classes.root} style={style ? style : null}>
    {!forceHide &&
      <Collapse in={(uuid && langify_app_embed_code_in_theme === false && langify_code_in_theme === false) || showAlways || keepOpen} classes={{ wrapper: classes.collapseWrapper }} style={{ width: '100%' }}>
        <Paper className={showInline ? classes.inlineInfoBox : classes.infoBox} elevation={0}>
          {!hideHeadline &&
            <React.Fragment>
              {!showInline ?
                <Typography variant="h6" className={classes.infoText} style={{ margin: '4px 0 0 0', fontSize: 18 }}>
                  <InfoOutlinedIcon style={{ fontSize: 26, margin: '0 8px 4px 0' }} /> 
                  {t('themeAppExtension.manuallyAddLangifyCode.title')}
                </Typography>
              :
                <Typography variant="h5" component="h3" className={classes.headline}>
                  {showCheckMark && <CheckCircleOutlineOutlinedIcon className={classNames(classes.publishCheckMark, langify_app_embed_code_in_theme ? 'green' : null)} /> }  
                  {publishing && t('themeAppExtension.manuallyAddLangifyCode.publishTitle')}
                  {!publishing && t('themeAppExtension.manuallyAddLangifyCode.unpublishTitle')}
                </Typography>
              }
            </React.Fragment>
          }

          {!hideDescription && !error &&
            <Typography variant="body1">
              {publishing && <Trans i18nKey={'themeAppExtension.manuallyAddLangifyCode.description'}></Trans>}
              {!publishing && <Trans i18nKey={'themeAppExtension.manuallyAddLangifyCode.unpublishDescription'}></Trans>}
            </Typography>
          }

          <Collapse in={started} style={{ width: '100%' }}>
            <Stepper activeStep={activeStep} className={classes.stepperRoot}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel completed={(activeStep === 2 && keepOpen) || index === activeStep - 1} {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {getStepContent(activeStep)}
            </div>
          </Collapse>
          {!started && !error &&
            <Button onClick={handleStart} className={classes.stepperBtn} variant="outlined">
              {publishing && t('themeAppExtension.manuallyAddLangifyCode.startManualPublishing')}
              {!publishing && t('themeAppExtension.manuallyAddLangifyCode.startManualUnpublishing')}
            </Button>
          }

          {error &&
            <Typography variant="body2" style={{color: 'red'}} styles={{margin: '0 0 12px 0'}}>
              <Trans errorMsg={errorMsg}>
                The code could not be generated, due to an invalid html structure in the Layout/theme.liquid file.<br/>
                Please try to fix this and try again or reach out to our support team.<br/>
              </Trans>
              {errorMsg && <>Error message: {errorMsg}<br/></>}
              <br/>
              <Button variant="outlined" onClick={handleCancel}>
                {t('system.cancel')}
              </Button>
            </Typography>
          }

          {(loading || generating) &&
            <div className={classes.loadingLayer}>
              <span className={classes.loadingSpinner}>
                <CircularProgress />
              </span>
            </div>
          }
        </Paper>      
      </Collapse>
    }
  </div>
}



export const CopyPasteSingleFile = ({ template, url, value, publish }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleOpenTemplate = () => {
    window.open(url, '_blank');
  }

  const handleCopyToClipboard = (isUnpublished=false) => {
    var textToCopy = value;
    var textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return <div className={classNames(classes.singleFileRoot, publish ? 'publish' : 'unpublish')}>
  <Divider className={classes.divider2} />    
  <span>
    <strong>{publish ? t('themeAppExtension.copyPasteSingleFile.publishAsset') : t('themeAppExtension.copyPasteSingleFile.unpublishAsset')} </strong>
    {template}
  </span>
  <span className={classes.actionBtnContainer}>
    <Tooltip title={t('themeAppExtension.copyPasteSingleFile.copyToClipboard')} arrow>
      <Button onClick={() => {handleCopyToClipboard(publish)}} size="small" variant="outlined" className={classes.btn}>
        <FileCopyOutlinedIcon style={{marginRight: 8, fontSize: 16}} />
        {copied && t('themeAppExtension.copyPasteSingleFile.copiedCode')}
        {(!copied && publish) && t('themeAppExtension.copyPasteSingleFile.copyPublishedCode')}
        {(!copied && !publish) && t('themeAppExtension.copyPasteSingleFile.copyUnpublishedCode')}
      </Button>
    </Tooltip>
    <Tooltip title={t('themeAppExtension.copyPasteSingleFile.openTemplate')} arrow>
      <Button onClick={handleOpenTemplate} size="small" variant="outlined" className={classes.btn}>
        <OpenInNewOutlinedIcon style={{marginRight: 8, fontSize: 16}} />{t('themeAppExtension.copyPasteSingleFile.open')}
      </Button>
    </Tooltip>     
  </span>
</div>
}


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  icon: {
    marginLeft: 8
  },
  headline: {
    marginBottom: 8
  },
  collapseWrapper: {
    width: '100%',
  
  },
  btn: {
    wordBreak: 'normal',
    marginLeft: 8
  },
  deeplinkBtn: {
    marginTop: 8
  },
  cancelBtn: {

  },
  divider: {
    margin: '8px 0',
  },
  divider2: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%'
  },
  infoBox: {
    position: 'relative',
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText: {
    color: '#5c6bc0',
  },
  infoBox2: {
    backgroundColor: 'rgba(26, 35, 126, 1)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 20,
  },
  infoText2: {
    color: '#b5bce6',
  },

  inlineInfoBox: {
    position: 'relative',
  },


  singleFileRoot: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    '&.publish': {
      backgroundColor: 'red',
      color: 'white',
      '& *': {
        color: 'white',
        borderColor: 'white'
      },
    },
    '&.unpublish': {
      '& *': {
        //color: 'white',
        //borderColor: 'white'
      }
    }
  },
  loadingLayer: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,.5)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    '& .MuiCircularProgress-root': {
    }
  },
  loadingSpinner: {
    position: 'relative',
    display: 'inline-block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  stepperRoot: {
    padding: '16px 0',
    backgroundColor: 'transparent',
  },
  stepperBtn: {
    margin: '8px 8px 8px 0',
  },

  publishCheckMark: {
    marginBottom: 4,
    marginRight: 8,
    fontSize: 30,
    color: 'lightgrey',
    '&.green': {
      color: 'green'
    }
  }
}));