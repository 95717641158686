import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneOutlined from '@material-ui/icons/DoneOutlined';

class SmallProgressIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  componentDidMount() {
    if(this.props.progress === 100) {
      this.setState({isCompleted: true})
    } else {
      this.setState({isCompleted: false})
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.progress === 100) {
      this.setState({isCompleted: true})
    } else {
      this.setState({isCompleted: false})
    }
  }

  render() {
    const {
      classes,
      progress,
    } = this.props;
    const {
      isCompleted,
    } = this.state;

    return (
      <span styles={classes.root}>
        <CircularProgress 
          variant="determinate" 
          value={100} 
          className={classes.progressBg}
          size={16}
        />
        <CircularProgress 
          variant="determinate" 
          value={progress} 
          className={classes.progress}
          size={16}
          classes={{
            colorPrimary: progress > 50 ? classes.green : classes.red,
          }}
        />
        {isCompleted ?
          (<span className={classes.completedSign}>
            <DoneOutlined />
          </span>) : null
        }
      </span>
    )
  }
}

const styles = theme => ({
  root: {
    position: 'relative',
  },
  progressBg: {
    position: 'absolute',
    top: 9,
    left: 12,
    opacity: .25,
    color: '#000',
  },
  progress: {
    position: 'absolute',
    top: 9,
    left: 12,
    color: 'seagreen',
  },
  completedSign: {
    position: 'absolute',
    top: 6,
    left: 15,
    color: 'seagreen',
    '& svg': {
      fontSize: 10
    }
  },
  red: {
    color: 'orangered !important'
  },
  green: {
    color: 'seagreen !important'
  }
});

export default withStyles(styles)(SmallProgressIndicator);