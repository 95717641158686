import React, { Component } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import API from '../../api/api.js'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { BulkTranslationSettings } from '../../components/BulkTranslations';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { saveShop, enableLangifyFeature, disableLangifyFeature } from '../../modules/shop';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack'; 



const useStyles = makeStyles({
  root: {
    maxWidth: 225,
  },
  selectMenu: {
    //fontSize: 14,
    //paddingBottom: 3,
    display: 'inline-block',
  }
})
const UILanguageSelector = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles(props);

  function changeLanguage(e) {
    //i18n.changeLanguage(e.target.value);
    window.localStorage.setItem('ly-ui-language', e.target.value);
    window.location.reload();
  }
  return(
    <FormControl variant="outlined" fullWidth={false} className={classes.root}>
      {/*<InputLabel id="ui-language-select-label">{t('system.uiLanguage')}</InputLabel>*/}
      <Select
        //label="UI language"
        labelId="ui-language-select-label"
        id="ui-language-select-outlined"
        onChange={changeLanguage} 
        value={i18n.language}
        classes={{selectMenu: classes.selectMenu }}
      >
        <MenuItem value='en'>{t('system.english')}</MenuItem>
        <MenuItem value='de'>{t('system.german')}</MenuItem>
        <MenuItem value='it'>{t('system.italian')}</MenuItem>
        <MenuItem value='fr'>{t('system.french')}</MenuItem>
        <MenuItem value='es'>{t('system.spanish')}</MenuItem>
      </Select>
    </FormControl>
  )
}

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      richtextEditorVersion:            (props.langifyFeatures.find(x => x.name === 'editor_v2') && props.langifyFeatures.find(x => x.name === 'editor_v2').is_enabled) ? 'v2' : 'v1',
      richtextEditorOnParagraphFields:  (props.langifyFeatures.find(x => x.name === 'html_text_2_rich_text') && props.langifyFeatures.find(x => x.name === 'html_text_2_rich_text').is_enabled) ? true : false,
      showStaticMenuEntry:              (typeof props.langifyFeatures.find(x => x.name === 'static_translations') === 'undefined' || props.langifyFeatures.find(x => x.name === 'static_translations').is_enabled) ? true : false,
      customImageUploadPrefixState: (
        props.customImageUploadPrefix === '' ? 'no_prefix' :
        typeof props.customImageUploadPrefix === 'string' ? 'custom_prefix' :
        'default_prefix'
      ),
      customImageUploadPrefixValue: props.customImageUploadPrefix,
      customImageUploadPrefixWarning: null,
      saveCustomImageUploadPrefixSuccess: null,
      saveCustomImageUploadPrefixWarning: false,
      importV1ProductMetafieldsProgress: 0,
      progressingPoFile: false,
    };
  }

  onImportV1ProductMetafieldTranslations = () => {
  	const editLanguage = this.props.editLanguage;

    this.setState({
      importV1ProductMetafieldsProgress: 1,
    });

  	API.syncProducts(
  		editLanguage,
  		progress => {
  			console.log('sync products: ' + progress)
  		},
      response => {
        if(response.success == true) {
          this.syncProductMetafields(
            editLanguage,
            response.products.slice(0, 10),
            0,
            progress => {
              console.log('import translations: ' + progress);
              this.setState({
                importV1ProductMetafieldsProgress: progress,
              });
            },
            result => {
              console.log(result);
              this.setState({
                importV1ProductMetafieldsProgress: 100,
              });
            }
          )
        }
      }
    );
  };

  syncProductMetafields = (language, products, index, progress, result) => {
  	if(index >= products.length - 1) {
  		result('!');
  	} else {
	  	API.syncProductMetafields(
	  		products[index].id,
	  		language,
	  		progress => {},
	  		response => {
	  			if(response.success == true && response.metafields.length > 0) {
					API.importV1ProductMetafieldTranslations(
						language,
						products[index].id,
						response.metafields,
						progress => {},
						response => {
				  			progress(Math.ceil(index / (products.length - 1) * 100));
				  			this.syncProductMetafields(language, products, index + 1, progress, result);
						}
					);
	  			} else {
		  			progress(Math.ceil(index / (products.length - 1) * 100));
		  			this.syncProductMetafields(language, products, index + 1, progress, result);
	  			}
	  		}
  		)
  	}
  };

  onUploadPO = (e) => {
    this.setState({progressingPoFile: true});

    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    
    const formData = new FormData();
    formData.append('file', file);

    API.splitPOfile(
      formData,
      this.props.editLanguage,
      progress => {
        this.setState({progressingPoFile: true});
        console.log('Progress:', progress);
      },
      data => {
        this.setState({progressingPoFile: false});
        console.log('Result:', data);
      }
    );
  }

  toggleRichtextOnParagraphFields = (event) => {
    this.setState({
      richtextEditorOnParagraphFields: !this.state.richtextEditorOnParagraphFields
    }, () => {
      if(this.state.richtextEditorOnParagraphFields) {
        this.props.enableLangifyFeature('html_text_2_rich_text');
      } else {
        this.props.disableLangifyFeature('html_text_2_rich_text');
      }
    });
  }

  toggleshowStaticMenuEntry = (event) => {
    this.setState({
      showStaticMenuEntry: !this.state.showStaticMenuEntry
    }, () => {
      if(this.state.showStaticMenuEntry) {
        this.props.enableLangifyFeature('static_translations');
      } else {
        this.props.disableLangifyFeature('static_translations');
      }
    });
  }

  changeRichtextEditorVersion = (event) => {
    this.setState({
      richtextEditorVersion: event.target.value
    }, () => {
      if(this.state.richtextEditorVersion === 'v2') {
        this.props.enableLangifyFeature('editor_v2');
      } else {
        this.props.disableLangifyFeature('editor_v2');
      }
    });
  }

  changeCustomImageUploadPrefix = (event) => {
    this.setState({
      customImageUploadPrefixState: event.target.value,
      saveCustomImageUploadPrefixSuccess: null
    });
  }

  saveCustomImageUploadPrefix = async () => {
    let result;
    await this.setState({
    }, async () => {
      if(this.state.customImageUploadPrefixState === 'no_prefix') {
        result = await API.saveShopSetting('custom_image_upload_prefix','');
      } else if(this.state.customImageUploadPrefixState === 'custom_prefix') {
        result = await API.saveShopSetting('custom_image_upload_prefix',this.state.customImageUploadPrefixValue);
      } else {
        result = await API.deleteShopSetting('custom_image_upload_prefix');
      }

      this.props.saveShop({
        settings: {
          custom_image_upload_prefix: result.value
        }
      });

      let message = result.feedback ? result.feedback.message : null;

      if(message) {
        this.props.enqueueSnackbar(<span dangerouslySetInnerHTML={{__html: message}}></span>, {
          variant: 'warning',
          persist: false,
          autoHideDuration: 10000
        })
      };

      this.setState({
        customImageUploadPrefixValue: result.value,
        saveCustomImageUploadPrefixSuccess: result.success,
        saveCustomImageUploadPrefixWarning: result.feedback !== null,
        customImageUploadPrefixWarning: message
      })
    })
  }

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('tools.titleSettings')}
        </Typography>
        <Divider className="divider-white" />        
        <Paper className={classes.root} elevation="1">
          
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.editorVersionTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.editorVersionInfo')}
            </Typography>
            <RadioGroup aria-label="richtext editor" name="richTextEditorVersion" value={this.state.richtextEditorVersion} onChange={this.changeRichtextEditorVersion}>
              <FormControlLabel value="v2" control={<Radio />} label={`${t('tools.editorVersion')} 2.0`} />
              <FormControlLabel value="v1" control={<Radio />} label={`${t('tools.editorVersion')} 1.0`} />
            </RadioGroup>
          </FormControl>

          <div className={classes.spacer} />

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.editorParagraphTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.editorParagraphInfo')}
            </Typography>
            <FormControlLabel
              control={<Switch checked={this.state.richtextEditorOnParagraphFields} onChange={this.toggleRichtextOnParagraphFields} />}
              label={this.state.richtextEditorOnParagraphFields ? 'enabled' : 'disabled'}
            />
          </FormControl>

          <div className={classes.spacer} />

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.uiLanguageTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>{t('tools.uiLanguageInfo')}</Typography>
            <UILanguageSelector />
          </FormControl>
        </Paper>

        <br />
        <Paper className={classes.root} elevation="1">
          <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">{t('tools.customImageUploadPrefixTitle')}</FormLabel>
              <Divider style={{margin: '8px 0'}} />
              <Typography>
              <Trans i18nKey={'tools.customImageUploadPrefixInfo'}>
                </Trans>
              </Typography>
              <RadioGroup aria-label="custom image upload prefix settings" name="customImageUploadPrefix" value={this.state.customImageUploadPrefixState ? this.state.customImageUploadPrefixState : "default_prefix"} onChange={this.changeCustomImageUploadPrefix}>
                <FormControlLabel value="default_prefix" control={<Radio />} label={t('tools.customImageUploadPrefixDefaultPrefix')} />
                <FormControlLabel value="no_prefix" control={<Radio />} label={t('tools.customImageUploadPrefixNoPrefix')} />
                <FormControlLabel value="custom_prefix" control={<Radio />} label={t('tools.customImageUploadPrefixCustomPrefix')} />
                {this.state.customImageUploadPrefixState == 'custom_prefix' &&
                  <div style={{marginLeft: 36}}>
                    <TextField
                      disabled={this.state.customImageUploadPrefixState == 'custom_prefix' ? false : true}
                      // commented out error report attributes
                      // currently there are no useful errors to report
                      //error={this.state.customImageUploadPrefixWarning ? true : false}
                      //label={t('tools.customImageUploadPrefixCustomPrefix')}
                      //helperText={this.state.customImageUploadPrefixWarning ? this.state.customImageUploadPrefixWarning : null }
                      //id={this.state.customImageUploadPrefixWarning ? "outlined-error-helper-text" : "outlined-basic"}
                      id="outlined-basic"
                      value={this.state.customImageUploadPrefixState === 'custom_prefix' && this.state.customImageUploadPrefixValue ? this.state.customImageUploadPrefixValue : null}
                      onChange={(e) => this.setState({ customImageUploadPrefixValue: e.target.value, saveCustomImageUploadPrefixSuccess: null })}

                      variant="outlined"
                      InputProps={{ inputProps: { maxLength: 20, minLength: 1 } }}
                    />
                    <Typography
                      style={{marginTop: 5, color:'grey'}}>
                        {t('tools.customImageUploadPrefixCleanupInfo')}
                    </Typography>
                  </div>
                }
              </RadioGroup>

              <Button
                onClick={this.saveCustomImageUploadPrefix}
                variant="contained"
                color="secondary"
                style={{
                  width: 200,
                  marginLeft: 'auto',
                  backgroundColor: this.state.saveCustomImageUploadPrefixSuccess ? "green" : ""
                }}
              >
              {this.state.saveCustomImageUploadPrefixSuccess ? (
                <div>
                  <CheckCircleIcon style={{fontSize: 18, marginTop: -3, marginRight: 5}}/>
                  {t('system.saveSuccess')}
                </div>
              ) : (
                <div>
                  {t('system.save')}
                </div>
              )}
              </Button>

            </FormControl>
        </Paper>

        <br />      
        <Paper className={classes.root} elevation="1">
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.staticTranslationsTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.staticTranslationsInfo')}
            </Typography>
            <FormControlLabel
              control={<Switch checked={this.state.showStaticMenuEntry} onChange={this.toggleshowStaticMenuEntry} />}
              label={this.state.showStaticMenuEntry ? 'enabled' : 'disabled'}
            />
          </FormControl>
        </Paper>

        <br />
        <br />
        <Typography color="textPrimary" variant="h4" gutterBottom component="h2" classes={{root: classes.h2}}>
          {t('tools.titleTools')}
        </Typography>
        <Divider className="divider" />
        <Paper className={classes.root} elevation="1">
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('tools.poSplitTitle')}</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              {t('tools.poSplitInfo')}
              <br />
              <br />
              {t('tools.poSplitUpload')}
              <br />
              <Button onClick={e => {this.import.click();}} variant="contained" color="secondary" disabled={this.state.progressingPoFile} fullWidth={false}>
                {t('tools.poSplitUploadSelect')} {this.state.progressingPoFile && <CircularProgress size={20} style={{marginLeft: 12}} />}
              </Button>
            </Typography>
            <input
              style={{display: 'none'}}
              accept={'.po'}
              ref={(ref) => this.import = ref}
              type="file"
              onChange={(event) => {this.onUploadPO(event)}}/>
          </FormControl>
        </Paper>

      </React.Fragment> 
    )
  }
}

const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  langifyFeatures: shop.shop.langify_features,
  customImageUploadPrefix: shop.shop.settings ? shop.shop.settings.custom_image_upload_prefix : '',
  editLanguage: shop.shop.edit_language,
  languages: languages.languages
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveShop,
      enableLangifyFeature,
      disableLangifyFeature
    },
    dispatch
  )

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    //overflowX: 'auto',
    padding: 24,
  },
  subtext: {
    padding: 2,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  h2: {
    color: '#333 !important', 
    fontSize: 28
  },
  content: {
    color: '#999'
  },
  spacer: {
    height: 24
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(withSnackbar(Settings)))))