import React, { Component } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import withRouter from 'with-router'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from "react-router-dom";
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import static_theme from '../../modules/static_theme';

import PartnerIntegrationsSettings from '../../components/PartnerIntegrations'
import LoadingIndicator from '../../components/LoadingIndicator'
import API from '../../api/api';

let categories = {
  'search': 'Search Apps',
  'review': 'Review Apps',
  'bundle': 'Bundle Apps',
  'pagebuilder': 'Page Builder',
  'seo': 'SEO & Tools',
  'mobile': 'Mobile Apps',
}

/**
 * 
 * 
 * 
  {
    slug: "sitemapper",
    category: 'SEO & Tools',
    title: 'Sitemapper Pro Sitemap Builder',
    subtitle: 'Shopinet',
    description: 'Generate a sitemap page for your store, improve SEO, and help customers to find your products.',
    logo: 'sitemapper.jpg',
    link: '',
    storeLink: 'https://apps.shopify.com/sitemap-page',
    docsLink: '',

    partnerIntegration: { 
      data: {
        apiKey: String
      }
      modules: ['enable', 'apiKey'] // 'enable' | 'apiKey'
    }
  }

 */

class RecommendedApps extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      category: '',
      partners: [],
      loading: true,
      activeIntegartions: [],
      error: null
    };
  }
  
  componentDidMount() {
    API.partnerGetAll((res) => {
      if(res.success === true) {
        let newPartners = JSON.parse(JSON.stringify(res.partners));
        let newActiveIntegartions = [];
        newPartners.forEach((partner) => {
          if(partner.partnerIntegration.integration !== null) {
            newActiveIntegartions.push(partner);            
          }
        });
        this.setState({
          loading: false,
          activeIntegartions: newActiveIntegartions,
          partners: newPartners
        });
      } else {
        this.setState({
          loading: false,
          error: res.error
        });
      }
    })
  }

  componentWillUnmount() {
    
  }

  handleChange = (event) => {
    this.setState({
      category: event.target.value
    })
  }

  _getCategoryIdByLabel = (label) => {
    var found = Object.keys(categories).filter(function(key) {
      return categories[key] === label ? key : null;
    });
    if (found.length) {
       return found[0];
    }
  }

  orderByCat = (partners) => {
    return partners.sort((a, b) => {
      const categoryA = a.category.toLowerCase();
      const categoryB = b.category.toLowerCase();
  
      if (categoryA < categoryB) {
        return -1;
      }
      if (categoryA > categoryB) {
        return 1;
      }
      return 0;
    }); 
  }

  renderPartnerCard = (item, index, divider) => {
    const { classes, t } = this.props;
    const { activeIntegartions, category } = this.state;

    //console.log(item.partnerIntegration);


    return <React.Fragment>
        {divider &&
          divider
        }
        <Card key={`card-${item.title}`} className={classes.cardRoot}>
          
          <div className={classes.banner}>
            <div
              className={classes.media}
            >
              <img src={'https://v2.langify-app.com/files/images/recommendedApps/' + item.logo.replace('.jpg', '_b.jpg')} alt={item.title}/>
              <div className={classes.diffusor}></div>
            </div>

            <span
              className={classes.mediaLogo}
              style={{backgroundImage: `url(https://v2.langify-app.com/files/images/recommendedApps/${item.logo})`}}
            />
          </div>
          <CardContent className={classes.cardContent}>

            <Typography gutterBottom variant="h5" component="h4" align="center">
              {item.title} <br />
            </Typography>

            {item.subtitle &&
              <Typography gutterBottom variant="caption" component="p" align="center" style={{marginBottom: 12}}>
                {item.link ?
                  <a href={item.link} target="_blank" className={classes.companyLink}>
                    <Chip label={item.subtitle} color="default" size="small" variant="outlined" style={{cursor: 'pointer'}} />
                  </a>
                :
                  <Chip label={item.subtitle} color="default" size="small" variant="outlined" />
                }
                <br />
              </Typography>
            }
            
            <Typography variant="body2" color="textSecondary" component="p">
              {item.description}
            </Typography>
          </CardContent>
        <CardActions className={classes.cardActions}>
          {item.docsLink &&
            <Button size="small" color="primary" href={item.docsLink} target="_blank">
              {t('partnerIntegrations.documentationBtn')}
            </Button>
          }
          {(item.storeLink && !item.partnerIntegration.modules) &&
            <Button size="small" color="secondary" variant="contained" href={item.storeLink} target="_blank">
              {t('partnerIntegrations.getAppBtn')}
            </Button>
          }
          {item.partnerIntegration.modules &&
            <PartnerIntegrationsSettings
              partnerHandle={item.slug}
              partnerData={item}
              
              activeIntegration={activeIntegartions.find(x => x.slug === item.slug)}
              partnerIntegration={item.partnerIntegration.integration}
            />
          }
        </CardActions>
      </Card>
    </React.Fragment>
  }

  render() {
    const { classes, t } = this.props;
    const { category, activeIntegartions, partners } = this.state;
    let lastCategory = '';

    return (
      <React.Fragment>
        <LoadingIndicator 
          pending={this.state.loading}
        />


        {activeIntegartions.length > 0 &&
          <div className={'page-content'}>
            <Typography variant="h2" gutterBottom component="h2">
              {t('partnerIntegrations.activeIntegrations')}
            </Typography>
            <Divider className="divider-white" />    
            <div className={classes.root}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="stretch"
                spacing={10}
                className={classes.gridRoot}
              >
                {
                  activeIntegartions.map((item, index) => {
                    return this.renderPartnerCard(item, index, null)
                  })
                }
              </Grid>
            </div>          
          </div>
        }


        <Typography variant="h4" gutterBottom component="h2" 
          className={activeIntegartions.length > 0 ? classes.blueOverwrite : null}
        >
          {t('partnerIntegrations.recommendedApps')}
          <FormControl variant="outlined" margin="none" className={classNames(classes.formControl, activeIntegartions.length > 0 ? 'blue' : null)}>
            <Select
              id="category-select"
              value={category}
              onChange={this.handleChange}
              margin="none"
              size="small"
              displayEmpty
            >
              <MenuItem value=""><em>{t('partnerIntegrations.selectCategory')}</em></MenuItem>
              {Object.entries(categories).map((item) => {
                return <MenuItem value={item[0]}>
                  {category === item[0] && <span class="selected-label">{t('partnerIntegrations.category')}</span>}
                  {item[1]}
                </MenuItem>
              })}
            </Select>
          </FormControl>           
        </Typography>
        <Divider className={!activeIntegartions.length > 0 ? 'divider-white' : 'divider'} />    
        <div className={classes.root}>
          <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="stretch"
            spacing={10}
            className={classes.gridRoot}
          >
            {this.orderByCat(partners).map((item, index) => {
                let divider = null;
                if(lastCategory !== item.category) {
                  lastCategory = item.category;
                  divider = <Grid item xs={12} style={{padding: 0, margin: '0 20px'}}>
                    <h3 style={{color: (index === 0 && activeIntegartions === []) ? '#ffffff' : 'grey'}}>{item.category}</h3>
                    <Divider />
                  </Grid>
                }

                return (this._getCategoryIdByLabel(item.category) === category || category === '') && this.renderPartnerCard(item, index, divider)
              })
            }
          </Grid>
        </div>

      </React.Fragment> 
    )
  }

  _rerender = (event) => {
    console.log('update');
    this.forceUpdate();
  }
}

const mapStateToProps = ({ shop }) => ({
  editLanguage: shop.shop.edit_language
})

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflow: 'hidden',
    padding: 24,
  },
  subtext: {
    padding: 2,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  content: {
    color: '#999'
  },
  spacer: {
    height: 24
  },
  gridRoot: {
    //margin: '20px -40px',
    justifyContent: 'start',
  },
  cardRoot: {
    maxWidth: 325,
    width: 325,
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    justifyContent: 'start',
    position: 'relative'
  },
  banner: {
    position: 'relative',
    //backgroundColor: '#000',
  },
  media: {
    height: 120,
    opacity: 1,
    borderBottom: 'solid 1px #eeeeee',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      filter: 'contrast(200%) grayscale(50%) blur(4px) opacity(0.75)'
    }
  },
  mediaLogo: {
    width: 75,
    height: 75,
    position: 'absolute',
    display: 'inline-block',
    top: 60,
    left: 16,
    boxShadow: '2px -2px 10px 0px rgba(0,0,0,0.35)',
    border: 'solid 4px #fff',
    backgroundSize: 'contain'
  },
  cardContent: {
    height: '100%',
    marginTop: 10,
  },
  cardActions: {
    justifyContent: 'end',
    
  },
  diffusor: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.25)'
  },
  companyLink: {
    color: '#1a237e',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  blueOverwrite: {
    color: '#1a237e !important',
    marginTop: 60,
  },
  formControl: {
    float: 'right',
    marginTop: -4,
    color: 'white',
    '& .MuiOutlinedInput-input': {
      padding: '12px 32px 12px 20px',
      color: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .5) !important',
    },
    '& .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .5) !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .23) !important',
    },
    '& .MuiSelect-icon': {
      color: 'rgba(255, 255, 255, 0.54) !important',
    },

    '&.blue': {
      color: '#1a237e',
      '& .MuiOutlinedInput-input': {
        padding: '12px 32px 12px 20px',
        color: '#1a237e'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 35, 126, 0.23)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 35, 126, .5) !important',
      },
      '& .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 35, 126, .5) !important',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 35, 126, .23) !important',
      },
      '& .MuiSelect-icon': {
        color: 'rgba(26, 35, 126, 0.54) !important',
      }
    }
  }
})

export default withRouter(connect(
  mapStateToProps,
  null,
)(withStyles(styles)(withTranslation()(RecommendedApps))))