import React from 'react';
import { syncTheme, syncAsset, saveAsset, syncCheckout, saveCheckout, syncSections, saveSections } from '../../modules/theme'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';

const styles = theme => ({

});

class Theme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      general: [
      {
        id: 'checkout',
        synchronized: false,
        title: 'checkout',
        info: '',
        fields: []
      },{
        id: 'sections',
        synchronized: false,
        title: 'sections',
        info: '',
        fields: []
      }
      ],
      assets: []
    }
  }

  componentDidMount() {
    this.props.syncTheme(this.props.themes.edit_theme)
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncTheme(this.props.themes.edit_theme)
      return
    }

    const currentTheme = this.props.themes.edit_theme;
    const newTheme = newProps.themes.edit_theme;
    if(currentTheme != newTheme) {
      this.props.syncTheme(newTheme)
      return
    }

    if(this.props.theme.sync_checkout_pending === true && newProps.theme.sync_checkout_pending === false) {
      const sectionFields = this.state.general.find(x => x.id === 'sections').fields.slice()

      let checkoutFields = []
      for(let checkoutKey in newProps.theme.checkout) {
        const item = newProps.theme.checkout[checkoutKey]
        checkoutFields.push({
          id: item.id,
          source: item.source || '',
          target: item.target || '',
          multiline: true,
          rows: 5
        })
      }

      this.setState({
        general: [
        {
          id: 'checkout',
          synchronized: false,
          title: 'checkout',
          info: '',
          fields: checkoutFields
        },{
          id: 'sections',
          synchronized: false,
          title: 'sections',
          info: '',
          fields: sectionFields
        }
        ]
      });
    }

    if(this.props.theme.sync_sections_pending === true && newProps.theme.sync_sections_pending === false) {
      const checkoutFields = this.state.general.find(x => x.id === 'checkout').fields.slice()

      let sectionFields = []
      newProps.theme.sections.forEach(item => {
        sectionFields.push({
          id: item.id,
          source: item.source || '',
          target: item.target || '',
          multiline: true,
          rows: 5
        })
      })
      
      this.setState({
        general: [
        {
          id: 'checkout',
          //progress: 100,
          synchronized: false,
          title: 'checkout',
          info: '',
          fields: checkoutFields
        },{
          id: 'sections',
          //progress: 75,
          synchronized: false,
          title: 'sections',
          info: '',
          fields: sectionFields
        }
        ]
      });
    }

    const newAssets = [];
    for(let assetKey in newProps.theme.assets) {
      const item = newProps.theme.assets[assetKey];
      let fields = []
      for(let stringKey in item.assetStrings) {
        const assetString = item.assetStrings[stringKey]
        let usedSource = ''

        if(assetString.source.stringForView)  {
          usedSource = assetString.source.stringForView
        } else {
          usedSource = assetString.source.string
        }

        fields.push(
        {
          id: assetString.id,
          isSetting: assetString.isSetting,
          isLocalizedString: assetString.isLocalizedString,
          isLocalizedHtmlString: assetString.isLocalizedHtmlString,
          isReplacement: assetString.isReplacement,
          pluralizedPlaceholder: assetString.pluralizedPlaceholder,
          multiline: true,
          isHidden: assetString.pluralizedPlaceholder,
          rows: 5,
          source: usedSource,
          target: assetString.target.string || ''
        })
      }

      newAssets.push({
        id: item.id,
        progress: 25,
        synchronized: item.synchronized,
        title: item.key,
        info: '',
        fields: fields
      })
    }

    this.setState({assets:newAssets});
  }

  onExpandAsset(id) {
    if(this.state.assets.find(x => x.id == id).synchronized !== true) {
      this.props.syncAsset(this.props.themes.edit_theme, id, this.props.editLanguage)
    }
  }

  onExpandCheckout(id) {
    if(id === 'checkout' && this.props.theme.checkout_synchronized !== true) {
      this.props.syncCheckout(this.props.themes.edit_theme, this.props.editLanguage)
    }
    if(id === 'sections' && this.props.theme.sections_synchronized !== true) {
      this.props.syncSections(this.props.themes.edit_theme, this.props.editLanguage)
    }
  }

  onSaveCheckout = items => {
    const checkoutItem = items.find(x => x.id === 'checkout')
    if(checkoutItem) {
      const usedItems = []
      checkoutItem.fields.forEach(field => {
        usedItems.push({
          id: field.id,
          text: field.target
        })
      })
      this.props.saveCheckout(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
    }

    const sectionsItem = items.find(x => x.id === 'sections')
    if(sectionsItem) {
      const usedItems = []
      sectionsItem.fields.forEach(field => {
        usedItems.push({
          id: field.id,
          text: field.target
        })
      })
      this.props.saveSections(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
    }
  }

  onSaveAsset = items => {
    const usedItems = [];
    items.forEach(item => {
      const usedFields = [];
      item.fields.forEach(field => {
        usedFields.push({
          id: field.id,
          isLocalizedHtmlString: field.isLocalizedHtmlString,
          isLocalizedString: field.isLocalizedString,
          isReplacement: field.isReplacement,
          isSetting: field.isSetting,
          pluralizedPlaceholder: field.pluralizedPlaceholder,
          text: field.target
        })
      })

      usedItems.push({
        id: item.id,
        strings: usedFields
      });
    })

    this.props.saveAsset(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
  }

  render() {
    const { classes } = this.props

    if(this.props.theme.sync_theme_pending === true) {
    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        Theme Translations
      </Typography>
      <Divider className="divider-white" />
      <ItemsListSkeleton rows="2" hideBottomToolbar/>
      <ItemsListSkeleton rows="10"/>
      <LoadingIndicator
        pending={this.props.theme.sync_theme_pending}
        progress={this.props.theme.progress}
        withLogo
      />

    </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        Theme Translations
      </Typography>
      <Divider className="divider-white" />
      <ItemsList 
        virtualized={true}
        type="theme"
        pagination={false}
        onExpand={(id) => {this.onExpandCheckout(id)}}
        onCollapsed={this.onCollapsedCheckout}
        isPending={this.props.theme.sync_theme_pending || this.props.theme.sync_asset_pending || this.props.theme.save_asset_pending || this.props.theme.sync_checkout_pending || this.props.theme.save_checkout_pending || this.props.theme.sync_sections_pending || this.props.theme.save_sections_pending}
        isSaving={this.props.theme.save_checkout_pending || this.props.theme.save_sections_pending}
        onSave={this.onSaveCheckout}
        data={this.state.general}
        isSticky={false}
        paginate={false}
        hideFilterbar
        hideSorting
        extraRouteSegment={this.props.themes.edit_theme}
        toolbarTitle="Additional Fields"
        allowAutoTranslation={true}
      />
      <ItemsList 
        type="theme"
        pagination={true}
        onExpand={(id) => {this.onExpandAsset(id)}}
        onCollapsed={this.onCollapsedAsset}
        isPending={this.props.theme.sync_theme_pending || this.props.theme.sync_asset_pending || this.props.theme.save_asset_pending /*|| this.props.theme.sync_checkout_pending || this.props.theme.save_checkout_pending || this.props.theme.sync_sections_pending || this.props.theme.save_sections_pending*/}
        isSaving={this.props.theme.save_asset_pending}
        onSave={this.onSaveAsset}
        data={this.state.assets}
        extraRouteSegment={this.props.themes.edit_theme}
        allowAutoTranslation={true}
      />
      <LoadingIndicator
        pending={this.props.theme.sync_theme_pending || this.props.theme.sync_asset_pending || this.props.theme.save_asset_pending || this.props.theme.sync_checkout_pending || this.props.theme.save_checkout_pending || this.props.theme.sync_sections_pending || this.props.theme.save_sections_pending}
        loadingType={(this.props.theme.save_sections_pending || this.props.theme.save_asset_pending || this.props.theme.save_checkout_pending) ? 'saving' : 'loading'}
        progress={this.props.theme.progress}
        withLogo
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ shop, themes, theme }) => ({
  editLanguage: shop.shop.edit_language,
  themes: themes,
  theme: theme
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncTheme,
      syncAsset,
      saveAsset,
      syncCheckout,
      saveCheckout,
      syncSections,
      saveSections
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Theme)))