import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import { SnackbarProvider } from 'notistack';
import App from './containers/app'

import 'flag-icon-css/css/flag-icon.min.css';
import 'sanitize.css/sanitize.css';
import './index.css';

const target = document.querySelector('#root')

const theme = createTheme({
  palette: {
    //type: 'dark',
    text: {
      color: 'rgba(0, 0, 0, 0.87)',
      contrastText: '#fff',
    },
    primary: {
      light: '#757ce8',
      main: '#1a237e',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7c88cc',
      main: '#5c6bc0',
      dark: '#404a86',
      contrastText: '#fff',
    },
    /*success: {
      main: '#2F5233',
    },*/
    /*info: {
      light: '#4fc3f7',
      main: '#29b6f6',
      dark: '#0288d1',
    },*/
    /*warning: {
      light: '#ffb74d',
      main: '#ffa726',
      dark: '#f57c00',
    },*/
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    custom: {
      light: '#ffab00',
      main: '#ffab00',
      dark: '#ffab00',
      contrastText: '#fff',
    }
  },
  colors: {
    primary: '#1a237e',
    secondary: '#5c6bc0',
    white: '#ffffff',
    gray: '#999',
  },
  overrides: {
    MuiTypography: {
      //root: {color: '#999'}
    },
    MuiCheckbox: {
      root: {
        padding: '8px 12px',
      }
    },
    MuiRadio: {
      root: {
        padding: '8px 12px',
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px 20px',
      }
    },
    MuiInputBase: {
      input: {
        padding: '14px 0',
        height: 'initial',
      }
    },
    MuiInputLabel: {
      root: {
        //padding: '0 24px',
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, .5)',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '13000 !important',
      },
    },
    MuiPopover: {
      root: {
        zIndex: '13000 !important',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiTimeline: {
      root: {
        '& > :before': {
          flex: '0 !important',
        }
      }
    },
    MuiTimelineItem: {
      '& missingOppositeContent:before': {
        flex: '0 !important',
      },
      '& alignLeft:before': {
        flex: '0 !important',
      }
    },
    MuiTimelineContent: {
      root: {
        flex: 3,
      }
    },
    MuiTimelineOppositeContent: {
      root: {
        flex: '0 !important',
      }
    },
  }
});

render(
  <Router>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5} dense={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
              <ParallaxProvider>
                <App />       
              </ParallaxProvider>
          </ConnectedRouter>
        </Provider>      
      </SnackbarProvider>
    </ThemeProvider>    
  </Router>
,
  target
)
