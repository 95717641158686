import React from 'react';
import withRouter from 'with-router'
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getTranslationProgress } from '../../modules/stats'
import classNames from 'classnames';
import {Radar, PieChart, Pie, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, BarChart, Bar, LabelList, Label, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const data02 = [
  {
    "name": "Translated Fields",
    "value": 21400
  },
  {
    //"name": "Untranslated Fields",
    //"value": 4567
  },
];

class TranslationProgressSection extends React.Component {

  pendingLock = false;
  
  constructor(props) {
    super(props);
    
    this.state = {
      progressData: [],
      progressMenuAnchor: null,
      showProgressMenu: false,
      currentLanguage: props.editLanguage,
    };
  }

  componentDidMount() {
    this.props.getTranslationProgress(this.props.editLanguage, this.props.editTheme);
    this.setProgressFilter(this.props.editLanguage, this.props.editTheme);
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    const {t} = this.props;
    if(currentLanguage != newLanguage) {
      this.setState({
        currentLanguage: newLanguage
      });
      this.props.getTranslationProgress(newLanguage, this.props.editTheme)
      return
    }

    const currentTheme = this.props.editTheme;
    const newTheme = newProps.editTheme;
    if(currentTheme != newTheme) {
      this.props.getTranslationProgress(newLanguage, newTheme)
      return
    }

    if((this.props.stats.get_translation_progresses_pending === true && newProps.stats.get_translation_progresses_pending === false)) {
      this.pendingLock = true;
      const newProgressData = [];
      for(let i = 0; i < newProps.stats.translation_progresses.length; i++) {
        const item = newProps.stats.translation_progresses[i];
        if(item.type !== 'products' && item.type !== 'collections' && item.type !== 'blogs' && item.type !== 'articles') {
          newProgressData.push({
            name: t(`progressTile.${item.label.toLowerCase()}`),
            a: {
              name: item.label,
              value: item.progress ? item.progress : 0,
            },
            total: item.progress ? item.progress : 0,
          })
        } else {
          if(item.type === 'products' || item.type === 'blogs') {
            let totalValue = (item.progress ? item.progress : 0) + (newProps.stats.translation_progresses[i+1].progress ? newProps.stats.translation_progresses[i+1].progress : 0);
            newProgressData.push({
              name: item.type === 'products' ? t('progressTile.inventory') : t(`progressTile.${item.label.toLowerCase()}`),
              a: {
                name: item.label,
                value: item.progress ? item.progress / 2 : 0,
              },
              b: {
                name: newProps.stats.translation_progresses[i+1].label,
                value: newProps.stats.translation_progresses[i+1].progress ? newProps.stats.translation_progresses[i+1].progress / 2 : 0,
              },
              total: Math.round((totalValue / 2) * 100) / 100,
            });
          }
        }
      }

      this.setState({
        progressData: newProgressData
      });

      this.pendingLock = false;
    }
  }

  setProgressFilter = (languageId, themeId) => {
    this.props.getTranslationProgress(languageId, themeId);
    this.setState({
      showProgressMenu: false, 
      currentLanguage: languageId
    });
  }

  openProgressMenu = (e) => {
    this.setState({
      progressMenuAnchor:e.target,
      showProgressMenu: true
    });
  }

  closeProgressMenu = () => {
    this.setState({showProgressMenu: false});
  }

  renderCustomizedLabel = (props) => {
    const { x, y, width, height, value} = props;
    const radius = 8;
    let posX = 0;
    if(width === 0) {
      posX = value > 25 ? (x - 25) : (x + 25);
    } else {
      posX = value > 25 ? (x + width - 25) : (x + width + 25);
    }
    return (
      <g>
        <text x={posX} y={y + radius} fill={value > 25 ? '#fff' : '#5c6bc0'} textAnchor="middle" dominantBaseline="middle">
          {value}%
        </text>
      </g>
    );
  };
  renderCustomizedTooltip = ({ active, payload, label }) => {
    if(payload.length > 0) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${label}: ${!payload[0].payload.b ? payload[0].payload.a.value : Math.round((payload[0].payload.a.value + payload[0].payload.b.value) * 100) / 100}%`}
          </p>
          {payload[0].payload.b &&
            <div>
              <small className="subfield a">{payload[0].payload.a.name}: {payload[0].payload.a.value * 2}%</small>
              <small className="subfield b">{payload[0].payload.b.name}: {payload[0].payload.b.value * 2}%</small>
            </div>
          }
        </div>
      );      
    }
    return null;
  };

  renderSelect = () => {
    const usedLabel = this.props.languages.languages.find(x => x.id == this.state.currentLanguage).name;
    return <React.Fragment>
      <span onClick={(e) => {this.openProgressMenu(e)}} style={{cursor:'pointer',float:'right',fontSize: 16}}><span style={{position:'relative',left:'4px'}}>{usedLabel}</span> <ExpandMoreIcon/></span>
      <Menu
        dense="true"
        onClose={this.closeProgressMenu}
        anchorEl={this.state.progressMenuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.showProgressMenu}
      >
        {
          this.props.languages.languages.map((item, i) => {
            if(!item.base) {
              //console.log(this.state.currentLanguage, item.id)
              return <MenuItem key={`language-item-${i}`} selected={this.state.currentLanguage === item.id} value={item.id} onClick={() => {this.setProgressFilter(item.id, this.props.editTheme)}}>{item.name}</MenuItem>
            }
          })
        }
      </Menu>
    </React.Fragment>
  }

  render() {
    const { t, classes } = this.props;

    if(this.props.stats.get_translation_progresses_pending && !this.pendingLock) {
      return (
        <div variant="body1" className={classNames(classes.contentContainer)}>
            <Card className={classes.card}>
              <CardContent className={classNames(classes.cardContent, classes.fluidContent)} style={{height: '313px'}}>
                <Typography variant="subtitle1" style={{margin: '0 16px 0 24px', position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
                  {t('progressTile.title')}
                  { this.renderSelect() }
                </Typography>
                <Divider style={{marginTop: 16, marginBottom: 0}} />

                <CircularProgress color="primary" size={60} style={{margin: 'auto'}} thickness={2}/>
              </CardContent>
            </Card>
        </div>
      );
    } else {
      return (
        <div variant="body1" className={classNames(classes.contentContainer)}>
            <Card className={classes.card}>
              <CardContent className={classNames(classes.cardContent, classes.fluidContent)} style={{height: '313px'}}>
                <Typography variant="subtitle1" style={{margin: '0 16px 0 24px', position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
                {t('progressTile.title')}
                  { this.renderSelect() }
                </Typography>
                <Divider style={{marginTop: 16, marginBottom: 0}} />
                <ResponsiveContainer height={250}>

                  <BarChart 
                    className={this.props.classes.radarChart} 
                    data={this.state.progressData} 
                    layout="vertical" 
                    margin={{left: 24}}
                    maxBarSize={100}
                  >
                    <CartesianGrid 
                      stroke="#ccc" 
                      strokeDasharray="4" 
                      layout="vertical" 
                    />
                    <Tooltip content={this.renderCustomizedTooltip} arrow />
                    <Bar 
                      dataKey={val => val.a.value}
                      stackId="a"
                      fill="#5c6bc0" 
                      barSize={14} 
                      maxBarSize={100}
                    />
                    <Bar 
                      dataKey={val => val.b ? val.b.value : 0}
                      stackId="a"
                      fill="rgba(92, 107, 192, 0.7)" 
                      barSize={14} 
                      maxBarSize={100}
                    >
                      <LabelList 
                        dataKey="total" 
                        content={this.renderCustomizedLabel}
                      />
                    </Bar>
                    <XAxis type="number" stacked={true} dataKey="total" padding={{bottom: 0}} domain={[0, 100]} hide />
                    <YAxis type="category" dataKey="name" interval={0} />
                  </BarChart>    

                </ResponsiveContainer>
              </CardContent>
            </Card>
        </div>
      )
    }
  }
}

const styles = (theme, state) => ({
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fff',
    background: 'linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%)',
    position: 'relative',
    padding: '16px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    }
  },
  fluidContent: {
    padding: '0 !important',
  },
  radarChart: {
    padding: '10px',
    '& text': {
      fontSize: 13,
    },
    '& .recharts-cartesian-axis-tick-value': {
      fontSize: 13,
      fill: '#000',
    },
    '& .recharts-surface': {
      height: '230px !important',
      overflow: 'initial'
    }
  },
});


const mapStateToProps = ({shop, languages, themes, stats}) => ({
  stats: stats,
  languages: languages,
  editTheme: themes.edit_theme,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTranslationProgress
    },
    dispatch
  )
  
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TranslationProgressSection))))
