
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import classNames from 'classnames';

import RichTextEditor from '../../components/RichTextEditor'
import LoadingIndicator from '../../components/LoadingIndicator';
import ItemsListSkeleton from '../../components/ItemsListSkeleton';

import { ReactComponent as Logo } from './logo.svg';

const styles = theme => ({
  root: {
    
  },
  headerBanner: {
    textAlign: 'center',
    height: 200, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
});


const html = `
<h1>Lorem ipsum</h1>
<div>Lorem ipsum</div>
<ul id="ul1" class="unordered-list">
<li>Our signature shift dress featured in a beautiful stripe dobby cotton gauze. Short sleeve, with a keyhole in the back.</li>
<li>Made in the <strong>USA</strong></li>
<li>100% Cotton</li>
<li>Unlined</li>
<li>Hand wash/Dry clean</li>
<li>Fits true to size</li>
<li>Model is 5'9" and wearing a size small</li>
</ul>
<script>alert('hi')</script>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<style>li { color: red }</style>
`;
const html2 = `
<p>Lorem ipsum dolor</p>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
`;


class Design extends React.Component {
  
  state = {
    open: false,
  };

  componentDidMount() {
    const logo = document.querySelectorAll('#langify g > path')
    for(let i = 0; i < logo.length; i++) {
      console.log(logo[i].getTotalLength())
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>


        <LoadingIndicator
          pending={open}
          progress={50}
          withLogo
        />

        <div className={classNames('header-banner', classes.headerBanner)}>
          <div className={classes.headerBannerInner}>
            <Typography variant="h3" component="h2" gutterBottom> 
                LovelyApps UI
            </Typography>
            <Typography variant="h5" gutterBottom color="textSecondary">
              The react & redux user interface based on material design
            </Typography>  

          </div>
        </div>
        
        <div className={classNames('themes-section', classes.contentContainer)}>
          <ItemsListSkeleton 
            rows="5"
            hideBottomToolbar
          />          
          <Button 
            size="large"
            variant="outlined"
            style={{marginTop: 24}}
            onClick={this.handleClick}>Show Loading Indicator</Button>
        </div>

        <div className={classNames('themes-section', classes.contentContainer)}>
          <Typography variant="h5" color="">Richtext Editor</Typography>
          <Divider />
          <div className={classNames('themes-section', classes.contentContainer)}>
            <RichTextEditor
              id="e1"
              initialData={''}
              sourceData={html}
              onChange={() => {}}
              config={{
                placeholder: 'Enter translation here...'
              }}
              hasImportOriginalBtn
              hasAutoTranslateBtn
            />
          </div>

          <Typography variant="h5" color="">Richtext Editor (Densed)</Typography>
          <Divider />
          <div className={classNames('themes-section', classes.contentContainer)}>
            <RichTextEditor
              id="e2"
              initialData={''}
              sourceData={html}
              onChange={() => {}}
              config={{
                placeholder: 'Enter translation here...'
              }}
              hasImportOriginalBtn
              hasAutoTranslateBtn
              densed
            />
          </div>

          <Typography variant="h5" color="">Richtext Editor (Air Toolbar)</Typography>
          <Divider />
          <div className={classNames('themes-section', classes.contentContainer)}>
            <RichTextEditor
              id="e3"
              initialData={'<p>Select me to see the <strong>Air Toolbar</strong> live in action.</p>'}
              sourceData={html}
              onChange={() => {}}
              config={{
                air: true,
                placeholder: 'Enter translation here...'
              }}
              hasImportOriginalBtn
              hasAutoTranslateBtn
              densed
            />
          </div>

        </div>
      </div>
    );
  }
}
Design.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRoot(withStyles(styles)(Design));
