import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

class Faq extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          FAQ
        </Typography>
        <Divider className="divider-white" />
        
        <Paper className={classes.root} elevation="1">
        
        </Paper>
      </React.Fragment> 
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
    padding: 24,
  },
  contentContainer: {
    marginBottom: theme.spacing(4),
  },
})

export default withStyles(styles)(Faq)