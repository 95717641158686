import API from '../api/api'

// ACTION TYPES
const GET_LANGUAGES_PENDING = 'GET_LANGUAGES_PENDING'
const GET_LANGUAGES_RESPONSE = 'GET_LANGUAGES_RESPONSE'
const CREATE_LANGUAGE_REQUEST = 'CREATE_LANGUAGE_REQUEST'
const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS'
const CREATE_LANGUAGE_ERROR = 'CREATE_LANGUAGE_ERROR'
const SAVE_LANGUAGE_REQUEST = 'SAVE_LANGUAGE_REQUEST'
const SAVE_LANGUAGE_SUCCESS = 'SAVE_LANGUAGE_SUCCESS'
const SAVE_LANGUAGE_ERROR = 'SAVE_LANGUAGE_ERROR'
const DELETE_LANGUAGE_REQUEST = 'DELETE_LANGUAGE_REQUEST'
const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS'
const DELETE_LANGUAGE_ERROR = 'DELETE_LANGUAGE_ERROR'
const RESTORE_LANGUAGE_REQUEST = 'RESTORE_LANGUAGE_REQUEST'
const RESTORE_LANGUAGE_SUCCESS = 'RESTORE_LANGUAGE_SUCCESS'
const RESTORE_LANGUAGE_ERROR = 'RESTORE_LANGUAGE_ERROR'
const PUBLISH_LANGUAGE_REQUEST = 'PUBLISH_LANGUAGE_REQUEST'
const PUBLISH_LANGUAGE_SUCCESS = 'PUBLISH_LANGUAGE_SUCCESS'
const PUBLISH_LANGUAGE_ERROR = 'PUBLISH_LANGUAGE_ERROR'
const UNPUBLISH_LANGUAGE_REQUEST = 'UNPUBLISH_LANGUAGE_REQUEST'
const UNPUBLISH_LANGUAGE_SUCCESS = 'UNPUBLISH_LANGUAGE_SUCCESS'
const UNPUBLISH_LANGUAGE_ERROR = 'UNPUBLISH_LANGUAGE_ERROR'
const LANGUAGE_EDITOR_OPEN = 'LANGUAGE_EDITOR_OPEN'
const LANGUAGE_EDITOR_CLOSE = 'LANGUAGE_EDITOR_CLOSE'

const initialState = {
  get_languages_pending: true,
  progress: 0,
  error: false,
  languages: [],
  available_language_codes: [],
  create_language_requesting: false,
  save_language_requesting: false,
  delete_language_requesting: false,
  restore_language_requesting: false,
  publish_language_requesting: false,
  unpublish_language_requesting: false,
  language_editor_is_open: false,
}

// REDUCERS
export default (state = initialState, action) => {
  switch(action.type) {
    case GET_LANGUAGES_PENDING: {
      return {
        ...state,
        get_languages_pending: true
      }
    }
    case GET_LANGUAGES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_languages_pending: false,
          error: false,
          languages: action.response.languages.slice(),
          available_language_codes: action.response.available_language_codes.slice()
        }
      } else {
        return {
          ...state,
          get_languages_pending: false,
          error: true
        }
      }
    }
    case CREATE_LANGUAGE_REQUEST: {
      return {
        ...state,
        create_language_requesting: true
      }
    }
    case CREATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        create_language_requesting: false,
        languages: action.languages.slice(),
        language_editor_is_open: false
      }
    }
    case CREATE_LANGUAGE_ERROR: {
      return {
        ...state,
        create_language_requesting: false
      }
    }
    case SAVE_LANGUAGE_REQUEST: {
      return {
        ...state,
        save_language_requesting: true
      }
    }
    case SAVE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        save_language_requesting: false,
        languages: action.languages.slice(),
        language_editor_is_open: false
      }
    }
    case SAVE_LANGUAGE_ERROR: {
      return {
        ...state,
        save_language_requesting: false
      }
    }
    case DELETE_LANGUAGE_REQUEST: {
      return {
        ...state,
        delete_language_requesting: true
      }
    }
    case DELETE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        delete_language_requesting: false,
        languages: action.response.languages.slice(),
        language_editor_is_open: false,
      }
    }
    case DELETE_LANGUAGE_ERROR: {
      return {
        ...state,
        delete_language_requesting: false
      }
    }
    case RESTORE_LANGUAGE_REQUEST: {
      return {
        ...state,
        restore_language_requesting: true
      }
    }
    case RESTORE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        restore_language_requesting: false,
        languages: action.response.languages.slice(),
      }
    }
    case RESTORE_LANGUAGE_ERROR: {
      return {
        ...state,
        restore_language_requesting: false
      }
    }
    case PUBLISH_LANGUAGE_REQUEST: {
      return {
        ...state,
        publish_language_requesting: true
      }
    }
    case PUBLISH_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: action.response.languages.slice(),
        publish_language_requesting: false
      }
    }
    case PUBLISH_LANGUAGE_ERROR: {
      return {
        ...state,
        publish_language_requesting: false
      }
    }
    case UNPUBLISH_LANGUAGE_REQUEST: {
      return {
        ...state,
        unpublish_language_requesting: true
      }
    }
    case UNPUBLISH_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: action.response.languages.slice(),
        unpublish_language_requesting: false
      }
    }
    case UNPUBLISH_LANGUAGE_ERROR: {
      return {
        ...state,
        unpublish_language_requesting: false
      }
    }
    case LANGUAGE_EDITOR_OPEN: {
      return {
        ...state,
        language_editor_is_open: true
      }
    }
    case LANGUAGE_EDITOR_CLOSE: {
      return {
        ...state,
        language_editor_is_open: false
      }
    }
    default:
      return state
  }
}

// ACTION CREATORS
export const getLanguages = () => {  
  return dispatch => {
    dispatch({
      type: GET_LANGUAGES_PENDING
    })

    API.getLanguages(response => {
      dispatch({
        type: GET_LANGUAGES_RESPONSE,
        response: response
      })
    })
  }
}

export const createLanguage = (data) => {
  return dispatch => {
    dispatch({
      type: CREATE_LANGUAGE_REQUEST
    })

    API.createLanguage(
      data,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: CREATE_LANGUAGE_SUCCESS,
          languages: response.languages
        })
      } else {
        dispatch({
          type: CREATE_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const saveLanguage = (language, data) => {
  return dispatch => {
    dispatch({
      type: SAVE_LANGUAGE_REQUEST
    })

    API.saveLanguage(
      language,
      data,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: SAVE_LANGUAGE_SUCCESS,
          languages: response.languages
        })
      } else {
        dispatch({
          type: SAVE_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const deleteLanguage = (language) => {
  return dispatch => {
    dispatch({
      type: DELETE_LANGUAGE_REQUEST
    })

    API.deleteLanguage(
      language,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: DELETE_LANGUAGE_SUCCESS,
          response: response
        })
      } else {
        dispatch({
          type: DELETE_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const restoreLanguage = (language) => {
  return dispatch => {
    dispatch({
      type: RESTORE_LANGUAGE_REQUEST
    })

    API.restoreLanguage(
      language,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: RESTORE_LANGUAGE_SUCCESS,
          response: response
        })
      } else {
        dispatch({
          type: RESTORE_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const publishLanguage = (language) => {
  return dispatch => {
    dispatch({
      type: PUBLISH_LANGUAGE_REQUEST
    })

    API.publishLanguage(
      language,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: PUBLISH_LANGUAGE_SUCCESS,
          response: response
        })
      } else {
        dispatch({
          type: PUBLISH_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const unpublishLanguage = (language) => {
  return dispatch => {
    dispatch({
      type: UNPUBLISH_LANGUAGE_REQUEST
    })

    API.unpublishLanguage(
      language,
      progress => {

      },
      response => {
      if(response.success === true) {
        dispatch({
          type: UNPUBLISH_LANGUAGE_SUCCESS,
          response: response
        })
      } else {
        dispatch({
          type: UNPUBLISH_LANGUAGE_ERROR
        })
      }
    });
  }
}

export const openLanguageEditor = () => {
  return dispatch => {
    dispatch({
      type: LANGUAGE_EDITOR_OPEN
    })
  }
}

export const closeLanguageEditor = () => {
  return dispatch => {
    dispatch({
      type: LANGUAGE_EDITOR_CLOSE
    })
  }
}