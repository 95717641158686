import API from '../api/api'

const GET_LANGIFY_NOTIFICATIONS_PENDING = 'GET_LANGIFY_NOTIFICATIONS_PENDING'
const GET_LANGIFY_NOTIFICATIONS_RESPONSE = 'GET_LANGIFY_NOTIFICATIONS_RESPONSE'

const SET_LANGIFY_NOTIFICATION_AS_VIEWED_PENDING = 'SET_LANGIFY_NOTIFICATION_AS_VIEWED_PENDING'
const SET_LANGIFY_NOTIFICATION_AS_VIEWED_RESPONSE = 'SET_LANGIFY_NOTIFICATION_AS_VIEWED_RESPONSE'

const SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_PENDING = 'SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_PENDING'
const SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_RESPONSE = 'SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_RESPONSE'

const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  get_langify_notifications_pending: true,
  set_langify_notification_as_viewed_pending: true,
  set_langify_notification_as_unviewed_pending: true,
  progress: 0,
  error: false,
  notifications: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case GET_LANGIFY_NOTIFICATIONS_PENDING: {
      return {
        ...state,
        get_langify_notifications_pending: true,
        error: false,
      }
    }
    case GET_LANGIFY_NOTIFICATIONS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_langify_notifications_pending: false,
          notifications: action.response.notifications
        }
      } else {
        return {
          ...state,
          get_langify_notifications_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case SET_LANGIFY_NOTIFICATION_AS_VIEWED_PENDING: {
      return {
        ...state,
        set_langify_notification_as_viewed_pending: true,
        error: false,
      }
    }
    case SET_LANGIFY_NOTIFICATION_AS_VIEWED_RESPONSE: {
      let error = false;
      let newNotifications = state.notifications.slice();

      if(action.response.success !== true) {
        error = {
          message: action.response,
          action: action.action,
        };
        let newNotification = newNotifications.find(x => x.id == action.notification.id)
        newNotification.viewed = action.notification.viewed
      } else {
        let newNotification = newNotifications.find(x => x.id == action.response.notification.id)
        newNotification.viewed = action.response.notification.viewed
      }
      return {
        ...state,
        notifications: newNotifications,
        set_langify_notification_as_viewed_pending: false,
        error: error,
      }
    }
    case SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_PENDING: {
      return {
        ...state,
        set_langify_notification_as_unviewed_pending: true,
        error: false,
      }
    }
    case SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_RESPONSE: {
      let error = false;
      let newNotifications = state.notifications.slice();

      if(action.response.success !== true) {
        error = {
          message: action.response,
          action: action.action,
        };
        let newNotification = newNotifications.find(x => x.id == action.notification.id)
        newNotification.viewed = action.notification.viewed
      } else {
        let newNotification = newNotifications.find(x => x.id == action.response.notification.id)
        newNotification.viewed = action.response.notification.viewed
      }
      return {
        ...state,
        notifications: newNotifications,
        set_langify_notification_as_unviewed_pending: false,
        error: error,
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const getLangifyNotifications = () => {  
  return dispatch => {
    dispatch({
      type: GET_LANGIFY_NOTIFICATIONS_PENDING
    })

    API.getLangifyNotifications(response => {
      dispatch({
        type: GET_LANGIFY_NOTIFICATIONS_RESPONSE,
        response: response,
        action: () => getLangifyNotifications()
      })
    })
  }
}

export const setLangifyNotificationAsViewed = (notificationId) => {  
  return dispatch => {
    dispatch({
      type: SET_LANGIFY_NOTIFICATION_AS_VIEWED_PENDING
    })

    API.setLangifyNotificationAsViewed(
      notificationId,
      response => {
        dispatch({
          type: SET_LANGIFY_NOTIFICATION_AS_VIEWED_RESPONSE,
          response: response,
          action: () => setLangifyNotificationAsViewed(notificationId)
        }
      )}
    )
  }
}

export const setLangifyNotificationAsUnviewed = (notificationId) => {  
  return dispatch => {
    dispatch({
      type: SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_PENDING
    })

    API.setLangifyNotificationAsUnviewed(
      notificationId,
      response => {
        dispatch({
          type: SET_LANGIFY_NOTIFICATION_AS_UNVIEWED_RESPONSE,
          response: response,
          action: () => setLangifyNotificationAsUnviewed(notificationId)
        }
      )}
    )
  }
}