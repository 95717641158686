import { ContactlessOutlined } from '@material-ui/icons';
import API from '../api/api';

const COUNT_WORDS_PENDING = 'COUNT_WORDS_PENDING';
const COUNT_WORDS_PROGRESS = 'COUNT_WORDS_PROGRESS';
const COUNT_WORDS_RESPONSE = 'COUNT_WORDS_RESPONSE';
const TRANSLATE_WORDS_PENDING = 'TRANSLATE_WORDS_PENDING';
const TRANSLATE_WORDS_PROGRESS = 'TRANSLATE_WORDS_PROGRESS';
const TRANSLATE_WORDS_RESPONSE = 'TRANSLATE_WORDS_RESPONSE';
const RESET_ERRORS = 'RESET_ERRORS';
const GET_RUNNING_TASKS_PENDING = 'GET_RUNNING_TASKS_PENDING';
const GET_RUNNING_TASKS_RESPONSE = 'GET_RUNNING_TASKS_RESPONSE';
const CLEAR_TASKS_PENDING = 'CLEAR_TASKS_PENDING';
const CLEAR_TASKS_RESPONSE = 'CLEAR_TASKS_RESPONSE';
const PREPARE_COUNT_WORDS = 'PREPARE_COUNT_WORDS';
const REWIND_BULK_SETTINGS = 'REWIND_BULK_SETTINGS';

const initialState = {
  prepare_word_count_pending: false,
  count_words_pending: false,
  count_progress: 0,
  count_tasks: {},
  all_count_jobs_count: 0,
  all_count_jobs_done: 0,
  translate_words_pending: false,
  translate_progress: 0,
  translate_tasks: {},
  all_translate_jobs_count: 0,
  all_translate_jobs_done: 0,
  total_words: 0,
  error: false,
  task_errors: [],
  bulk_rewind_settings: false,
  unfinished_process: false,
  is_task_querying: false,
}

const sumBy = (obj, field) => obj
  .map(item => {
    if(!item[1]) return null;
    return item[1][field];
  })
  .reduce((prev, curr) => prev + curr, 0);

const concatBy = (obj, field) => obj
  .map(item => {
    if(!item.task) return null;
    return item.task[field];
  })
  .reduce((prev, curr) => prev.concat(curr), []);

const taskKey2DataObject = (key) => {
  let split1 = key.split(':');
  let split2 = split1[2].split('_')
  return {
    store: split1[1],
    to: split2[0],
    scope: split2[1].toLowerCase(),
    type: split2[2].toLowerCase(),
  };
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case PREPARE_COUNT_WORDS: {
      return {
        ...state,
        prepare_word_count_pending: true,
        bulk_rewind_settings: false,
        total_words: 0,
        count_progress: 0,
        count_tasks: {},
        translate_progress: 0,
        translate_tasks: {}
      }
    }
    case COUNT_WORDS_PENDING: {
      return {
        ...state,
        prepare_word_count_pending: false,
        count_words_pending: true,
      }
    }
    case COUNT_WORDS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          //count_words_pending: false,
          error: {
            message: action.error,
            level: 0,
          },
        }
      }
    }



    case TRANSLATE_WORDS_PENDING: {
      return {
        ...state,
        translate_progress: 0,
        translate_words_pending: true,
        count_words_pending: false,
        error: false,
        task_errors: [],
        unfinished_process: true,
      }
    }
    case TRANSLATE_WORDS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          //translate_words_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }


    case GET_RUNNING_TASKS_RESPONSE: {
      if(state.prepare_word_count_pending) {
        return {
          ...state
        }
      }


      // Update the countProcesses array
      let countTasks = {};
      let translateTasks = {};
      let new_is_task_querying = false;

      if(action.response.success && action.response.tasks) {
        for(let [task_key, task_value] of Object.entries(action.response.tasks)) {
          // 1. Seperate count and translate tasks
          if(task_key.indexOf('_COUNT') > 0) {
            countTasks[task_key] = task_value;
            if(task_value.bulk_operation) {
              if(task_value.bulk_operation.type === 'QUERY' && task_value.bulk_operation.status === 'RUNNING') {
                new_is_task_querying = true;
              }
            }
          }
          if(task_key.indexOf('_TRANSLATE') > 0) {
            translateTasks[task_key] = task_value;
          }
        }
      }

      
      let all_total_words = sumBy(Object.entries(countTasks), 'WordCount');
      let all_count_jobs_done = sumBy(Object.entries(countTasks), 'jobs_done');
      let all_count_jobs_count = sumBy(Object.entries(countTasks), 'jobs_count');
      let new_count_progress = Math.round(all_count_jobs_done / all_count_jobs_count * 100);
      let new_count_words_pending = false;
      
      let all_translate_jobs_done = sumBy(Object.entries(translateTasks), 'jobs_done');
      let all_translate_jobs_count = sumBy(Object.entries(translateTasks), 'jobs_count');
      let new_translate_progress = Math.round(all_translate_jobs_done / all_translate_jobs_count * 100);
      let new_translate_words_pending = false;
      
      if(Object.values(countTasks).find(x => x.status === 'running')) {
        new_count_words_pending = true;
      }
      if(Object.values(translateTasks).find(x => x.status === 'running')) {
        new_translate_words_pending = true;
      }

      let new_unfinished_process = false;
      if(Object.values(translateTasks).length > 0 || window.localStorage.getItem('ly_bulk_translation:out_of_sync')) {
        new_unfinished_process = true;
      }

      return {
        ...state,
        total_words: all_total_words,
        count_tasks: countTasks,
        count_progress: new_count_progress ? new_count_progress : 0,
        count_words_pending: new_count_words_pending,
        translate_tasks: translateTasks,
        translate_progress: new_translate_progress ? new_translate_progress : 0,
        translate_words_pending: new_translate_words_pending,
        all_count_jobs_count: all_count_jobs_count,
        all_count_jobs_done: all_count_jobs_done,
        all_translate_jobs_count: all_translate_jobs_count,
        all_translate_jobs_done: all_translate_jobs_done,
        unfinished_process: new_unfinished_process,
        is_task_querying: new_is_task_querying,
        //task_errors: all_errors,
      }
    }

    case CLEAR_TASKS_RESPONSE: {
      return {
        ...state,
        total_words: 0,
        count_words_pending: false,
        count_progress: 0,
        count_tasks: {},
        translate_words_pending: false,
        translate_progress: 0,
        translate_tasks: {},
        all_count_jobs_count: 0,
        all_count_jobs_done: 0,
        all_translate_jobs_count: 0,
        all_translate_jobs_done: 0,
      }
    }

    case REWIND_BULK_SETTINGS: {
      return {
        ...state,
        bulk_rewind_settings: true,
        unfinished_process: false,
      }
    }

    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const prepareCountWords = () => {
  return dispatch => {
    dispatch({
      type: PREPARE_COUNT_WORDS
    });
  }
}
export const bulkCountWords = (store, from, to, scope, subObject, gids, outdated, callback) => {
  return dispatch => {
    dispatch({
      type: COUNT_WORDS_PENDING,
      store: store,
      from: from,
      to: to,
      scope: scope
    })

    API.bulkCountStart(
      store, 
      from, 
      to,
      scope,
      subObject,
      gids,
      outdated,
      response => {
        dispatch({
          type: COUNT_WORDS_RESPONSE,
          response: response,
          scope: scope
        })
        callback(response);
    })
  }
}
export const bulkTranslationStart = (store, tasks, callback) => {  
  return async dispatch => {

    for(const entry of Object.entries(tasks)) {

      if(entry[1].WordCount > 0) {
        dispatch({
          type: TRANSLATE_WORDS_PENDING,
          store: store,
          key: entry[1].key,
        })
    
        await API.bulkTranslationStart(
          store, 
          entry[1].key,
          response => {
            dispatch({
              type: TRANSLATE_WORDS_RESPONSE,
              response: response,
            });
            callback(response);
        })
      } else {
        await API.bulkTranslationClearTask(entry[0]);
      }
    }    
  }
}
export const bulkTranslationGetTasks = (callback) => {
  return dispatch => {
    dispatch({
      type: GET_RUNNING_TASKS_PENDING
    });

    API.bulkTranslationGetRunningTasks(
      response => {
        dispatch({
          type: GET_RUNNING_TASKS_RESPONSE,
          response: response
        });
        callback(response);
      }
    )
  }
}
export const bulkTranslationClearTasks = (tasks, callback) => {
  return async dispatch => {
    dispatch({
      type: CLEAR_TASKS_PENDING
    });
    for(const entry of Object.entries(tasks)) {
      await API.bulkTranslationClearTask(entry[0]);
    }
    dispatch({
      type: CLEAR_TASKS_RESPONSE
    });
    if(typeof callback === 'function') {
      callback();
    }
  }
}
export const bulkTranslationRewind = () => {
  return dispatch => {
    dispatch({
      type: REWIND_BULK_SETTINGS
    });
  }
}