import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Grid,
  Divider
} from '@material-ui/core';

import ProfileMenu from '../ProfileMenu.js';
import { PlanSelect } from "../../../components/PricingPlans.js"

  
const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  label: {
      width: 200,
      background: '#fff',
      borderRight: 'solid 1px #ccc',
      position: 'sticky',
      left: 0,
      zIndex: 1
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12
  }
});


const SubscriptionPage = ({ classes }) => {

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems="flex-start" className="page-content">
        <Grid item xs={12}>
          <ProfileMenu />
        </Grid>
        <Grid item xs={12} className="page-content">
          <Typography variant="h4" component="h2">Subscription Plans</Typography>
          <Divider className="divider-white" />
        </Grid>
        {/*<Grid item xs={12}>
          <Paper className={classes.paper}>
            Lorem ipsum dolor sed amit
          </Paper>
        </Grid>*/}
        <Grid item xs={12} style={{paddingTop: 0}}>
          <PlanSelect />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(SubscriptionPage);
