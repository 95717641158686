import API from '../api/api'

const SYNC_ITEM_PENDING = 'SYNC_ITEM_PENDING'
const SYNC_ITEM_RESULT = 'SYNC_ITEM_RESULT'

const initialState = {
  pending: false,
  result: null,
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case SYNC_ITEM_PENDING: {
      return {
        ...state,
        pending: true
      }
    }
    case SYNC_ITEM_RESULT: {
      if(action.response.success === true) {
        return {
          ...state,
          result: action.response.result,
          pending: false
        }
      } else {
        return {
          ...state,
          pending: false
        }
      }
    }
    default:
      return state
  }
}

export const syncProductByShopifyId = (id) => { 
  return dispatch => {
    dispatch({
      type: SYNC_ITEM_PENDING
    })

    API.syncProductByShopifyId(
      id,
      response => {
        dispatch({
          type: SYNC_ITEM_RESULT,
          response: response
        })
      }
    )
  }
}

export const syncCollectionByShopifyId = (id) => { 
  return dispatch => {
    dispatch({
      type: SYNC_ITEM_PENDING
    })

    API.syncCollectionByShopifyId(
      id,
      response => {
        dispatch({
          type: SYNC_ITEM_RESULT,
          response: response
        })
      }
    )
  }
}

export const syncPageByShopifyId = (id) => { 
  return dispatch => {
    dispatch({
      type: SYNC_ITEM_PENDING
    })

    API.syncPageByShopifyId(
      id,
      response => {
        dispatch({
          type: SYNC_ITEM_RESULT,
          response: response
        })
      }
    )
  }
}

export const syncArticleByShopifyId = (id) => { 
  return dispatch => {
    dispatch({
      type: SYNC_ITEM_PENDING
    })

    API.syncArticleByShopifyId(
      id,
      response => {
        dispatch({
          type: SYNC_ITEM_RESULT,
          response: response
        })
      }
    )
  }
}