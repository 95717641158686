import React, { Component } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import API from '../../api/api.js'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Trans, withTranslation, useTranslation } from 'react-i18next';



class Admin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      richtextEditorVersion: 'v2',
      richtextEditorOnParagraphFields: false,
      importV1ProductMetafieldsProgress: 0,
    };
  }

  onImportV1ProductMetafieldTranslations = () => {
  	const editLanguage = this.props.editLanguage;

    this.setState({
      importV1ProductMetafieldsProgress: 1,
    });

  	API.syncProducts(
  		editLanguage,
  		progress => {
  			console.log('sync products: ' + progress)
  		},
      response => {
        if(response.success == true) {
          this.syncProductMetafields(
            editLanguage,
            response.products.slice(0, 10),
            0,
            progress => {
              console.log('import translations: ' + progress);
              this.setState({
                importV1ProductMetafieldsProgress: progress,
              });
            },
            result => {
              console.log(result);
              this.setState({
                importV1ProductMetafieldsProgress: 100,
              });
            }
          )
        }
      }
    );
  };

  syncProductMetafields = (language, products, index, progress, result) => {
  	if(index >= products.length - 1) {
  		result('!');
  	} else {
	  	API.syncProductMetafields(
	  		products[index].id,
	  		language,
	  		progress => {},
	  		response => {
	  			if(response.success == true && response.metafields.length > 0) {
					API.importV1ProductMetafieldTranslations(
						language,
						products[index].id,
						response.metafields,
						progress => {},
						response => {
				  			progress(Math.ceil(index / (products.length - 1) * 100));
				  			this.syncProductMetafields(language, products, index + 1, progress, result);
						}
					);
	  			} else {
		  			progress(Math.ceil(index / (products.length - 1) * 100));
		  			this.syncProductMetafields(language, products, index + 1, progress, result);
	  			}
	  		}
  		)
  	}
  };


  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Support Admin
        </Typography>
        <Divider className="divider-white" />        
        <Paper className={classes.root} elevation="1">
          

          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Import V1 Product Metafield Translations</FormLabel>
            <Divider style={{margin: '8px 0'}} />
            <Typography>
              Imports existing langify v1 metafield translations into v2 <br />
              <Button 
                onClick={e => {this.onImportV1ProductMetafieldTranslations()}} 
                disabled={(this.state.importV1ProductMetafieldsProgress > 0)}
                variant="contained" 
                color="secondary" 
                fullWidth={false}>
                {this.state.importV1ProductMetafieldsProgress < 100 ? 'Import' : 'Done'}
                {(this.state.importV1ProductMetafieldsProgress > 0 && this.state.importV1ProductMetafieldsProgress < 100) &&
                  <CircularProgress size={20} variant="determinate" />
                }
              </Button>
            </Typography>
          </FormControl>

        </Paper>

      </React.Fragment> 
    )
  }
}

const mapStateToProps = ({ shop }) => ({
  editLanguage: shop.shop.edit_language
})

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
    padding: 24,
  },
  subtext: {
    padding: 2,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  content: {
    color: '#999'
  },
  spacer: {
    height: 24
  }
})

export default withRouter(connect(
  mapStateToProps,
  null,
)(withStyles(styles)(Admin)))