import API from '../api/api'
import {_exportDownloadLinkDecorator} from '../helper'

const SYNC_THEME_APP_EXT_PENDING = 'SYNC_THEME_APP_EXT_PENDING'
const SYNC_THEME_APP_EXT_RESPONSE = 'SYNC_THEME_APP_EXT_RESPONSE'
const CHECK_THEME_PUBLISH_STATE_PENDING = 'CHECK_THEME_PUBLISH_STATE_PENDING'
const CHECK_THEME_PUBLISH_STATE_RESPONSE = 'CHECK_THEME_PUBLISH_STATE_RESPONSE'

const initialState = {
	sync_theme_app_extension_pending: false,
	langify_code_in_theme: null,
	langify_app_embed_code_in_theme: null,
	synced: false,
	uuid: null,
	app: '',
	disabled: '',
	settings: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case SYNC_THEME_APP_EXT_PENDING: {
      return {
        ...state,
        sync_theme_app_extension_pending: true,
      }
    }
    case SYNC_THEME_APP_EXT_RESPONSE: {
      if(action.response.success === true) {
        let schemaData = JSON.parse(action.response.value.value);
        let data = {};
        const regex = /shopify:\/\/apps\/([^\/]+)\/blocks\/langify\/([0-9a-f]{8}-[0-9a-f]{4}-[4-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;
        for (let key in schemaData.current.blocks) {
          if (schemaData.current.blocks.hasOwnProperty(key)) {
            const block = schemaData.current.blocks[key]
            const match = block.type.match(regex);
            if(match) {
              data = {
                uuid: match[2],
                app: match[1],
                disabled: block.disabled,
                settings: block.settings
              }
            }
          }
        }
        return {
          ...state,
          ...data,
          synced: true,
          sync_theme_app_extension_pending: false,
        }
      } else {
        return {
          ...state,
          sync_theme_app_extension_pending: false,
        }
      }
    }
		case CHECK_THEME_PUBLISH_STATE_PENDING: {
      return {
        ...state,
        sync_theme_app_extension_pending: true,
      }
		}
		case CHECK_THEME_PUBLISH_STATE_RESPONSE: {
			const lyDelimiter = '{% comment %}ly_liquid_scripts_begin{% endcomment %}{% capture ly_liquid_content %}{% comment %}ly_liquid_scripts_end{% endcomment %}<!doctype html>';
			const lyPolyfillCodeDelimiter = '{% comment %}ly_liquid_scripts_begin{% endcomment %}{% endcapture %}{%- comment -%}Version';
			if(action.response.success && action.response.value) {
				return {
					...state,
					sync_theme_app_extension_pending: false,
					langify_code_in_theme: (action.response.value.value.indexOf(lyDelimiter) > -1 && action.response.value.value.indexOf(lyPolyfillCodeDelimiter) === -1) ? true : false,
					langify_app_embed_code_in_theme: (action.response.value.value.indexOf(lyPolyfillCodeDelimiter) > -1) ? true : false
				}       
			} else {
        return {
          ...state,
          sync_theme_app_extension_pending: false,
        }
			}	
		}
    default:
      return state
  }
}





// ACTIONS
export const syncThemeAppExtension = (themeId, callback) => { 
  return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: SYNC_THEME_APP_EXT_PENDING
			})
			const data = {
				key: 'config/settings_data.json',
			};
			API.getCustomAsset(
				themeId,
				data,
				response => {
					dispatch({
						type: SYNC_THEME_APP_EXT_RESPONSE,
						response: response,
					});
					if(callback)
						callback(response);
				}
			)
      resolve();
		});
	}
}

export const checkThemePublishState = (themeId, callback) => {
	const data = {
		key: 'layout/theme.liquid',
	};
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: CHECK_THEME_PUBLISH_STATE_PENDING
			})
			API.getCustomAsset(
				themeId, 
				data, 
				response => {
					dispatch({
						type: CHECK_THEME_PUBLISH_STATE_RESPONSE,
						response: response
					});
					if(callback)
						callback(response);
			});
      resolve();
		});
	}
}