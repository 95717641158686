import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ImportIcon from '@material-ui/icons/GetApp';
import ExportIcon from '@material-ui/icons/Publish';
import ResaveIcon from '@material-ui/icons/Replay';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SyncIcon from '@material-ui/icons/Sync';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { UpgradePlanHint } from './PricingPlans';

const styles = theme => ({
  root: {position: 'relative', float: 'right', marginLeft: 5, color: '#ffffff', bottom: 7, right: 0},
  inlined: {position: 'absolute', right: 12, top: 4, padding: 8},
});

class OptionsMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null
    }
  }

  openMenu = event => {
    this.setState({ anchorElement: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorElement: null });
  };


  render() {
    const { classes } = this.props;
    return <React.Fragment>
    <IconButton classes={{root: this.props.inlined ? classes.inlined : classes.root}}
      aria-owns={this.state.anchorElement ? 'options-menu' : undefined}
      aria-haspopup="true"
      aria-label="Options list"
      onClick={this.openMenu}
    >
      <MoreVertIcon />
    </IconButton>

    <Menu
      id="options-menu"
      anchorEl={this.state.anchorElement}
      open={Boolean(this.state.anchorElement)}
      onClose={this.closeMenu}
      disableAutoFocusItem
      //dense
      style={{ top: 0, right: 12 }}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {this.props.onResaveAll ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onResaveAll();}}><ResaveIcon style={{marginRight:'10px'}}/>{t('system.resaveAll')}</MenuItem>) : null}
      {this.props.onResyncAll ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onResyncAll();}}><SyncIcon style={{marginRight:'10px'}}/>{t('system.resyncAll')}</MenuItem>) : null}
      {this.props.onClearAll ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onClearAll();}}><ClearAllIcon style={{marginRight:'10px'}}/>{t('system.clearAll')}</MenuItem>) : null}
      {this.props.onMigrateConfig ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onMigrateConfig();}}><MergeTypeIcon style={{marginRight:'10px', transform: 'rotate(180deg)'}}/>{t('system.migrateConfiguration')}</MenuItem>) : null}
      {this.props.onRevertConfig ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onRevertConfig();}}><RestorePageOutlinedIcon style={{marginRight:'10px'}}/>{t('system.revertConfiguration')}</MenuItem>) : null}

      <div style={{position: 'relative'}}>
        {this.props.onExport ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onExport();}} disabled={this.props.shopPlan === 'free' ? true : false} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><SaveAltRoundedIcon style={{marginRight:'10px'}}/> {this.props.exportLabel ? this.props.exportLabel : t('system.exportTranslations') }</MenuItem>) : null}
        {this.props.onExport && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay/>}
      </div>
      <div style={{position: 'relative'}}>
        {this.props.onImport ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onImport();}} disabled={this.props.shopPlan === 'free' ? true : false} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><ExitToAppRoundedIcon style={{marginRight:'10px'}}/>{this.props.importLabel ? this.props.importLabel : t('system.importTranslations')}</MenuItem>) : null}
        {this.props.onImport && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay />}
      </div>
      <div style={{position: 'relative'}}>
        {this.props.onImportFile ? (<MenuItem onClick={() => {this.import.click();}} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><input style={{display: 'none'}} accept={this.props.jsonFormat ? '.json' : '.mo,.csv'} ref={(ref) => this.import = ref} type="file" onChange={(event) => {this.closeMenu(); this.props.onImportFile(event)}} disabled={this.props.shopPlan === 'free' ? true : false}/><ExitToAppRoundedIcon style={{marginRight:'10px'}}/>{this.props.importLabel ? this.props.importLabel : t('system.importTranslations')}</MenuItem>) : null }
        {this.props.onImportFile && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay />}
      </div>
      <div style={{position: 'relative'}}>
        {this.props.onExportMetafields ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onExportMetafields();}} disabled={this.props.shopPlan === 'free' ? true : false} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><SaveAltRoundedIcon style={{marginRight:'10px'}}/>{t('system.exportMetafieldTranslations')}</MenuItem>) : null }
        {this.props.onExportMetafields && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay />}
      </div>
      <div style={{position: 'relative'}}>
        {this.props.onImportMetafields ? (<MenuItem onClick={() => {this.importMetafields.click();}} disabled={this.props.shopPlan === 'free' ? true : false} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><input style={{display: 'none'}} accept={this.props.jsonFormat ? '.json' : '.mo,.csv'} ref={(ref) => this.importMetafields = ref} type="file" onChange={(event) => {this.closeMenu(); this.props.onImportMetafields(event)}}/><ExitToAppRoundedIcon style={{marginRight:'10px'}}/>{t('system.importMetafieldTranslations')}</MenuItem>) : null }
        {this.props.onImportMetafields && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay />}
      </div>
      <div style={{position: 'relative'}}>
        {this.props.onBulkTranslateAll ? (<MenuItem onClick={() => {this.closeMenu(); this.props.onBulkTranslateAll();}} disabled={this.props.shopPlan === 'free' ? true : false} style={{paddingRight: this.props.shopPlan === 'free' ? 80 : 16}}><ClearAllIcon style={{marginRight:'10px'}}/>{t('system.bulkTranslate')}</MenuItem>) : null}
        {this.props.onBulkTranslateAll && this.props.shopPlan === 'free' && <UpgradePlanHint neededPlan={'basic'} position="end" placement="left" top={0} isBlock isOverlay />}
      </div>
    </Menu>
    </React.Fragment>
  }
}

export default withStyles(styles)(withTranslation()(OptionsMenu));
